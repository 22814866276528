import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Injectable()
export class PreviousRouteService {

  private isFromCompare: boolean = false;
  private currentUrl: string;

  constructor(private router: Router) {
    // this.currentUrl = this.router.url;
    // router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {        
    //     this.previousUrl = this.currentUrl;
    //     this.currentUrl = event.url;
    //   };
    // });
  }

  public getIsFromCompare() {
    return this.isFromCompare;
  }

  public setIsFromCompare(isFromCompare) {
    return isFromCompare ? this.isFromCompare = true : this.isFromCompare = false;
  }
}
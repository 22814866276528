import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'back-to-top',
  templateUrl: './back-to-top.component.html',
  styleUrls: ['./back-to-top.component.scss']
})
export class BackToTopComponent implements OnInit {
  showBackToTop: boolean = false;

  constructor() { }

  ngOnInit() {
	  window.addEventListener('scroll', () => {this.onScroll();}, true);
  }
  
  onScroll() {
	this.showBackToTop = false;
	if (window.pageYOffset > 0) {
		this.showBackToTop = true;
	}
  }
  
  onClick() {
	window.scroll(0, 0); 
  }

}

import { Component, OnInit, OnChanges, Input, Output, EventEmitter, ViewEncapsulation, ViewChild } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'popover',
  template: `
    <div *ngIf="screenwidth === 'LG' || screenwidth === 'XL'">
      <div class="tooltip 7 " [ngClass]="{'tooltip-active': expanded}" style="margin-left: 10px; margin-top: -12px;">
        <span class="login-prompt" [ngClass]="type === 'Game Bundle' ? 'bundle-width' : 'availability-width'">
          <span *ngIf="bundle" class="text" style="line-height: 18px;">
            {{bundle}}
          </span>
          <span *ngIf="availability" class="text" style="line-height: 18px;">
            {{availability}}
          </span>
        </span>
        <div class="tooltip-pointer" style="margin-left: 0px;"></div>
      </div>
      <div class="18" (mouseover)="expanded=true;" (mouseleave)="expanded=false;">
        <span [ngClass]="type === 'Game Bundle' ? 'bundle-icon' : 'info-icon'">{{offerLabel}}</span>
      </div>
    </div>

    <div *ngIf="screenwidth === 'SM' || screenwidth === 'MD'">
      <div class="tooltip 24" [ngClass]="{'tooltip-active': expanded}" style="margin-left: 10px; margin-top: -12px;">
        <span class="login-prompt" [ngClass]="type === 'Game Bundle' ? 'bundle-width' : 'availability-width'">
          <span *ngIf="bundle" class="text" style="line-height: 18px;">
            {{bundle}}
          </span>
          <span *ngIf="availability" class="text" style="line-height: 18px;">
            {{availability}}
          </span>
        </span>
        <div class="tooltip-pointer" style="margin-left: 0px;"></div>
      </div>
      <div class="35" (click)="toggle()">
        <span [ngClass]="type === 'Game Bundle' ? 'bundle-icon' : 'info-icon'">{{offerLabel}}</span>
      </div>
    </div>
  `
})
export class PopoverComponent implements OnInit {
  @Input() screenwidth: string;
  @Input() type: string;
  @Input() bundle?: string;
  @Input() availability?: string;
  @Input() data?: string;
  @Input() offerLabel: string;

  // @Output() viewType = new EventEmitter<Event>();
  private expanded;

  constructor(private translate: TranslateService) {
  }

  ngOnInit() {
    this.expanded = false;

  }

  toggle(){
    this.expanded = !this.expanded;
  }

}

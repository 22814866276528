import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';


@Component({
  selector: "load-more",
  template: `
    <div *ngIf="hideFlag && totalProducts > 0 && ((isFeaturedProductIncludedInCount && (page + 1) * limit < totalProducts) 
    || (!isFeaturedProductIncludedInCount && page * limit < totalProducts))" class="load-more-container">
      <button
        kuiButton
        [size]="'large'"
        [danger]="false"
        [type]="'primary'"
        class="buy-link load-more-btn cta-link-large"
        (click)="loadMore($event)"
      >
        {{ "load_more" | translate }}
      </button>
    </div>

    <div
      class="legalinfo"
      *ngIf="
        locale === 'en-us' ||
        locale === 'en-gb' ||
        locale === 'de-de' ||
        locale === 'de-at' ||
        locale === 'fr-fr' ||
        locale === 'fr-ca' ||
        locale === 'en-ca' ||
        locale === 'pl-pl' ||
        locale === 'es-es' ||
        locale === 'nl-nl' ||
        locale === 'it-it' ||
        locale === 'zh-tw' ||
        locale === 'id-id' ||
        locale === 'vi-vn'
       "
    >
      {{ "msrp_note" | translate }}
    </div>
  `,
})
export class LoadMoreComponent implements OnInit {
  @Input() page: number;
  @Input() limit: number;
  @Input() totalProducts: number;
  @Input() isFeaturedProductIncludedInCount: boolean;
  @Input() locale: string;
  @Output() onPaging = new EventEmitter();
  hideFlag: boolean = true; 

  constructor() {}

  ngOnInit() {}

  loadMore(event) {
    console.log(this.page);
    event.preventDefault();
    console.log('limit',this.limit)
    const totalPage = Math.round(this.totalProducts/this.limit);
    if (totalPage&&(totalPage>=this.page+1)) {
      this.onPaging.emit({
        value: {
          page: +this.page + 1,
        },
      });
    }
    else{
      this.hideFlag = false
    }
    window["hatchwidgetHelpers"].nvbuyinit();
    event.target.blur();
  }
}

import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { NouisliderModule } from 'ng2-nouislider';

import { ProductComponent } from './product.component';
import { ProductFiltersComponent } from './product-filters/product-filters.component';
import { ProductChipsFiltersComponent } from './product-chips-filters/product-chips-filters.component';
import { RatingComponent } from '../shared/components/rating-generator.component';

import { RangeSliderComponent } from '../shared/components/range-slider.component';
import { FeaturedProductComponent } from './featured-product/featured-product.component';
import { ProductDetailsComponent } from './product-details/product-details.component';
import { SearchComponent } from './search/search.component';
import { ProductFilterCrumbsComponent } from './product-filter-crumbs/product-filter-crumbs.component';
import { CompareOverlayComponent } from './compare/compare-overlay/compare-overlay.component';
import { ComparePageComponent } from './compare/compare-page/compare-page.component';
import { CssLoadingIndicator } from '../shared/css-loading';
import { DrLoadingIndicator } from '../shared/dr-loading';
import { LoadMoreComponent } from '../shared/components/load-more';
import { HatchOverlayComponent } from './hatch-overlay/hatch-overlay.component';
import { SuggestedProduct } from './suggested-product/suggested-product.component';
import { FooterComponent } from '../shared/layout/footer/footer.component';
import { ModalComponent } from '../shared/components/modal.component';
import { ModalService } from '../shared/service/modal.service';
import { SimpleDropdown } from '../shared/components/simple-dropdown';
import { PreviousRouteService } from '../shared/service/previous-route.service';
import { PopoverComponent } from '../shared/components/popover.component';
import { NgxSelectModule } from "ngx-select-ex";
import { ProductPdpComponent } from './product-pdp/product-pdp.component';
import { TextModule } from "@kui-angular/text";
import { ImageModule } from "@kui-angular/image";
import { ButtonModule } from "@kui-angular/button";
import { RadioModule } from "@kui-angular/radio";
import { CheckboxModule } from "@kui-angular/checkbox";
import { CategoryHeaderComponent } from './category-header/category-header.component';

@NgModule({
  imports: [
    TextModule,
    ImageModule,
    RadioModule,
    ButtonModule,
    CheckboxModule,
    CommonModule,
    RouterModule,
    FormsModule,
    TranslateModule,
    NouisliderModule,
    ReactiveFormsModule,
    NgxSelectModule,
  ],
  exports: [],
  declarations: [
    ProductComponent,
    ProductFiltersComponent,
    ProductChipsFiltersComponent,
    RatingComponent,
    RangeSliderComponent,
    FeaturedProductComponent,
    ProductDetailsComponent,
    SearchComponent,
    ProductFilterCrumbsComponent,
    CompareOverlayComponent,
    ComparePageComponent,
    CssLoadingIndicator,
    DrLoadingIndicator,
    LoadMoreComponent,
    HatchOverlayComponent,
    SuggestedProduct,
    FooterComponent,
    ModalComponent,
    SimpleDropdown,
    PopoverComponent,
    ProductPdpComponent,
    CategoryHeaderComponent
  ],
  providers: [ModalService, PreviousRouteService],
  schemas: [NO_ERRORS_SCHEMA,CUSTOM_ELEMENTS_SCHEMA]
})
export class ProductModule { }


<div class="page-footer-wrapper" id="brandFooter">
    <div class="page-footer"
        *ngIf="locale === 'en-us'||locale === 'en-au'||locale === 'en-ca'||locale === 'en-gb'||locale === 'en-in'||locale === 'en-my'||locale === 'en-ph'||locale === 'en-sg'">
        <div class="page-footer__links">
            <div class="page-footer-link-set set-4" [ngStyle]="getFlinksStyles()">
                <div class="page-footer-link-set__title">Products</div>
                <ul  class="page-footer-link-set__links">
                    <li *ngIf="locale!='en-sg'"><a
                            [href]="'https://store.nvidia.com/' + locale + '/consumer/graphics-cards/?page=1&limit=9&locale='+locale+'&category=GPU'"
                            target="_self">Graphics Cards</a></li>
                    <li><a [href]="'https://store.nvidia.com/' + locale + '/consumer/gaming-laptops/?page=1&limit=9&locale='+locale+'&category=LAPTOP'"
                            target="_self">Gaming Laptops</a></li>
                    <li *ngIf="locale=='en-us' || locale=='en-gb' || locale=='fr-fr'"><a
                            [href]="'https://store.nvidia.com/' + locale + '/consumer/studio-laptops/?page=1&limit=9&locale='+locale+'&category=STUDIO-LAPTOP'"
                            target="_self">Studio Laptops</a></li>
                    <li *ngIf="locale!='en-au' && locale!='en-in' "><a
                            [href]="'https://store.nvidia.com/' + locale + '/consumer/gaming-desktops/?page=1&limit=9&locale='+locale+'&category=DESKTOP'"
                            target="_self">Gaming Desktops</a></li>
                    <li
                        *ngIf="locale!='en-au' && locale!='en-in' && locale!='en-sg' && locale!='en-my' && locale!='en-ph'">
                        <a [href]="'https://store.nvidia.com/' + locale + '/consumer/gaming-monitors/?page=1&limit=9&locale='+locale+'&category=MONITOR'"
                            target="_self">Gaming Monitors</a></li>
                    <li *ngIf="locale=='en-gb'"><a
                            [href]="'https://store.nvidia.com/' + locale + '/consumer/studio-desktops/?page=1&limit=9&locale='+locale+'&category=STUDIO_DESKTOP'"
                            target="_self">Studio Desktops</a></li>
                    <li *ngIf="locale=='en-us'"><a
                            [href]="'https://store.nvidia.com/' + locale + '/consumer/gfn/?page=1&limit=9&locale='+locale+'&category=GFN'"
                            target="_self">GFN Cloud Gaming</a></li>
                    <li *ngIf="locale=='en-us'"><a
                            [href]="'https://store.nvidia.com/' + locale + '/consumer/gaming-models/?page=1&limit=9&locale='+locale+'&category=MODELS'"
                            target="_self">Gaming Models</a></li>
                    <li *ngIf="locale=='en-us'"><a
                            [href]="'https://store.nvidia.com/' + locale + '/consumer/gaming-software/?page=1&limit=9&locale='+locale+'&category=GAMING_SOFTWARE'"
                            target="_self">Gaming Software</a></li>

                </ul>
            </div>
            <div class="page-footer-link-set set-4" [ngStyle]="getFlinksStyles()">
                <div class="page-footer-link-set__title">Deals</div>
                <ul class="page-footer-link-set__links">
                    <li><a target="_self"
                           [href]="'https://store.nvidia.com/' + locale + '/consumer/graphics-cards/?page=1&limit=9&locale='+locale+'&has_offer=discount&category=GPU'">Graphics
                            Cards</a></li>
                    <li><a [href]="'https://store.nvidia.com/' + locale + '/consumer/gaming-laptops/?page=1&amp;limit=9&amp;locale='+locale+'&amp;has_offer=discount&amp;category=LAPTOP'"
                            target="_self">Gaming Laptops</a></li>
                    <li><a [href]="'https://store.nvidia.com/' + locale + '/consumer/gaming-desktops/?page=1&limit=9&locale='+locale+'&has_offer=discount&category=DESKTOP'"
                            target="_self">Gaming Desktops</a></li>
                </ul>

            </div>
            <div class="page-footer-link-set set-4" [ngStyle]="getFlinksStyles()" *ngIf="locale === 'en-us'">
                <div class="page-footer-link-set__title">Support</div>
                <ul class="page-footer-link-set__links">
                    <li><a [href]="'https://buy.nvidia.com/store/nvidia/help?locale=' + locale" target="_self">NVIDIA
                            Products</a></li>
                    <li><a href="https://order.store.nvidia.com/support/" target="_blank">Partner Products</a></li>
                </ul>
            </div>

            <div class="page-footer-link-set set-4" [ngStyle]="getFlinksStyles()">
                <div class="page-footer-link-set__title">Company Info</div>
                <ul *ngIf="locale === 'en-us' ||locale === 'en-gb' ||locale === 'en-my'" class="page-footer-link-set__links">
                    <li><a [href]="'https://www.nvidia.com/'+ locale +'/about-nvidia/'" target="_self">About
                            NVIDIA</a></li>
                    <li><a [href]="'https://www.nvidia.com/'+ locale +'/about-nvidia/ai-computing/'" target="_self">Corporate
                            Overview</a></li>
                    <li><a [href]="'https://www.nvidia.com/'+ locale +'/about-nvidia/careers/'" target="_self">Careers</a></li>
                    <li><a [href]="'https://www.nvidia.com/'+ locale +'/foundation/'" target="_self">NVIDIA
                            Foundation</a></li>
                    <li><a href="https://investor.nvidia.com/" target="_blank">Investors</a></li>
                    <li><a [href]="'https://www.nvidia.com/'+ locale +'/research/'" target="_self">NVIDIA Research</a>
                    </li>
                    <li><a [href]="'https://www.nvidia.com/'+ locale +'/csr/'" target="_self">Social
                            Responsibility</a></li>
                    <li><a [href]="'https://www.nvidia.com/'+ locale +'/technologies/'" target="_self">Technologies</a></li>
                </ul>
                <ul *ngIf="locale === 'en-au'" class="page-footer-link-set__links">
                    <li><a [href]="'https://www.nvidia.com/'+ locale +'/about-nvidia/'" target="_self">About
                            NVIDIA</a></li>
                    <li><a [href]="'https://www.nvidia.com/en-us/about-nvidia/ai-computing/'" target="_self">Corporate Overview</a></li>
                    <li><a [href]="'https://www.nvidia.com/en-us/about-nvidia/careers/'" target="_self">Careers</a></li>
                    <li><a [href]="'https://www.nvidia.com/'+ locale +'/foundation/'" target="_self">NVIDIA
                            Foundation</a></li>
                    <li><a href="https://investor.nvidia.com/" target="_blank">Investors</a></li>
                    <li><a [href]="'https://www.nvidia.com/'+ locale +'/research/'" target="_self">NVIDIA Research</a>
                    </li>
                    <li><a [href]="'https://www.nvidia.com/'+ locale +'/csr/'" target="_self">Social
                            Responsibility</a></li>
                    <li><a [href]="'https://www.nvidia.com/'+ locale +'/technologies/'" target="_self">Technologies</a></li>
                </ul>
                <ul *ngIf="locale === 'en-ca'" class="page-footer-link-set__links">
                    <li><a [href]="'https://www.nvidia.com/en-us/about-nvidia/'" target="_self">About
                            NVIDIA</a></li>
                    <li><a [href]="'https://www.nvidia.com/en-us/about-nvidia/ai-computing/'" target="_self">Corporate Overview</a></li>
                    <li><a [href]="'https://www.nvidia.com/en-us/about-nvidia/careers/'" target="_self">Careers</a></li>
                    <li><a [href]="'https://www.nvidia.com/en-us/foundation/'" target="_self">NVIDIA
                            Foundation</a></li>
                    <li><a href="https://investor.nvidia.com/" target="_blank">Investors</a></li>
                    <li><a [href]="'https://www.nvidia.com/en-us/research/'" target="_self">NVIDIA Research</a>
                    </li>
                    <li><a [href]="'https://www.nvidia.com/en-us/csr/'" target="_self">Social
                            Responsibility</a></li>
                    <li><a [href]="'https://www.nvidia.com/en-us/technologies/'" target="_self">Technologies</a></li>
                </ul>
                <ul *ngIf="locale === 'en-in'" class="page-footer-link-set__links">
                    <li><a [href]="'https://www.nvidia.com/'+ locale +'/about-nvidia/'" target="_self">About
                            NVIDIA</a></li>
                    <li><a [href]="'https://www.nvidia.com/'+ locale +'/about-nvidia/ai-computing/'" target="_self">Corporate
                            Overview</a></li>
                    <li><a [href]="'https://www.nvidia.com/'+ locale +'/about-nvidia/careers/'" target="_self">Careers</a></li>
                    <li><a [href]="'https://www.nvidia.com/'+ locale +'/foundation/'" target="_self">NVIDIA
                            Foundation</a></li>
                    <li><a href="https://investor.nvidia.com/" target="_blank">Investors</a></li>
                    <li><a [href]="'https://www.nvidia.com/en-us/research/'" target="_self">NVIDIA Research</a>
                    </li>
                    <li><a [href]="'https://www.nvidia.com/'+ locale +'/csr/'" target="_self">Social
                            Responsibility</a></li>
                    <li><a [href]="'https://www.nvidia.com/'+ locale +'/technologies/'" target="_self">Technologies</a></li>
                </ul>
                <ul *ngIf="locale === 'en-ph'" class="page-footer-link-set__links">
                    <li><a [href]="'https://www.nvidia.com/en-us/about-nvidia/'" target="_self">About
                            NVIDIA</a></li>
                    <li><a [href]="'https://www.nvidia.com/en-us/about-nvidia/ai-computing/'" target="_self">Corporate
                            Overview</a></li>
                    <li><a [href]="'https://www.nvidia.com/en-us/about-nvidia/careers/'" target="_self">Careers</a></li>
                    <li><a [href]="'https://www.nvidia.com/en-us/foundation/'" target="_self">NVIDIA
                            Foundation</a></li>
                    <li><a href="https://investor.nvidia.com/" target="_blank">Investors</a></li>
                    <li><a [href]="'https://www.nvidia.com/en-us/research/'" target="_self">NVIDIA Research</a>
                    </li>
                    <li><a [href]="'https://www.nvidia.com/'+ locale +'/csr/'" target="_self">Social
                            Responsibility</a></li>
                    <li><a [href]="'https://www.nvidia.com/en-us/technologies/'" target="_self">Technologies</a></li>
                </ul>
                <ul *ngIf="locale === 'en-sg'" class="page-footer-link-set__links">
                    <li><a [href]="'https://www.nvidia.com/'+ locale +'/about-nvidia/'" target="_self">About
                            NVIDIA</a></li>
                    <li><a [href]="'https://www.nvidia.com/en-us/about-nvidia/ai-computing/'" target="_self">Corporate
                            Overview</a></li>
                    <li><a [href]="'https://www.nvidia.com/en-us/about-nvidia/careers/'" target="_self">Careers</a></li>
                    <li><a [href]="'https://www.nvidia.com/'+ locale +'/foundation/'" target="_self">NVIDIA
                            Foundation</a></li>
                    <li><a href="https://investor.nvidia.com/" target="_blank">Investors</a></li>
                    <li><a [href]="'https://www.nvidia.com/en-us/research/'" target="_self">NVIDIA Research</a>
                    </li>
                    <li><a [href]="'https://www.nvidia.com/'+ locale +'/csr/'" target="_self">Social
                            Responsibility</a></li>
                    <li><a [href]="'https://www.nvidia.com/'+ locale +'/technologies/'" target="_self">Technologies</a></li>
                </ul>
            </div>
        </div>
        <div class="row footer-row">
            <div class="page-footer__subscribe col-md-6 col-sm-12">
                <div class="cq-dd-paragraph">
                    <div class="nv_rawhtml nv-rawhtml">
                        <div id="nv-rawhtml-51422112d4" class="general-container-text            ">
                            <div class="subscribe-container">
                                <div class="center-content">
                                    <div class="subscribe-logo hidden-xs"><svg xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink" width="48" height="36"
                                            viewBox="0 0 48 36">
                                            <image id="icon_mail_copy" data-name="icon mail copy" width="48" height="36"
                                                xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAkCAMAAAD4m0k4AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABnlBMVEWHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgAAAADqIFB3AAAAiHRSTlMAPqKqqKucL9zD8v383e77xqnO2fBFYfRSbfUDDgIPDF6QUA0SWJMIQI4qAQQznT8XHW7k6DEKadYFCUq3z1kGMqPt6XokK4fz55glwVQLX828VR6L4PeUNT2h/td9FUmv+tIWdtukcdCsTmU0md9/iY8HWr60TYLl2kOt+DoaE0djybDCPJ8umjQepgAAAAFiS0dEiRxhJswAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAHdElNRQfgChgTJSW4Gs96AAABYklEQVQ4y2NgZGJmIRqwsrEzcHSQBDgZuDo6uHmIBNwdHbwMfB38AoKsRAEWIZ4OYaAGEVEGIoGYOFgDlwSxGiSlKNAgLYNXqaycLKoGeQVFJWXc6lVU1STUUTRodHTwaGppY1euo6vX0SGii6JBXwQYKcJiBtjUGxoZAyVNTFE0mJlbgOJRUN8SXbmVtQ1IxtbOHi2UHBydgOLOLq6o6t3cPYDCnl7eWIJVTABklI+vH5Jn/QOAQvyBQdjjITgkFCgdFh4BUx8ZFQ0UiImNwxlx8QmJQBVJ5slg/SmpQI5UWiS+mFZJzwC5KxOYvLLAnhXItiKQNHJyQb7Myy9wBoVBoRbhtFRUXALLLaVlqFGJK/F5l1eAlFemBONJfKigqpq/hqkWQxhP8q5T9a1nIEUDdjCqYVBpEGkgVkMjRAN/E2szUYA5A1QYg4t7bqIATzSouE8lrUJpYWhtI6XKym0HAEPUyge2kF93AAAAAElFTkSuQmCC">
                                            </image>
                                        </svg></div>
                                    <div class="subscribe-text">
                                        <!-- <svg xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink" width="48" height="36"
                                            viewBox="0 0 48 36" class="hidden-md hidden-sm hidden-lg">
                                            <image id="icon_mail_copy" data-name="icon mail copy" width="48" height="36"
                                                xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAkCAMAAAD4m0k4AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABnlBMVEWHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgCHwgAAAADqIFB3AAAAiHRSTlMAPqKqqKucL9zD8v383e77xqnO2fBFYfRSbfUDDgIPDF6QUA0SWJMIQI4qAQQznT8XHW7k6DEKadYFCUq3z1kGMqPt6XokK4fz55glwVQLX828VR6L4PeUNT2h/td9FUmv+tIWdtukcdCsTmU0md9/iY8HWr60TYLl2kOt+DoaE0djybDCPJ8umjQepgAAAAFiS0dEiRxhJswAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAHdElNRQfgChgTJSW4Gs96AAABYklEQVQ4y2NgZGJmIRqwsrEzcHSQBDgZuDo6uHmIBNwdHbwMfB38AoKsRAEWIZ4OYaAGEVEGIoGYOFgDlwSxGiSlKNAgLYNXqaycLKoGeQVFJWXc6lVU1STUUTRodHTwaGppY1euo6vX0SGii6JBXwQYKcJiBtjUGxoZAyVNTFE0mJlbgOJRUN8SXbmVtQ1IxtbOHi2UHBydgOLOLq6o6t3cPYDCnl7eWIJVTABklI+vH5Jn/QOAQvyBQdjjITgkFCgdFh4BUx8ZFQ0UiImNwxlx8QmJQBVJ5slg/SmpQI5UWiS+mFZJzwC5KxOYvLLAnhXItiKQNHJyQb7Myy9wBoVBoRbhtFRUXALLLaVlqFGJK/F5l1eAlFemBONJfKigqpq/hqkWQxhP8q5T9a1nIEUDdjCqYVBpEGkgVkMjRAN/E2szUYA5A1QYg4t7bqIATzSouE8lrUJpYWhtI6XKym0HAEPUyge2kF93AAAAAElFTkSuQmCC">
                                            </image>
                                        </svg> -->
                                        <span>Sign Up for NVIDIA News</span>
                                    </div>
                                    <div class="button">
                                        <div class="nv-button btn-content" style="padding:0px"><a
                                                href="https://www.nvidia.com/en-us/preferences/email-signup/"
                                                class="btn-content btncta">
                                                <div class="btn-text">Subscribe</div>
                                            </a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="page-footer__social col-md-6 col-sm-12 p0">
                <div class="page-footer__social__label">Follow NVIDIA</div>
                <div><a href="http://www.facebook.com/NVIDIA" class="page-footer__social__link facebook" target="_blank"
                        title="<util:I18n key=&quot;Follow GeForce on Facebook&quot; />"><svg
                            xmlns="http://www.w3.org/2000/svg" width="22" height="46" viewBox="0 0 22 46">
                            <title>Facebook</title>
                            <path
                                d="M28.638,16.072V12.109a8.484,8.484,0,0,1,.085-1.381,2.648,2.648,0,0,1,.387-0.963,1.579,1.579,0,0,1,.974-0.656,7.1,7.1,0,0,1,1.791-.182h4.066V1h-6.5Q23.8,1,21.335,3.609T18.872,11.3v4.773H14V24h4.869V47h9.766V24h6.5L36,16.072h-7.36Z"
                                transform="translate(-14 -1)"></path>
                        </svg></a><a href="https://twitter.com/nvidiaomniverse" class="page-footer__social__link"
                        target="_blank" title="<util:I18n key=&quot;Follow GeForce on Twitter&quot; />"><svg
                            xmlns="http://www.w3.org/2000/svg" width="46" height="37.375" viewBox="0 0 46 37.375">
                            <title>Twitter</title>
                            <path
                                d="M47,9.749a18.8,18.8,0,0,1-5.429,1.461,9.159,9.159,0,0,0,4.145-5.2,18.5,18.5,0,0,1-5.985,2.278,9.449,9.449,0,0,0-13.56-.219,9.092,9.092,0,0,0-2.759,6.673A10.469,10.469,0,0,0,23.65,16.9,26.266,26.266,0,0,1,12.8,14a26.733,26.733,0,0,1-8.6-6.965,9.243,9.243,0,0,0-1.284,4.76,9.192,9.192,0,0,0,1.139,4.5,9.421,9.421,0,0,0,3.065,3.358,9.35,9.35,0,0,1-4.262-1.2v0.117a9.117,9.117,0,0,0,2.145,6,9.273,9.273,0,0,0,5.415,3.256,9.757,9.757,0,0,1-2.481.321A12.021,12.021,0,0,1,6.164,28a9.45,9.45,0,0,0,8.816,6.541,18.457,18.457,0,0,1-11.706,4.03A20.123,20.123,0,0,1,1,38.456,26.177,26.177,0,0,0,15.476,42.69,27.548,27.548,0,0,0,24.9,41.1a23.966,23.966,0,0,0,7.532-4.264,28.514,28.514,0,0,0,5.386-6.147,27.4,27.4,0,0,0,3.371-7.257A26.806,26.806,0,0,0,42.3,15.852q0-.818-0.029-1.227A19.746,19.746,0,0,0,47,9.749Z"
                                transform="translate(-1 -5.313)"></path>
                        </svg></a><a href="https://www.linkedin.com/showcase/nvidia-omniverse/"
                        class="page-footer__social__link" target="_blank"><svg xmlns="http://www.w3.org/2000/svg"
                            width="46" height="44" viewBox="0 0 46 44">
                            <title>LinkedIn</title>
                            <path
                                d="M1.569,16.3h9.883V45.985H1.569V16.3Zm42.317,2.711a10.635,10.635,0,0,0-8.235-3.4,11.875,11.875,0,0,0-3.43.464,7.783,7.783,0,0,0-2.605,1.3,11.16,11.16,0,0,0-2.86,3.142V16.3H16.9l0.03,1.438q0.03,1.438.03,8.866T16.9,45.985h9.853V29.421a7.242,7.242,0,0,1,.329-2.426,6.365,6.365,0,0,1,1.9-2.561,4.849,4.849,0,0,1,3.16-1.034,4.26,4.26,0,0,1,3.789,1.782,8.667,8.667,0,0,1,1.213,4.927V45.984H47V28.972Q47,22.412,43.886,19.014ZM6.57,2.015A5.637,5.637,0,0,0,2.542,3.468,4.823,4.823,0,0,0,1,7.137a4.909,4.909,0,0,0,1.5,3.654A5.4,5.4,0,0,0,6.45,12.258H6.51a5.642,5.642,0,0,0,4.058-1.468,4.742,4.742,0,0,0,1.512-3.654,5,5,0,0,0-1.527-3.669A5.475,5.475,0,0,0,6.57,2.015Z"
                                transform="translate(-1 -2)"></path>
                        </svg></a><a href="https://www.instagram.com/nvidiaomniverse/" class="page-footer__social__link"
                        target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="46" height="46"
                            viewBox="0 0 46 46">
                            <title>Instagram</title>
                            <path
                                d="M45.264,2.737A5.678,5.678,0,0,0,41.1,1H6.9A5.68,5.68,0,0,0,2.737,2.737,5.677,5.677,0,0,0,1,6.9V41.1a5.677,5.677,0,0,0,1.737,4.163A5.679,5.679,0,0,0,6.9,47H41.1A5.932,5.932,0,0,0,47,41.1V6.9A5.678,5.678,0,0,0,45.264,2.737ZM17.517,17.576a9.037,9.037,0,0,1,6.514-2.62,9.074,9.074,0,0,1,6.544,2.621,8.756,8.756,0,0,1,0,12.668,9.072,9.072,0,0,1-6.544,2.621,9.038,9.038,0,0,1-6.514-2.621A8.755,8.755,0,0,1,17.517,17.576Zm24.273,22.3a1.744,1.744,0,0,1-.539,1.3,1.784,1.784,0,0,1-1.288.524H7.948a1.791,1.791,0,0,1-1.827-1.827V20.466h4.222a12.892,12.892,0,0,0-.6,3.923A13.151,13.151,0,0,0,13.938,34.2a13.993,13.993,0,0,0,10.093,4.058A14.4,14.4,0,0,0,31.219,36.4a13.979,13.979,0,0,0,5.211-5.047,13.269,13.269,0,0,0,1.917-6.963,12.9,12.9,0,0,0-.6-3.923h4.043V39.873h0Zm0-26.744A2.046,2.046,0,0,1,39.723,15.2H34.513a2.045,2.045,0,0,1-2.066-2.066V8.188a2.011,2.011,0,0,1,.6-1.453,1.972,1.972,0,0,1,1.467-.614h5.211a1.971,1.971,0,0,1,1.468.614,2.009,2.009,0,0,1,.6,1.453v4.941Z"
                                transform="translate(-1 -1)"></path>
                        </svg></a><a href="https://www.youtube.com/channel/UCSKUoczbGAcMld7HjpCR8OA"
                        class="page-footer__social__link" target="_blank"><svg xmlns="http://www.w3.org/2000/svg"
                            width="38.125" height="46" viewBox="0 0 576 512">
                            <title>YouTube</title>
                            <path
                                d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z">
                            </path>
                        </svg></a></div>
            </div>
        </div>
    </div>
</div>
<div class="footer-wrapper">
    <div *ngIf="locale === 'en-us'||locale === 'en-au'||locale === 'en-ca'||locale === 'en-gb'||locale === 'en-in'||locale === 'en-my'||locale === 'en-ph'||locale === 'en-sg'"
        class="footer-inner footer-inner1">
        <!-- <div class="left-links">
                <div class="footer-item" role="button" aria-pressed="true" (click)="openModal('countries')">
                    <a href="javascript: void(0)" target="_self">
                    <span class="country-select">USA - United States</span>
                    </a>
                </div>
                <div class="footer-item">
                    <a href="https://www.nvidia.com/en-us/about-nvidia/privacy-policy" target="_blank" rel="noopener">
                        <span>Privacy</span>
                    </a>
                </div>
                <div class="footer-item">
                    <a href="https://www.nvidia.com/en-us/about-nvidia/terms-of-service/" target="_blank" rel="noopener">
                        <span>Terms of Service</span>
                    </a>
                </div>
                <div class="footer-item">
                    <a href="https://www.nvidia.com/en-us/contact" target="_blank" rel="noopener">
                        <span>Contact</span>
                    </a>
                </div>
            </div> -->

        <div class="global-footer-container">
            <div class="global-footer " id="globalFooter"><svg class="global-footer__logo"
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 407.8 1000 184.4"
                    enable-background="new 0 407.8 1000 184.4" xml:space="preserve">
                    <title>NVIDIA</title>
                    <g id="nvidia-logo">
                        <path
                            d="M987.1 555.7v-2.8h1.7c0.9 0 2.3 0.1 2.3 1.2 0 1.2-0.7 1.5-1.8 1.5H987.1M987.1 557.6h1.2l2.8 4.8h3l-3.1-5c1.5-0.1 2.9-0.8 2.9-3 0-2.7-1.8-3.5-4.9-3.5h-4.4v11.5h2.6L987.1 557.6M1000 556.7c0-6.8-5.2-10.7-11.1-10.7 -5.8 0-11.1 3.9-11.1 10.7 0 6.8 5.2 10.7 11.1 10.7S1000 563.5 1000 556.7M996.8 556.7c0 4.9-3.6 8.2-7.9 8.2l0 0c-4.4 0-7.9-3.3-7.9-8.2 0-4.9 3.6-8.2 7.9-8.2C993.2 548.6 996.8 551.9 996.8 556.7z">
                        </path>
                        <path
                            d="M593.2 442.7v121.1h34.2V442.7H593.2zM324.4 442.5v121.2h34.5v-94.1l26.9 0.1c8.8 0 15 2.2 19.2 6.7 5.4 5.7 7.6 15.1 7.6 32v55.3H446v-66.9c0-47.8-30.5-54.3-60.2-54.3C385.8 442.5 324.4 442.5 324.4 442.5zM648.3 442.7v121.1h55.4c29.5 0 39.2-4.9 49.6-15.9 7.4-7.7 12.1-24.7 12.1-43.3 0-17-4-32.2-11.1-41.7 -12.5-16.9-30.8-20.2-58.1-20.2H648.3zM682.2 469h14.7c21.3 0 35.1 9.5 35.1 34.4 0 24.8-13.7 34.4-35.1 34.4h-14.7V469zM544 442.7l-28.5 95.9 -27.3-95.9h-36.9l39 121.1h49.2l39.3-121.1H544zM781.4 563.7h34.2v-121h-34.2V563.7zM877.2 442.7l-47.7 121h33.7l7.6-21.3h56.5l7.2 21.3h36.6l-48.2-121H877.2zM899.4 464.8l20.7 56.6H878L899.4 464.8z">
                        </path>
                        <path
                            d="M103.9 462.8v-16.6c1.6-0.1 3.3-0.2 4.9-0.2 45.6-1.4 75.4 39.1 75.4 39.1s-32.2 44.8-66.8 44.8c-5 0-9.4-0.8-13.4-2.2v-50.5c17.7 2.2 21.3 10 32 27.7l23.7-20c0 0-17.3-22.7-46.5-22.7C110 462.3 106.9 462.5 103.9 462.8M103.9 407.8v24.8c1.6-0.1 3.3-0.2 4.9-0.3 63.3-2.2 104.6 51.9 104.6 51.9s-47.4 57.7-96.7 57.7c-4.5 0-8.7-0.4-12.7-1.1v15.4c3.4 0.4 7 0.7 10.6 0.7 46 0 79.2-23.5 111.4-51.2 5.3 4.3 27.2 14.7 31.7 19.2 -30.6 25.6-101.9 46.3-142.3 46.3 -3.9 0-7.6-0.2-11.3-0.6v21.6h174.7V407.8H103.9zM103.9 527.8v13.1c-42.5-7.6-54.3-51.8-54.3-51.8s20.4-22.6 54.3-26.3v14.4c0 0 0 0-0.1 0 -17.7-2.2-31.7 14.5-31.7 14.5S80 519.6 103.9 527.8M28.4 487.2c0 0 25.2-37.1 75.5-41v-13.5C48.2 437.2 0 484.4 0 484.4s27.3 79 103.9 86.3v-14.4C47.7 549.2 28.4 487.2 28.4 487.2z">
                        </path>
                    </g>
                </svg>
                <div *ngIf=" locale === 'en-us'" class="global-footer__region"><a
                        href="https://www.nvidia.com/object/country-selector.html" target="_self"><span
                            class="global-footer__region__label">United States</span></a></div>

                <div *ngIf=" locale === 'en-au'" class="global-footer__region"><a
                        href="https://www.nvidia.com/object/country-selector.html" target="_self"><span
                            class="global-footer__region__label">AU - AUSTRALIA</span></a></div>
                <div *ngIf=" locale === 'en-ca'" class="global-footer__region"><a
                        href="https://www.nvidia.com/object/country-selector.html" target="_self"><span
                            class="global-footer__region__label">CA - CANADA</span></a></div>
                <div *ngIf=" locale === 'en-gb'" class="global-footer__region"><a
                        href="https://www.nvidia.com/object/country-selector.html" target="_self"><span
                            class="global-footer__region__label">UK - United Kingdom</span></a></div>
                <div *ngIf=" locale === 'en-in'" class="global-footer__region"><a
                        href="https://www.nvidia.com/object/country-selector.html" target="_self"><span
                            class="global-footer__region__label">IN - India</span></a></div>
                <div *ngIf=" locale === 'en-my'" class="global-footer__region"><a
                        href="https://www.nvidia.com/object/country-selector.html" target="_self"><span
                            class="global-footer__region__label">My - Malaysia</span></a></div>
                <div *ngIf=" locale === 'en-ph'" class="global-footer__region"><a
                        href="https://www.nvidia.com/object/country-selector.html" target="_self"><span
                            class="global-footer__region__label">PH - Philippines</span></a></div>
                <div *ngIf=" locale === 'en-sg'" class="global-footer__region"><a
                        href="https://www.nvidia.com/object/country-selector.html" target="_self"><span
                            class="global-footer__region__label">SG - Singapore</span></a></div>

                <ul class="global-footer__links">
                    <li><a href="https://www.nvidia.com/en-us/about-nvidia/privacy-policy/" target="_blank">Privacy
                            Policy</a>
                    </li>
                    <li><a href="https://www.nvidia.com/en-us/privacy-center/" target="_blank">Manage My Privacy</a>
                    </li>
                    <li><a href="https://www.nvidia.com/en-us/preferences/email-preferences/" target="_blank">Do Not
                            Sell or
                            Share My Data</a></li>
                    <li><a href="https://www.nvidia.com/en-us/about-nvidia/terms-of-service/" target="_blank">Terms of
                            Service</a></li>
                    <li><a href="https://www.nvidia.com/en-us/about-nvidia/accessibility/"
                            target="_blank">Accessibility</a>
                    </li>
                    <li><a href="https://www.nvidia.com/en-us/about-nvidia/company-policies/" target="_self">Corporate
                            Policies</a></li>
                    <li><a href="https://www.nvidia.com/en-us/product-security/" target="_blank">Product Security</a>
                    </li>
                    <li><a href="https://www.nvidia.com/en-us/contact/" target="_blank">Contact</a></li>
                </ul>
                <div class="global-footer__copyright">Copyright © <!-- -->2024<!-- --> NVIDIA Corporation</div>
            </div>
        </div>

    </div>

    <div *ngIf="locale === 'pl-pl'" class="footer-inner">
        <div>
            <svg class="global-footer__logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 407.8 1000 184.4"
                enableBackground="new 0 407.8 1000 184.4" xmlSpace="preserve">
                <title>NVIDIA</title>
                <g id="nvidia-logo">
                    <path
                        d="M987.1 555.7v-2.8h1.7c0.9 0 2.3 0.1 2.3 1.2 0 1.2-0.7 1.5-1.8 1.5H987.1M987.1 557.6h1.2l2.8 4.8h3l-3.1-5c1.5-0.1 2.9-0.8 2.9-3 0-2.7-1.8-3.5-4.9-3.5h-4.4v11.5h2.6L987.1 557.6M1000 556.7c0-6.8-5.2-10.7-11.1-10.7 -5.8 0-11.1 3.9-11.1 10.7 0 6.8 5.2 10.7 11.1 10.7S1000 563.5 1000 556.7M996.8 556.7c0 4.9-3.6 8.2-7.9 8.2l0 0c-4.4 0-7.9-3.3-7.9-8.2 0-4.9 3.6-8.2 7.9-8.2C993.2 548.6 996.8 551.9 996.8 556.7z">
                    </path>
                    <path
                        d="M593.2 442.7v121.1h34.2V442.7H593.2zM324.4 442.5v121.2h34.5v-94.1l26.9 0.1c8.8 0 15 2.2 19.2 6.7 5.4 5.7 7.6 15.1 7.6 32v55.3H446v-66.9c0-47.8-30.5-54.3-60.2-54.3C385.8 442.5 324.4 442.5 324.4 442.5zM648.3 442.7v121.1h55.4c29.5 0 39.2-4.9 49.6-15.9 7.4-7.7 12.1-24.7 12.1-43.3 0-17-4-32.2-11.1-41.7 -12.5-16.9-30.8-20.2-58.1-20.2H648.3zM682.2 469h14.7c21.3 0 35.1 9.5 35.1 34.4 0 24.8-13.7 34.4-35.1 34.4h-14.7V469zM544 442.7l-28.5 95.9 -27.3-95.9h-36.9l39 121.1h49.2l39.3-121.1H544zM781.4 563.7h34.2v-121h-34.2V563.7zM877.2 442.7l-47.7 121h33.7l7.6-21.3h56.5l7.2 21.3h36.6l-48.2-121H877.2zM899.4 464.8l20.7 56.6H878L899.4 464.8z">
                    </path>
                    <path
                        d="M103.9 462.8v-16.6c1.6-0.1 3.3-0.2 4.9-0.2 45.6-1.4 75.4 39.1 75.4 39.1s-32.2 44.8-66.8 44.8c-5 0-9.4-0.8-13.4-2.2v-50.5c17.7 2.2 21.3 10 32 27.7l23.7-20c0 0-17.3-22.7-46.5-22.7C110 462.3 106.9 462.5 103.9 462.8M103.9 407.8v24.8c1.6-0.1 3.3-0.2 4.9-0.3 63.3-2.2 104.6 51.9 104.6 51.9s-47.4 57.7-96.7 57.7c-4.5 0-8.7-0.4-12.7-1.1v15.4c3.4 0.4 7 0.7 10.6 0.7 46 0 79.2-23.5 111.4-51.2 5.3 4.3 27.2 14.7 31.7 19.2 -30.6 25.6-101.9 46.3-142.3 46.3 -3.9 0-7.6-0.2-11.3-0.6v21.6h174.7V407.8H103.9zM103.9 527.8v13.1c-42.5-7.6-54.3-51.8-54.3-51.8s20.4-22.6 54.3-26.3v14.4c0 0 0 0-0.1 0 -17.7-2.2-31.7 14.5-31.7 14.5S80 519.6 103.9 527.8M28.4 487.2c0 0 25.2-37.1 75.5-41v-13.5C48.2 437.2 0 484.4 0 484.4s27.3 79 103.9 86.3v-14.4C47.7 549.2 28.4 487.2 28.4 487.2z">
                    </path>
                </g>
            </svg>
        </div>
        <div class="left-links">
            <div class="footer-item">
                <a href="https://www.nvidia.pl/about-nvidia/privacy-policy/" target="_blank" rel="noopener">
                    <span>Polityka Prywatnosci</span>
                </a>
            </div>
            <div class="footer-item">
                <a href="https://www.nvidia.com/en-us/about-nvidia/terms-of-service/" target="_blank" rel="noopener">
                    <span>Warunki korzystania z usługi</span>
                </a>
            </div>
            <div class="footer-item">
                <a href="https://www.nvidia.pl/contact/europe/" target="_blank" rel="noopener">
                    <span>Kontakt</span>
                </a>
            </div>
        </div>

        <div class="footer-item" role="button" aria-pressed="true" (click)="openModal('countries')">
            <a href="javascript: void(0)" target="_self">
                <span class="country-select">PL - POLAND</span>
            </a>
        </div>

        <div class="global-footer__copyright global-footer__copyright_adjustment">Copyright © <!-- -->2024<!-- -->
            NVIDIA Corporation</div>
    </div>
    <div *ngIf="locale === 'fr-ca'" class="footer-inner">
        <div>
            <svg class="global-footer__logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 407.8 1000 184.4"
                enableBackground="new 0 407.8 1000 184.4" xmlSpace="preserve">
                <title>NVIDIA</title>
                <g id="nvidia-logo">
                    <path
                        d="M987.1 555.7v-2.8h1.7c0.9 0 2.3 0.1 2.3 1.2 0 1.2-0.7 1.5-1.8 1.5H987.1M987.1 557.6h1.2l2.8 4.8h3l-3.1-5c1.5-0.1 2.9-0.8 2.9-3 0-2.7-1.8-3.5-4.9-3.5h-4.4v11.5h2.6L987.1 557.6M1000 556.7c0-6.8-5.2-10.7-11.1-10.7 -5.8 0-11.1 3.9-11.1 10.7 0 6.8 5.2 10.7 11.1 10.7S1000 563.5 1000 556.7M996.8 556.7c0 4.9-3.6 8.2-7.9 8.2l0 0c-4.4 0-7.9-3.3-7.9-8.2 0-4.9 3.6-8.2 7.9-8.2C993.2 548.6 996.8 551.9 996.8 556.7z">
                    </path>
                    <path
                        d="M593.2 442.7v121.1h34.2V442.7H593.2zM324.4 442.5v121.2h34.5v-94.1l26.9 0.1c8.8 0 15 2.2 19.2 6.7 5.4 5.7 7.6 15.1 7.6 32v55.3H446v-66.9c0-47.8-30.5-54.3-60.2-54.3C385.8 442.5 324.4 442.5 324.4 442.5zM648.3 442.7v121.1h55.4c29.5 0 39.2-4.9 49.6-15.9 7.4-7.7 12.1-24.7 12.1-43.3 0-17-4-32.2-11.1-41.7 -12.5-16.9-30.8-20.2-58.1-20.2H648.3zM682.2 469h14.7c21.3 0 35.1 9.5 35.1 34.4 0 24.8-13.7 34.4-35.1 34.4h-14.7V469zM544 442.7l-28.5 95.9 -27.3-95.9h-36.9l39 121.1h49.2l39.3-121.1H544zM781.4 563.7h34.2v-121h-34.2V563.7zM877.2 442.7l-47.7 121h33.7l7.6-21.3h56.5l7.2 21.3h36.6l-48.2-121H877.2zM899.4 464.8l20.7 56.6H878L899.4 464.8z">
                    </path>
                    <path
                        d="M103.9 462.8v-16.6c1.6-0.1 3.3-0.2 4.9-0.2 45.6-1.4 75.4 39.1 75.4 39.1s-32.2 44.8-66.8 44.8c-5 0-9.4-0.8-13.4-2.2v-50.5c17.7 2.2 21.3 10 32 27.7l23.7-20c0 0-17.3-22.7-46.5-22.7C110 462.3 106.9 462.5 103.9 462.8M103.9 407.8v24.8c1.6-0.1 3.3-0.2 4.9-0.3 63.3-2.2 104.6 51.9 104.6 51.9s-47.4 57.7-96.7 57.7c-4.5 0-8.7-0.4-12.7-1.1v15.4c3.4 0.4 7 0.7 10.6 0.7 46 0 79.2-23.5 111.4-51.2 5.3 4.3 27.2 14.7 31.7 19.2 -30.6 25.6-101.9 46.3-142.3 46.3 -3.9 0-7.6-0.2-11.3-0.6v21.6h174.7V407.8H103.9zM103.9 527.8v13.1c-42.5-7.6-54.3-51.8-54.3-51.8s20.4-22.6 54.3-26.3v14.4c0 0 0 0-0.1 0 -17.7-2.2-31.7 14.5-31.7 14.5S80 519.6 103.9 527.8M28.4 487.2c0 0 25.2-37.1 75.5-41v-13.5C48.2 437.2 0 484.4 0 484.4s27.3 79 103.9 86.3v-14.4C47.7 549.2 28.4 487.2 28.4 487.2z">
                    </path>
                </g>
            </svg>
        </div>
        <div class="left-links">
            <div class="footer-item">
                <a href="https://www.nvidia.com/en-us/about-nvidia/privacy-policy/" target="_blank" rel="noopener">
                    <span>Confidentialité</span>
                </a>
            </div>
            <div class="footer-item">
                <a href="https://www.nvidia.com/en-us/about-nvidia/terms-of-service/" target="_blank" rel="noopener">
                    <span>Mentions légales</span>
                </a>
            </div>
            <div class="footer-item">
                <a href="https://www.nvidia.com/en-us/contact/" target="_blank" rel="noopener">
                    <span>Contact</span>
                </a>
            </div>
        </div>
        <div class="footer-item" role="button" aria-pressed="true" (click)="openModal('countries')">
            <a href="javascript: void(0)" target="_self">
                <span class="country-select">CA - CANADA</span>
            </a>
        </div>
        <div class="global-footer__copyright global-footer__copyright_adjustment">Copyright © <!-- -->2024<!-- -->
            NVIDIA Corporation</div>
    </div>
    <div *ngIf="locale === 'fr-fr'" class="footer-inner">
        <div>
            <svg class="global-footer__logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 407.8 1000 184.4"
                enableBackground="new 0 407.8 1000 184.4" xmlSpace="preserve">
                <title>NVIDIA</title>
                <g id="nvidia-logo">
                    <path
                        d="M987.1 555.7v-2.8h1.7c0.9 0 2.3 0.1 2.3 1.2 0 1.2-0.7 1.5-1.8 1.5H987.1M987.1 557.6h1.2l2.8 4.8h3l-3.1-5c1.5-0.1 2.9-0.8 2.9-3 0-2.7-1.8-3.5-4.9-3.5h-4.4v11.5h2.6L987.1 557.6M1000 556.7c0-6.8-5.2-10.7-11.1-10.7 -5.8 0-11.1 3.9-11.1 10.7 0 6.8 5.2 10.7 11.1 10.7S1000 563.5 1000 556.7M996.8 556.7c0 4.9-3.6 8.2-7.9 8.2l0 0c-4.4 0-7.9-3.3-7.9-8.2 0-4.9 3.6-8.2 7.9-8.2C993.2 548.6 996.8 551.9 996.8 556.7z">
                    </path>
                    <path
                        d="M593.2 442.7v121.1h34.2V442.7H593.2zM324.4 442.5v121.2h34.5v-94.1l26.9 0.1c8.8 0 15 2.2 19.2 6.7 5.4 5.7 7.6 15.1 7.6 32v55.3H446v-66.9c0-47.8-30.5-54.3-60.2-54.3C385.8 442.5 324.4 442.5 324.4 442.5zM648.3 442.7v121.1h55.4c29.5 0 39.2-4.9 49.6-15.9 7.4-7.7 12.1-24.7 12.1-43.3 0-17-4-32.2-11.1-41.7 -12.5-16.9-30.8-20.2-58.1-20.2H648.3zM682.2 469h14.7c21.3 0 35.1 9.5 35.1 34.4 0 24.8-13.7 34.4-35.1 34.4h-14.7V469zM544 442.7l-28.5 95.9 -27.3-95.9h-36.9l39 121.1h49.2l39.3-121.1H544zM781.4 563.7h34.2v-121h-34.2V563.7zM877.2 442.7l-47.7 121h33.7l7.6-21.3h56.5l7.2 21.3h36.6l-48.2-121H877.2zM899.4 464.8l20.7 56.6H878L899.4 464.8z">
                    </path>
                    <path
                        d="M103.9 462.8v-16.6c1.6-0.1 3.3-0.2 4.9-0.2 45.6-1.4 75.4 39.1 75.4 39.1s-32.2 44.8-66.8 44.8c-5 0-9.4-0.8-13.4-2.2v-50.5c17.7 2.2 21.3 10 32 27.7l23.7-20c0 0-17.3-22.7-46.5-22.7C110 462.3 106.9 462.5 103.9 462.8M103.9 407.8v24.8c1.6-0.1 3.3-0.2 4.9-0.3 63.3-2.2 104.6 51.9 104.6 51.9s-47.4 57.7-96.7 57.7c-4.5 0-8.7-0.4-12.7-1.1v15.4c3.4 0.4 7 0.7 10.6 0.7 46 0 79.2-23.5 111.4-51.2 5.3 4.3 27.2 14.7 31.7 19.2 -30.6 25.6-101.9 46.3-142.3 46.3 -3.9 0-7.6-0.2-11.3-0.6v21.6h174.7V407.8H103.9zM103.9 527.8v13.1c-42.5-7.6-54.3-51.8-54.3-51.8s20.4-22.6 54.3-26.3v14.4c0 0 0 0-0.1 0 -17.7-2.2-31.7 14.5-31.7 14.5S80 519.6 103.9 527.8M28.4 487.2c0 0 25.2-37.1 75.5-41v-13.5C48.2 437.2 0 484.4 0 484.4s27.3 79 103.9 86.3v-14.4C47.7 549.2 28.4 487.2 28.4 487.2z">
                    </path>
                </g>
            </svg>
        </div>
        <div class="left-links">
            <div class="footer-item">
                <a href="https://www.nvidia.fr/about-nvidia/privacy-policy/" target="_blank" rel="noopener">
                    <span>Confidentialité</span>
                </a>
            </div>
            <div class="footer-item">
                <a href="https://www.nvidia.com/en-us/about-nvidia/terms-of-service/" target="_blank" rel="noopener">
                    <span>Conditions d’utilisation</span>
                </a>
            </div>
            <div class="footer-item">
                <a href="https://www.nvidia.fr/contact/europe/" target="_blank" rel="noopener">
                    <span>Contact</span>
                </a>
            </div>
        </div>
        <div class="footer-item" role="button" aria-pressed="true" (click)="openModal('countries')">
            <a href="javascript: void(0)" target="_self">
                <span class="country-select">FR - France</span>
            </a>
        </div>
        <div class="global-footer__copyright global-footer__copyright_adjustment">Copyright © <!-- -->2024<!-- -->
            NVIDIA Corporation</div>
    </div>
    <div *ngIf="locale === 'de-de'" class="footer-inner">
        <div>
            <svg class="global-footer__logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 407.8 1000 184.4"
                enableBackground="new 0 407.8 1000 184.4" xmlSpace="preserve">
                <title>NVIDIA</title>
                <g id="nvidia-logo">
                    <path
                        d="M987.1 555.7v-2.8h1.7c0.9 0 2.3 0.1 2.3 1.2 0 1.2-0.7 1.5-1.8 1.5H987.1M987.1 557.6h1.2l2.8 4.8h3l-3.1-5c1.5-0.1 2.9-0.8 2.9-3 0-2.7-1.8-3.5-4.9-3.5h-4.4v11.5h2.6L987.1 557.6M1000 556.7c0-6.8-5.2-10.7-11.1-10.7 -5.8 0-11.1 3.9-11.1 10.7 0 6.8 5.2 10.7 11.1 10.7S1000 563.5 1000 556.7M996.8 556.7c0 4.9-3.6 8.2-7.9 8.2l0 0c-4.4 0-7.9-3.3-7.9-8.2 0-4.9 3.6-8.2 7.9-8.2C993.2 548.6 996.8 551.9 996.8 556.7z">
                    </path>
                    <path
                        d="M593.2 442.7v121.1h34.2V442.7H593.2zM324.4 442.5v121.2h34.5v-94.1l26.9 0.1c8.8 0 15 2.2 19.2 6.7 5.4 5.7 7.6 15.1 7.6 32v55.3H446v-66.9c0-47.8-30.5-54.3-60.2-54.3C385.8 442.5 324.4 442.5 324.4 442.5zM648.3 442.7v121.1h55.4c29.5 0 39.2-4.9 49.6-15.9 7.4-7.7 12.1-24.7 12.1-43.3 0-17-4-32.2-11.1-41.7 -12.5-16.9-30.8-20.2-58.1-20.2H648.3zM682.2 469h14.7c21.3 0 35.1 9.5 35.1 34.4 0 24.8-13.7 34.4-35.1 34.4h-14.7V469zM544 442.7l-28.5 95.9 -27.3-95.9h-36.9l39 121.1h49.2l39.3-121.1H544zM781.4 563.7h34.2v-121h-34.2V563.7zM877.2 442.7l-47.7 121h33.7l7.6-21.3h56.5l7.2 21.3h36.6l-48.2-121H877.2zM899.4 464.8l20.7 56.6H878L899.4 464.8z">
                    </path>
                    <path
                        d="M103.9 462.8v-16.6c1.6-0.1 3.3-0.2 4.9-0.2 45.6-1.4 75.4 39.1 75.4 39.1s-32.2 44.8-66.8 44.8c-5 0-9.4-0.8-13.4-2.2v-50.5c17.7 2.2 21.3 10 32 27.7l23.7-20c0 0-17.3-22.7-46.5-22.7C110 462.3 106.9 462.5 103.9 462.8M103.9 407.8v24.8c1.6-0.1 3.3-0.2 4.9-0.3 63.3-2.2 104.6 51.9 104.6 51.9s-47.4 57.7-96.7 57.7c-4.5 0-8.7-0.4-12.7-1.1v15.4c3.4 0.4 7 0.7 10.6 0.7 46 0 79.2-23.5 111.4-51.2 5.3 4.3 27.2 14.7 31.7 19.2 -30.6 25.6-101.9 46.3-142.3 46.3 -3.9 0-7.6-0.2-11.3-0.6v21.6h174.7V407.8H103.9zM103.9 527.8v13.1c-42.5-7.6-54.3-51.8-54.3-51.8s20.4-22.6 54.3-26.3v14.4c0 0 0 0-0.1 0 -17.7-2.2-31.7 14.5-31.7 14.5S80 519.6 103.9 527.8M28.4 487.2c0 0 25.2-37.1 75.5-41v-13.5C48.2 437.2 0 484.4 0 484.4s27.3 79 103.9 86.3v-14.4C47.7 549.2 28.4 487.2 28.4 487.2z">
                    </path>
                </g>
            </svg>
        </div>
        <div class="left-links">
            <div class="footer-item">
                <a href="https://www.nvidia.de/about-nvidia/privacy-policy/" target="_blank" rel="noopener">
                    <span>Datenschutz</span>
                </a>
            </div>
            <div class="footer-item">
                <a href="https://www.nvidia.com/en-us/about-nvidia/terms-of-service/" target="_blank" rel="noopener">
                    <span>Nutzungsbedingungen</span>
                </a>
            </div>
            <div class="footer-item">
                <a href="https://www.nvidia.de/contact/europe/" target="_blank" rel="noopener">
                    <span>Kontakt</span>
                </a>
            </div>
        </div>
        <div class="footer-item" role="button" aria-pressed="true" (click)="openModal('countries')">
            <a href="javascript: void(0)" target="_self">
                <span class="country-select">DE - Deutschland</span>
            </a>
        </div>
        <div class="global-footer__copyright global-footer__copyright_adjustment">Copyright © <!-- -->2024<!-- -->
            NVIDIA Corporation</div>
    </div>
    <div *ngIf="locale === 'de-at'" class="footer-inner">
        <div>
            <svg class="global-footer__logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 407.8 1000 184.4"
                enableBackground="new 0 407.8 1000 184.4" xmlSpace="preserve">
                <title>NVIDIA</title>
                <g id="nvidia-logo">
                    <path
                        d="M987.1 555.7v-2.8h1.7c0.9 0 2.3 0.1 2.3 1.2 0 1.2-0.7 1.5-1.8 1.5H987.1M987.1 557.6h1.2l2.8 4.8h3l-3.1-5c1.5-0.1 2.9-0.8 2.9-3 0-2.7-1.8-3.5-4.9-3.5h-4.4v11.5h2.6L987.1 557.6M1000 556.7c0-6.8-5.2-10.7-11.1-10.7 -5.8 0-11.1 3.9-11.1 10.7 0 6.8 5.2 10.7 11.1 10.7S1000 563.5 1000 556.7M996.8 556.7c0 4.9-3.6 8.2-7.9 8.2l0 0c-4.4 0-7.9-3.3-7.9-8.2 0-4.9 3.6-8.2 7.9-8.2C993.2 548.6 996.8 551.9 996.8 556.7z">
                    </path>
                    <path
                        d="M593.2 442.7v121.1h34.2V442.7H593.2zM324.4 442.5v121.2h34.5v-94.1l26.9 0.1c8.8 0 15 2.2 19.2 6.7 5.4 5.7 7.6 15.1 7.6 32v55.3H446v-66.9c0-47.8-30.5-54.3-60.2-54.3C385.8 442.5 324.4 442.5 324.4 442.5zM648.3 442.7v121.1h55.4c29.5 0 39.2-4.9 49.6-15.9 7.4-7.7 12.1-24.7 12.1-43.3 0-17-4-32.2-11.1-41.7 -12.5-16.9-30.8-20.2-58.1-20.2H648.3zM682.2 469h14.7c21.3 0 35.1 9.5 35.1 34.4 0 24.8-13.7 34.4-35.1 34.4h-14.7V469zM544 442.7l-28.5 95.9 -27.3-95.9h-36.9l39 121.1h49.2l39.3-121.1H544zM781.4 563.7h34.2v-121h-34.2V563.7zM877.2 442.7l-47.7 121h33.7l7.6-21.3h56.5l7.2 21.3h36.6l-48.2-121H877.2zM899.4 464.8l20.7 56.6H878L899.4 464.8z">
                    </path>
                    <path
                        d="M103.9 462.8v-16.6c1.6-0.1 3.3-0.2 4.9-0.2 45.6-1.4 75.4 39.1 75.4 39.1s-32.2 44.8-66.8 44.8c-5 0-9.4-0.8-13.4-2.2v-50.5c17.7 2.2 21.3 10 32 27.7l23.7-20c0 0-17.3-22.7-46.5-22.7C110 462.3 106.9 462.5 103.9 462.8M103.9 407.8v24.8c1.6-0.1 3.3-0.2 4.9-0.3 63.3-2.2 104.6 51.9 104.6 51.9s-47.4 57.7-96.7 57.7c-4.5 0-8.7-0.4-12.7-1.1v15.4c3.4 0.4 7 0.7 10.6 0.7 46 0 79.2-23.5 111.4-51.2 5.3 4.3 27.2 14.7 31.7 19.2 -30.6 25.6-101.9 46.3-142.3 46.3 -3.9 0-7.6-0.2-11.3-0.6v21.6h174.7V407.8H103.9zM103.9 527.8v13.1c-42.5-7.6-54.3-51.8-54.3-51.8s20.4-22.6 54.3-26.3v14.4c0 0 0 0-0.1 0 -17.7-2.2-31.7 14.5-31.7 14.5S80 519.6 103.9 527.8M28.4 487.2c0 0 25.2-37.1 75.5-41v-13.5C48.2 437.2 0 484.4 0 484.4s27.3 79 103.9 86.3v-14.4C47.7 549.2 28.4 487.2 28.4 487.2z">
                    </path>
                </g>
            </svg>
        </div>
        <div class="left-links">

            <div class="footer-item">
                <a href="https://www.nvidia.de/about-nvidia/privacy-policy/" target="_blank" rel="noopener">
                    <span>Datenschutz</span>
                </a>
            </div>
            <div class="footer-item">
                <a href="https://www.nvidia.com/en-us/about-nvidia/terms-of-service/" target="_blank" rel="noopener">
                    <span>Rechtliche Angaben</span>
                </a>
            </div>
            <div class="footer-item">
                <a href="https://www.nvidia.de/contact/europe/" target="_blank" rel="noopener">
                    <span>Kontakt</span>
                </a>
            </div>
        </div>
        <div class="footer-item" role="button" aria-pressed="true" (click)="openModal('countries')">
            <a href="javascript: void(0)" target="_self">
                <span class="country-select">AT - Österreich</span>
            </a>
        </div>
        <div class="global-footer__copyright global-footer__copyright_adjustment">Copyright © <!-- -->2024<!-- -->
            NVIDIA Corporation</div>
    </div>
    <div *ngIf="locale === 'es-es'" class="footer-inner">
        <div>
            <svg class="global-footer__logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 407.8 1000 184.4"
                enableBackground="new 0 407.8 1000 184.4" xmlSpace="preserve">
                <title>NVIDIA</title>
                <g id="nvidia-logo">
                    <path
                        d="M987.1 555.7v-2.8h1.7c0.9 0 2.3 0.1 2.3 1.2 0 1.2-0.7 1.5-1.8 1.5H987.1M987.1 557.6h1.2l2.8 4.8h3l-3.1-5c1.5-0.1 2.9-0.8 2.9-3 0-2.7-1.8-3.5-4.9-3.5h-4.4v11.5h2.6L987.1 557.6M1000 556.7c0-6.8-5.2-10.7-11.1-10.7 -5.8 0-11.1 3.9-11.1 10.7 0 6.8 5.2 10.7 11.1 10.7S1000 563.5 1000 556.7M996.8 556.7c0 4.9-3.6 8.2-7.9 8.2l0 0c-4.4 0-7.9-3.3-7.9-8.2 0-4.9 3.6-8.2 7.9-8.2C993.2 548.6 996.8 551.9 996.8 556.7z">
                    </path>
                    <path
                        d="M593.2 442.7v121.1h34.2V442.7H593.2zM324.4 442.5v121.2h34.5v-94.1l26.9 0.1c8.8 0 15 2.2 19.2 6.7 5.4 5.7 7.6 15.1 7.6 32v55.3H446v-66.9c0-47.8-30.5-54.3-60.2-54.3C385.8 442.5 324.4 442.5 324.4 442.5zM648.3 442.7v121.1h55.4c29.5 0 39.2-4.9 49.6-15.9 7.4-7.7 12.1-24.7 12.1-43.3 0-17-4-32.2-11.1-41.7 -12.5-16.9-30.8-20.2-58.1-20.2H648.3zM682.2 469h14.7c21.3 0 35.1 9.5 35.1 34.4 0 24.8-13.7 34.4-35.1 34.4h-14.7V469zM544 442.7l-28.5 95.9 -27.3-95.9h-36.9l39 121.1h49.2l39.3-121.1H544zM781.4 563.7h34.2v-121h-34.2V563.7zM877.2 442.7l-47.7 121h33.7l7.6-21.3h56.5l7.2 21.3h36.6l-48.2-121H877.2zM899.4 464.8l20.7 56.6H878L899.4 464.8z">
                    </path>
                    <path
                        d="M103.9 462.8v-16.6c1.6-0.1 3.3-0.2 4.9-0.2 45.6-1.4 75.4 39.1 75.4 39.1s-32.2 44.8-66.8 44.8c-5 0-9.4-0.8-13.4-2.2v-50.5c17.7 2.2 21.3 10 32 27.7l23.7-20c0 0-17.3-22.7-46.5-22.7C110 462.3 106.9 462.5 103.9 462.8M103.9 407.8v24.8c1.6-0.1 3.3-0.2 4.9-0.3 63.3-2.2 104.6 51.9 104.6 51.9s-47.4 57.7-96.7 57.7c-4.5 0-8.7-0.4-12.7-1.1v15.4c3.4 0.4 7 0.7 10.6 0.7 46 0 79.2-23.5 111.4-51.2 5.3 4.3 27.2 14.7 31.7 19.2 -30.6 25.6-101.9 46.3-142.3 46.3 -3.9 0-7.6-0.2-11.3-0.6v21.6h174.7V407.8H103.9zM103.9 527.8v13.1c-42.5-7.6-54.3-51.8-54.3-51.8s20.4-22.6 54.3-26.3v14.4c0 0 0 0-0.1 0 -17.7-2.2-31.7 14.5-31.7 14.5S80 519.6 103.9 527.8M28.4 487.2c0 0 25.2-37.1 75.5-41v-13.5C48.2 437.2 0 484.4 0 484.4s27.3 79 103.9 86.3v-14.4C47.7 549.2 28.4 487.2 28.4 487.2z">
                    </path>
                </g>
            </svg>
        </div>
        <div class="left-links">
            <div class="footer-item">
                <a href="https://www.nvidia.com/es-es/about-nvidia/privacy-policy/" target="_blank" rel="noopener">
                    <span>Política de privacidad</span>
                </a>
            </div>
            <div class="footer-item">
                <a href="https://www.nvidia.com/en-us/about-nvidia/terms-of-service/" target="_blank" rel="noopener">
                    <span>Términos de servicio</span>
                </a>
            </div>
            <div class="footer-item">
                <a href="https://www.nvidia.com/es-es/contact/europe/" target="_blank" rel="noopener">
                    <span>Contactar</span>
                </a>
            </div>
        </div>
        <div class="footer-item" role="button" aria-pressed="true" (click)="openModal('countries')">
            <a href="javascript: void(0)" target="_self">
                <span class="country-select">ES- ESPAÑA</span>
            </a>
        </div>

        <div class="global-footer__copyright global-footer__copyright_adjustment">Copyright © <!-- -->2024<!-- -->
            NVIDIA Corporation</div>
    </div>

    <div *ngIf="locale === 'es-mx'" class="footer-inner">
        <div>
            <svg class="global-footer__logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 407.8 1000 184.4"
                enableBackground="new 0 407.8 1000 184.4" xmlSpace="preserve">
                <title>NVIDIA</title>
                <g id="nvidia-logo">
                    <path
                        d="M987.1 555.7v-2.8h1.7c0.9 0 2.3 0.1 2.3 1.2 0 1.2-0.7 1.5-1.8 1.5H987.1M987.1 557.6h1.2l2.8 4.8h3l-3.1-5c1.5-0.1 2.9-0.8 2.9-3 0-2.7-1.8-3.5-4.9-3.5h-4.4v11.5h2.6L987.1 557.6M1000 556.7c0-6.8-5.2-10.7-11.1-10.7 -5.8 0-11.1 3.9-11.1 10.7 0 6.8 5.2 10.7 11.1 10.7S1000 563.5 1000 556.7M996.8 556.7c0 4.9-3.6 8.2-7.9 8.2l0 0c-4.4 0-7.9-3.3-7.9-8.2 0-4.9 3.6-8.2 7.9-8.2C993.2 548.6 996.8 551.9 996.8 556.7z">
                    </path>
                    <path
                        d="M593.2 442.7v121.1h34.2V442.7H593.2zM324.4 442.5v121.2h34.5v-94.1l26.9 0.1c8.8 0 15 2.2 19.2 6.7 5.4 5.7 7.6 15.1 7.6 32v55.3H446v-66.9c0-47.8-30.5-54.3-60.2-54.3C385.8 442.5 324.4 442.5 324.4 442.5zM648.3 442.7v121.1h55.4c29.5 0 39.2-4.9 49.6-15.9 7.4-7.7 12.1-24.7 12.1-43.3 0-17-4-32.2-11.1-41.7 -12.5-16.9-30.8-20.2-58.1-20.2H648.3zM682.2 469h14.7c21.3 0 35.1 9.5 35.1 34.4 0 24.8-13.7 34.4-35.1 34.4h-14.7V469zM544 442.7l-28.5 95.9 -27.3-95.9h-36.9l39 121.1h49.2l39.3-121.1H544zM781.4 563.7h34.2v-121h-34.2V563.7zM877.2 442.7l-47.7 121h33.7l7.6-21.3h56.5l7.2 21.3h36.6l-48.2-121H877.2zM899.4 464.8l20.7 56.6H878L899.4 464.8z">
                    </path>
                    <path
                        d="M103.9 462.8v-16.6c1.6-0.1 3.3-0.2 4.9-0.2 45.6-1.4 75.4 39.1 75.4 39.1s-32.2 44.8-66.8 44.8c-5 0-9.4-0.8-13.4-2.2v-50.5c17.7 2.2 21.3 10 32 27.7l23.7-20c0 0-17.3-22.7-46.5-22.7C110 462.3 106.9 462.5 103.9 462.8M103.9 407.8v24.8c1.6-0.1 3.3-0.2 4.9-0.3 63.3-2.2 104.6 51.9 104.6 51.9s-47.4 57.7-96.7 57.7c-4.5 0-8.7-0.4-12.7-1.1v15.4c3.4 0.4 7 0.7 10.6 0.7 46 0 79.2-23.5 111.4-51.2 5.3 4.3 27.2 14.7 31.7 19.2 -30.6 25.6-101.9 46.3-142.3 46.3 -3.9 0-7.6-0.2-11.3-0.6v21.6h174.7V407.8H103.9zM103.9 527.8v13.1c-42.5-7.6-54.3-51.8-54.3-51.8s20.4-22.6 54.3-26.3v14.4c0 0 0 0-0.1 0 -17.7-2.2-31.7 14.5-31.7 14.5S80 519.6 103.9 527.8M28.4 487.2c0 0 25.2-37.1 75.5-41v-13.5C48.2 437.2 0 484.4 0 484.4s27.3 79 103.9 86.3v-14.4C47.7 549.2 28.4 487.2 28.4 487.2z">
                    </path>
                </g>
            </svg>
        </div>
        <div class="left-links">
            <div class="footer-item">
                <a href="https://www.nvidia.com/es-la/about-nvidia/privacy-policy/" target="_blank" rel="noopener">
                    <span>Política de privacidad</span>
                </a>
            </div>
            <div class="footer-item">
                <a href="https://www.nvidia.com/en-us/about-nvidia/terms-of-service/" target="_blank" rel="noopener">
                    <span>Información legal</span>
                </a>
            </div>
            <div class="footer-item">
                <a href="https://www.nvidia.com/es-la/contact/europe/" target="_blank" rel="noopener">
                    <span>Contactar</span>
                </a>
            </div>
        </div>
        <div class="footer-item" role="button" aria-pressed="true" (click)="openModal('countries')">
            <a href="javascript: void(0)" target="_self">
                <span class="country-select">ES- ESPAÑA</span>
            </a>
        </div>
        <div class="global-footer__copyright global-footer__copyright_adjustment">Copyright © <!-- -->2024<!-- -->
            NVIDIA Corporation</div>
    </div>



    <div *ngIf="locale === 'ru-ru'" class="footer-inner">
        <div>
            <svg class="global-footer__logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 407.8 1000 184.4"
                enableBackground="new 0 407.8 1000 184.4" xmlSpace="preserve">
                <title>NVIDIA</title>
                <g id="nvidia-logo">
                    <path
                        d="M987.1 555.7v-2.8h1.7c0.9 0 2.3 0.1 2.3 1.2 0 1.2-0.7 1.5-1.8 1.5H987.1M987.1 557.6h1.2l2.8 4.8h3l-3.1-5c1.5-0.1 2.9-0.8 2.9-3 0-2.7-1.8-3.5-4.9-3.5h-4.4v11.5h2.6L987.1 557.6M1000 556.7c0-6.8-5.2-10.7-11.1-10.7 -5.8 0-11.1 3.9-11.1 10.7 0 6.8 5.2 10.7 11.1 10.7S1000 563.5 1000 556.7M996.8 556.7c0 4.9-3.6 8.2-7.9 8.2l0 0c-4.4 0-7.9-3.3-7.9-8.2 0-4.9 3.6-8.2 7.9-8.2C993.2 548.6 996.8 551.9 996.8 556.7z">
                    </path>
                    <path
                        d="M593.2 442.7v121.1h34.2V442.7H593.2zM324.4 442.5v121.2h34.5v-94.1l26.9 0.1c8.8 0 15 2.2 19.2 6.7 5.4 5.7 7.6 15.1 7.6 32v55.3H446v-66.9c0-47.8-30.5-54.3-60.2-54.3C385.8 442.5 324.4 442.5 324.4 442.5zM648.3 442.7v121.1h55.4c29.5 0 39.2-4.9 49.6-15.9 7.4-7.7 12.1-24.7 12.1-43.3 0-17-4-32.2-11.1-41.7 -12.5-16.9-30.8-20.2-58.1-20.2H648.3zM682.2 469h14.7c21.3 0 35.1 9.5 35.1 34.4 0 24.8-13.7 34.4-35.1 34.4h-14.7V469zM544 442.7l-28.5 95.9 -27.3-95.9h-36.9l39 121.1h49.2l39.3-121.1H544zM781.4 563.7h34.2v-121h-34.2V563.7zM877.2 442.7l-47.7 121h33.7l7.6-21.3h56.5l7.2 21.3h36.6l-48.2-121H877.2zM899.4 464.8l20.7 56.6H878L899.4 464.8z">
                    </path>
                    <path
                        d="M103.9 462.8v-16.6c1.6-0.1 3.3-0.2 4.9-0.2 45.6-1.4 75.4 39.1 75.4 39.1s-32.2 44.8-66.8 44.8c-5 0-9.4-0.8-13.4-2.2v-50.5c17.7 2.2 21.3 10 32 27.7l23.7-20c0 0-17.3-22.7-46.5-22.7C110 462.3 106.9 462.5 103.9 462.8M103.9 407.8v24.8c1.6-0.1 3.3-0.2 4.9-0.3 63.3-2.2 104.6 51.9 104.6 51.9s-47.4 57.7-96.7 57.7c-4.5 0-8.7-0.4-12.7-1.1v15.4c3.4 0.4 7 0.7 10.6 0.7 46 0 79.2-23.5 111.4-51.2 5.3 4.3 27.2 14.7 31.7 19.2 -30.6 25.6-101.9 46.3-142.3 46.3 -3.9 0-7.6-0.2-11.3-0.6v21.6h174.7V407.8H103.9zM103.9 527.8v13.1c-42.5-7.6-54.3-51.8-54.3-51.8s20.4-22.6 54.3-26.3v14.4c0 0 0 0-0.1 0 -17.7-2.2-31.7 14.5-31.7 14.5S80 519.6 103.9 527.8M28.4 487.2c0 0 25.2-37.1 75.5-41v-13.5C48.2 437.2 0 484.4 0 484.4s27.3 79 103.9 86.3v-14.4C47.7 549.2 28.4 487.2 28.4 487.2z">
                    </path>
                </g>
            </svg>
        </div>
        <div class="left-links">
            <div class="footer-item">
                <a href="https://www.nvidia.ru/object/privacy-policy-ru.html" target="_blank" rel="noopener">
                    <span>Положение о конфиденциальности</span>
                </a>
            </div>
            <div class="footer-item">
                <a href="https://www.nvidia.com/en-us/about-nvidia/terms-of-service/" target="_blank" rel="noopener">
                    <span>Условия использования</span>
                </a>
            </div>
            <div class="footer-item">
                <a href="http://www.nvidia.ru/object/contact-information-europe-ru.html" target="_blank" rel="noopener">
                    <span>Связаться с нами</span>
                </a>
            </div>
        </div>
        <div class="footer-item" role="button" aria-pressed="true" (click)="openModal('countries')">
            <a href="javascript: void(0)" target="_self">
                <span class="country-select">RU - Россия</span>
            </a>
        </div>
        <div class="global-footer__copyright global-footer__copyright_adjustment">Copyright © <!-- -->2024<!-- -->
            NVIDIA Corporation</div>
    </div>
    <div *ngIf="locale === 'nl-nl'" class="footer-inner">
        <div>
            <svg class="global-footer__logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 407.8 1000 184.4"
                enableBackground="new 0 407.8 1000 184.4" xmlSpace="preserve">
                <title>NVIDIA</title>
                <g id="nvidia-logo">
                    <path
                        d="M987.1 555.7v-2.8h1.7c0.9 0 2.3 0.1 2.3 1.2 0 1.2-0.7 1.5-1.8 1.5H987.1M987.1 557.6h1.2l2.8 4.8h3l-3.1-5c1.5-0.1 2.9-0.8 2.9-3 0-2.7-1.8-3.5-4.9-3.5h-4.4v11.5h2.6L987.1 557.6M1000 556.7c0-6.8-5.2-10.7-11.1-10.7 -5.8 0-11.1 3.9-11.1 10.7 0 6.8 5.2 10.7 11.1 10.7S1000 563.5 1000 556.7M996.8 556.7c0 4.9-3.6 8.2-7.9 8.2l0 0c-4.4 0-7.9-3.3-7.9-8.2 0-4.9 3.6-8.2 7.9-8.2C993.2 548.6 996.8 551.9 996.8 556.7z">
                    </path>
                    <path
                        d="M593.2 442.7v121.1h34.2V442.7H593.2zM324.4 442.5v121.2h34.5v-94.1l26.9 0.1c8.8 0 15 2.2 19.2 6.7 5.4 5.7 7.6 15.1 7.6 32v55.3H446v-66.9c0-47.8-30.5-54.3-60.2-54.3C385.8 442.5 324.4 442.5 324.4 442.5zM648.3 442.7v121.1h55.4c29.5 0 39.2-4.9 49.6-15.9 7.4-7.7 12.1-24.7 12.1-43.3 0-17-4-32.2-11.1-41.7 -12.5-16.9-30.8-20.2-58.1-20.2H648.3zM682.2 469h14.7c21.3 0 35.1 9.5 35.1 34.4 0 24.8-13.7 34.4-35.1 34.4h-14.7V469zM544 442.7l-28.5 95.9 -27.3-95.9h-36.9l39 121.1h49.2l39.3-121.1H544zM781.4 563.7h34.2v-121h-34.2V563.7zM877.2 442.7l-47.7 121h33.7l7.6-21.3h56.5l7.2 21.3h36.6l-48.2-121H877.2zM899.4 464.8l20.7 56.6H878L899.4 464.8z">
                    </path>
                    <path
                        d="M103.9 462.8v-16.6c1.6-0.1 3.3-0.2 4.9-0.2 45.6-1.4 75.4 39.1 75.4 39.1s-32.2 44.8-66.8 44.8c-5 0-9.4-0.8-13.4-2.2v-50.5c17.7 2.2 21.3 10 32 27.7l23.7-20c0 0-17.3-22.7-46.5-22.7C110 462.3 106.9 462.5 103.9 462.8M103.9 407.8v24.8c1.6-0.1 3.3-0.2 4.9-0.3 63.3-2.2 104.6 51.9 104.6 51.9s-47.4 57.7-96.7 57.7c-4.5 0-8.7-0.4-12.7-1.1v15.4c3.4 0.4 7 0.7 10.6 0.7 46 0 79.2-23.5 111.4-51.2 5.3 4.3 27.2 14.7 31.7 19.2 -30.6 25.6-101.9 46.3-142.3 46.3 -3.9 0-7.6-0.2-11.3-0.6v21.6h174.7V407.8H103.9zM103.9 527.8v13.1c-42.5-7.6-54.3-51.8-54.3-51.8s20.4-22.6 54.3-26.3v14.4c0 0 0 0-0.1 0 -17.7-2.2-31.7 14.5-31.7 14.5S80 519.6 103.9 527.8M28.4 487.2c0 0 25.2-37.1 75.5-41v-13.5C48.2 437.2 0 484.4 0 484.4s27.3 79 103.9 86.3v-14.4C47.7 549.2 28.4 487.2 28.4 487.2z">
                    </path>
                </g>
            </svg>
        </div>
        <div class="left-links">
            <div class="footer-item">
                <a href="https://www.nvidia.com/nl-nl/about-nvidia/privacy-policy/" target="_blank" rel="noopener">
                    <span>Privacybeleid</span>
                </a>
            </div>
            <div class="footer-item">
                <a href="https://www.nvidia.com/en-us/about-nvidia/terms-of-service/" target="_blank" rel="noopener">
                    <span> Servicevoorwaarden</span>
                </a>
            </div>
            <div class="footer-item">
                <a href="https://www.nvidia.com/en-gb/contact/" target="_blank" rel="noopener">
                    <span>Contact met ons op</span>
                </a>
            </div>
        </div>
        <div class="footer-item" role="button" aria-pressed="true" (click)="openModal('countries')">
            <a href="javascript: void(0)" target="_self">
                <span class="country-select">NL - Netherlands</span>
            </a>
        </div>
        <div class="global-footer__copyright global-footer__copyright_adjustment">Copyright © <!-- -->2024<!-- -->
            NVIDIA Corporation</div>
    </div>

    <div *ngIf="locale === 'ja-jp'" class="footer-inner">
        <svg class="global-footer__logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 407.8 1000 184.4"
            enableBackground="new 0 407.8 1000 184.4" xmlSpace="preserve">
            <title>NVIDIA</title>
            <g id="nvidia-logo">
                <path
                    d="M987.1 555.7v-2.8h1.7c0.9 0 2.3 0.1 2.3 1.2 0 1.2-0.7 1.5-1.8 1.5H987.1M987.1 557.6h1.2l2.8 4.8h3l-3.1-5c1.5-0.1 2.9-0.8 2.9-3 0-2.7-1.8-3.5-4.9-3.5h-4.4v11.5h2.6L987.1 557.6M1000 556.7c0-6.8-5.2-10.7-11.1-10.7 -5.8 0-11.1 3.9-11.1 10.7 0 6.8 5.2 10.7 11.1 10.7S1000 563.5 1000 556.7M996.8 556.7c0 4.9-3.6 8.2-7.9 8.2l0 0c-4.4 0-7.9-3.3-7.9-8.2 0-4.9 3.6-8.2 7.9-8.2C993.2 548.6 996.8 551.9 996.8 556.7z">
                </path>
                <path
                    d="M593.2 442.7v121.1h34.2V442.7H593.2zM324.4 442.5v121.2h34.5v-94.1l26.9 0.1c8.8 0 15 2.2 19.2 6.7 5.4 5.7 7.6 15.1 7.6 32v55.3H446v-66.9c0-47.8-30.5-54.3-60.2-54.3C385.8 442.5 324.4 442.5 324.4 442.5zM648.3 442.7v121.1h55.4c29.5 0 39.2-4.9 49.6-15.9 7.4-7.7 12.1-24.7 12.1-43.3 0-17-4-32.2-11.1-41.7 -12.5-16.9-30.8-20.2-58.1-20.2H648.3zM682.2 469h14.7c21.3 0 35.1 9.5 35.1 34.4 0 24.8-13.7 34.4-35.1 34.4h-14.7V469zM544 442.7l-28.5 95.9 -27.3-95.9h-36.9l39 121.1h49.2l39.3-121.1H544zM781.4 563.7h34.2v-121h-34.2V563.7zM877.2 442.7l-47.7 121h33.7l7.6-21.3h56.5l7.2 21.3h36.6l-48.2-121H877.2zM899.4 464.8l20.7 56.6H878L899.4 464.8z">
                </path>
                <path
                    d="M103.9 462.8v-16.6c1.6-0.1 3.3-0.2 4.9-0.2 45.6-1.4 75.4 39.1 75.4 39.1s-32.2 44.8-66.8 44.8c-5 0-9.4-0.8-13.4-2.2v-50.5c17.7 2.2 21.3 10 32 27.7l23.7-20c0 0-17.3-22.7-46.5-22.7C110 462.3 106.9 462.5 103.9 462.8M103.9 407.8v24.8c1.6-0.1 3.3-0.2 4.9-0.3 63.3-2.2 104.6 51.9 104.6 51.9s-47.4 57.7-96.7 57.7c-4.5 0-8.7-0.4-12.7-1.1v15.4c3.4 0.4 7 0.7 10.6 0.7 46 0 79.2-23.5 111.4-51.2 5.3 4.3 27.2 14.7 31.7 19.2 -30.6 25.6-101.9 46.3-142.3 46.3 -3.9 0-7.6-0.2-11.3-0.6v21.6h174.7V407.8H103.9zM103.9 527.8v13.1c-42.5-7.6-54.3-51.8-54.3-51.8s20.4-22.6 54.3-26.3v14.4c0 0 0 0-0.1 0 -17.7-2.2-31.7 14.5-31.7 14.5S80 519.6 103.9 527.8M28.4 487.2c0 0 25.2-37.1 75.5-41v-13.5C48.2 437.2 0 484.4 0 484.4s27.3 79 103.9 86.3v-14.4C47.7 549.2 28.4 487.2 28.4 487.2z">
                </path>
            </g>
        </svg>
        <div class="left-links">
            <div class="footer-item">
                <a href="https://www.nvidia.com/ja-jp/about-nvidia/privacy-policy/" target="_blank" rel="noopener">
                    <span>個人情報の保護</span>
                </a>
            </div>
            <div class="footer-item">
                <a href="https://www.nvidia.com/ja-jp/about-nvidia/terms-of-service/" target="_blank" rel="noopener">
                    <span>利用規約</span>
                </a>
            </div>
            <div class="footer-item">
                <a href="https://www.nvidia.com/ja-jp/contact/" target="_blank" rel="noopener">
                    <span>お問い合わせ</span>
                </a>
            </div>
        </div>
        <div class="footer-item" role="button" aria-pressed="true" (click)="openModal('countries')">
            <a href="javascript: void(0)" target="_self">
                <span class="country-select">JP - Japan</span>
            </a>
        </div>
        <div class="global-footer__copyright global-footer__copyright_adjustment">Copyright © <!-- -->2024<!-- -->
            NVIDIA Corporation</div>
    </div>


    <div *ngIf="locale === 'vi-vn'" class="footer-inner">
        <svg class="global-footer__logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 407.8 1000 184.4"
            enableBackground="new 0 407.8 1000 184.4" xmlSpace="preserve">
            <title>NVIDIA</title>
            <g id="nvidia-logo">
                <path
                    d="M987.1 555.7v-2.8h1.7c0.9 0 2.3 0.1 2.3 1.2 0 1.2-0.7 1.5-1.8 1.5H987.1M987.1 557.6h1.2l2.8 4.8h3l-3.1-5c1.5-0.1 2.9-0.8 2.9-3 0-2.7-1.8-3.5-4.9-3.5h-4.4v11.5h2.6L987.1 557.6M1000 556.7c0-6.8-5.2-10.7-11.1-10.7 -5.8 0-11.1 3.9-11.1 10.7 0 6.8 5.2 10.7 11.1 10.7S1000 563.5 1000 556.7M996.8 556.7c0 4.9-3.6 8.2-7.9 8.2l0 0c-4.4 0-7.9-3.3-7.9-8.2 0-4.9 3.6-8.2 7.9-8.2C993.2 548.6 996.8 551.9 996.8 556.7z">
                </path>
                <path
                    d="M593.2 442.7v121.1h34.2V442.7H593.2zM324.4 442.5v121.2h34.5v-94.1l26.9 0.1c8.8 0 15 2.2 19.2 6.7 5.4 5.7 7.6 15.1 7.6 32v55.3H446v-66.9c0-47.8-30.5-54.3-60.2-54.3C385.8 442.5 324.4 442.5 324.4 442.5zM648.3 442.7v121.1h55.4c29.5 0 39.2-4.9 49.6-15.9 7.4-7.7 12.1-24.7 12.1-43.3 0-17-4-32.2-11.1-41.7 -12.5-16.9-30.8-20.2-58.1-20.2H648.3zM682.2 469h14.7c21.3 0 35.1 9.5 35.1 34.4 0 24.8-13.7 34.4-35.1 34.4h-14.7V469zM544 442.7l-28.5 95.9 -27.3-95.9h-36.9l39 121.1h49.2l39.3-121.1H544zM781.4 563.7h34.2v-121h-34.2V563.7zM877.2 442.7l-47.7 121h33.7l7.6-21.3h56.5l7.2 21.3h36.6l-48.2-121H877.2zM899.4 464.8l20.7 56.6H878L899.4 464.8z">
                </path>
                <path
                    d="M103.9 462.8v-16.6c1.6-0.1 3.3-0.2 4.9-0.2 45.6-1.4 75.4 39.1 75.4 39.1s-32.2 44.8-66.8 44.8c-5 0-9.4-0.8-13.4-2.2v-50.5c17.7 2.2 21.3 10 32 27.7l23.7-20c0 0-17.3-22.7-46.5-22.7C110 462.3 106.9 462.5 103.9 462.8M103.9 407.8v24.8c1.6-0.1 3.3-0.2 4.9-0.3 63.3-2.2 104.6 51.9 104.6 51.9s-47.4 57.7-96.7 57.7c-4.5 0-8.7-0.4-12.7-1.1v15.4c3.4 0.4 7 0.7 10.6 0.7 46 0 79.2-23.5 111.4-51.2 5.3 4.3 27.2 14.7 31.7 19.2 -30.6 25.6-101.9 46.3-142.3 46.3 -3.9 0-7.6-0.2-11.3-0.6v21.6h174.7V407.8H103.9zM103.9 527.8v13.1c-42.5-7.6-54.3-51.8-54.3-51.8s20.4-22.6 54.3-26.3v14.4c0 0 0 0-0.1 0 -17.7-2.2-31.7 14.5-31.7 14.5S80 519.6 103.9 527.8M28.4 487.2c0 0 25.2-37.1 75.5-41v-13.5C48.2 437.2 0 484.4 0 484.4s27.3 79 103.9 86.3v-14.4C47.7 549.2 28.4 487.2 28.4 487.2z">
                </path>
            </g>
        </svg>
        <div class="left-links">
            <div class="footer-item">
                <a href="https://www.nvidia.com/en-us/about-nvidia/privacy-policy" target="_blank" rel="noopener">
                    <span>Privacy</span>
                </a>
            </div>
            <div class="footer-item">
                <a href="https://www.nvidia.com/en-us/about-nvidia/legal-info/" target="_blank" rel="noopener">
                    <span>Legal Info</span>
                </a>
            </div>
            <div class="footer-item">
                <a href="https://www.nvidia.com/object/contact-information.html" target="_blank" rel="noopener">
                    <span>Contact Us</span>
                </a>
            </div>
        </div>
        <div class="footer-item" role="button" aria-pressed="true" (click)="openModal('countries')">
            <a href="javascript: void(0)" target="_self">
                <span class="country-select">VN - Vietnam</span>
            </a>
        </div>
        <div class="global-footer__copyright global-footer__copyright_adjustment">Copyright © <!-- -->2024<!-- -->
            NVIDIA Corporation</div>
    </div>

    <div *ngIf="locale === 'it-it'" class="footer-inner">
        <div>
            <svg class="global-footer__logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 407.8 1000 184.4"
                enableBackground="new 0 407.8 1000 184.4" xmlSpace="preserve">
                <title>NVIDIA</title>
                <g id="nvidia-logo">
                    <path
                        d="M987.1 555.7v-2.8h1.7c0.9 0 2.3 0.1 2.3 1.2 0 1.2-0.7 1.5-1.8 1.5H987.1M987.1 557.6h1.2l2.8 4.8h3l-3.1-5c1.5-0.1 2.9-0.8 2.9-3 0-2.7-1.8-3.5-4.9-3.5h-4.4v11.5h2.6L987.1 557.6M1000 556.7c0-6.8-5.2-10.7-11.1-10.7 -5.8 0-11.1 3.9-11.1 10.7 0 6.8 5.2 10.7 11.1 10.7S1000 563.5 1000 556.7M996.8 556.7c0 4.9-3.6 8.2-7.9 8.2l0 0c-4.4 0-7.9-3.3-7.9-8.2 0-4.9 3.6-8.2 7.9-8.2C993.2 548.6 996.8 551.9 996.8 556.7z">
                    </path>
                    <path
                        d="M593.2 442.7v121.1h34.2V442.7H593.2zM324.4 442.5v121.2h34.5v-94.1l26.9 0.1c8.8 0 15 2.2 19.2 6.7 5.4 5.7 7.6 15.1 7.6 32v55.3H446v-66.9c0-47.8-30.5-54.3-60.2-54.3C385.8 442.5 324.4 442.5 324.4 442.5zM648.3 442.7v121.1h55.4c29.5 0 39.2-4.9 49.6-15.9 7.4-7.7 12.1-24.7 12.1-43.3 0-17-4-32.2-11.1-41.7 -12.5-16.9-30.8-20.2-58.1-20.2H648.3zM682.2 469h14.7c21.3 0 35.1 9.5 35.1 34.4 0 24.8-13.7 34.4-35.1 34.4h-14.7V469zM544 442.7l-28.5 95.9 -27.3-95.9h-36.9l39 121.1h49.2l39.3-121.1H544zM781.4 563.7h34.2v-121h-34.2V563.7zM877.2 442.7l-47.7 121h33.7l7.6-21.3h56.5l7.2 21.3h36.6l-48.2-121H877.2zM899.4 464.8l20.7 56.6H878L899.4 464.8z">
                    </path>
                    <path
                        d="M103.9 462.8v-16.6c1.6-0.1 3.3-0.2 4.9-0.2 45.6-1.4 75.4 39.1 75.4 39.1s-32.2 44.8-66.8 44.8c-5 0-9.4-0.8-13.4-2.2v-50.5c17.7 2.2 21.3 10 32 27.7l23.7-20c0 0-17.3-22.7-46.5-22.7C110 462.3 106.9 462.5 103.9 462.8M103.9 407.8v24.8c1.6-0.1 3.3-0.2 4.9-0.3 63.3-2.2 104.6 51.9 104.6 51.9s-47.4 57.7-96.7 57.7c-4.5 0-8.7-0.4-12.7-1.1v15.4c3.4 0.4 7 0.7 10.6 0.7 46 0 79.2-23.5 111.4-51.2 5.3 4.3 27.2 14.7 31.7 19.2 -30.6 25.6-101.9 46.3-142.3 46.3 -3.9 0-7.6-0.2-11.3-0.6v21.6h174.7V407.8H103.9zM103.9 527.8v13.1c-42.5-7.6-54.3-51.8-54.3-51.8s20.4-22.6 54.3-26.3v14.4c0 0 0 0-0.1 0 -17.7-2.2-31.7 14.5-31.7 14.5S80 519.6 103.9 527.8M28.4 487.2c0 0 25.2-37.1 75.5-41v-13.5C48.2 437.2 0 484.4 0 484.4s27.3 79 103.9 86.3v-14.4C47.7 549.2 28.4 487.2 28.4 487.2z">
                    </path>
                </g>
            </svg>
        </div>
        <div class="left-links">
            <div class="footer-item">
                <a href="https://www.nvidia.com/it-it/about-nvidia/privacy-policy" target="_blank" rel="noopener">
                    <span>Privacy</span>
                </a>
            </div>
            <div class="footer-item">
                <a href="https://www.nvidia.com/en-us/about-nvidia/terms-of-service/" target="_blank" rel="noopener">
                    <span>Termini di servizio</span>
                </a>
            </div>
            <div class="footer-item">
                <a href="https://www.nvidia.com/it-it/contact" target="_blank" rel="noopener">
                    <span>Contatti</span>
                </a>
            </div>
        </div>
        <div class="footer-item" role="button" aria-pressed="true" (click)="openModal('countries')">
            <a href="javascript: void(0)" target="_self">
                <span class="country-select">IT - ITALIA</span>
            </a>
        </div>

        <div class="global-footer__copyright global-footer__copyright_adjustment">Copyright © <!-- -->2024<!-- -->
            NVIDIA Corporation</div>
    </div>
    <div *ngIf="locale === 'pt-br'" class="footer-inner">
        <svg class="global-footer__logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 407.8 1000 184.4"
            enableBackground="new 0 407.8 1000 184.4" xmlSpace="preserve">
            <title>NVIDIA</title>
            <g id="nvidia-logo">
                <path
                    d="M987.1 555.7v-2.8h1.7c0.9 0 2.3 0.1 2.3 1.2 0 1.2-0.7 1.5-1.8 1.5H987.1M987.1 557.6h1.2l2.8 4.8h3l-3.1-5c1.5-0.1 2.9-0.8 2.9-3 0-2.7-1.8-3.5-4.9-3.5h-4.4v11.5h2.6L987.1 557.6M1000 556.7c0-6.8-5.2-10.7-11.1-10.7 -5.8 0-11.1 3.9-11.1 10.7 0 6.8 5.2 10.7 11.1 10.7S1000 563.5 1000 556.7M996.8 556.7c0 4.9-3.6 8.2-7.9 8.2l0 0c-4.4 0-7.9-3.3-7.9-8.2 0-4.9 3.6-8.2 7.9-8.2C993.2 548.6 996.8 551.9 996.8 556.7z">
                </path>
                <path
                    d="M593.2 442.7v121.1h34.2V442.7H593.2zM324.4 442.5v121.2h34.5v-94.1l26.9 0.1c8.8 0 15 2.2 19.2 6.7 5.4 5.7 7.6 15.1 7.6 32v55.3H446v-66.9c0-47.8-30.5-54.3-60.2-54.3C385.8 442.5 324.4 442.5 324.4 442.5zM648.3 442.7v121.1h55.4c29.5 0 39.2-4.9 49.6-15.9 7.4-7.7 12.1-24.7 12.1-43.3 0-17-4-32.2-11.1-41.7 -12.5-16.9-30.8-20.2-58.1-20.2H648.3zM682.2 469h14.7c21.3 0 35.1 9.5 35.1 34.4 0 24.8-13.7 34.4-35.1 34.4h-14.7V469zM544 442.7l-28.5 95.9 -27.3-95.9h-36.9l39 121.1h49.2l39.3-121.1H544zM781.4 563.7h34.2v-121h-34.2V563.7zM877.2 442.7l-47.7 121h33.7l7.6-21.3h56.5l7.2 21.3h36.6l-48.2-121H877.2zM899.4 464.8l20.7 56.6H878L899.4 464.8z">
                </path>
                <path
                    d="M103.9 462.8v-16.6c1.6-0.1 3.3-0.2 4.9-0.2 45.6-1.4 75.4 39.1 75.4 39.1s-32.2 44.8-66.8 44.8c-5 0-9.4-0.8-13.4-2.2v-50.5c17.7 2.2 21.3 10 32 27.7l23.7-20c0 0-17.3-22.7-46.5-22.7C110 462.3 106.9 462.5 103.9 462.8M103.9 407.8v24.8c1.6-0.1 3.3-0.2 4.9-0.3 63.3-2.2 104.6 51.9 104.6 51.9s-47.4 57.7-96.7 57.7c-4.5 0-8.7-0.4-12.7-1.1v15.4c3.4 0.4 7 0.7 10.6 0.7 46 0 79.2-23.5 111.4-51.2 5.3 4.3 27.2 14.7 31.7 19.2 -30.6 25.6-101.9 46.3-142.3 46.3 -3.9 0-7.6-0.2-11.3-0.6v21.6h174.7V407.8H103.9zM103.9 527.8v13.1c-42.5-7.6-54.3-51.8-54.3-51.8s20.4-22.6 54.3-26.3v14.4c0 0 0 0-0.1 0 -17.7-2.2-31.7 14.5-31.7 14.5S80 519.6 103.9 527.8M28.4 487.2c0 0 25.2-37.1 75.5-41v-13.5C48.2 437.2 0 484.4 0 484.4s27.3 79 103.9 86.3v-14.4C47.7 549.2 28.4 487.2 28.4 487.2z">
                </path>
            </g>
        </svg>
        <div class="left-links">
            <div class="footer-item">
                <a href="https://www.nvidia.com/pt-br/about-nvidia/privacy-policy" target="_blank" rel="noopener">
                    <span>Privacidade</span>
                </a>
            </div>
            <div class="footer-item">
                <a href="https://www.nvidia.com/en-us/about-nvidia/terms-of-service/" target="_blank" rel="noopener">
                    <span>Terms of Service</span>
                </a>
            </div>
            <div class="footer-item">
                <a href="https://www.nvidia.com/pt-br/contact" target="_blank" rel="noopener">
                    <span>Contato</span>
                </a>
            </div>
        </div>
        <div class="footer-item" role="button" aria-pressed="true" (click)="openModal('countries')">
            <a href="javascript: void(0)" target="_self">
                <span class="country-select">BR - BRAZIL</span>
            </a>
        </div>
        <div class="global-footer__copyright global-footer__copyright_adjustment">Copyright © <!-- -->2024<!-- -->
            NVIDIA Corporation</div>
    </div>
    <div *ngIf="locale === 'zh-tw'" class="footer-inner">
        <svg class="global-footer__logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 407.8 1000 184.4"
            enableBackground="new 0 407.8 1000 184.4" xmlSpace="preserve">
            <title>NVIDIA</title>
            <g id="nvidia-logo">
                <path
                    d="M987.1 555.7v-2.8h1.7c0.9 0 2.3 0.1 2.3 1.2 0 1.2-0.7 1.5-1.8 1.5H987.1M987.1 557.6h1.2l2.8 4.8h3l-3.1-5c1.5-0.1 2.9-0.8 2.9-3 0-2.7-1.8-3.5-4.9-3.5h-4.4v11.5h2.6L987.1 557.6M1000 556.7c0-6.8-5.2-10.7-11.1-10.7 -5.8 0-11.1 3.9-11.1 10.7 0 6.8 5.2 10.7 11.1 10.7S1000 563.5 1000 556.7M996.8 556.7c0 4.9-3.6 8.2-7.9 8.2l0 0c-4.4 0-7.9-3.3-7.9-8.2 0-4.9 3.6-8.2 7.9-8.2C993.2 548.6 996.8 551.9 996.8 556.7z">
                </path>
                <path
                    d="M593.2 442.7v121.1h34.2V442.7H593.2zM324.4 442.5v121.2h34.5v-94.1l26.9 0.1c8.8 0 15 2.2 19.2 6.7 5.4 5.7 7.6 15.1 7.6 32v55.3H446v-66.9c0-47.8-30.5-54.3-60.2-54.3C385.8 442.5 324.4 442.5 324.4 442.5zM648.3 442.7v121.1h55.4c29.5 0 39.2-4.9 49.6-15.9 7.4-7.7 12.1-24.7 12.1-43.3 0-17-4-32.2-11.1-41.7 -12.5-16.9-30.8-20.2-58.1-20.2H648.3zM682.2 469h14.7c21.3 0 35.1 9.5 35.1 34.4 0 24.8-13.7 34.4-35.1 34.4h-14.7V469zM544 442.7l-28.5 95.9 -27.3-95.9h-36.9l39 121.1h49.2l39.3-121.1H544zM781.4 563.7h34.2v-121h-34.2V563.7zM877.2 442.7l-47.7 121h33.7l7.6-21.3h56.5l7.2 21.3h36.6l-48.2-121H877.2zM899.4 464.8l20.7 56.6H878L899.4 464.8z">
                </path>
                <path
                    d="M103.9 462.8v-16.6c1.6-0.1 3.3-0.2 4.9-0.2 45.6-1.4 75.4 39.1 75.4 39.1s-32.2 44.8-66.8 44.8c-5 0-9.4-0.8-13.4-2.2v-50.5c17.7 2.2 21.3 10 32 27.7l23.7-20c0 0-17.3-22.7-46.5-22.7C110 462.3 106.9 462.5 103.9 462.8M103.9 407.8v24.8c1.6-0.1 3.3-0.2 4.9-0.3 63.3-2.2 104.6 51.9 104.6 51.9s-47.4 57.7-96.7 57.7c-4.5 0-8.7-0.4-12.7-1.1v15.4c3.4 0.4 7 0.7 10.6 0.7 46 0 79.2-23.5 111.4-51.2 5.3 4.3 27.2 14.7 31.7 19.2 -30.6 25.6-101.9 46.3-142.3 46.3 -3.9 0-7.6-0.2-11.3-0.6v21.6h174.7V407.8H103.9zM103.9 527.8v13.1c-42.5-7.6-54.3-51.8-54.3-51.8s20.4-22.6 54.3-26.3v14.4c0 0 0 0-0.1 0 -17.7-2.2-31.7 14.5-31.7 14.5S80 519.6 103.9 527.8M28.4 487.2c0 0 25.2-37.1 75.5-41v-13.5C48.2 437.2 0 484.4 0 484.4s27.3 79 103.9 86.3v-14.4C47.7 549.2 28.4 487.2 28.4 487.2z">
                </path>
            </g>
        </svg>
        <div class="left-links">
            <div class="footer-item">
                <a href="https://www.nvidia.com/zh-tw/about-nvidia/privacy-policy" target="_blank" rel="noopener">
                    <span>隱私權</span>
                </a>
            </div>
            <div class="footer-item">
                <a href="https://www.nvidia.com/en-us/about-nvidia/terms-of-service/" target="_blank" rel="noopener">
                    <span>合法的</span>
                </a>
            </div>
            <div class="footer-item">
                <a href="https://www.nvidia.com/zh-tw/contact" target="_blank" rel="noopener">
                    <span>聯絡</span>
                </a>
            </div>
        </div>
        <div class="footer-item" role="button" aria-pressed="true" (click)="openModal('countries')">
            <a href="javascript: void(0)" target="_self">
                <span class="country-select">TW - Taiwan</span>
            </a>
        </div>
        <div class="global-footer__copyright global-footer__copyright_adjustment">Copyright © <!-- -->2024<!-- -->
            NVIDIA Corporation</div>
    </div>


    <div *ngIf="locale === 'fi-fi'" class="footer-inner">
        <svg class="global-footer__logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 407.8 1000 184.4"
            enableBackground="new 0 407.8 1000 184.4" xmlSpace="preserve">
            <title>NVIDIA</title>
            <g id="nvidia-logo">
                <path
                    d="M987.1 555.7v-2.8h1.7c0.9 0 2.3 0.1 2.3 1.2 0 1.2-0.7 1.5-1.8 1.5H987.1M987.1 557.6h1.2l2.8 4.8h3l-3.1-5c1.5-0.1 2.9-0.8 2.9-3 0-2.7-1.8-3.5-4.9-3.5h-4.4v11.5h2.6L987.1 557.6M1000 556.7c0-6.8-5.2-10.7-11.1-10.7 -5.8 0-11.1 3.9-11.1 10.7 0 6.8 5.2 10.7 11.1 10.7S1000 563.5 1000 556.7M996.8 556.7c0 4.9-3.6 8.2-7.9 8.2l0 0c-4.4 0-7.9-3.3-7.9-8.2 0-4.9 3.6-8.2 7.9-8.2C993.2 548.6 996.8 551.9 996.8 556.7z">
                </path>
                <path
                    d="M593.2 442.7v121.1h34.2V442.7H593.2zM324.4 442.5v121.2h34.5v-94.1l26.9 0.1c8.8 0 15 2.2 19.2 6.7 5.4 5.7 7.6 15.1 7.6 32v55.3H446v-66.9c0-47.8-30.5-54.3-60.2-54.3C385.8 442.5 324.4 442.5 324.4 442.5zM648.3 442.7v121.1h55.4c29.5 0 39.2-4.9 49.6-15.9 7.4-7.7 12.1-24.7 12.1-43.3 0-17-4-32.2-11.1-41.7 -12.5-16.9-30.8-20.2-58.1-20.2H648.3zM682.2 469h14.7c21.3 0 35.1 9.5 35.1 34.4 0 24.8-13.7 34.4-35.1 34.4h-14.7V469zM544 442.7l-28.5 95.9 -27.3-95.9h-36.9l39 121.1h49.2l39.3-121.1H544zM781.4 563.7h34.2v-121h-34.2V563.7zM877.2 442.7l-47.7 121h33.7l7.6-21.3h56.5l7.2 21.3h36.6l-48.2-121H877.2zM899.4 464.8l20.7 56.6H878L899.4 464.8z">
                </path>
                <path
                    d="M103.9 462.8v-16.6c1.6-0.1 3.3-0.2 4.9-0.2 45.6-1.4 75.4 39.1 75.4 39.1s-32.2 44.8-66.8 44.8c-5 0-9.4-0.8-13.4-2.2v-50.5c17.7 2.2 21.3 10 32 27.7l23.7-20c0 0-17.3-22.7-46.5-22.7C110 462.3 106.9 462.5 103.9 462.8M103.9 407.8v24.8c1.6-0.1 3.3-0.2 4.9-0.3 63.3-2.2 104.6 51.9 104.6 51.9s-47.4 57.7-96.7 57.7c-4.5 0-8.7-0.4-12.7-1.1v15.4c3.4 0.4 7 0.7 10.6 0.7 46 0 79.2-23.5 111.4-51.2 5.3 4.3 27.2 14.7 31.7 19.2 -30.6 25.6-101.9 46.3-142.3 46.3 -3.9 0-7.6-0.2-11.3-0.6v21.6h174.7V407.8H103.9zM103.9 527.8v13.1c-42.5-7.6-54.3-51.8-54.3-51.8s20.4-22.6 54.3-26.3v14.4c0 0 0 0-0.1 0 -17.7-2.2-31.7 14.5-31.7 14.5S80 519.6 103.9 527.8M28.4 487.2c0 0 25.2-37.1 75.5-41v-13.5C48.2 437.2 0 484.4 0 484.4s27.3 79 103.9 86.3v-14.4C47.7 549.2 28.4 487.2 28.4 487.2z">
                </path>
            </g>
        </svg>
        <div class="left-links">
            <div class="footer-item">
                <a href="https://www.nvidia.com/fi-fi/about-nvidia/privacy-policy" target="_blank" rel="noopener">
                    <span>Tietosuoja</span>
                </a>
            </div>
            <div class="footer-item">
                <a href="https://www.nvidia.com/en-us/about-nvidia/terms-of-service/" target="_blank" rel="noopener">
                    <span>Palveluehdot</span>
                </a>
            </div>
            <div class="footer-item">
                <a href="https://www.nvidia.com/en-gb/contact" target="_blank" rel="noopener">
                    <span>Ota yhteyttä</span>
                </a>
            </div>
        </div>
        <div class="footer-item" role="button" aria-pressed="true" (click)="openModal('countries')">
            <a href="javascript: void(0)" target="_self">
                <span class="country-select">FI - SUOMI</span>
            </a>
        </div>
        <div class="global-footer__copyright global-footer__copyright_adjustment">Copyright © <!-- -->2024<!-- -->
            NVIDIA Corporation</div>
    </div>

    <div *ngIf="locale === 'nb-no'" class="footer-inner">
        <svg class="global-footer__logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 407.8 1000 184.4"
            enableBackground="new 0 407.8 1000 184.4" xmlSpace="preserve">
            <title>NVIDIA</title>
            <g id="nvidia-logo">
                <path
                    d="M987.1 555.7v-2.8h1.7c0.9 0 2.3 0.1 2.3 1.2 0 1.2-0.7 1.5-1.8 1.5H987.1M987.1 557.6h1.2l2.8 4.8h3l-3.1-5c1.5-0.1 2.9-0.8 2.9-3 0-2.7-1.8-3.5-4.9-3.5h-4.4v11.5h2.6L987.1 557.6M1000 556.7c0-6.8-5.2-10.7-11.1-10.7 -5.8 0-11.1 3.9-11.1 10.7 0 6.8 5.2 10.7 11.1 10.7S1000 563.5 1000 556.7M996.8 556.7c0 4.9-3.6 8.2-7.9 8.2l0 0c-4.4 0-7.9-3.3-7.9-8.2 0-4.9 3.6-8.2 7.9-8.2C993.2 548.6 996.8 551.9 996.8 556.7z">
                </path>
                <path
                    d="M593.2 442.7v121.1h34.2V442.7H593.2zM324.4 442.5v121.2h34.5v-94.1l26.9 0.1c8.8 0 15 2.2 19.2 6.7 5.4 5.7 7.6 15.1 7.6 32v55.3H446v-66.9c0-47.8-30.5-54.3-60.2-54.3C385.8 442.5 324.4 442.5 324.4 442.5zM648.3 442.7v121.1h55.4c29.5 0 39.2-4.9 49.6-15.9 7.4-7.7 12.1-24.7 12.1-43.3 0-17-4-32.2-11.1-41.7 -12.5-16.9-30.8-20.2-58.1-20.2H648.3zM682.2 469h14.7c21.3 0 35.1 9.5 35.1 34.4 0 24.8-13.7 34.4-35.1 34.4h-14.7V469zM544 442.7l-28.5 95.9 -27.3-95.9h-36.9l39 121.1h49.2l39.3-121.1H544zM781.4 563.7h34.2v-121h-34.2V563.7zM877.2 442.7l-47.7 121h33.7l7.6-21.3h56.5l7.2 21.3h36.6l-48.2-121H877.2zM899.4 464.8l20.7 56.6H878L899.4 464.8z">
                </path>
                <path
                    d="M103.9 462.8v-16.6c1.6-0.1 3.3-0.2 4.9-0.2 45.6-1.4 75.4 39.1 75.4 39.1s-32.2 44.8-66.8 44.8c-5 0-9.4-0.8-13.4-2.2v-50.5c17.7 2.2 21.3 10 32 27.7l23.7-20c0 0-17.3-22.7-46.5-22.7C110 462.3 106.9 462.5 103.9 462.8M103.9 407.8v24.8c1.6-0.1 3.3-0.2 4.9-0.3 63.3-2.2 104.6 51.9 104.6 51.9s-47.4 57.7-96.7 57.7c-4.5 0-8.7-0.4-12.7-1.1v15.4c3.4 0.4 7 0.7 10.6 0.7 46 0 79.2-23.5 111.4-51.2 5.3 4.3 27.2 14.7 31.7 19.2 -30.6 25.6-101.9 46.3-142.3 46.3 -3.9 0-7.6-0.2-11.3-0.6v21.6h174.7V407.8H103.9zM103.9 527.8v13.1c-42.5-7.6-54.3-51.8-54.3-51.8s20.4-22.6 54.3-26.3v14.4c0 0 0 0-0.1 0 -17.7-2.2-31.7 14.5-31.7 14.5S80 519.6 103.9 527.8M28.4 487.2c0 0 25.2-37.1 75.5-41v-13.5C48.2 437.2 0 484.4 0 484.4s27.3 79 103.9 86.3v-14.4C47.7 549.2 28.4 487.2 28.4 487.2z">
                </path>
            </g>
        </svg>
        <div class="left-links">
            <div class="footer-item">
                <a href="https://www.nvidia.com/nb-no/about-nvidia/privacy-policy" target="_blank" rel="noopener">
                    <span>Personvern</span>
                </a>
            </div>
            <div class="footer-item">
                <a href="https://www.nvidia.com/en-us/about-nvidia/terms-of-service/" target="_blank" rel="noopener">
                    <span>Tjenestevilkår</span>
                </a>
            </div>
            <div class="footer-item">
                <a href="https://www.nvidia.com/en-gb/contact" target="_blank" rel="noopener">
                    <span>Kontakt</span>
                </a>
            </div>
        </div>
        <div class="footer-item" role="button" aria-pressed="true" (click)="openModal('countries')">
            <a href="javascript: void(0)" target="_self">
                <span class="country-select">NO - NORGE</span>
            </a>
        </div>
        <div class="global-footer__copyright global-footer__copyright_adjustment">Copyright © <!-- -->2024<!-- -->
            NVIDIA Corporation</div>
    </div>



    <div *ngIf="locale === 'da-dk'" class="footer-inner">
        <svg class="global-footer__logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 407.8 1000 184.4"
            enableBackground="new 0 407.8 1000 184.4" xmlSpace="preserve">
            <title>NVIDIA</title>
            <g id="nvidia-logo">
                <path
                    d="M987.1 555.7v-2.8h1.7c0.9 0 2.3 0.1 2.3 1.2 0 1.2-0.7 1.5-1.8 1.5H987.1M987.1 557.6h1.2l2.8 4.8h3l-3.1-5c1.5-0.1 2.9-0.8 2.9-3 0-2.7-1.8-3.5-4.9-3.5h-4.4v11.5h2.6L987.1 557.6M1000 556.7c0-6.8-5.2-10.7-11.1-10.7 -5.8 0-11.1 3.9-11.1 10.7 0 6.8 5.2 10.7 11.1 10.7S1000 563.5 1000 556.7M996.8 556.7c0 4.9-3.6 8.2-7.9 8.2l0 0c-4.4 0-7.9-3.3-7.9-8.2 0-4.9 3.6-8.2 7.9-8.2C993.2 548.6 996.8 551.9 996.8 556.7z">
                </path>
                <path
                    d="M593.2 442.7v121.1h34.2V442.7H593.2zM324.4 442.5v121.2h34.5v-94.1l26.9 0.1c8.8 0 15 2.2 19.2 6.7 5.4 5.7 7.6 15.1 7.6 32v55.3H446v-66.9c0-47.8-30.5-54.3-60.2-54.3C385.8 442.5 324.4 442.5 324.4 442.5zM648.3 442.7v121.1h55.4c29.5 0 39.2-4.9 49.6-15.9 7.4-7.7 12.1-24.7 12.1-43.3 0-17-4-32.2-11.1-41.7 -12.5-16.9-30.8-20.2-58.1-20.2H648.3zM682.2 469h14.7c21.3 0 35.1 9.5 35.1 34.4 0 24.8-13.7 34.4-35.1 34.4h-14.7V469zM544 442.7l-28.5 95.9 -27.3-95.9h-36.9l39 121.1h49.2l39.3-121.1H544zM781.4 563.7h34.2v-121h-34.2V563.7zM877.2 442.7l-47.7 121h33.7l7.6-21.3h56.5l7.2 21.3h36.6l-48.2-121H877.2zM899.4 464.8l20.7 56.6H878L899.4 464.8z">
                </path>
                <path
                    d="M103.9 462.8v-16.6c1.6-0.1 3.3-0.2 4.9-0.2 45.6-1.4 75.4 39.1 75.4 39.1s-32.2 44.8-66.8 44.8c-5 0-9.4-0.8-13.4-2.2v-50.5c17.7 2.2 21.3 10 32 27.7l23.7-20c0 0-17.3-22.7-46.5-22.7C110 462.3 106.9 462.5 103.9 462.8M103.9 407.8v24.8c1.6-0.1 3.3-0.2 4.9-0.3 63.3-2.2 104.6 51.9 104.6 51.9s-47.4 57.7-96.7 57.7c-4.5 0-8.7-0.4-12.7-1.1v15.4c3.4 0.4 7 0.7 10.6 0.7 46 0 79.2-23.5 111.4-51.2 5.3 4.3 27.2 14.7 31.7 19.2 -30.6 25.6-101.9 46.3-142.3 46.3 -3.9 0-7.6-0.2-11.3-0.6v21.6h174.7V407.8H103.9zM103.9 527.8v13.1c-42.5-7.6-54.3-51.8-54.3-51.8s20.4-22.6 54.3-26.3v14.4c0 0 0 0-0.1 0 -17.7-2.2-31.7 14.5-31.7 14.5S80 519.6 103.9 527.8M28.4 487.2c0 0 25.2-37.1 75.5-41v-13.5C48.2 437.2 0 484.4 0 484.4s27.3 79 103.9 86.3v-14.4C47.7 549.2 28.4 487.2 28.4 487.2z">
                </path>
            </g>
        </svg>
        <div class="left-links">

            <div class="footer-item">
                <a href="https://www.nvidia.com/da-dk/about-nvidia/privacy-policy" target="_blank" rel="noopener">
                    <span>Personlige oplysninger</span>
                </a>
            </div>
            <div class="footer-item">
                <a href="https://www.nvidia.com/en-us/about-nvidia/terms-of-service/" target="_blank" rel="noopener">
                    <span>Servicevilkår</span>
                </a>
            </div>
            <div class="footer-item">
                <a href="https://www.nvidia.com/en-gb/contact" target="_blank" rel="noopener">
                    <span>Kontakt</span>
                </a>
            </div>
        </div>
        <div class="footer-item" role="button" aria-pressed="true" (click)="openModal('countries')">
            <a href="javascript: void(0)" target="_self">
                <span class="country-select">DK - DANMARK</span>
            </a>
        </div>
        <div class="global-footer__copyright global-footer__copyright_adjustment">Copyright © <!-- -->2024<!-- -->
            NVIDIA Corporation</div>
    </div>

    <div *ngIf="locale === 'sv-se'" class="footer-inner">
        <svg class="global-footer__logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 407.8 1000 184.4"
            enableBackground="new 0 407.8 1000 184.4" xmlSpace="preserve">
            <title>NVIDIA</title>
            <g id="nvidia-logo">
                <path
                    d="M987.1 555.7v-2.8h1.7c0.9 0 2.3 0.1 2.3 1.2 0 1.2-0.7 1.5-1.8 1.5H987.1M987.1 557.6h1.2l2.8 4.8h3l-3.1-5c1.5-0.1 2.9-0.8 2.9-3 0-2.7-1.8-3.5-4.9-3.5h-4.4v11.5h2.6L987.1 557.6M1000 556.7c0-6.8-5.2-10.7-11.1-10.7 -5.8 0-11.1 3.9-11.1 10.7 0 6.8 5.2 10.7 11.1 10.7S1000 563.5 1000 556.7M996.8 556.7c0 4.9-3.6 8.2-7.9 8.2l0 0c-4.4 0-7.9-3.3-7.9-8.2 0-4.9 3.6-8.2 7.9-8.2C993.2 548.6 996.8 551.9 996.8 556.7z">
                </path>
                <path
                    d="M593.2 442.7v121.1h34.2V442.7H593.2zM324.4 442.5v121.2h34.5v-94.1l26.9 0.1c8.8 0 15 2.2 19.2 6.7 5.4 5.7 7.6 15.1 7.6 32v55.3H446v-66.9c0-47.8-30.5-54.3-60.2-54.3C385.8 442.5 324.4 442.5 324.4 442.5zM648.3 442.7v121.1h55.4c29.5 0 39.2-4.9 49.6-15.9 7.4-7.7 12.1-24.7 12.1-43.3 0-17-4-32.2-11.1-41.7 -12.5-16.9-30.8-20.2-58.1-20.2H648.3zM682.2 469h14.7c21.3 0 35.1 9.5 35.1 34.4 0 24.8-13.7 34.4-35.1 34.4h-14.7V469zM544 442.7l-28.5 95.9 -27.3-95.9h-36.9l39 121.1h49.2l39.3-121.1H544zM781.4 563.7h34.2v-121h-34.2V563.7zM877.2 442.7l-47.7 121h33.7l7.6-21.3h56.5l7.2 21.3h36.6l-48.2-121H877.2zM899.4 464.8l20.7 56.6H878L899.4 464.8z">
                </path>
                <path
                    d="M103.9 462.8v-16.6c1.6-0.1 3.3-0.2 4.9-0.2 45.6-1.4 75.4 39.1 75.4 39.1s-32.2 44.8-66.8 44.8c-5 0-9.4-0.8-13.4-2.2v-50.5c17.7 2.2 21.3 10 32 27.7l23.7-20c0 0-17.3-22.7-46.5-22.7C110 462.3 106.9 462.5 103.9 462.8M103.9 407.8v24.8c1.6-0.1 3.3-0.2 4.9-0.3 63.3-2.2 104.6 51.9 104.6 51.9s-47.4 57.7-96.7 57.7c-4.5 0-8.7-0.4-12.7-1.1v15.4c3.4 0.4 7 0.7 10.6 0.7 46 0 79.2-23.5 111.4-51.2 5.3 4.3 27.2 14.7 31.7 19.2 -30.6 25.6-101.9 46.3-142.3 46.3 -3.9 0-7.6-0.2-11.3-0.6v21.6h174.7V407.8H103.9zM103.9 527.8v13.1c-42.5-7.6-54.3-51.8-54.3-51.8s20.4-22.6 54.3-26.3v14.4c0 0 0 0-0.1 0 -17.7-2.2-31.7 14.5-31.7 14.5S80 519.6 103.9 527.8M28.4 487.2c0 0 25.2-37.1 75.5-41v-13.5C48.2 437.2 0 484.4 0 484.4s27.3 79 103.9 86.3v-14.4C47.7 549.2 28.4 487.2 28.4 487.2z">
                </path>
            </g>
        </svg>
        <div class="left-links">
            <div class="footer-item">
                <a href="https://www.nvidia.com/sv-se/about-nvidia/privacy-policy" target="_blank" rel="noopener">
                    <span>Sekretess</span>
                </a>
            </div>
            <div class="footer-item">
                <a href="https://www.nvidia.com/en-us/about-nvidia/terms-of-service/" target="_blank" rel="noopener">
                    <span>Användarvillkor</span>
                </a>
            </div>
            <div class="footer-item">
                <a href="https://www.nvidia.com/en-gb/contact" target="_blank" rel="noopener">
                    <span>Kontakta</span>
                </a>
            </div>
        </div>
        <div class="footer-item" role="button" aria-pressed="true" (click)="openModal('countries')">
            <a href="javascript: void(0)" target="_self">
                <span class="country-select">SE - SVERIGE</span>
            </a>
        </div>

        <div class="global-footer__copyright global-footer__copyright_adjustment">Copyright © <!-- -->2024<!-- -->
            NVIDIA Corporation</div>
    </div>

    <div *ngIf="locale === 'th-th'" class="footer-inner">
        <svg class="global-footer__logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 407.8 1000 184.4"
            enableBackground="new 0 407.8 1000 184.4" xmlSpace="preserve">
            <title>NVIDIA</title>
            <g id="nvidia-logo">
                <path
                    d="M987.1 555.7v-2.8h1.7c0.9 0 2.3 0.1 2.3 1.2 0 1.2-0.7 1.5-1.8 1.5H987.1M987.1 557.6h1.2l2.8 4.8h3l-3.1-5c1.5-0.1 2.9-0.8 2.9-3 0-2.7-1.8-3.5-4.9-3.5h-4.4v11.5h2.6L987.1 557.6M1000 556.7c0-6.8-5.2-10.7-11.1-10.7 -5.8 0-11.1 3.9-11.1 10.7 0 6.8 5.2 10.7 11.1 10.7S1000 563.5 1000 556.7M996.8 556.7c0 4.9-3.6 8.2-7.9 8.2l0 0c-4.4 0-7.9-3.3-7.9-8.2 0-4.9 3.6-8.2 7.9-8.2C993.2 548.6 996.8 551.9 996.8 556.7z">
                </path>
                <path
                    d="M593.2 442.7v121.1h34.2V442.7H593.2zM324.4 442.5v121.2h34.5v-94.1l26.9 0.1c8.8 0 15 2.2 19.2 6.7 5.4 5.7 7.6 15.1 7.6 32v55.3H446v-66.9c0-47.8-30.5-54.3-60.2-54.3C385.8 442.5 324.4 442.5 324.4 442.5zM648.3 442.7v121.1h55.4c29.5 0 39.2-4.9 49.6-15.9 7.4-7.7 12.1-24.7 12.1-43.3 0-17-4-32.2-11.1-41.7 -12.5-16.9-30.8-20.2-58.1-20.2H648.3zM682.2 469h14.7c21.3 0 35.1 9.5 35.1 34.4 0 24.8-13.7 34.4-35.1 34.4h-14.7V469zM544 442.7l-28.5 95.9 -27.3-95.9h-36.9l39 121.1h49.2l39.3-121.1H544zM781.4 563.7h34.2v-121h-34.2V563.7zM877.2 442.7l-47.7 121h33.7l7.6-21.3h56.5l7.2 21.3h36.6l-48.2-121H877.2zM899.4 464.8l20.7 56.6H878L899.4 464.8z">
                </path>
                <path
                    d="M103.9 462.8v-16.6c1.6-0.1 3.3-0.2 4.9-0.2 45.6-1.4 75.4 39.1 75.4 39.1s-32.2 44.8-66.8 44.8c-5 0-9.4-0.8-13.4-2.2v-50.5c17.7 2.2 21.3 10 32 27.7l23.7-20c0 0-17.3-22.7-46.5-22.7C110 462.3 106.9 462.5 103.9 462.8M103.9 407.8v24.8c1.6-0.1 3.3-0.2 4.9-0.3 63.3-2.2 104.6 51.9 104.6 51.9s-47.4 57.7-96.7 57.7c-4.5 0-8.7-0.4-12.7-1.1v15.4c3.4 0.4 7 0.7 10.6 0.7 46 0 79.2-23.5 111.4-51.2 5.3 4.3 27.2 14.7 31.7 19.2 -30.6 25.6-101.9 46.3-142.3 46.3 -3.9 0-7.6-0.2-11.3-0.6v21.6h174.7V407.8H103.9zM103.9 527.8v13.1c-42.5-7.6-54.3-51.8-54.3-51.8s20.4-22.6 54.3-26.3v14.4c0 0 0 0-0.1 0 -17.7-2.2-31.7 14.5-31.7 14.5S80 519.6 103.9 527.8M28.4 487.2c0 0 25.2-37.1 75.5-41v-13.5C48.2 437.2 0 484.4 0 484.4s27.3 79 103.9 86.3v-14.4C47.7 549.2 28.4 487.2 28.4 487.2z">
                </path>
            </g>
        </svg>
        <div class="left-links">
            <div class="footer-item">
                <a href="https://www.nvidia.com/th-th/about-nvidia/privacy-policy" target="_blank" rel="noopener">
                    <span>ความเป็นส่วนตัว</span>
                </a>
            </div>
            <div class="footer-item">
                <a href="https://www.nvidia.com/en-us/about-nvidia/terms-of-service/" target="_blank" rel="noopener">
                    <span>ข้อกำหนดทางกฎหมาย</span>
                </a>
            </div>
            <div class="footer-item">
                <a href="https://www.nvidia.com/en-gb/contact" target="_blank" rel="noopener">
                    <span>ติดต่อ</span>
                </a>
            </div>
        </div>
        <div class="footer-item" role="button" aria-pressed="true" (click)="openModal('countries')">
            <a href="javascript: void(0)" target="_self">
                <span class="country-select">TH - THAILAND</span>
            </a>
        </div>
        <div class="global-footer__copyright global-footer__copyright_adjustment">Copyright © <!-- -->2024<!-- -->
            NVIDIA Corporation</div>
    </div>
    <div *ngIf="locale === 'id-id'" class="footer-inner">
        <svg class="global-footer__logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 407.8 1000 184.4"
            enableBackground="new 0 407.8 1000 184.4" xmlSpace="preserve">
            <title>NVIDIA</title>
            <g id="nvidia-logo">
                <path
                    d="M987.1 555.7v-2.8h1.7c0.9 0 2.3 0.1 2.3 1.2 0 1.2-0.7 1.5-1.8 1.5H987.1M987.1 557.6h1.2l2.8 4.8h3l-3.1-5c1.5-0.1 2.9-0.8 2.9-3 0-2.7-1.8-3.5-4.9-3.5h-4.4v11.5h2.6L987.1 557.6M1000 556.7c0-6.8-5.2-10.7-11.1-10.7 -5.8 0-11.1 3.9-11.1 10.7 0 6.8 5.2 10.7 11.1 10.7S1000 563.5 1000 556.7M996.8 556.7c0 4.9-3.6 8.2-7.9 8.2l0 0c-4.4 0-7.9-3.3-7.9-8.2 0-4.9 3.6-8.2 7.9-8.2C993.2 548.6 996.8 551.9 996.8 556.7z">
                </path>
                <path
                    d="M593.2 442.7v121.1h34.2V442.7H593.2zM324.4 442.5v121.2h34.5v-94.1l26.9 0.1c8.8 0 15 2.2 19.2 6.7 5.4 5.7 7.6 15.1 7.6 32v55.3H446v-66.9c0-47.8-30.5-54.3-60.2-54.3C385.8 442.5 324.4 442.5 324.4 442.5zM648.3 442.7v121.1h55.4c29.5 0 39.2-4.9 49.6-15.9 7.4-7.7 12.1-24.7 12.1-43.3 0-17-4-32.2-11.1-41.7 -12.5-16.9-30.8-20.2-58.1-20.2H648.3zM682.2 469h14.7c21.3 0 35.1 9.5 35.1 34.4 0 24.8-13.7 34.4-35.1 34.4h-14.7V469zM544 442.7l-28.5 95.9 -27.3-95.9h-36.9l39 121.1h49.2l39.3-121.1H544zM781.4 563.7h34.2v-121h-34.2V563.7zM877.2 442.7l-47.7 121h33.7l7.6-21.3h56.5l7.2 21.3h36.6l-48.2-121H877.2zM899.4 464.8l20.7 56.6H878L899.4 464.8z">
                </path>
                <path
                    d="M103.9 462.8v-16.6c1.6-0.1 3.3-0.2 4.9-0.2 45.6-1.4 75.4 39.1 75.4 39.1s-32.2 44.8-66.8 44.8c-5 0-9.4-0.8-13.4-2.2v-50.5c17.7 2.2 21.3 10 32 27.7l23.7-20c0 0-17.3-22.7-46.5-22.7C110 462.3 106.9 462.5 103.9 462.8M103.9 407.8v24.8c1.6-0.1 3.3-0.2 4.9-0.3 63.3-2.2 104.6 51.9 104.6 51.9s-47.4 57.7-96.7 57.7c-4.5 0-8.7-0.4-12.7-1.1v15.4c3.4 0.4 7 0.7 10.6 0.7 46 0 79.2-23.5 111.4-51.2 5.3 4.3 27.2 14.7 31.7 19.2 -30.6 25.6-101.9 46.3-142.3 46.3 -3.9 0-7.6-0.2-11.3-0.6v21.6h174.7V407.8H103.9zM103.9 527.8v13.1c-42.5-7.6-54.3-51.8-54.3-51.8s20.4-22.6 54.3-26.3v14.4c0 0 0 0-0.1 0 -17.7-2.2-31.7 14.5-31.7 14.5S80 519.6 103.9 527.8M28.4 487.2c0 0 25.2-37.1 75.5-41v-13.5C48.2 437.2 0 484.4 0 484.4s27.3 79 103.9 86.3v-14.4C47.7 549.2 28.4 487.2 28.4 487.2z">
                </path>
            </g>
        </svg>
        <div class="left-links">
            <div class="footer-item">
                <a href="https://www.nvidia.com/id-id/about-nvidia/privacy-policy" target="_blank" rel="noopener">
                    <span>Privacy</span>
                </a>
            </div>
            <div class="footer-item">
                <a href="https://www.nvidia.com/en-us/about-nvidia/terms-of-service/" target="_blank" rel="noopener">
                    <span>Terms of Service</span>
                </a>
            </div>
            <div class="footer-item">
                <a href="https://www.nvidia.com/en-us/contact" target="_blank" rel="noopener">
                    <span>Kontak</span>
                </a>
            </div>
        </div>
        <div class="footer-item" role="button" aria-pressed="true" (click)="openModal('countries')">
            <a href="javascript: void(0)" target="_self">
                <span class="country-select">ID - INDONESIA</span>
            </a>
        </div>
        <div class="global-footer__copyright global-footer__copyright_adjustment">Copyright © <!-- -->2024<!-- -->
            NVIDIA Corporation</div>
    </div>
    <div *ngIf="locale === 'ko-kr'" class="footer-inner">
        <svg class="global-footer__logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 407.8 1000 184.4"
            enableBackground="new 0 407.8 1000 184.4" xmlSpace="preserve">
            <title>NVIDIA</title>
            <g id="nvidia-logo">
                <path
                    d="M987.1 555.7v-2.8h1.7c0.9 0 2.3 0.1 2.3 1.2 0 1.2-0.7 1.5-1.8 1.5H987.1M987.1 557.6h1.2l2.8 4.8h3l-3.1-5c1.5-0.1 2.9-0.8 2.9-3 0-2.7-1.8-3.5-4.9-3.5h-4.4v11.5h2.6L987.1 557.6M1000 556.7c0-6.8-5.2-10.7-11.1-10.7 -5.8 0-11.1 3.9-11.1 10.7 0 6.8 5.2 10.7 11.1 10.7S1000 563.5 1000 556.7M996.8 556.7c0 4.9-3.6 8.2-7.9 8.2l0 0c-4.4 0-7.9-3.3-7.9-8.2 0-4.9 3.6-8.2 7.9-8.2C993.2 548.6 996.8 551.9 996.8 556.7z">
                </path>
                <path
                    d="M593.2 442.7v121.1h34.2V442.7H593.2zM324.4 442.5v121.2h34.5v-94.1l26.9 0.1c8.8 0 15 2.2 19.2 6.7 5.4 5.7 7.6 15.1 7.6 32v55.3H446v-66.9c0-47.8-30.5-54.3-60.2-54.3C385.8 442.5 324.4 442.5 324.4 442.5zM648.3 442.7v121.1h55.4c29.5 0 39.2-4.9 49.6-15.9 7.4-7.7 12.1-24.7 12.1-43.3 0-17-4-32.2-11.1-41.7 -12.5-16.9-30.8-20.2-58.1-20.2H648.3zM682.2 469h14.7c21.3 0 35.1 9.5 35.1 34.4 0 24.8-13.7 34.4-35.1 34.4h-14.7V469zM544 442.7l-28.5 95.9 -27.3-95.9h-36.9l39 121.1h49.2l39.3-121.1H544zM781.4 563.7h34.2v-121h-34.2V563.7zM877.2 442.7l-47.7 121h33.7l7.6-21.3h56.5l7.2 21.3h36.6l-48.2-121H877.2zM899.4 464.8l20.7 56.6H878L899.4 464.8z">
                </path>
                <path
                    d="M103.9 462.8v-16.6c1.6-0.1 3.3-0.2 4.9-0.2 45.6-1.4 75.4 39.1 75.4 39.1s-32.2 44.8-66.8 44.8c-5 0-9.4-0.8-13.4-2.2v-50.5c17.7 2.2 21.3 10 32 27.7l23.7-20c0 0-17.3-22.7-46.5-22.7C110 462.3 106.9 462.5 103.9 462.8M103.9 407.8v24.8c1.6-0.1 3.3-0.2 4.9-0.3 63.3-2.2 104.6 51.9 104.6 51.9s-47.4 57.7-96.7 57.7c-4.5 0-8.7-0.4-12.7-1.1v15.4c3.4 0.4 7 0.7 10.6 0.7 46 0 79.2-23.5 111.4-51.2 5.3 4.3 27.2 14.7 31.7 19.2 -30.6 25.6-101.9 46.3-142.3 46.3 -3.9 0-7.6-0.2-11.3-0.6v21.6h174.7V407.8H103.9zM103.9 527.8v13.1c-42.5-7.6-54.3-51.8-54.3-51.8s20.4-22.6 54.3-26.3v14.4c0 0 0 0-0.1 0 -17.7-2.2-31.7 14.5-31.7 14.5S80 519.6 103.9 527.8M28.4 487.2c0 0 25.2-37.1 75.5-41v-13.5C48.2 437.2 0 484.4 0 484.4s27.3 79 103.9 86.3v-14.4C47.7 549.2 28.4 487.2 28.4 487.2z">
                </path>
            </g>
        </svg>
        <div class="left-links">
            <div class="footer-item">
                <a href="https://www.nvidia.com/ko-kr/about-nvidia/privacy-policy/" target="_blank" rel="noopener">
                    <span>개인정보보호정책</span>
                </a>
            </div>
            <div class="footer-item">
                <a href="https://www.nvidia.com/ko-kr/about-nvidia/privacy-center/" target="_blank" rel="noopener">
                    <span>내 개인정보 관리</span>
                </a>
            </div>
            <div class="footer-item">
                <a href="https://www.nvidia.com/ko-kr/preferences/email-preferences/" target="_blank" rel="noopener">
                    <span>내 데이터 판매 또는 공유 금지</span>
                </a>
            </div>
            <div class="footer-item">
                <a href="https://www.nvidia.com/ko-kr/about-nvidia/terms-of-service/" target="_blank" rel="noopener">
                    <span>서비스 약관</span>
                </a>
            </div>
            <div class="footer-item">
                <a href="https://www.nvidia.com/ko-kr/about-nvidia/accessibility/" target="_blank" rel="noopener">
                    <span>접근성</span>
                </a>
            </div>
            <div class="footer-item">
                <a href="https://www.nvidia.com/ko-kr/about-nvidia/company-policies/" target="_blank" rel="noopener">
                    <span>기업 정책</span>
                </a>
            </div>
            <div class="footer-item">
                <a href="https://www.nvidia.com/ko-kr/product-security/" target="_blank" rel="noopener">
                    <span>제품 보안</span>
                </a>
            </div>
            <div class="footer-item">
                <a href="https://www.nvidia.com/ko-kr/contact/" target="_blank" rel="noopener">
                    <span>연락처</span>
                </a>
            </div>
        </div>
        <div class="footer-item" role="button" aria-pressed="true" (click)="openModal('countries')">
            <a href="javascript: void(0)" target="_self">
                <span class="country-select"> 대한민국</span>
            </a>
        </div>
        <div class="global-footer__copyright global-footer__copyright_adjustment">Copyright © <!-- -->2024<!-- -->
            NVIDIA Corporation</div>
    </div>

</div>


<modal id="countries" (click)="closeOverlay($event, par)" (window:keyup)="closeOverlay($event, par)">
    <div class="modal" #par>
        <div class="modal-body">
            <div class="modal-header">
                <div class="modal-header-text">SELECT YOUR LOCATION</div>
                <span class="modal-close" (click)="closeModal('countries');"></span>
            </div>
            <ul class="modal-country-list">
                <li *ngFor="let regionAndCountryList of regionsAndCountriesList" class="modal-region">
                    <span class="modal-country-text">
                        <div class="gf-sub-title">{{regionAndCountryList.region}}</div>
                <li *ngFor="let countryList of regionAndCountryList.countries" class="modal-country">
                    <span class="modal-country-text">
                        <a href="{{countryList.storeUrl}}">
                            <span class="country-line-1">{{countryList.country1}}</span>
                            <span class="country-line-2"><br>{{countryList.country2}}</span>
                        </a>
                    </span>
                </li>
                </span>
                </li>

            </ul>
        </div>
    </div>
    <div #cs id="cs" class="modal-background"></div>
</modal>
import { Injectable } from '@angular/core';
import { Observable ,  Subject } from 'rxjs';
 
@Injectable()
export class LayoutService {
  
  subject = new Subject<any>();

  setLayout(layoutType: string) {
    this.subject.next(layoutType);
  }

  getLayout(): Observable<any> {
    return this.subject.asObservable();
  }

  clearLayout() {
    this.subject.next();
  }

}
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormControl } from "@angular/forms";
import { INgxSelectOption } from "ngx-select-ex";
import { TranslateService } from "@ngx-translate/core";
import { Store } from "@ngrx/store";
import * as reducers from "../../reducers";
import { Observable } from "rxjs";

@Component({
  selector: "simple-dropdown",
  template: `
    <!--
		<div #selectCont class="custom-select-container" (window:click)="closeDropdown($event, selectCont)">
			<div [class.disable-dropdown]="!totalProducts" class="custom-select-input" (click)="toggleOptionList()">{{selectedLabel | translate}}</div>
			<div id="ulPar" [class.show-options]="showOptions" class="custom-select-ul-container" (click)="selectOption($event)" (mouseover)="onMouseOver($event)">
				<ul id="selectList">
					<li *ngFor="let item of productSort; let i = index" [attr.data-value]="item.value" [class.highlighted]="highlighted === item.value" [class.clear-highlight]="highlighted !== item.value">{{item.displayName | translate}}</li>
				</ul>
			</div>
		</div>
	-->

    <ngx-select
      aria-expanded="false"
      role="listbox"
      id="sortfilter"
      [(ngModel)]="itemId"
      [items]="prodSortList"
      optionValueField="value"
      [noAutoComplete]="true"
      (focus)="doFocus()"
      (blur)="doBlur()"
      (open)="doOpen()"
      (close)="doClose()"
      (select)="doSelect($event)"
      (remove)="doRemove($event)"
      (selectionChanges)="doSelectOptions($event)"
      optionTextField="displayName"
      [placeholder]="(('sort_by' | translate)+': ' + (itemId | translate))"
      name="sort-filter"
    >
    </ngx-select>
  `,
})
export class SimpleDropdown implements OnInit {
  @Input() totalProducts;
  @Input() productSort;
  @Input() locale;
  @Output() optionChange = new EventEmitter();
  selected = null;
  highlighted = null;
  selectedLabel: string = "";
  showOptions: boolean = false;
  items: string[];
  public ngxControl = new FormControl(1);
  private _ngxDefaultTimeout;
  private _ngxDefaultInterval;
  private _ngxDefault;
  public itemId;
  public prodSortList: any;
  private hasSelected: boolean;
  private translator: any;
  private productcategoryFilter: any;
  productFilter$: Observable<any>;
  constructor(
    private translate: TranslateService,
    private store: Store<reducers.State>
  ) {
    this.translator = translate;
    this._ngxDefaultTimeout = setTimeout(() => {
      this._ngxDefaultInterval = setInterval(() => {
        const idx = Math.floor(Math.random() * (this.items.length - 1));
        this._ngxDefault = this.items[idx];
      }, 100);
    }, 650);
  }
  public doNgxDefault(): any {
    return this._ngxDefault;
  }
  collapsed = false;
  public doFocus = () => console.log("SingleDemoComponent.doFocus");

  public doBlur = () => console.log("SingleDemoComponent.doBlur");

  public doOpen = () => {
    this.collapsed = true;
    console.log("SingleDemoComponent.doOpen", this.collapsed);
  };

  public doClose = () => console.log("SingleDemoComponent.doClose");

  public doSelect = (value: any) => {
    console.log("SingleDemoComponent.doSelect", value);
    this.selectOption(value);
  };

  public doRemove = (value: any) =>
    console.log("SingleDemoComponent.doRemove", value);

  public doSelectOptions = (options: INgxSelectOption[]) =>
    console.log("SingleDemoComponent.doSelectOptions", options);

  ngOnInit() {
    let tmpsortList = [];
    this.productFilter$ = this.store.select(reducers.getProductFilter);
    this.productFilter$.subscribe((data) => {
      this.productcategoryFilter = data.filter(
        (x) => x.filterField == "category_filter"
      );
    });
    this.prodSortList = this.productSort.map((product) => {
      let sl = {
        displayName: product.displayName,
        selected: product.selected,
        value: product.value,
      };
      if (product.selected) {
        this.itemId = product.displayName;
      }
      if (!product.selected) {
        this.translate.get("featured_products").subscribe((text: string) => {
          this.itemId = text;
        });
      }

      this.translate.get(product.displayName).subscribe((text: string) => {
        sl.displayName = text;
      });
      var categories = this.productcategoryFilter[0]?.filterValues?.map(
        (cat) => {
          return cat["dispValue"];
        }
      );
      if (
        sl.value === "lp" ||
        sl.value === "hp" ||
        sl.value === "fp" ||
        sl.value === "dp" ||
        (categories.includes("LAPTOP") && sl.value == "fl") ||
        (categories.includes("LAPTOP") && sl.value == "fd") ||
        (categories.includes("GPU") && sl.value == "fg") ||
        (categories.includes("NVLINKS") && sl.value == "fn")
      ) {
        this.translate.get(sl.displayName).subscribe((text: string) => {
          sl.displayName = text;
        });
        tmpsortList.push(sl);
      }

      return sl;
    });
    let tmpArray;
    this.transformData(tmpsortList)
      .then((modifiedResult) => {
        tmpArray = modifiedResult;
        this.prodSortList = tmpArray;
      })
      .catch((error) => {
        console.error("Promise rejected with error:", error);
      });
    this.items = tmpsortList.map((item) => {
      return item.value;
    });

    for (let i = 0; i < tmpsortList.length; i++) {
      if (tmpsortList[i].selected) {
        this.hasSelected = true;
        this.setOption(i);
      }
    }
  }

  async transformData(data: any[]) {
    let transformData = [];
    return new Promise((resolve, reject) => {
      data.map(async (item) => {
        if (item.displayName && item.displayName.includes("_")) {
          this.translate.get(item.displayName).subscribe((transformedItem) => {
            item.displayName = transformedItem;
            transformData.push(item);
          }),
            (error: any) => {
              console.error("Error===>", error);
            };
        } else {
          transformData.push(item);
        }
        resolve(transformData);
      });
    });
  }

  ngAfterViewInit() {
    for (let i = 0; i < this.productSort.length; i++) {
      if (this.productSort[i].selected) {
        this.hasSelected = true;
        this.setOption(i);
      }
    }
    if (!this.hasSelected) {
      this.setOption(0);
    }
    if (this.productSort.length < 3 && document.getElementById("ulPar")) {
      document.getElementById("ulPar").style.height =
        34 * this.productSort.length + 1 + "px";
    }
    clearTimeout(this._ngxDefaultTimeout);
    clearInterval(this._ngxDefaultInterval);
  }

  toggleULPar() {
    this.showOptions = !this.showOptions;
    this.highlighted = this.selected;
  }

  toggleOptionList() {
    if (this.totalProducts) {
      this.toggleULPar();
    }
  }

  selectOption(e) {
    if (e) {
      let prevSelected = this.selected;
      this.highlighted = this.selected = e;
      if (prevSelected !== this.selected) {
        this.optionChange.emit(this.selected);
      }
    }
  }

  setOption(i) {
    setTimeout(() => {
      this.highlighted = this.selected = this.productSort[i].value;
      this.selectedLabel = this.productSort[i].value;
    }, 60);
  }

  onMouseOver(e) {
    this.highlighted = e.target.getAttribute("data-value");
  }

  closeDropdown(e, selectCont) {
    if (e.target !== selectCont && !selectCont.contains(e.target)) {
      this.showOptions && this.toggleULPar();
    }
  }
}

<div #hoc *ngIf="hatchOverlayDisplay === 'block'" class="hatch-overlay-container" [style.display]="hatchOverlayDisplay"
 (click)="closeOverlay($event, hoc)" (window:keyup)="closeOverlay($event, hoc)">
	<div *ngIf="!showSpinner" class="hatch-overlay">
		<div class="hatch-overlay-header">
			<input type="button" class="hatch-overlay-button" (click)="hideHatchOverlay()" />
		</div>
		<div *ngIf="retailInfo" class="hatch-overlay-body">
			<div class="product-info-container">
				<div class="product-image-container"><img title="{{productDetail.displayName}} {{ 'graphics_cards' | translate }}"
					 src='{{productDetail.imageURL}}' class="product-image" (load)="resizeImg($event.target, thumbnailW, thumbnailH)"
					 (error)="showImgError($event.target)" width="100%" height="100%"></div>
				<div class="product-title-container"><span style="margin: auto 0;">{{productDetail.productTitle}}</span></div>
			</div>
			<div *ngIf="screenWidth === 'SM'" class="hatch-main">
				<div *ngFor="let item of retailInfo" class="etailer-container">
					<div class="logo-container"><img src='{{item.retailerLogo}}' class="logo" width="100%" height="100%"></div>
					<div class="hatch-price-stock-container">
						<div class="hatch-price-container-sm"><span class="hatch-price">{{item.productPrice}}</span></div>
						<div class="hatch-stock-container-sm"><a href='{{item.purchaseLink}}' target="_blank" rel="noopener" class="buy-link" style="font-weight: normal; color: #76b900; background-color: transparent"><span>{{
									'check_availability' | translate }}</span></a></div>
					</div>
					<div class="hatch-buy-container"><a href='{{item.purchaseLink}}' target="_blank" rel="noopener" class="buy-link" style="line-height: 24px;">{{getBuyButtonText(locale)
							| translate }}</a></div>
				</div>
			</div>
			<div *ngIf="screenWidth !== 'SM'" class="hatch-main">
				<div *ngFor="let item of retailInfo" class="etailer-container">
					<div class="logo-container"><img src='{{item.retailerLogo}}' class="logo" width="100%" height="100%"></div>
					<div class="hatch-price-container"><span class="hatch-price">{{item.productPrice}}</span></div>
					<div class="hatch-stock-container"><a href='{{item.purchaseLink}}' target="_blank" rel="noopener" class="buy-link" style="font-weight: normal; color: #76b900; background-color: transparent"><span>{{
								'check_availability' | translate }}</span></a></div>
					<div class="hatch-buy-container"><a href='{{item.purchaseLink}}' target="_blank" rel="noopener" class="buy-link" style="line-height: 24px;">{{getBuyButtonText(locale)
							| translate }}</a></div>
				</div>
			</div>
		</div>
	</div>
</div>
import { Injectable } from "@angular/core";
import { ScriptStore } from "../script.store";
import { HttpClient } from '@angular/common/http';

declare var document: any;

@Injectable()
export class ScriptService {

  private scripts: any = {};

  constructor(private http: HttpClient) {
    ScriptStore.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src
      };
    });
  }

  public load1() {
    let body = <HTMLDivElement>document.body;
    let script = document.createElement('script');
    // let base_url = null;
    // let dir = 'nv-nav'
    // if (window.location.origin.indexOf('localhost') > -1) {
    //   base_url = 'http://localhost';
    //   dir = '/nv-nav-ui/js/nv-nav-build.min.3.0.js';
    // } else {
    //   base_url = window.location.origin;
    //   dir = '/nv-nav/js/nv-nav-build.min.3.0.js';
    // }
    script.src = '../../../assets/js/clientlibs-all.js';
    script.async = true;
    script.defer = true;
    body.appendChild(script);

  }

  load(...scripts: string[]) {
    var promises: any[] = [];
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }

  loadScript(name: string) {
    return new Promise((resolve, reject) => {
      //resolve if already loaded
      if (this.scripts[name].loaded) {
        resolve({ script: name, loaded: true, status: 'Already Loaded' });
      }
      else {
        //load script
        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.scripts[name].src;
        if (script.readyState) {  //IE
          script.onreadystatechange = () => {
            if (script.readyState === "loaded" || script.readyState === "complete") {
              script.onreadystatechange = null;
              this.scripts[name].loaded = true;
              resolve({ script: name, loaded: true, status: 'Loaded' });
            }
          };
        } else {
          script.onload = () => {
            this.scripts[name].loaded = true;
            resolve({ script: name, loaded: true, status: 'Loaded' });
          };
        }
        script.onerror = (error: any) => resolve({ script: name, loaded: false, status: 'Loaded' });
        document.getElementsByTagName('head')[0].appendChild(script);
      }
    });
  }

}
import { Component, Input, OnInit, NgZone, HostListener, ElementRef, AfterViewInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Observable, Subject } from 'rxjs';


import * as reducers from '../reducers';
import * as productActions from './product.actions';

import { ViewportDetector } from '../shared/viewport-detector';
import { SearchComponent } from './search/search.component';
import { isEmptyObject } from '../util';

import { ProductService } from './product.service';

import { ProductDetails } from '../shared/interface/product-details';
import { ProductCompare } from '../shared/interface/product-compare';
import { ProductSearchCriteria } from '../shared/interface/product-search-criteria';

import { ProductSort } from '../shared/interface/product-sort';
import { SeoService } from '../shared/service/seo.service';
import { PreviousRouteService } from '../shared/service/previous-route.service';
import * as $ from 'jquery';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';


@Component({
  selector: "product",
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
})
export class ProductComponent implements OnInit, AfterViewInit {
  escdown = false;
  prevWidth: number = window.innerWidth;
  @HostListener("document:keydown.escape", ["$event"])
  onKeydown(e) {
    this.escdown = true;
  }

  @HostListener("document:keyup.escape", ["$event"])
  onKeyup(e) {
    this.escdown = false;
  }
  showFilterCol: boolean = false;
  showMainCol: boolean = true;
  showFilterCompareButtons: boolean = true;
  useNormalZIndex: boolean = false;
  layoutType: string;
  hatchOverlayDisplay: string = "none";
  winPageYOffset: number;
  retailInfoLoading$;
  retailInfo$;
  productDetail;
  productFilter$;
  featuredProduct$;
  productDetails$: Observable<ProductDetails[]>;
  productCompare$: Observable<ProductCompare[]>;
  suggestedProduct$;
  productSearchCriteria = {
    page: 1,
    limit: 12,
    locale: "",
  };
  viewportType: string;
  params;
  destroy$: Subject<boolean> = new Subject<boolean>();
  loading$: Observable<boolean>;
  totalProducts$: Observable<number>;
  locale$: Observable<string>;
  productSort$: Observable<ProductSort[]>;
  productSearch$: Observable<string>;
  isFeaturedProductIncludedInCount$: Observable<boolean>;
  locale;
  screenWidth: string;
  isPaging: boolean = false;
  notDirectlyFromProductParams: boolean = false;
  qp;
  isRefreshed: boolean = false;
  isPreviousRouteCompare: boolean = false;

  isMobileReset: boolean = false;
  disableMobileFilterReset: boolean = false;
  isProductDetailsAvailable: boolean = false;
  isSuggestedProductAvailable: boolean = false;
  drTimer;
  startAttachingNotifyMeListenerStart: number;
  secondaryBreadcrumb: string;
  primaryBreadcrumb: string;
  constructor(
    private store: Store<reducers.State>,
    private viewportDetector: ViewportDetector,
    private route: ActivatedRoute,
    private router: Router,
    private productService: ProductService,
    private seoService: SeoService,
    private previousRouteService: PreviousRouteService,
    private elRef: ElementRef,
    private translate: TranslateService
  ) {
    this.getScreenWidth();
  }

  ngAfterViewInit() {
    let locale = window.location.pathname.split("/")[1];
    // this.translate.get('cooling_system').subscribe(some=> {
    //   some;
    // })
  }
  detectEscClickModal() {
    let focusedModal = $<Element>(".popClose");
    focusedModal = $<Element>(focusedModal).attr("id", "popClose");
    let modalCloseElement: HTMLElement = document.getElementById(
      "popClose"
    ) as HTMLElement;

    modalCloseElement.click();

    // console.log("----*-sss-lastFocusedElement->", focusedModal, "------>", modalCloseElement);
  }
  ngAfterViewChecked() {
    if (this.escdown) {
      // console.log("-----esc -- key---");
      var $focused = $(":focus");
      $focused.blur();
      var getActiveModal = $(".popBody").hasClass("modal-active");

      if (getActiveModal) {
        this.detectEscClickModal();
        //  console.log("------focused----esc---->");
      }
      window["appSetupHelpers"].scanPID();
      // console.log("------focused----esc---->");
    }
  }
  ngOnInit() {
    var that = this;
    this.productFilter$ = this.store.select(reducers.getProductFilter);
    this.featuredProduct$ = this.store.select(reducers.getProductFeatured);
    this.productDetails$ = this.store.select(reducers.getProductDetails);
    this.productCompare$ = this.store.select(reducers.getProductCompare);
    this.loading$ = this.store.select(reducers.getProductLoading);
    this.totalProducts$ = this.store.select(reducers.getTotalProducts);
    this.retailInfoLoading$ = this.store.select(reducers.getRetailInfoLoading);
    this.retailInfo$ = this.store.select(reducers.getRetailInfo);
    this.suggestedProduct$ = this.store.select(reducers.getSuggestedProduct);
    this.locale$ = this.store.select(reducers.getLocale);
    this.productSort$ = this.store.select(reducers.getProductSort);
    this.productSearch$ = this.store.select(reducers.getProductSearch);
    this.isFeaturedProductIncludedInCount$ = this.store.select(
      reducers.getIsFeaturedProductIncludedInCount
    );
    let fedata = this.route.snapshot.data;
    var tempData = fedata.feinfo;
    this.productService.setFedata(tempData);
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      if (params && params.locale) {
        if (
          params.locale === "en-us" ||
          params.locale === "en-gb" ||
          params.locale === "en-ca" ||
          params.locale === "fr-ca" ||
          params.locale === "de-de" ||
          params.locale === "fr-fr" ||
          params.locale === "en-au" ||
          params.locale === "en-sg" ||
          params.locale === "pl-pl" ||
          params.locale === "es-es" ||
          params.locale === "nl-nl" ||
          params.locale === "es-ar" ||
          params.locale === "es-mx" ||
          params.locale === "it-it" ||
          params.locale === "zh-tw" ||
          params.locale === "en-ph" ||
          params.locale === "nb-no" ||
          params.locale === "da-dk" ||
          params.locale === "sv-se" ||
          params.locale === "fi-fi" ||
          params.locale === "pt-br" ||
          params.locale === "ko-kr" ||
          params.locale === "en-my" ||
          params.locale === "en-in" ||
          params.locale === "ja-jp" ||
          params.locale === "th-th" ||
          params.locale === "de-at" ||
          params.locale == 'id-id' ||
          params.locale == 'vi-vn'
        ) {
          this.locale = params.locale;
          this.productSearchCriteria = Object.assign(
            {},
            this.productSearchCriteria,
            {
              locale: this.locale,
            }
          );
          this.store.dispatch(new productActions.LoadLocale(this.locale));
          this.seoService.generateTags(this.locale, false, "product");
        } else {
          window.location.href = `https://www.nvidia.com/en-us/consumer/page-not-found`;
        }
      }
    });

    this.productDetails$.subscribe((productDetails) => {
      productDetails.length
        ? (this.isProductDetailsAvailable = true)
        : (this.isProductDetailsAvailable = false);
    });

    this.suggestedProduct$.subscribe((suggestedProduct) => {
      suggestedProduct && suggestedProduct.productDetails.length
        ? (this.isSuggestedProductAvailable = true)
        : (this.isSuggestedProductAvailable = false);
    });

    // this.store.select(reducers.getProductParams).subscribe((params: ProductSearchCriteria)=>{
    //   console.log(params);
    //   this.params = params;
    //   this.params = Object.assign({}, this.params, {
    //     locale: this.locale
    //   });
    //   this.notDirectlyFromProductParams = true;
    //   if ((this.viewportType === 'XL' || this.viewportType === 'LG')) {
    //     this.store.dispatch(new productActions.LoadProduct({ productSearchCriteria: this.params, locale: this.locale }));
    //   }
    // });

    // this.route.queryParams.subscribe(queryParams=> {
    //   this.qp = queryParams;
    //   if(this.isOtherThanDefaultQueryParam(queryParams) && !this.notDirectlyFromProductParams) {
    //     //this.store.dispatch(new productActions.ClearProduct());
    //     this.store.dispatch(new productActions.UpdateProductParamsOnload(queryParams));
    //     this.store.dispatch(new productActions.LoadProduct({ productSearchCriteria: queryParams, refreshRequired: true, locale: this.locale }));
    //   }
    //   if (Object.keys(this.qp).length === 1 && Object.keys(this.qp)) {
    //     this.productSearchCriterion = Object.assign({}, this.productSearchCriterion, {
    //       gpu: this.qp.gpu
    //     })
    //   }
    //   // else {
    //   //   if (this.isQueryParamPresent(queryParams) ) {
    //   //    this.router.navigate([`/${this.locale}/consumer`], {
    //   //       relativeTo: this.route,
    //   //       queryParams: this.productSearchCriterion
    //   //     });
    //   //     this.store.dispatch(new productActions.LoadProduct({ productSearchCriteria: queryParams, locale: this.locale }));
    //   //   }
    //   // }
    // });

    // this.store.dispatch(new productActions.LoadProduct({ productSearchCriteria: this.isOtherThanDefaultQueryParam(this.qp) ? this.qp :  this.productSearchCriterion, locale: this.locale }));
    //console.log(this.previousRouteService.getIsFromCompare(), "this.previousRouteService.getIsFromCompare()");

    this.route.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe((queryParams) => {
        this.secondaryBreadcrumb='';
        this.primaryBreadcrumb='';
        if (!this.previousRouteService.getIsFromCompare() && !this.isPaging) {
          let size = Object.keys(queryParams).length;
          let defaultQueryParams = Object.assign({}, queryParams, {
            page: 1,
            locale: this.locale,
          });
          if (size > 3 && !this.isRefreshed) {

            this.store.dispatch(
              new productActions.ClearProductFilterSelection(
                this.productSearchCriteria
              )
            );
            this.store.dispatch(
              new productActions.UpdateProductParamsOnload(defaultQueryParams)
            );
          }
          if ((size == 6 || size == 5) && queryParams?.category) {
            this.primaryBreadcrumb = 'primary';
          }

          const validCategories =
          [ 'GPU', 'LAPTOP', 'STUDIO-LAPTOP', 'DESKTOP', 'STUDIO_DESKTOP', 'MONITOR', 'GFN',
          'NVLINKS', 'MODELS', 'GAMING_SOFTWARE'];

          if(size == 6 && (queryParams?.category?.split(',')?.length==1)) {
            if (queryParams?.category) {
              let category = validCategories.find(category => queryParams.category.toLowerCase().includes(category.toLowerCase()));
              if (category) {
                this.secondaryBreadcrumb = "category_" + category.toLowerCase();
              }

              else {
              this.secondaryBreadcrumb= "default";
              }
            }
          }

          if(size==5&& (queryParams?.category?.split(',')?.length==1 || queryParams?.gpu?.split(',')?.length==1 || queryParams?.manufacturer?.split(',')?.length==1 || queryParams?.nv_technology?.split(',')?.length==1  || queryParams?.screen_size?.split(',')?.length==1  )){
            if( queryParams?.category){
              let category = validCategories.find(category => queryParams.category.toLowerCase().includes(category.toLowerCase()));
              if (category) {
                this.secondaryBreadcrumb = "category_" + category.toLowerCase();
              }

              else {
              this.secondaryBreadcrumb= "default";
              console.log(this.secondaryBreadcrumb)
              }
            }
            else if( queryParams?.gpu){
              this.secondaryBreadcrumb= queryParams?.gpu?.split(',')?.[0] || queryParams?.manufacturer?.split(',')?.[0] || queryParams?.nv_technology?.split(',')?.[0]  || queryParams?.screen_size?.split(',')?.[0];
            }

          }
          if (size === 1 && !this.isRefreshed) {
            this.productSearchCriteria = Object.assign(
              {},
              this.productSearchCriteria,
              {
                gpu: queryParams.gpu,
              }
            );
            this.store.dispatch(
              new productActions.UpdateProductParamsOnload(
                this.productSearchCriteria
              )
            );
          }
        }
        // if (this.previousRouteService.getIsFromCompare() && (this.isProductDetailsAvailable || this.isSuggestedProductAvailable)) {
        //   this.productSearchCriteria = Object.assign({}, this.productSearchCriteria, queryParams);
        // }
        // if (this.previousRouteService.getIsFromCompare() && !this.isProductDetailsAvailable && !this.isSuggestedProductAvailable) {
        //   this.store.dispatch(new productActions.LoadProduct({ productSearchCriteria: this.productSearchCriteria, locale: this.locale }));
        // }
        if (this.previousRouteService.getIsFromCompare()) {
          this.store.dispatch(
            new productActions.LoadProduct({
              productSearchCriteria: this.productSearchCriteria,
              locale: this.locale,
            })
          );
          this.store.dispatch(
            new productActions.UpdateProductParamsOnload(
              this.productSearchCriteria
            )
          );
        }
      });

    this.store
      .select(reducers.getProductParams)
      .pipe(takeUntil(this.destroy$))
      .subscribe((params: ProductSearchCriteria) => {
        //this.isPreviousRouteCompare = this.previousRouteService.getPreviousUrl().indexOf('compare') > -1;
        if (!this.previousRouteService.getIsFromCompare()) {
          this.productSearchCriteria = Object.assign({}, params);
          this.isRefreshed = true;
          if (
            this.isMobileReset ||
            !this.showFilterCol ||
            this.screenWidth === "LG" ||
            this.screenWidth === "XL"
          ) {
            this.store.dispatch(
              new productActions.LoadProduct({
                productSearchCriteria: params,
                locale: this.locale,
              })
            );
          }
        }
      });

    // if (!this.previousRouteService.getIsFromCompare()) {
    //   this.store.dispatch(new productActions.LoadProduct({ productSearchCriteria: this.productSearchCriteria, locale: this.locale }));
    // }

    this.viewportDetector.$viewport.subscribe((viewport) => {
      this.viewportType = viewport;
      // SHOP-546 on phone orientation change, HTML is refreshed and loses old notify me listener
      //this.listenForNotifyMe();
    });

    this.translate.onLangChange
      .pipe(takeUntil(this.destroy$))
      .subscribe((event: LangChangeEvent) => {
        setTimeout(() => {
          $(".cart-main-section .cart__empty")[0] &&
            ($(".cart-main-section .cart__empty")[0].innerHTML =
              that.translate.instant("empty_cart"));
          $(".cart-main-section .cart__title")[0] &&
            ($(".cart-main-section .cart__title")[0].innerHTML =
              that.translate.instant("cart"));
          $(".cart-main-section .cart__items").each(function () {
            $(this)
              .find(".cart_remove .cart-item__remove")
              .each(function (i) {
                $(".cart_remove .cart-item__remove")[i].innerHTML =
                  that.translate.instant("remove");
              });
            $(this)
              .find(".cart__promotions")
              .each(function (i) {
                $(".cart_bundle_link")[i] &&
                  ($(".cart_bundle_link")[i].innerHTML =
                    that.translate.instant("included"));
              });
          });
          $(".cart-main-section .cart__checkout-button")[0] &&
            ($(".cart-main-section .cart__checkout-button")[0].innerHTML =
              that.translate.instant("checkout"));
        }, 1000);
      });
  }

  @HostListener("window:resize", ["$event"]) onScreenResize(event: Event) {
    this.getScreenWidth();
    const currentWidth = window.innerWidth;
    if (currentWidth !== this.prevWidth) {
      clearTimeout(this.drTimer);
      this.drTimer = setTimeout(() => {
        console.log("window.resize==>", window.innerWidth);
        $(".js-product-item").removeData();
        // setTimeout(function () {
        window["appSetupHelpers"].scanPID();
        // }, 500);
      }, 1000);
    }
    //if(this.locale == 'en-us'){
    //window['hatchwidgetHelpers'].reloadScript();
    window["hatchwidgetHelpers"].reloadScript();
    //  }
  }

  /*listenForNotifyMe() {
    const startAttachingNotifyMeListenerStart = Date.now();
    let notifyMeListenerAttached = false;
    function addNotifyMeListener() {
      // .dr-modal-form-for-notify-me is outside of our Angular app
      $('.dr-modal-form-for-notify-me .btn').click(
        function() {
          $('body').addClass('notify-me-open');

          setTimeout(function() {
            $('#cboxOverlay, #cboxClose').click(function() {
              $('body').removeClass('notify-me-open');
            });
          }, 0) // 300 worked

        }
      );
    }
    function tryDrModalForm() {
      if ($('.dr-modal-form-for-notify-me').length < 1) {
        // console.log(`$('.dr-modal-form-for-notify-me').length ${$('.dr-modal-form-for-notify-me').length}`);
        // window.requestAnimationFrame(tryDrModalForm);
        // stack overflow
        // tryDrModalForm();
        return false;
      } else {
        // console.log(`$('.dr-modal-form-for-notify-me').length ${$('.dr-modal-form-for-notify-me').length}`);
        let drModalReadyTime = Date.now() - startAttachingNotifyMeListenerStart;
        console.log('time for .dr-modal-form-for-notify-me ready: ' + drModalReadyTime);
        // addNotifyMeListener();
        setTimeout(addNotifyMeListener, 0);
        notifyMeListenerAttached = true;
        return true;
       }
    };
    tryDrModalForm();
    for (let i = 0; i < 100; i += 1) {
        (function(i) {
          setTimeout(function() {
            // console.log('trying dr modal form ' + i);
          if (!notifyMeListenerAttached) {
            tryDrModalForm();
            console.log('trying DrModalForm ' + i);
          }
        }, i * 1);
      })(i);
    }

  }*/

  getScreenWidth() {
    if (window.matchMedia("(min-width: 1281px)").matches) {
      console.log("Width==>", $(window).width());
      this.screenWidth = "XL";
    } else if (window.matchMedia("(min-width: 1025px)").matches) {
      console.log("Width==>", $(window).width());
      this.screenWidth = "LG";
    } else if (window.matchMedia("(min-width: 768px)").matches) {
      console.log("Width==>", $(window).width());
      this.screenWidth = "MD";
    } else {
      console.log("Width==>", $(window).width());
      this.screenWidth = "SM";
    }
  }

  // ngDoCheck() {
  // used to try to fix SHOP-546, causes notify me button to not work many times
  // console.log('ngDoCheck');
  // .dr-modal-form-for-notify-me is outside of our Angular app
  // $('.dr-modal-form-for-notify-me .btn').click(
  //   function() {
  //     $('body').addClass('notify-me-open');

  //     setTimeout(function() {
  //       $('#cboxOverlay, #cboxClose').click(function() {
  //         $('body').removeClass('notify-me-open');
  //       });
  //     }, 0) // 300 worked

  //   }
  // );
  // }

  // ngAfterViewInit() {
  // does not work to fix SHOP-546
  // .dr-modal-form-for-notify-me is outside of our Angular app
  // $('.dr-modal-form-for-notify-me .btn').click(
  //   function() {
  //     $('body').addClass('notify-me-open');

  //     setTimeout(function() {
  //       $('#cboxOverlay, #cboxClose').click(function() {
  //         $('body').removeClass('notify-me-open');
  //       });
  //     }, 0) // 300 worked

  //   }
  // );
  // }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  onFilterChange(event) {
    const payload = JSON.parse(event);
    console.log("pc-onfilterchanges");
    this.store.dispatch(
      new productActions.LoadProduct({
        productSearchCriteria: payload,
        locale: this.locale,
      })
    );
  }

  onPaging(event) {
    this.previousRouteService.setIsFromCompare(false);
    this.isPaging = true;
    const payload = event.value;
    this.productSearchCriteria = Object.assign({}, this.productSearchCriteria, {
      page: payload.page,
    });
    this.store.dispatch(
      new productActions.LoadProduct({
        productSearchCriteria: this.productSearchCriteria,
        locale: this.locale,
      })
    );
  }

  onClearFilter(isMobileReset) {
    if (this.disableMobileFilterReset) {
      return;
    }
    if (isMobileReset) {
      this.isMobileReset = true;
    }
    //this.isPreviousRouteCompare = false;
    this.previousRouteService.setIsFromCompare(false);
    let clearFilter = {
      page: 1,
      limit: 12,
      locale: this.locale,
    };

    for (var addtionalKey in this.productSearchCriteria) {
      if (addtionalKey === "sorting") {
        clearFilter = Object.assign({}, clearFilter, {
          [addtionalKey]:
            this.productSearchCriteria[addtionalKey] !== ""
              ? this.productSearchCriteria[addtionalKey]
              : undefined,
        });
      }
    }

    this.store.dispatch(
      new productActions.ClearProductFilterSelection(clearFilter)
    );

    var subNavSearchBox = document.getElementById('subNav-searchBox');
    var removeButtonLGXL = document.getElementById('subNav-searchRemove');
    var removeButtonSMMD = document.getElementById('removeButtonSMMD');

    if (subNavSearchBox && this.screenWidth === 'LG' || this.screenWidth === 'XL') {
      subNavSearchBox['value'] = '';
      removeButtonLGXL['style'].display = 'none';
    }

    else {
      removeButtonSMMD['style'].display = 'none';
    }
    //this.store.dispatch(new productActions.LoadProduct({ productSearchCriteria: clearFilter, refreshRequired: true, locale: this.locale }));
  }

  applyFilter() {
    window.scroll(0, 0);
    this.store.dispatch(
      new productActions.LoadProduct({
        productSearchCriteria: this.productSearchCriteria,
        locale: this.locale,
      })
    );
    this.showFilterCol = false;
    this.showMainCol = true;
    this.showFilterCompareButtons = true;
  }

  toggleMobileFilterReset(disabled) {
    if (this.isMobileReset) {
      this.isMobileReset = false;
    } else if (
      (this.screenWidth === "SM" || this.screenWidth === "MD") &&
      this.showFilterCol &&
      disabled
    ) {
      this.onClearFilter(true);
    }
    this.disableMobileFilterReset = disabled;
  }

  onFilterButtonClick(): void {
    window.scroll(0, 0);
    this.showFilterCol = true;
    this.showMainCol = false;
    this.showFilterCompareButtons = false;
  }

  onFilterCloseButtonClick(): void {
    window.scroll(0, 0);
    this.showFilterCol = false;
    this.showMainCol = true;
    this.showFilterCompareButtons = true;
  }

  onCompareOverlayTransitionEnd(event) {
    this.useNormalZIndex = event.useNormalZIndex;
  }

  isQueryParamPresent(queryParams) {
    return isEmptyObject(queryParams);
  }

  isOtherThanDefaultQueryParam(params) {
    return Object.keys(params).length > 3 ? true : false;
  }

  showHatchOverlay(e) {
    this.winPageYOffset = e.winPageYOffset;
    this.productDetail = e.productDetail;
    this.hatchOverlayDisplay = "none";
    setTimeout(() => {
      this.hatchOverlayDisplay = "block";
    }, 100);
  }
}

import { Component, OnInit, Input } from '@angular/core';


@Component({
  selector: 'rating-generator',
  template: `
		<span *ngFor="let item of output">
			<span> 
				<img src="./assets/images/{{item}}" alt="" />
			</span>
		</span>
  `,
  styles: [`
		
  `]
})
export class RatingComponent implements OnInit {

	@Input() points;
	output = [];

  constructor() { }

  ngOnInit() {
  	this.generateRatings(this.points);
  }

  generateRatings(rating) {
  	if (rating) {
  		rating = Math.floor(rating * 2) / 2;
	  // Append all the filled whole stars
	  for (var i = rating; i >= 1; i--)
	    this.output.push('star.svg');

	  // If there is a half a star, append it
	  if (i >= .5) this.output.push('half-star.svg');

	  // Fill the empty stars
	  for (let i = (5 - rating); i >= 1; i--)
	    this.output.push('empty-star.svg');

	  return this.output;
  	}
  }

}

declare var PriceSpider;

import * as productActions from "../../products/product.actions";

export function resizeImg(img, thumbnailW, thumbnailH, locale) {
  //console.log(locale);
  //	console.log("Img naturalWidth",img.naturalWidth)
  if (!img.naturalWidth || !img.naturalHeight) return;

  //add padding of 10px around image
  let padding = 0;
  let origThumbnailW = thumbnailW;
  let origThumbnailH = thumbnailH;
  thumbnailW -= padding;
  thumbnailH -= padding;
  let imgContainer = img.parentNode;
  let scale = Math.min(
    thumbnailW / img.naturalWidth,
    thumbnailH / img.naturalHeight
  );
  let scaledW = img.naturalWidth * scale;
  let scaledH = img.naturalHeight * scale;
  /*	img.style.width = scaledW + "px";
	img.style.height = scaledH + "px";*/
  /*	imgContainer.style.paddingTop = Math.round((origThumbnailH - scaledH)/2) + "px";
	imgContainer.style.paddingLeft = Math.round((origThumbnailW - scaledW)/2) + "px";*/
  //console.log(locale!=='en-gb' && locale!== 'en-us')
 
  imgContainer.style.background = "transparent";
  
  /*imgContainer.style.background = "#fff";*/
  imgContainer.style.visibility = "visible";
  imgContainer.style.overflow = "hidden";
}

export function showImgError(img) {
  setTimeout(() => {
    img.parentNode.style.visibility = "visible";
  }, 2000);
}

export function getBuyButtonText(locale): string {
  return "buy_now";
  /*if (locale === 'en-us') {
		return "BUY NOW";
	} else if (locale === 'en-gb') {
		return "PRE-ORDER";
	}*/
}

export function changeDRId(digitialRiverID) {
  $(".js-product-item").removeData();
  return digitialRiverID;
}

export function showOverlay(productDetail, locale, store, hatchOverlayDisplay) {
  if (locale === "en-us") {
    //console.log("showOverlay PriceSpider: " + locale + ", " + productDetail.productTitle + ", " + productDetail.productUPCOriginal);
    showPriceSpiderOverlay(productDetail.productUPCOriginal);
  } else {
    showPriceSpiderOverlay(productDetail.productUPCOriginal);
  } /*else {
		console.log("showOverlay Hatch: " + locale + ", " + productDetail.productTitle + ", " + productDetail.productUPCOriginal);
		hatchOverlayDisplay && hatchOverlayDisplay.emit({hatchOverlayDisplay: true, productDetail: productDetail, winPageYOffset: window.pageYOffset});
		store.dispatch(new productActions.LoadRetailInfo(productDetail.productUPC));
		document.body.style.overflow = 'hidden';
		stopBodyScrolling(true);
	}*/
}

export function stopBodyScrolling(bool) {
  if (bool === true) {
    (<any>document).addEventListener("touchmove", freezeVp, { passive: false });
    $("html").addClass("freeze-html");
  } else {
    (<any>document).removeEventListener("touchmove", freezeVp, {
      passive: false,
    });
    $("html").removeClass("freeze-html");
  }
  /*if (bool === true) {console.log("add listener");
       document.body.addEventListener("touchmove", freezeVp, false);
   } else {console.log("remove listener");
       document.body.removeEventListener("touchmove", freezeVp, false);
   }*/
}

function freezeVp(e) {
  //e.preventDefault();
  e.stopPropagation();
}

function showPriceSpiderOverlay(upc) {
  let psDivPar = document.getElementById("psPar");
  psDivPar.innerHTML = "";
  let psD = document.createElement("DIV");
  psD.setAttribute("class", "ps-widget");
  psD.setAttribute("ps-sku", upc);
  psDivPar.appendChild(psD);

  PriceSpider.rebind(function () {
    //console.log("showPriceSpiderOverlay click");
    psD.click();
  });
}

function removeNoScrollClass(winPageYOffset) {
  //$(document.body).removeClass("noscroll");
  stopBodyScrolling(false);
  window.scroll(0, winPageYOffset);
  //document.body.style.overflow = '';
}
export function getFormatedPrice(price, locale): string {
  let priceinfo = price.replace(/[^0-9\.]+/g, "");
  let currency = "";

  let tmplocale = locale;
  switch (locale) {
    case 'en-us':
      currency = "USD";
      break;
    case 'en-gb':
      currency = "GBP";
      break;
    case 'de-de' || 'de-at':
      tmplocale = "en-US";
      currency = "EUR";
      break;
    case 'de-at':
      tmplocale = "en-US";
      currency = "EUR";
      break;
    case 'fr-fr':
      tmplocale = "en-US";
      currency = "EUR";
      break;
    case 'en-ca':
      currency = "CAD";
      break;
    case 'en-au':
      currency = "AUD";
      break;
    case 'es-es':
      tmplocale = "en-US";
      currency = "EUR";
      break;
    case 'es-mx':
      tmplocale = "en-US";
      currency = "USD";
      break;
    case 'en-sg':
      currency = "SGD";
      break;
    case 'en-my':
      currency = "MYR";
      break;
    case 'ko-kr':
      currency = "KRW";
      break;
    case 'pt-br':
      currency = "BRL";
      break;
    case 'en-in':
      currency = "INR";
      break;
    case 'ja-jp':
      currency = "JPY";
      break;
    case 'th-th':
      currency = "THB";
      break; break;
    case 'id-id':
      currency = "IDR";
      break;
    case 'pl-pl':
      currency = "PLN";
      break;
    case 'vi-vn':
      currency = "VND";
      break;
    case 'en-ph':
      currency = "PHP";
      break;
    case 'zh-tw':
      tmplocale = "en-US";
      currency = "USD";
      break;
    case ('da-dk'):      //---
      tmplocale = "en-US";
      currency = "USD";
      break;
    case 'sv-se':      //---
      tmplocale = "en-US";
      currency = "USD";
      break;
    case 'nb-no':      //---
      tmplocale = "en-US";
      currency = "USD";
      break;
    case 'fi-fi':       //---
      currency = "EUR";
      break;
    case 'fr-ca':      //---
      currency = "USD";
      break;
    case 'it-it':      //---
      currency = "EUR";
      break;

    case 'nl-nl':     //---
      currency = "EUR";
      break;

    default:
      currency = "USD";
  }

  priceinfo = new Intl.NumberFormat(tmplocale, {
      style: "currency",
      currency: currency
  }).format(priceinfo);

  if (locale == "vi-vn") {
      priceinfo = priceinfo.replace("$", "S$"); 
      priceinfo= priceinfo?.replace(".",",")?.replace(".",",").replace("₫", "đ");
  } 

  if(locale == "da-dk" || locale == 'sv-se' || locale == 'nb-no') {
    priceinfo = priceinfo.replace("$", ""); 
    priceinfo = "kr."+priceinfo;
  }


  return priceinfo;
}
export function getPriceIntegerDot(price): string {
  //console.log("integer dot",price.substring(0, price.length - 2))
  return price.substring(0, price.length - 2);
}

export function getPriceDecimal(price): string {
  //	console.log(price.substr(-2));

  return price.substr(-2);
}

export function extractDRPriceIntegerDot(priceDiv, drSpinnerCont) {
  let str = priceDiv.innerHTML;
  if (!str) {
    return;
  }
  drSpinnerCont && (drSpinnerCont.style.backgroundImage = "none");
  if (str.indexOf("<sup>") === -1) {
    return str;
  }
  return str.substring(0, str.indexOf("<sup>")).replace(/ /g, "");
}

export function extractDRPriceDecimal(priceDiv) {
  let str = priceDiv.innerHTML;
  if (!str || str.indexOf("<sup>") === -1) {
    return;
  }
  return str
    .substring(str.indexOf("<sup>") + 5, str.indexOf("</sup>"))
    .replace(/ /g, "");
}

export function productName(productDetail) {
  var locale = window.location.pathname.split("/")[1];
  var buy_now;
  switch (locale) {
    case "en-us":
      buy_now = "BUY NOW";
      break;
    case "en-in":
      buy_now = "BUY NOW";
      break;
    case "ja-jp":
      buy_now = "購入する ";
      break;
    case "en-ca":
      buy_now = "BUY NOW";
      break;
    case "en-au":
      buy_now = "BUY NOW";
      break;
    case "en-gb":
      buy_now = "BUY NOW";
      break;
    case "pl-pl":
      buy_now = "KUP TERAZ";
      break;
    case "fr-fr":
      buy_now = "Acheter maintenant";
      break;
    case "fr-ca":
      buy_now = "Acheter maintenant";
      break;
    case "de-de":
      buy_now = "Jetzt Kaufen";
      break;
    case "ru-ru":
      buy_now = "КУПИТЬ";
      break;
    case "es-es":
      buy_now = "COMPRAR AHORA";
      break;
    case "nl-nl":
      buy_now = "Nu kopen";
      break;
    case "it-it":
      buy_now = "Acquista ora";
      break;
    case "id-id":
      buy_now = "Beli Sekarang";
      break; 
      case "vi-vn":
        buy_now = "Mua ngay";
        break;     
    default:
      buy_now = "BUY NOW";
      break;
  }

  setTimeout(function () {
    var $title_element = $(".hatch-76b900").parent().siblings().first();
    $title_element
      .find("div")
      .html(productDetail.productTitle)
      .css({
        color: "#595959",
        "max-width": "800px",
        "font-weight": "bold",
        "font-size": "24px",
      });
    $title_element
      .css({ "border-bottom": "1px solid #e1e1e1", position: "relative" })
      .find("svg")
      .css("stroke", "#000");
    $(".hatch-76b900")
      .find("svg")
      .replaceWith(
        '<div  class="buy-link popup-cta" style="line-height: 24px;">' +
          buy_now +
          "</div>"
      );
    $title_element.parent().css("padding-top", "24px");
  }, 500);
}

/*export function listenForNotifyMe() {
  const startAttachingNotifyMeListenerStart = Date.now();
  let notifyMeListenerAttached = false;
  function addNotifyMeListener() {
    // .dr-modal-form-for-notify-me is outside of our Angular app
    // $('.dr-modal-form-for-notify-me .btn').click(
    $('.dr-modal-form-for-notify-me').click(
        function() {
        // console.log('.dr-modal-form-for-notify-me .btn click');
        $('body').addClass('notify-me-open');

        setTimeout(function() {
          $('#cboxOverlay, #cboxClose').click(function() {
            $('body').removeClass('notify-me-open');
          });
        }, 0) // 300 worked

      }
    );
  }
  function tryDrModalForm() {
    if ($('.dr-modal-form-for-notify-me').length < 1) {
      // console.log(`$('.dr-modal-form-for-notify-me').length ${$('.dr-modal-form-for-notify-me').length}`);
      // window.requestAnimationFrame(tryDrModalForm);
      // stack overflow
      // tryDrModalForm();
      return false;
    } else {
      // console.log(`$('.dr-modal-form-for-notify-me').length ${$('.dr-modal-form-for-notify-me').length}`);
      let drModalReadyTime = Date.now() - startAttachingNotifyMeListenerStart;
      // console.log('time for .dr-modal-form-for-notify-me ready: ' + drModalReadyTime);
      // addNotifyMeListener();
      setTimeout(addNotifyMeListener, 0);
      notifyMeListenerAttached = true;
      return true;
     }
  };
  for (let i = 0; i < 1000; i += 1) {
      (function(i) {
        setTimeout(function() {
          // console.log('trying dr modal form ' + i);
        if (!notifyMeListenerAttached) {
          tryDrModalForm();
          // console.log('trying DrModalForm ' + i);
        }
      }, i * 1);
    })(i);
  }

}*/

export function reBindCart(callback) {
  $(".js-product-item").removeData();
  window["appSetupHelpers"].appSetup();
  callback();
}
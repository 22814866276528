import { Component, OnInit, AfterViewChecked, ElementRef } from '@angular/core';
import { ProductService } from '../../../products/product.service';
// import { TopNavStaticData } from './topnav-static-data';
import { TopNavStaticData } from './topnav-static-data-shield';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as reducers from '../../../reducers';
import * as productActions from '../../../products/product.actions';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';

// RxJS v6+
import { first } from 'rxjs/operators';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { CartService } from '../../../shared/service/cart.service';




@Component({
  selector: 'top-nav',
  templateUrl: './topnav.component.html',
  styleUrls: ['./topnav.component.scss']
})
export class TopnavComponent implements OnInit, AfterViewChecked {

	locale: string;
  hostValue:boolean=false;
  afterViewChecked: boolean = false;
  showMobileMenu: boolean = false;
  showCart: boolean = false;
  sanitizedJavaScriptVoid: SafeUrl;

  NVIDIAheaderFooter: any;
  NVIDIAsubBrandHeader: any;
  NVIDIAglobalHeader: any;
  hideGlobalnavMenu: any;
  toggleGlobalnavMenu: any;
  topNavStaticData: any = TopNavStaticData;

  globalHeader: any;
  subBrandHeader: any;

  constructor(
    private store: Store<reducers.State>,
    private sanitizer:DomSanitizer,
    private elRef:ElementRef,
    private translate: TranslateService,
    private cartService: CartService
  ) {

  }

  ngOnInit() {
    if(window.location.href.indexOf("/studio-laptops/") !== -1){
      console.log("studio-laptops")
    }else{
      localStorage.removeItem('cat2');          
    }

    let locale = window.location.pathname.split('/')[1];

    if(locale === 'en-ca'){
      locale = 'en-us'
    }

    else if(locale === 'fr-ca'){
      locale = 'fr-fr'
    }

    if (window.location.hostname==='localhost') {
      this.hostValue=true;
    } else {
      this.hostValue=false;
    }


    this.sanitizedJavaScriptVoid = this.sanitizer.bypassSecurityTrustUrl('javascript:void(0)');
    let useStaticHeaderData = (/10.110|192.168|localhost|wwwdev/).test(window.location.href);

    // Once wwwdev header API returns three level headers we uncomment to
    // turn off static header data below:
    useStaticHeaderData = false;

    if (!useStaticHeaderData) {

      this.store.dispatch(new productActions.LoadHeaderInfo(locale));

      this.store.select(reducers.getGlobalHeader).subscribe(globalHeader => {
        if(globalHeader) {
          this.globalHeader = globalHeader;
        }
      });

      this.store.select(reducers.getSubBrandHeader).subscribe(subBrandHeader => {
        if(subBrandHeader) {
          this.subBrandHeader = subBrandHeader;
        }
      });

    }
    else {
      console.log('Static data is being used for the header.')
      this.NVIDIAheaderFooter = this.topNavStaticData;
      this.globalHeader = this.NVIDIAheaderFooter.NVIDIAglobalHeader[0];
      this.subBrandHeader = this.NVIDIAheaderFooter.NVIDIAsubBrandHeader[0];
    }

    this.store.select(reducers.getSubBrandHeader);

    this.hideGlobalnavMenu = function(overlayElement) {
      overlayElement.removeClass("in");
      overlayElement.removeClass("show").addClass("hide");
      $(document.body).removeClass("noscroll");
      $('.dropdown-arrow').removeClass("dropdown-expanded");
    }

    this.toggleGlobalnavMenu = function() {
      var overlayElement = $(".global-menu-overlay");
      if (overlayElement.hasClass("hide")) {
             overlayElement.removeClass("hide").addClass("show");
             overlayElement.outerWidth();
             overlayElement.addClass("in");
             $(document.body).addClass("noscroll");
             $('.dropdown-arrow').addClass("dropdown-expanded");
      } else {
             this.hideGlobalnavMenu(overlayElement);
      }
    }

    // let headerItemsResponse = this.getNvidiaNav(locale);
    // let example = headerItemsResponse.pipe(first());
    // let that = this;
    // let useStaticHeaderData = (/10.110|localhost/).test(window.location.href);

    // if (!useStaticHeaderData) {
    //   example.subscribe(res => {
    //     // this.dataD = res;
    //     this.NVIDIAheaderFooter = JSON.parse(res._body);
    //     this.NVIDIAglobalHeader = this.NVIDIAheaderFooter.NVIDIAglobalHeader;
    //     this.NVIDIAsubBrandHeader = this.NVIDIAheaderFooter.NVIDIAsubBrandHeader;
    //   });
    // }
    // else {
    //   this.NVIDIAheaderFooter = this.topNavStaticData;
    //   this.NVIDIAglobalHeader = this.NVIDIAheaderFooter.NVIDIAglobalHeader;
    //   this.NVIDIAsubBrandHeader = this.NVIDIAheaderFooter.NVIDIAsubBrandHeader;
    // }

    this.cartService.changeEmitted$.subscribe(
        text => {
            console.log(text);
        });
  }

  convertLocaleText($event) {
    console.log("In topnav");
  }

  hideBrandnavMenu(overlayElement) {
    overlayElement.removeClass("in");
    overlayElement.removeClass("show").addClass("hide");
    $(".ic-menu").removeClass("ic-menu-green");
    // $('.mobile-hoveritems').css('left', '100%');
    // $('.mobile-hoveritemslvl2').css('left', '100%');
    if ($( window ).width() > 640) {
      // 100% will refer to the correct responsive style setting in effect
      // $('.mobile-hoveritems, .mobile-hoveritemslvl2').css('right', '-640px');
      $('.mobile-hoveritems, .mobile-hoveritemslvl2').css('right', '-100%');
    }
    else {
      $('.mobile-hoveritems, .mobile-hoveritemslvl2').css('right', '-100%');
    }
    $(document.body).removeClass("noscroll");
  }

  toggleBrandnavMenu() {
    var overlayElement = $(".mobile-menu-container");
    if (overlayElement.hasClass("hide")) {
           overlayElement.removeClass("hide").addClass("show");
           overlayElement.outerWidth();
           overlayElement.addClass("in");
           $(".ic-menu").addClass("ic-menu-green");
           $(document.body).addClass("noscroll");
    } else {
           this.hideBrandnavMenu(overlayElement);
    }
  }

  setWhiteTheme = function(e) {
    console.log('setWhiteTheme element clicked:', e.target);

  }

  ngAfterViewChecked() {
    var that = this;


  // Get the studio_laptop element
  // Find the element with the id "Studio Laptops"
  const studioLaptop: HTMLElement | null = document.getElementById('Studio Laptops');
    
  if (studioLaptop && studioLaptop.innerHTML === "Studio Laptops") {
    const parentElement = studioLaptop.parentElement;
    
    if (parentElement) {
      parentElement.addEventListener('click', (event) => {
        // event.preventDefault();
        localStorage.setItem('cat2', studioLaptop.innerHTML);
        console.log('LocalStorage item set to "studio_laptop".');
      });
    }
  }
  //  else {
  //   // localStorage.removeItem('cat2');
  //   console.error('Element with id "Studio Laptops" not found or innerHTML does not match "Studio Laptops".');
  // }





    if (!this.afterViewChecked) {
      //window['appSetupHelpers'].appSetup();
      //window['appSetupHelpers'].appSetup();
      this.afterViewChecked = true;

      let that = this;

      $('.toggle-global-menu-overlay').ready(function() {
        let topNavComponent = that;
        $('.toggle-global-menu-overlay').click(function() {
          topNavComponent.toggleGlobalnavMenu()
        });
      });

      $('.ic-menu-geforce').click(function() {
        that.toggleBrandnavMenu()
      });

      var $cart = $('.cart');

      // SHOP-480 This should not but was firing when mobile menu is clicked
      // $('.cart__close, .js-cart-close, .cart__continue-shopping').on('click', function(e) {
      //     $('.cart-blocker').removeClass('cart-blocker--shown');
      //     $cart.removeClass('cart--shown');
      //     $(document.body).removeClass("noscroll");

      //     return false;
      // });

      // SHOP-480 This replaces the above, the jQuery on is overlistening
      $('.sub-brand-nav-container')[0].addEventListener('touchmove', function(e) {
        // e.stopPropagation();
      }, true);

      // $('.cart__close').ready(function() {
      //   $('.cart__close')[0].addEventListener('click', function(e) {
      //     that.showCart = false;
      //     $('.cart-blocker').removeClass('cart-blocker--shown');
      //     $cart.removeClass('cart--shown');
      //     $(document.body).removeClass("noscroll");

      //     return false;
      //   }, true);
      // });


      // $('.nav-cart-link').ready(function() {
      //   console.log('.nav-cart-link  ready');

      //   $('.nav-cart-link, .cart-counter').on("click", function(){
      //     that.showCart = true;

      //     $('.cart-blocker').addClass('cart-blocker--shown');
      //     $cart.addClass('cart--shown');
      //     that.hideBrandnavMenu($(".mobile-menu-container"));
      //     $(document.body).addClass("noscroll");
      //     return false;
      //   });

      // })

      $('.sub-brand-link').hover(function(){
        $('#sort').blur();
      });

    }

    $('.quantity-selector__dec').off().click(function(e) {
      setTimeout(() => {
        $('.cart-main-section .cart__items').each(function() {
          $(this).find('.cart_remove .cart-item__remove').each(function(i){
            $('.cart_remove .cart-item__remove')[i].innerHTML = that.translate.instant('remove');
          });
        });
        // $('.cart-main-section .cart__checkout-button')[0] && ($('.cart-main-section .cart__checkout-button')[0].innerHTML = that.translate.instant('checkout'));
      }, 50);
    });​

    $('.quantity-selector__inc').off().click(function(e) {
       setTimeout(() => {
        $('.cart-main-section .cart__items').each(function() {
          $(this).find('.cart_remove .cart-item__remove').each(function(i){
            $('.cart_remove .cart-item__remove')[i].innerHTML = that.translate.instant('remove');
          });
        });
        // $('.cart-main-section .cart__checkout-button')[0] && ($('.cart-main-section .cart__checkout-button')[0].innerHTML = that.translate.instant('checkout'));
      }, 50);
    });​

    $(".cart-main-section .cart__items").find(".cart_remove .cart-item__remove").off().click(function(){
      setTimeout(() => {
        $('.cart-main-section .cart__empty')[0] && ($('.cart-main-section .cart__empty')[0].innerHTML = that.translate.instant('empty_cart'));
        // $('.cart-main-section .cart__checkout-button')[0] && ($('.cart-main-section .cart__checkout-button')[0].innerHTML = that.translate.instant('checkout'));
        $('.cart-main-section .cart__items').each(function() {
          $(this).find('.cart_remove .cart-item__remove').each(function(i){
            $('.cart_remove .cart-item__remove')[i].innerHTML = that.translate.instant('remove');
          });
        });
      }, 50);
    });

  }

  

  getNvidiaNav = function(locale) {
      let that = this;

     return this.productService.getHeaderData(locale);
  }

  onNavCartLinkClick = function() {
    this.showCart = true;
    $('.cart-blocker').removeClass('cart-blocker--shown');
    $('.cart').removeClass('cart--shown');
    // The cart modal is not long enough to scroll so we will not add background
    // scrolling fix
    // This will need to have a remove noscroll in case the user clicks outside
    // the cart modal
    // $(document.body).addClass("noscroll");
  }

  onNavCartCloseClick = function() {
    this.showCart = false;
    $('.cart-blocker').removeClass('cart-blocker--shown');
    $('.cart').removeClass('cart--shown');
    // This will need to have a remove noscroll in case the user clicks outside
    // the cart modal
    // $(document.body).removeClass("noscroll");
  }

  openSiblingMenu = function(e) {
    $(e.currentTarget).next().css('right', '0');
    if ($( window ).width() > 640) {
    }
    else {
    }
  }

  openSecondSiblingMenu = function(e) {
    $(e.currentTarget).next().next().css('right', '0');
    if ($( window ).width() > 640) {
    }
    else {
    }
  }

  closeHoverItems = function(e) {
    $(e.currentTarget).closest('.mobile-hoveritems').css('right', '-100%');
    // 100% will refer to the correct responsive style setting in effect
    // if ($( window ).width() > 640) {
    //   $(e.currentTarget).closest('.mobile-hoveritems').css('right', '-640px');
    // }
    // else {
    //   $(e.currentTarget).closest('.mobile-hoveritems').css('right', '-100%');
    // }
  }

  closeHoverItemsLvl2 = function(e) {
    $(e.currentTarget).closest('.mobile-hoveritemslvl2').css('right', '-100%');
  }
}

import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
  ViewChild,
} from "@angular/core";
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  FormControl,
} from "@angular/forms";
import { Store } from "@ngrx/store";
import * as reducers from "../../reducers";
import * as productActions from "../product.actions";
import { Observable } from "rxjs";
import { reBindCart } from "../../shared/utils/utils";
import { PreviousRouteService } from "../../shared/service/previous-route.service";
import { SimpleDropdown } from "../../shared/components/simple-dropdown";

@Component({
  selector: "search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"],
})
export class SearchComponent implements OnInit, OnChanges {
  @Input() totalProducts;
  @Input() productSort;
  @Input() productSearch: string;
  @Input() locale: string;
  @Input() screenWidth: string;
  @Input() suggestedProduct;
  @Input() productSearchTerm;
  @Output() searchSort = new EventEmitter();
  @Output() viewType = new EventEmitter<Event>();
  @ViewChild(SimpleDropdown) sortDropdown: SimpleDropdown;

  searchSortForm: UntypedFormGroup;
  productSort$: Observable<any>;
  currentView = "list";

  constructor(
    private store: Store<reducers.State>,
    private formBuilder: UntypedFormBuilder,
    private previousRouteService: PreviousRouteService
  ) {
    this.getScreenWidth();
    if (this.screenWidth === "SM") {
      this.store.dispatch(new productActions.ViewType("grid"));
    } else {
      this.store.dispatch(new productActions.ViewType("list"));
    }
    //
  }
  getScreenWidth() {
    if (window.matchMedia("(min-width: 1281px)").matches) {
      this.screenWidth = "XL";
    } else if (window.matchMedia("(min-width: 1025px)").matches) {
      this.screenWidth = "LG";
    } else if (window.matchMedia("(min-width: 768px)").matches) {
      this.screenWidth = "MD";
    } else {
      this.screenWidth = "SM";
    }
  }

  ngOnInit() {
    this.getScreenWidth();
    this.searchSortForm = this.formBuilder.group({
      search: this.productSearch ? [this.productSearch] : [""],
      sort: ["fg"],
    });
    setTimeout(function () {
      window["searchHelpers"].initclose();
    }, 100);
  }

  // searchKeyPress(event: any) {
  //   const pattern = /^([a-zA-Z0-9 -]+)$/;
  //   let inputChar = String.fromCharCode(event.charCode);
  //   if (!pattern.test(inputChar) && event.keyCode !== 13 && event.keyCode !== 8) {
  //     // invalid character, prevent input
  //     event.preventDefault();
  //   }
  // }

  ngOnChanges() {
    this.getScreenWidth();

    let isDefaultValue = true;
    let i = 0;
    if (this.searchSortForm) {
      this.searchSortForm.patchValue({ search: this.productSearch });

      this.productSort.map((sort, index) => {
        if (sort.selected) {
          isDefaultValue = false;
          i = index;
          this.searchSortForm.patchValue({ sort: sort.value });
        }
      });

      if (isDefaultValue) {
        this.searchSortForm.patchValue({ sort: "fg" });
      }
      this.sortDropdown && this.sortDropdown.setOption(i);

      if (this.searchSortForm.get("search").value !== "") {
        window["searchHelpers"].showClose();
      }
    }
    this.previousRouteService.setIsFromCompare(false);
  }

  onSort(value) {
    let payload;
    this.searchSortForm.patchValue({ sort: value });
    if (this.searchSortForm.get("search").value !== "") {
      payload = {
        fieldType: "searchSort",
        values: [
          {
            fieldType: "search",
            value: this.searchSortForm.get("search").value.trim(),
          },
          {
            fieldType: "sort",
            //value: value
            value: this.searchSortForm.get("sort").value,
          },
        ],
      };
    } else {
      payload = {
        value: this.searchSortForm.get("sort").value,
        //value: value,
        fieldType: "sort",
      };
    }
    this.store.dispatch(new productActions.UpdateProductParams(payload));
    this.previousRouteService.setIsFromCompare(false);
  }

  onSubmit(form) {
    let payload = {
      // page: 1,
      // limit: 9,
      fieldType: "search",
      value: this.searchSortForm.get("search").value.trim(),
    };

    let defaultPayload = {
      page: 1,
      limit: 12,
      locale: this.locale,
    };

    if (this.searchSortForm.get("search").value !== this.productSearch) {
      //this.sortDropdown.setOption(0);
      // this.store.dispatch(new productActions.SearchProducts(payload));
      // this.store.dispatch(new productActions.LoadProduct({ productSearchCriteria: payload, refreshRequired: true, locale: this.locale }));
      this.store.dispatch(
        new productActions.ClearProductFilterSelection(defaultPayload)
      );
      this.store.dispatch(new productActions.UpdateProductParams(payload));
    }
    this.previousRouteService.setIsFromCompare(false);
    // if ((this.viewportType === 'MD' || this.viewportType === 'SM')) {
    //   p = Object.assign({}, p, {
    //     search: this.searchSortForm.get('search').value.trim()
    //   })
    //     this.store.dispatch(new productActions.LoadProduct({ productSearchCriteria: p, locale: this.locale }));
    //   }
  }

  clearSearch(form) {
    //this.searchSortForm.get('search').value = " ";
    console.log("Clear search");
    this.searchSortForm.get("search").setValue(" ");
    this.onSubmit(this.searchSortForm);
  }

  ngAfterViewChecked() {
    this.getScreenWidth();
  }
  
  updateView(e, view) {
    this.getScreenWidth();
    setTimeout(function () {
      window["hatchwidgetHelpers"].reloadScript();
      window["searchHelpers"].hideFeatured();
    }, 500);
    this.currentView = view;
    this.viewType.emit(view);
    this.store.dispatch(new productActions.ViewType(view));
    e.target.blur();
  }

  setLayoutType(view) {
    this.currentView = view;
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'redirect',
  template: 'redirecting...'
})
export class RedirectComponent implements OnInit {

  private locale: string;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {

    this.route.snapshot.queryParams['locale'] ? this.locale = this.route.snapshot.queryParams['locale'] : this.locale = this.route.snapshot.url[0].path; 
    window.location.href = `https://www.nvidia.com/${this.locale}/consumer/page-not-found`;

  }

}
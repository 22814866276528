
<category-header style="width: 100%;" [screenWidth]="screenWidth" [productFilter]="productFilter$ | async"
(filterChange)="onFilterChange($event)" (clearFilter)="onClearFilter(false)"
(toggleReset)="toggleMobileFilterReset($event)"  >
</category-header>

<div [class.bottom-padding-md-sm]="showFilterCol" class="general-container" 
[style]="{'padding-top': '20px', 'margin-top': '0px !important'}">
<!-- <nav aria-label="breadcrumb" class="breadcrumb-wrapper" *ngIf="locale === 'en-us'&&primaryBreadcrumb">
  <ol class=" breadcrumb breadcrumb_dark__K3Cvh">
    <li class="breadcrumb-item "><a href="/en-us/">Marketplace</a></li>
    <li *ngIf="secondaryBreadcrumb" class="breadcrumb-item active">{{ secondaryBreadcrumb  | translate }} </li>
  </ol>
</nav> -->
  <css-loading-indicator [show]="loading$ | async"></css-loading-indicator>
  <div class="row">
    <!-- <h1 *ngIf="locale === 'en-us'" 
    class="shop-geforce-graphics-cards-header h1-like-h2 h--medium" kuiText
      style="padding-bottom: 25px !important; line-height: 0.8em"
      [class.hide-shop-geforce-graphics-cards-header]="showFilterCol">
      <span *ngIf="secondaryBreadcrumb" class="">{{ secondaryBreadcrumb | translate }}</span>
      <span *ngIf="!secondaryBreadcrumb" class="">{{ "header_text" | translate }}</span>
      <br>
      <span class="nv-subHeaderText">{{ "subheader_text" | translate }}</span> -->

      <!-- <span *ngIf="secondaryBreadcrumb" class="nv-subHeaderText">{{ (secondaryBreadcrumb + "_subheader_text") | translate }}</span>
      <span *ngIf="!secondaryBreadcrumb" class="nv-subHeaderText">{{ "subheader_text" | translate }}</span> -->
    <!-- </h1> -->
    <!-- <h3 *ngIf="locale  === 'en-us'" 
    class="nv-subHeaderText" kuiText
      [style]="locale ==='en-us' ? 'padding-bottom: 20px !important;' : ''"
      [class.hide-shop-geforce-graphics-cards-header]="showFilterCol">
      <span class="">{{ "subheader_text" | translate }}</span>
    </h3> -->
    <!--
    <h1 *ngIf="
      locale === 'en-in' ||
      locale === 'en-gb' ||
      locale === 'en-ca' ||
      locale === 'en-au' ||
      locale === 'en-sg' ||
      locale === 'en-my' ||
      locale === 'en-ph' ||
      locale === 'en-my' 
      " class="shop-geforce-graphics-cards-header h1-like-h2 h--medium" kuiText
      [style]="locale ==='en-us' ? 'padding-bottom: 20px !important;' : ''"
      [class.hide-shop-geforce-graphics-cards-header]="showFilterCol">
      {{ "shop" | translate }}
      <span class="">{{ "geforce" | translate }}</span>
      {{ "graphics_cards" | translate }}
    </h1>
    <h1 *ngIf="
        locale === 'nl-nl' ||
        locale === 'da-dk' ||
        locale === 'nb-no' ||
        locale === 'sv-se' ||
        locale === 'fi-fi' ||
        locale === 'ja-jp'||
        locale === 'th-th' ||
        locale == 'id-id' ||
        locale == 'vi-vn'
      " class="shop-geforce-graphics-cards-header h1-like-h2 h--medium" kuiText
      [class.hide-shop-geforce-graphics-cards-header]="showFilterCol">
      <span class="">{{ "shop_geforce_graphics_cards" | translate }}</span>
    </h1>
    <h1 *ngIf="locale === 'zh-tw'" class="shop-geforce-graphics-cards-header h1-like-h2 h--medium" kuiText
      [class.hide-shop-geforce-graphics-cards-header]="showFilterCol">
      <span class="">{{ "shop_geforce_graphics_cards" | translate }}</span>
    </h1>
    <h1 *ngIf="locale === 'it-it'" class="shop-geforce-graphics-cards-header h1-like-h2 h--medium" kuiText
      [class.hide-shop-geforce-graphics-cards-header]="showFilterCol">
      {{ "shop" | translate }}
      <span class="">{{ "geforce" | translate }}</span>
      {{ "graphics_cards" | translate }}
    </h1>

    <h1 *ngIf="locale === 'de-de'  || locale === 'de-at'" class="shop-geforce-graphics-cards-header h1-like-h2 h--medium"
      kuiText
      [class.hide-shop-geforce-graphics-cards-header]="showFilterCol">
      <span class="">{{ "geforce" | translate }}</span>
      {{ "graphics_cards" | translate }} {{ "shop" | translate }}
    </h1>

    <h1 *ngIf="locale === 'fr-fr' || locale === 'fr-ca'" class="shop-geforce-graphics-cards-header h1-like-h2 h--medium" kuiText
      [class.hide-shop-geforce-graphics-cards-header]="showFilterCol">
      {{ "shop" | translate }}
      {{ "graphics_cards" | translate }}
      <span class="">{{ "geforce" | translate }}</span>
      {{ "graphics_cards_last" | translate }}
    </h1>

    <h1 *ngIf="locale === 'pl-pl'" class="shop-geforce-graphics-cards-header h1-like-h2 h--medium" kuiText
      [class.hide-shop-geforce-graphics-cards-header]="showFilterCol">
      
      {{ "graphics_cards" | translate }} 
    </h1>

    <h1 *ngIf="locale === 'es-es'" class="shop-geforce-graphics-cards-header h1-like-h2 h--medium" kuiText
      [class.hide-shop-geforce-graphics-cards-header]="showFilterCol">
      <span class="">{{ "geforce" | translate }}</span>
      {{ "graphics_cards" | translate }} {{ "shop" | translate }}
    </h1>

    <h1 *ngIf="locale === 'es-mx'" class="shop-geforce-graphics-cards-header h1-like-h2 h--medium" kuiText
      [class.hide-shop-geforce-graphics-cards-header]="showFilterCol">
      {{ "graphics_cards" | translate }}
    </h1>
    <h1 *ngIf="locale === 'ko-kr'" class="shop-geforce-graphics-cards-header h1-like-h2 h--medium" kuiText
      [class.hide-shop-geforce-graphics-cards-header]="showFilterCol">
      {{ "graphics_cards" | translate }}
    </h1>
     <h1 *ngIf="locale === 'pt-br'" class="shop-geforce-graphics-cards-header h1-like-h2 h--medium" kuiText
      [class.hide-shop-geforce-graphics-cards-header]="showFilterCol">
      {{ "shop_geforce_graphics_cards" | translate }}
    </h1>
    <h1 *ngIf="locale === 'ru-ru'" class="shop-geforce-graphics-cards-header h1-like-h2 h--medium" kuiText
      [class.hide-shop-geforce-graphics-cards-header]="showFilterCol">
      {{ "shop_title" | translate }}
      <span *ngIf="locale !== 'ru-ru'" class="">{{
        "geforce" | translate
        }}</span>
    </h1>
  -->
    
    <!-- ///// Filter Column -->
    <div [class.show-filter-col]="showFilterCol" class="filter-col small-12 medium-12 column">
      <div class='filter-container nv-filterContainer'>
        <div class="filter-close-button-container">
          <button type="button" class="filter-close-button" (click)="onFilterCloseButtonClick()"></button>
        </div>
        <product-filters [screenWidth]="screenWidth" [productFilter]="productFilter$ | async"
          (filterChange)="onFilterChange($event)" (clearFilter)="onClearFilter(false)"
          (toggleReset)="toggleMobileFilterReset($event)" ></product-filters>
      </div>
    </div>
    <!-- ///// Right Column -->
    <div [class.hide-main-col]="!showMainCol" class="main-col small-12 medium-12 column">

          <!-- chips filter -->
          <product-chips-filters *ngIf="screenWidth !== 'SM' && screenWidth !== 'MD'" style="width: 100%;" [screenWidth]="screenWidth" [productFilter]="productFilter$ | async"
          (filterChange)="onFilterChange($event)" (clearFilter)="onClearFilter(false)"
          (toggleReset)="toggleMobileFilterReset($event)" ></product-chips-filters>
          
      <div id="mainCont" class='main-container nv-mainContainer'>
        <search #searchComp (viewType)="productDetails.sendData($event)" [totalProducts]="totalProducts$ | async"
          [productSort]="productSort$ | async" [productSearch]="productSearch$ | async" [locale]="locale$ | async"
          [suggestedProduct]="suggestedProduct$ | async"  [screenWidth]="screenWidth" [productSearchTerm]="productSearch$ | async">
        </search>

        <!-- <suggested-product *ngIf="locale !== 'en-us'" [screenWidth]="screenWidth" [suggestedProduct]="suggestedProduct$ | async"
          [totalProducts]="totalProducts$ | async" [productSearchTerm]="productSearch$ | async"></suggested-product> -->

        <!--- <product-filter-crumbs></product-filter-crumbs> ---->

        <featured-product [screenWidth]="screenWidth" (fpLoaded)="productDetails.fpLoaded($event)"
          [suggestedProduct]="suggestedProduct$ | async" [productCompare]="productCompare$ | async"
          (hatchOverlayDisplay)="showHatchOverlay($event)"></featured-product>
        <product-details #productDetails (curViewType)="searchComp.setLayoutType($event)" [screenWidth]="screenWidth"
          [productCompare]="productCompare$ | async" (hatchOverlayDisplay)="showHatchOverlay($event)"></product-details>
        <load-more [locale]="locale" [limit]="productSearchCriteria.limit" [page]="productSearchCriteria.page"
          [totalProducts]="totalProducts$ | async" (onPaging)="onPaging($event)" [isFeaturedProductIncludedInCount]="
            isFeaturedProductIncludedInCount$ | async
          "></load-more>
      </div>
    </div>
  </div>
  <div class="reminder-message" *ngIf="locale === 'ko-kr'"> <p>{{ "가격은 각 쇼핑몰에서 받은 상품 정보로, 최종가는 해당 쇼핑몰에서 직접 확인하시기 바랍니다." | translate }} </p></div>
  <div class="reminder-message" *ngIf="locale === 'id-id'"> <p>{{ "Harga ditentukan oleh retailer dan dapat berubah sewaktu waktu" | translate }} </p></div>
 <div class="reminder-message" *ngIf="locale === 'ja-jp'"> <p>{{ "価格は小売業者によって管理され、変更される場合があります" | translate }} </p></div>
 <div class="reminder-message" *ngIf="locale === 'th-th'"> <p>{{ "ราคากำหนดโดยตัวแทนจำหน่าย ขอสงวนสิทธิ์ในการเปลี่ยนแปลงโดยไม่ต้องแจ้งให้ทราบล่วงหน้า" | translate }} </p></div> 
 <div class="reminder-message" *ngIf="locale === 'vi-vn'"> <p>{{ "Giá được niêm yết bởi đơn vị bán hàng và có thể thay đổi" | translate }} </p></div> 
 <!-- <div *ngIf="locale !== 'en-us'" [class.hide-footer]="
      showFilterCol && (screenWidth === 'MD' || screenWidth === 'SM')
    " class="outer-footer-container">
  
    <footer></footer>
  </div> -->
</div>
<div [class.hide-footer]="
showFilterCol && (screenWidth === 'MD' || screenWidth === 'SM')
" class="outer-footer-container">

<footer></footer>
</div>
<div id="filterCompareCont" [class.hide-filter-compare]="!showFilterCompareButtons"
  class="filter-compare-buttons-main-container clearfix">
  <div class="filter-compare-buttons-container clearfix">
    <div [class.normal-z]="useNormalZIndex" class="filter-button-container" (click)="onFilterButtonClick()">
      <button type="button" class="filter-button">
        {{ "filter" | translate }}
      </button>
    </div>
    <!-- <compare-overlay [screenWidth]="screenWidth" [productCompare]="productCompare$ | async"
      (transitionEnd)="onCompareOverlayTransitionEnd($event)"></compare-overlay> -->
  </div>
</div>

<!-- /////------------ Apply and Reset filter buttons for the Tablet and Mobile view -----------///// -->
<div *ngIf="showFilterCol" class="product-footer-container">
  <input type="submit" value="{{ 'reset_filter' | translate }}"
    [class.reset-filters-disabled]="disableMobileFilterReset" class="product-footer-input reset-filters-input"
    (click)="onClearFilter(false)" />
  <input type="submit" value="{{ 'apply' | translate }}" class="product-footer-input apply-filters-input"
    (click)="applyFilter()" />
</div>
<hatch-overlay [showSpinner]="retailInfoLoading$ | async" [winPageYOffset]="winPageYOffset"
  [hatchOverlayDisplay]="hatchOverlayDisplay" [retailInfo]="retailInfo$ | async"
  [productDetail]="productDetail"></hatch-overlay>

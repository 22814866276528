<header id="main-header" class="">
    <div class="navigation" style="display: none;">

  <nav class="global-nav dynamic geforce">

    <div class="nav-header dynamic">
      <div class="nav-header-container">    <!--  *ngIf="globalHeader" -->
          <div class="brand-container">
              <a class="brand-link pull-left" href="{{globalHeader.logoLinkURL}}" title="NVIDIA">
                  <!-- logoImagePath is blank from the API, so giving static data -->
               <img *ngIf="!hostValue" src="./geforce/store/assets/images/NVIDIA-Logo.svg" alt="NVIDIA" height="44" width="110">
               <img *ngIf="hostValue" src="./assets/images/NVIDIA-Logo.svg" alt="NVIDIA" height="44" width="110">
              </a>

                  <a class="hide dropdown-arrow geforce"></a>

                  <div class="dropdown-arrow global-nav-dropdown toggle-global-menu-overlay xdropdown-expanded"></div>

          </div>
          <div class="nav-tools-container" *ngIf="false">
              <ul class="nav-header-list">
                  <li class="hide nav-header-item" *ngIf="globalHeader?.showSearch">
                    <a class="nav-search-link" href="#"></a>
                  </li>
                  <li class="nav-header-item"> <!--  *ngIf="globalHeader?.showCart" -->
                    <span class="nav-badge cart-counter" (click)="onNavCartLinkClick()">0</span>
                    <a class="nav-cart-link" (click)="onNavCartLinkClick()"></a>
                    <div class="cart-main-section">
                      <div class="cart-section">
                        <div class="cart_white cart" [ngClass]="{'cart--shown': showCart}"><!--  [hidden]="!showCart" -->
                          <a class="cart__close" (click)="onNavCartCloseClick()"></a><!-- href="#" -->
                          <div class="cart__title">Cart</div>
                          <div id="cart"> </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li *ngIf="globalHeader.showProfileIcon" class="nav-header-item">
                     <!--  <span class="nav-badge">0</span> -->
                      <a id="nv-login" class="nav-profile-link" href="{{globalHeader.loginURL}}">
                          <span class="nav-profile-logo pull-left" title="My Account"></span>
                         <!--  <span class="nav-profile-text pull-left hide">sign in</span> -->
                      </a>
                  </li>
              </ul>
          </div>
      </div>
  </div>

        <aside *ngIf="globalHeader" class="global-menu-overlay dynamic fade hide">
          <div class="menu-container">
              <div class="row overlay-padding">
                  <div class="column small-12 medium-12 large-12 xl-8 ">
                      <div class="nav-section-title">
                          <span class="body-text">{{ "platforms" | translate }}</span>
                      </div>

                          <div *ngFor="let nvPlatform of globalHeader.nvPlatforms" class="global-nav-item">
                            <a href="{{nvPlatform.navPath}}" class="global-nav-link">
                              <img src="{{nvPlatform.imagePath}}" class="global-nav-image" alt="{{nvPlatform.navLabel}}" title="{{nvPlatform.navLabel}}">
                              <span class="global-nav-title" [innerHTML]="nvPlatform.navLabel | safeHtml"></span>
                          </a>
                          <div *ngIf="nvPlatform.hasOwnProperty('hoveritemslvl2')">
                              <a href="{{nvPlatform.navPath}}" class="global-nav-link">
                                  <img src="{{nvPlatform.imagePath}}" class="global-nav-image" alt="{{nvPlatform.navLabel}}" title="{{nvPlatform.navLabel}}">
                                  <span class="global-nav-title" [innerHTML]="nvPlatform.navLabel | safeHtml"></span>
                              </a>
                              <div *ngFor="let hoveritemslvl2 of hoveritemslvl2" class="global-nav-item">
                                  <img src="{{hoveritemslvl2.imagePath}}" class="global-nav-image" alt="{{hoveritemslvl2.navLabel}}" title="{{hoveritemslvl2.navLabel}}">
                                  <span class="global-nav-title" [innerHTML]="hoveritemslvl2.navLabel | safeHtml"></span>
                              </div>
                          </div>
                      </div>

                  </div>
                  <div class="column small-12 medium-12 large-12 xl-4 ">
                      <div class="nav-section-title">
                          <span class="body-text">{{ "other_links" | translate }}</span>
                      </div>
                      <ul class="nav-other-links">

                              <li *ngFor= "let globalnavItem of globalHeader.globalnavItemsGrp1" class="other-links-element">
                                <a href="{{globalnavItem.navPath}}" class="global-nav-link">{{globalnavItem.navLabel}}</a>
                            </li>



                      </ul>
                      <ul class="nav-other-links">

                              <li *ngFor= "let globalnavItem of globalHeader.globalnavItemsGrp2" class="other-links-element">
                                <a href="{{globalnavItem.navPath}}" class="global-nav-link">{{globalnavItem.navLabel}}</a>
                              </li>
                      </ul>
                  </div>

                    <div class="column small-12 medium-12 large-12 xl-4 ">
                        <div class="nav-section-title">
                            <span class="body-text">Technologies</span>
                        </div>
                        <ul class="nav-other-links">

                                <li class="other-links-element">
                                    <a href="http://www.nvidia.com/object/virtual-reality.html" class="global-nav-link">VIRTUAL REALITY</a>
                                </li>

                        </ul>
                    </div>

              </div>
          </div>
      </aside>

        <div class="sub-brand-nav">
          <div class="sub-brand-nav-container">

              <div class="dynamic brand-nav-left fade in"><!--  *ngIf="subBrandHeader" -->
                    <div id="sub-brand">
                        <a class="sub-brand-name" href="{{subBrandHeader.logoPathURL}}">
                        <img id="sub-brand-logo" src="{{subBrandHeader.logoImagePath}}" title="GEFORCE" alt="">
                        </a>
                    </div>

                        <div *ngFor="let brandNavItem of subBrandHeader.brandNavItems" class="sub-brand-item">

                          <!-- Updated code for Clickable Navigation COM 109 fix -->
                                   <div *ngIf="!brandNavItem.hasOwnProperty('hoveritems')" class="sub-brand-link-and-submenu">
                                      <a href="{{brandNavItem.navPath}}" class="sub-brand-link" [innerHTML]="brandNavItem.navLabel | safeHtml"></a>
                                    </div>
                                    <div *ngIf="brandNavItem.hasOwnProperty('hoveritems')" class="sub-brand-link-and-submenu">
                                      <span class="sub-brand-link">
                                          <a *ngIf="!brandNavItem.hasOwnProperty('hoveritems') && brandNavItem.navPath" href="{{brandNavItem.navPath}}" class="sub-brand-link" [innerHTML]="brandNavItem.navLabel | safeHtml"></a>
                                          <span *ngIf="brandNavItem.hasOwnProperty('hoveritems')" class="sub-brand-link sub-brand-link" style="color: #999;" [innerHTML]="brandNavItem.navLabel | safeHtml"></span>
                                          <div class="ic-arrow-dropdown">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M7 10l5 5 5-5z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>
                                          </div>
                                      </span>

                                      <!-- End of Navigation COM 109 fix -->
                                      <ul class="dropdown-menu bullet">

                                        <div>
                                          <li *ngIf="brandNavItem.hasOwnProperty('navPath') && brandNavItem.navPath && brandNavItem.hasOwnProperty('hoveritems')">
                                            <a href="{{brandNavItem.navPath}}" class="global-nav-link">
                                                <span class="hoveritemlvl2-link-text">{{ "overview" | translate }}</span>
                                            </a>
                                          </li>
                                          <li *ngFor="let hoveritem of brandNavItem.hoveritems">
                                              <div *ngIf="!hoveritem.hasOwnProperty('hoveritemslvl2')">
                                                  <a href="{{hoveritem.navPath}}" class="global-nav-link">
                                                      <span class="hoveritemlvl2-link-text" [innerHTML]="hoveritem.navLabel | safeHtml"></span>
                                                  </a>
                                              </div>

                                              <div class="has-children" *ngIf="hoveritem.hasOwnProperty('hoveritemslvl2')">
                                                <a href="javascript:void(0)" class="global-nav-link lvl1">
                                                  <span class="has-submenu hoveritemlvl2-link-text" [innerHTML]="hoveritem.navLabel | safeHtml"></span>
                                                  <span class="arrow">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M10 17l5-5-5-5v10z"></path><path fill="none" d="M0 24V0h24v24H0z"></path></svg>
                                                  </span>
                                                </a>

                                                <ul class="dropdown-menu-lvl2 bullet">
                                                  <li *ngIf="hoveritem.hasOwnProperty('navPath') && hoveritem.navPath && hoveritem.hasOwnProperty('hoveritemslvl2')">
                                                    <a href="{{hoveritem.navPath}}" class="global-nav-link" style="display: flex">
                                                        <span class="hoveritemlvl2-link-text">{{ "overview" | translate }}</span>
                                                    </a>
                                                  </li>
                                                  <li *ngFor="let hoveritemlvl2 of hoveritem.hoveritemslvl2">
                                                    <div [ngClass]="{'has-children': hoveritemlvl2.hasOwnProperty('hoveritemslvl3')}">
                                                      <a [href]="hoveritemlvl2.hasOwnProperty('hoveritemslvl3') ? sanitizedJavaScriptVoid : hoveritemlvl2.navPath" class="global-nav-link" style="display: flex">
                                                        <span [ngClass]="{'has-submenu': hoveritemlvl2.hasOwnProperty('hoveritemslvl3')}" class="hoveritemlvl2-link-text" [innerHTML]="hoveritemlvl2.navLabel | safeHtml"></span>
                                                        <span *ngIf="hoveritemlvl2.hasOwnProperty('hoveritemslvl3')" class="arrow">
                                                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M10 17l5-5-5-5v10z"></path><path fill="none" d="M0 24V0h24v24H0z"></path></svg>
                                                        </span>
                                                      </a>

                                                      <ul *ngIf="hoveritemlvl2.hasOwnProperty('hoveritemslvl3')" class="dropdown-menu-lvl2 bullet lvl2">
                                                        <li *ngFor="let hoveritemlvl3 of hoveritemlvl2.hoveritemslvl3">
                                                            <a href="{{hoveritemlvl3.navPath}}" class="global-nav-link" [innerHTML]="hoveritemlvl3.navLabel | safeHtml"></a>
                                                        </li>
                                                       </ul>
                                                    </div>



                                                  </li>
                                                 </ul>
                                              </div>
                                          </li>
                                        </div>

                                      </ul>

                                    </div>

                                  </div>

                <!-- <div class="brand-nav-right">
                    <a class="ic-menu ic-menu-geforce"></a>
                </div>   -->
            </div><!-- end dynamic header -->

              <div class="brand-nav-right">
                  <a class="ic-menu ic-menu-geforce"></a>
              </div>
          </div>

          <div class="mobile-menu-container fade hide full-submenu-panes">

                      <ul class="brand-nav-list mobile-menu full-submenu-panes">
                                      <!-- Updated code for Mobile Clickable Navigation COM 109 fix -->

                                      <div *ngIf="subBrandHeader">
                                        <li *ngFor="let brandNavItem of subBrandHeader.brandNavItems" class="brand-nav-item brand-nav-link" [ngClass]="{'category-item': brandNavItem.hasOwnProperty('hoveritems')}">
                                          <!-- <div>
                                          </div> -->
                                          <a *ngIf="!brandNavItem.hasOwnProperty('hoveritems')" href="{{brandNavItem.navPath}}" class="brand-nav-link" [innerHTML]="brandNavItem.navLabel | safeHtml"></a>
                                          <div class="flex" *ngIf="brandNavItem.hasOwnProperty('hoveritems')">
                                              <!-- Updated code for Mobile Clickable Navigation COM 109 fix -->
                                              <!-- <li class="brand-nav-item category-item">
                                            </li> -->

                                            <a href="javascript:void(0)" class="brand-nav-link category-link" [innerHTML]="brandNavItem.navLabel | safeHtml" (click)="openSecondSiblingMenu($event)"></a><!-- {{brandNavItem.navPath}} -->
                                            <span class="mobile-arrow-right" (click)="openSiblingMenu($event)"></span>
                                            <ul class="brand-nav-list mobile-hoveritems">

                                              <li class="brand-nav-item sub-item back">
                                                <a class="brand-nav-link" (click)="closeHoverItems($event)"><span class="mobile-arrow-left first-level"><svg xmlns="http://www.w3.org/2000/svg" class="left-arrow" version="1.1" viewBox="0 0 512 512" xml:space="preserve"><polygon points="352,128.4 319.7,96 160,256 160,256 160,256 319.7,416 352,383.6 224.7,256"/></svg></span><span>Back</span></a>
                                              </li>

                                              <!--
                                              -->
                                              <li class="brand-nav-item sub-item">
                                                  <a class="brand-nav-link menu-title">{{brandNavItem.navLabel}}</a>
                                                </li>
                                              <li *ngIf="brandNavItem.hasOwnProperty('navPath') && brandNavItem.navPath && brandNavItem.hasOwnProperty('hoveritems')" class="brand-nav-item sub-item">
                                                <a href="{{brandNavItem.navPath}}" class="brand-nav-link">
                                                    <span>{{ "overview" | translate }}</span>
                                                </a>
                                              </li>
                                              <li *ngFor="let hoveritem of brandNavItem.hoveritems" class="brand-nav-item sub-item">
                                                <div *ngIf="!hoveritem.hasOwnProperty('hoveritemslvl2')">
                                                  <a href="{{hoveritem.navPath}}" class="brand-nav-link" [innerHTML]="hoveritem.navLabel | safeHtml"></a>
                                                </div>
                                                <div class="flex" *ngIf="hoveritem.hasOwnProperty('hoveritemslvl2')">
                                                  <a href="javascript:void(0)" class="brand-nav-link category-link" [innerHTML]="hoveritem.navLabel | safeHtml" (click)="openSecondSiblingMenu($event)"></a><!-- {{hoveritem.navPath}} -->
                                                  <span class="mobile-arrow-right" (click)="openSiblingMenu($event)"></span>
                                                  <ul class="mobile-hoveritemslvl2">

                                                    <li class="brand-nav-item sub-item back">
                                                        <a class="brand-nav-link" (click)="closeHoverItemsLvl2($event)"><span class="mobile-arrow-left"><svg xmlns="http://www.w3.org/2000/svg" class="left-arrow" version="1.1" viewBox="0 0 512 512" xml:space="preserve"><polygon points="352,128.4 319.7,96 160,256 160,256 160,256 319.7,416 352,383.6 224.7,256"/></svg></span><span>Back</span></a>
                                                      </li>
                                                    <li *ngIf="hoveritem.hasOwnProperty('navPath') && hoveritem.navPath && hoveritem.hasOwnProperty('hoveritemslvl2')" class="brand-nav-item sub-item flex">
                                                      <a href="{{hoveritem.navPath}}" class="brand-nav-link">
                                                          <span class="hoveritemlvl2-link-text">{{ "overview" | translate }}</span>
                                                      </a>
                                                    </li>
                                                    <li *ngFor="let hoveritemlvl2 of hoveritem.hoveritemslvl2" class="brand-nav-item sub-item flex">
                                                      <a [href]="hoveritemlvl2.hasOwnProperty('hoveritemslvl3') ? sanitizedJavaScriptVoid : hoveritemlvl2.navPath" class="brand-nav-link" [ngClass]="{'category-link': hoveritemlvl2.hasOwnProperty('hoveritemslvl3')}" [innerHTML]="hoveritemlvl2.navLabel | safeHtml" (click)="openSecondSiblingMenu($event)"></a>
                                                      <span *ngIf="hoveritemlvl2.hasOwnProperty('hoveritemslvl3')" class="mobile-arrow-right" (click)="openSiblingMenu($event)"></span>
                                                      <ul class="mobile-hoveritemslvl2">
                                                        <li class="hide brand-nav-item sub-item back">
                                                          <a class="brand-nav-link back">Back</a>
                                                        </li>
                                                        <li class="brand-nav-item sub-item back">
                                                            <a class="brand-nav-link" (click)="closeHoverItemsLvl2($event)"><span class="mobile-arrow-left"><svg xmlns="http://www.w3.org/2000/svg" class="left-arrow" version="1.1" viewBox="0 0 512 512" xml:space="preserve"><polygon points="352,128.4 319.7,96 160,256 160,256 160,256 319.7,416 352,383.6 224.7,256"/></svg></span><span>Back</span></a>
                                                          </li>

                                                        <li *ngFor="let hoveritemlvl3 of hoveritemlvl2.hoveritemslvl3" class="brand-nav-item sub-item">
                                                          <a [href]="hoveritemlvl3.hasOwnProperty('hoveritemslvl4') ? sanitizedJavaScriptVoid : hoveritemlvl3.navPath" class="brand-nav-link" [innerHTML]="hoveritemlvl3.navLabel | safeHtml" (click)="openSecondSiblingMenu($event)"></a>
                                                        </li>

                                                        <!-- need to push the last mobile menu links above the footer -->
                                                        <li class="brand-nav-item blank-line-so-last-item-can-show">
                                                            <a href="" class="brand-nav-link">&nbsp;</a>
                                                        </li>

                                                        <li class="brand-nav-item blank-line-so-last-item-can-show">
                                                            <a href="" class="brand-nav-link">&nbsp;</a>
                                                        </li>

                                                        <li class="brand-nav-item blank-line-so-last-two-items-can-show">
                                                          <a href="" class="brand-nav-link">&nbsp;</a>
                                                        </li>

                                                      </ul>
                                                    </li>

                                                    <!-- need to push the last mobile menu links above the footer -->
                                                    <li class="brand-nav-item blank-line-so-last-item-can-show">
                                                        <a href="" class="brand-nav-link">&nbsp;</a>
                                                    </li>

                                                    <li class="brand-nav-item blank-line-so-last-item-can-show">
                                                        <a href="" class="brand-nav-link">&nbsp;</a>
                                                    </li>

                                                    <li class="brand-nav-item blank-line-so-last-two-items-can-show">
                                                      <a href="" class="brand-nav-link">&nbsp;</a>
                                                    </li>

                                                  </ul>

                                                  <!--
                                                  <li *ngFor="let hoveritemlvl2 of hoveritem.hoveritemslvl2" class="brand-nav-item sub-item static-presentation">
                                                    <a href="{{hoveritemlvl2.navPath}}" class="brand-nav-link" [innerHTML]="hoveritemlvl2.navLabel"></a>
                                                  </li>
                                                  -->

                                                </div>
                                              </li>

                                              <!-- need to push the last mobile menu links above the footer -->
                                              <li class="brand-nav-item blank-line-so-last-item-can-show">
                                                  <a href="" class="brand-nav-link">&nbsp;</a>
                                              </li>

                                              <li class="brand-nav-item blank-line-so-last-item-can-show">
                                                  <a href="" class="brand-nav-link">&nbsp;</a>
                                                </li>

                                                <li class="brand-nav-item blank-line-so-last-two-items-can-show">
                                                  <a href="" class="brand-nav-link">&nbsp;</a>
                                                </li>

                                            </ul>


                                            <!-- End of Mobile Navigation COM 109 fix -->
                                            <ul class="hide  brand-nav-list static">

                                              <li *ngFor="let hoveritem of brandNavItem.hoveritems" class="brand-nav-item sub-item">
                                                <div *ngIf="!hoveritem.hasOwnProperty('hoveritemslvl2')">
                                                  <a href="{{hoveritem.navPath}}" class="brand-nav-link" [innerHTML]="hoveritem.navLabel | safeHtml"></a>
                                                </div>
                                                <div *ngIf="hoveritem.hasOwnProperty('hoveritemslvl2')">
                                                  <a href="javascript:void(0)" class="brand-nav-link" [innerHTML]="hoveritem.navLabel | safeHtml"></a><!-- {{hoveritem.navPath}} -->

                                                  <ul class="mobile-hoveritemslvl2">
                                                    <li *ngFor="let hoveritemlvl2 of hoveritem.hoveritemslvl2" class="brand-nav-item sub-item">
                                                      <a href="{{hoveritemlvl2.navPath}}" class="brand-nav-link" [innerHTML]="hoveritemlvl2.navLabel | safeHtml"></a>
                                                    </li>
                                                  </ul>

                                                  <li *ngFor="let hoveritemlvl2 of hoveritem.hoveritemslvl2" class="hide brand-nav-item sub-item static-presentation">
                                                    <a href="{{hoveritemlvl2.navPath}}" class="brand-nav-link" [innerHTML]="hoveritemlvl2.navLabel | safeHtml"></a>
                                                  </li>

                                                </div>
                                              </li>

                                            </ul>
                                        </div>
                                       </li>
                                        <!-- need to push the last mobile menu links above the footer -->
                                        <li class="brand-nav-item blank-line-so-last-item-can-show">
                                          <a href="" class="brand-nav-link">&nbsp;</a>
                                        </li>

                                      <li class="brand-nav-item blank-line-so-last-item-can-show">
                                        <a href="" class="brand-nav-link">&nbsp;</a>
                                      </li>

                                      <li class="brand-nav-item blank-line-so-last-two-items-can-show">
                                        <a href="" class="brand-nav-link">&nbsp;</a>
                                      </li>

                                      </div>
                          </ul>
      </div>
    </div><!-- end mobile menu 1 -->



    <div class="back-to-top" style="display: none;">
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47.5 47.5">
            <defs><style>.cls-1{fill:#79b600;}.cls-2{fill:#fff;}</style></defs>
            <title>ic_arrow-back-to-top</title>
            <rect class="cls-1" width="47.5" height="47.5"></rect><path class="cls-2" d="M13.53,31.77,24,21.29,34.54,31.77l3.23-3.23L24,14.81,10.3,28.54Z" transform="translate(0 -0.5)"></path>
        </svg>
    </div>
     <script>
        NVIDIAGDC.funcQueue.addToQueue({
            id : "navigationb5595a60_9a4b_4270_b5c5_631956f8c081",
            method : "navigation",
            params : [{
                globalSite:false,
                breadCrumbAdded: false
                }]
        });
    </script>


</nav></div>

</header>

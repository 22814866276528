/// Angular modules ///
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, NO_ERRORS_SCHEMA } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { UrlSerializer } from '@angular/router';
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
/// ngrx modules ///
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

/// External libs ///
import { NouisliderModule } from 'ng2-nouislider';
/// Routing ///
import { routing, appRoutingProviders } from './app.routing';
import { DataResolverService } from './products/fe-resolver';
/// Application modules ///
import { AppComponent } from './app.component';
import { ProductModule } from './products/product.module';
import { ProductService } from './products/product.service';
import { FeService } from './products/fe.service';
import { LayoutService } from './shared/service/layout.service';
import { ScriptService } from './shared/service/script.service';
import { SeoService } from './shared/service/seo.service';
import { CartService } from './shared/service/cart.service';
import { ProductEffects } from './products/product.effects';
import { reducers } from './reducers';
import { ViewportDetector } from './shared/viewport-detector';
import { TopnavComponent } from './shared/layout/topnav/topnav.component';
import { BackToTopComponent } from './back-to-top/back-to-top.component';
import { RedirectComponent } from './redirect/redirect.component';
import { TrailingSlashUrlSerializer } from './custome-url-serializer';
import { SafeHtmlPipe } from './shared/pipes/safe-html.pipe';
import { ThemeModule } from "@kui-angular/theme";
import { ButtonModule } from "@kui-angular/button";

export const metaReducers = [];
declare module "@angular/core" {
  interface ModuleWithProviders<T = any> {
    ngModule: Type<T>;
    providers?: (Provider | EnvironmentProviders)[];
  }
}

@NgModule({
  declarations: [
    AppComponent,
    TopnavComponent,
    BackToTopComponent,
    RedirectComponent,
    SafeHtmlPipe
  ],
  imports: [    
    ThemeModule,
    ButtonModule,
    BrowserModule,
    HttpClientModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    routing,
    NouisliderModule,
    FormsModule,
    ReactiveFormsModule,
    EffectsModule.forRoot([
      ProductEffects
    ]),
    StoreDevtoolsModule.instrument({ maxAge: 25 }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ProductModule
  ],
  providers: [appRoutingProviders, DataResolverService, FeService, ProductService, ViewportDetector, LayoutService, /*ModalService,*/ ScriptService, SeoService, CartService,
    { provide: UrlSerializer, useClass: TrailingSlashUrlSerializer },
    { provide: LOCALE_ID, useValue: 'fr' }],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA]

})
export class AppModule { }
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, (window.location.origin=="http://localhost:4200")? '../assets/i18n/' : '/geforce/store/assets/i18n/', '.json');
}

import { Component, OnInit, Input, HostListener } from '@angular/core';
import { ProductDetails } from '../../shared/interface/product-details';
import { ProductCompare } from '../../shared/interface/product-compare';
import { LayoutService } from '../../shared/service/layout.service';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import * as reducers from '../../reducers';


@Component({
	selector: "suggested-product",
	templateUrl: "./suggested-product.component.html",
	styleUrls: ['./suggested-product.component.scss']
})
export class SuggestedProduct implements OnInit {
	@Input() screenWidth: string;
	@Input() suggestedProduct;
	@Input() totalProducts;
	@Input() productSearchTerm;
	productDetail;
	locale: string;


	constructor() {

	}

	ngOnInit() {
		var that = this;
		let locale = window.location.pathname.split('/')[1];
		this.locale = locale;

	}
}

import { Component, OnInit, HostListener  } from "@angular/core";
import { Store } from "@ngrx/store";
import * as reducers from "../../../reducers";
import { stopBodyScrolling } from "../../utils/utils";
import { ActivatedRoute, Router } from "@angular/router";
import { ModalService } from "../../service/modal.service";
declare var $: any;

@Component({
  selector: "footer",
  templateUrl: "./footer.component.html",
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  countriesList;
  regionsAndCountriesList;
  stopBodyScrolling;
  locale: string;
  winPageYOffset: number;
  screenWidth: number;
  // locale: string = 'en-us';
  constructor(
    private store: Store<reducers.State>,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: ModalService,
  ) {
    this.stopBodyScrolling = stopBodyScrolling;
    this.screenWidth = window.innerWidth;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.screenWidth = event.target.innerWidth;
  }

  getFlinksStyles() {
    let width = this.locale === 'en-us' ? '25%' : '31%';

    if (this.screenWidth < 768) { // Example breakpoint for 'sm' screen
      width = '100%';
    } 
    // else if (this.screenWidth >= 768 && this.screenWidth < 992) { // Example breakpoint for 'md' screen
    //   fontSize = 'medium';
    // } else if (this.screenWidth >= 992) { // Example breakpoint for 'lg' screen
    //   fontSize = 'large';
    // }

    return {
      'width': width,
    };
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.locale = params["locale"];
    });
    this.regionsAndCountriesList = this.modalService.getRegionsAndCountries();
  }

  openModal(id: string) {
    this.modalService.open(id);
    this.winPageYOffset = window.pageYOffset;
    this.stopBodyScrolling(true);
    const modalID = document.getElementById(id);
    const openModal: HTMLElement = $(modalID).find(".modal");
    $(openModal).addClass("openModal");
    // openModal.style.backgroundColor = "red";
  }

  closeModal(id: string) {
    this.modalService.close(id);
    this.stopBodyScrolling(false);
    window.scroll(0, this.winPageYOffset);
    const modalID = document.getElementById(id);
    const openModal: HTMLElement = $(modalID).find(".modal");
    $(openModal).removeClass("openModal");
  }

  closeOverlay(e, overlay) {
    if (
      (e.type === "click" && e.target === overlay) ||
      (e.type === "keyup" && e.keyCode === 27)
    ) {
      this.closeModal("countries");
    }
  }
}

<div *ngIf="featuredProduct && !suggestedProduct.productDetails?.length">
    <div *ngIf="screenWidth === 'XL'" class="featured-container-xl">
        <div class="call-out" kuiText>
            {{ 'featured' | translate }}
        </div>
        <div tabindex="0" class="product-container clearfix">
            <div class="img-col-xl">
                <div class="image-xl">
                    <kui-image aspectRatio="1-1" class="featured-img-block">
                        <img #fim [title]="featuredProduct.category === 'MODELS' || featuredProduct.category === 'GAMING_SOFTWARE' ? featuredProduct.productTitle : featuredProduct.displayName" src="{{featuredProduct.imageURL}}"
                            alt="Featured product" class="featured-img 28" (window:resize)="createThumbnail(fim)"
                            (load)="createThumbnail(fim)" (error)="showImgError(fim)" width="100%" height="100%">
                    </kui-image>
                </div>
            </div>
            <div class="details-col">
                <a class="product-title" *ngIf="featuredProduct.isPDP;else title_content"
                    href='{{getPDPLink(featuredProduct)}}' target="_blank" rel="noopener">
                    <h2 kuiText class="product-name h--smallest" [innerHTML]="featuredProduct.productTitle"></h2>
                </a>
                <ng-template #title_content>
                    <h2 kuiText class="product-name h--smallest" [innerHTML]="featuredProduct.productTitle"></h2>
                </ng-template>
                <div class="specs-container">
                    <div *ngIf="featuredProduct.category==='GFN' || featuredProduct.category==='MODELS' || featuredProduct.category==='GAMING_SOFTWARE'" [innerHTML]="featuredProduct.productInfo[0].value"></div>
                    <ul *ngIf="featuredProduct.category!='GFN' && featuredProduct.category!='MODELS' && featuredProduct.category!='GAMING_SOFTWARE'">
                        <li *ngFor="let specs of featuredProduct.productInfo">
                            <div class="specs p-medium" kuiText>{{specs.name |
                                translate}}:&nbsp;{{valueTrimmer(specs.value)}} {{(((featuredProduct.category==='LAPTOP'
                                || featuredProduct.category==='STUDIO-LAPTOP') && specs.name
                                =='gpu')?'gpu_spec_sufix':'') | translate}}</div>
                        </li>
                    </ul>

                    <popover [offerLabel]="'game_bundle' | translate" role="button" tabindex="0"
                        *ngIf="featuredProduct.isOffer" [screenwidth]="screenWidth" [type]="'Game Bundle'"
                        [bundle]="featuredProduct.offerText"></popover>
                    <popover [offerLabel]="'game_bundle' | translate" role="button" tabindex="0"
                        *ngIf="featuredProduct.shipInfo" [screenwidth]="screenWidth" [type]="'Availability'"
                        [availability]="featuredProduct.shipInfo"></popover>
                </div>
                <div>
                    <a kuiText class="more-info" href="javascript:void(0);">More Info</a>
                </div>
            </div>
            <div #drSpinnerCont class="buy-col-xl 49" [class.dr-spinner-bg]="featuredProduct.digitialRiverID">

                <div *ngIf="!featuredProduct.digitialRiverID">
                    <div *ngIf="featuredProduct.category != 'MODELS' && featuredProduct.category!='GAMING_SOFTWARE'" class="featured-product-price-stock">
                    <div class="price h--smallest clearfix 52" *ngIf="locale === 'ru-ru' || locale ==='pl-pl' || locale =='vi-vn'">
                        <span kuiText [innerHtml]="getFormatedPrice(featuredProduct.productPrice,locale)"></span>
                    </div>
                    <div class="price h--smallest clearfix 53" *ngIf="locale !== 'ru-ru' && locale !=='pl-pl' && locale !=='vi-vn'">
                        <span kuiText [innerHtml]="getPriceIntegerDot(featuredProduct.productPrice)"></span>
                        <span kuiText class="decimal"
                            [innerHtml]="getPriceDecimal(featuredProduct.productPrice)"></span>
                    </div>
                </div>
                    <div class="buy fpc-56" [class.fe-pids]="featuredProduct.manufacturer==='NVIDIA'">
                        <div [attr.class]="getEANCode(featuredProduct.productUPCOriginal)"
                            [attr.id]="featuredProduct.productID" [attr.status]="featuredProduct.prdStatus" [attr.isFounderEdition]="featuredProduct.isFounderEdition" style="display:none">{{featuredProduct.retailers |
                            json}}</div>
                        <div *ngIf="featuredProduct.prdStatus !== 'gf_notify_me'">
                            <div *ngIf="featuredProduct.prdStatus !== 'upcoming'">
                                <div *ngIf="featuredProduct.prdStatus !== 'check_availability'
                           && featuredProduct.prdStatus !== 'out_of_stock'">
                        <a kuiText [ngClass]="{'model-buy-link': featuredProduct.category === 'MODELS' || featuredProduct.category==='GAMING_SOFTWARE', 'buy-link': (featuredProduct.category !== 'MODELS' && featuredProduct.category !== 'GAMING_SOFTWARE'), 'extra_style': true, 'cta-link-large': true, '61': true}"
                                        [attr.data-pid-code]="featuredProduct.productID"
                                        [attr.data-mpn-code]="getEANCode(featuredProduct.productUPCOriginal)"
                                        data-color="#76b900" data-secondary-color="#fff"
                                        target="_blank"
                                        (click)="productName(featuredProduct); detectFeaturedBuyClick();"
                                        [attr.href]="featuredProduct.category === 'MODELS' || featuredProduct.category==='GAMING_SOFTWARE' ? featuredProduct.retailers[0].purchaseLink : undefined">
                                        {{featuredProduct.prdStatus | translate }}</a>
                                </div>
                            </div>
                        </div>


                        <a kuiText *ngIf="featuredProduct.prdStatus === 'check_availability'"
                            class="extra_style buy-link cta-link-large 67 2"
                            [attr.data-pid-code]="featuredProduct.productID"
                            [attr.data-mpn-code]="getEANCode(featuredProduct.productUPCOriginal)" data-color="#76b900"
                            data-secondary-color="#fff"
                            (click)="productName(featuredProduct); detectFeaturedBuyClick();">{{'check_availability' |
                            translate}}</a>


                        <!-- <span *ngIf="featuredProduct.prdStatus !== 'gf_notify_me'" class="extra_style buy-link cta-link-large 70" style="visibility:hidden" [attr.data-pid-code]="featuredProduct.productID"   [attr.data-mpn-code]="getEANCode(featuredProduct.productUPCOriginal)" data-color="#76b900" data-secondary-color="#fff" (click)="productName(featuredProduct)" >{{featuredProduct.prdStatus| translate }}</span> -->
                        <!-- <input *ngIf="locale !== 'en-us'" type="button" class="buy-link cta-link-large" value="{{featuredProduct.prdStatus| translate }}" (click)="showOverlay(productDetail)" /> -->

                        <div *ngIf="featuredProduct.prdStatus === 'gf_notify_me'"
                            class="buy cta-preorder mobile-top lb_iframe"
                            [attr.data-href]="getFormPath(locale, featuredProduct.productTitle)" href="#"
                            target="overlay"
                            onclick="NVIDIAGDC.button.click(this, $(this).data('href'),true, function() { ;return NVIDIAGDC.button.callbacks(this); }); return false;">
                            <a kuiText
                                class="featured-buy-link link-btn brand-green cta-button show-nv-notify-me notify-me-btn cta-link-large"
                                data-nvnotify-form-path="/content/forms/af/nvidia-forms/products-notify-me/consumer-notify-me"
                                href="javascript:void(0);">{{'notify_me' | translate}}</a>
                        </div>

                        <a kuiText
                            *ngIf="featuredProduct.prdStatus === 'upcoming' || featuredProduct.prdStatus === 'out_of_stock'"
                            class="featured-buy-link link-btn brand-green  cta-button stock-grey-out cta-link-large"
                            href="javascript:void(0);">
                            {{featuredProduct.prdStatus | translate }}
                        </a>

                        <!-- input type="button" class="buy-link cta-link-large" value="{{featuredProduct.prdStatus| translate }}" (click)="showOverlay(featuredProduct)" / -->
                    </div>
                </div>


                <div #drId *ngIf="featuredProduct.digitialRiverID" class="featured product-row__item js-product-item 86"
                    data-analytics="btn-dr-id"
                    [attr.data-digital-river-id]="changeDRId(featuredProduct.digitialRiverID)">
                    <div #priceDiv class="price js-product-price clearfix h--smallest "></div>
                    <div class="price  outside-page h--smallest">
                        <span [innerHtml]="extractDRPriceIntegerDot(priceDiv, drSpinnerCont)"></span><span
                            class="decimal" [innerHtml]="extractDRPriceDecimal(priceDiv)"></span>
                    </div>


                    <div class="buy cta-preorder mobile-top" (click)="transmitToCart()">
                        <!-- Logic for NOTIFY ME -->

                        <!-- DR NOTIFY ME -->

                        <a kuiText *ngIf="featuredProduct.prdStatus === 'dr_notify_me'"
                            class="featured-buy-link link-btn brand-green  cta-button show-dr-notify-me cta-link-large"
                            href="javascript:void(0);">
                            {{ 'add_to_cart' | translate }}
                        </a>

                        <!-- END OF DR NOTIFY ME -->
                        <!-- NV NOTIFY ME -->
                        <a kuiText *ngIf="featuredProduct.prdStatus === 'nv_notify_me'"
                            class="featured-buy-link link-btn brand-green  cta-button show-nv-notify-me cta-link-large"
                            data-nvnotify-form-path="/content/forms/af/nvidia-forms/products-notify-me/consumer-notify-me"
                            href="javascript:void(0);">
                            {{ 'add_to_cart' | translate }}
                        </a>
                        <!-- END OF NV NOTIFY ME -->
                        <a kuiText *ngIf="featuredProduct.prdStatus === 'buy_now'"
                            class="featured-buy-link link-btn brand-green  cta-button cta-link-large"
                            href="javascript:void(0);">
                            {{ 'add_to_cart' | translate }}
                        </a>
                        <!-- OUT OF STOCK -->
                        <a kuiText *ngIf="featuredProduct.prdStatus === 'out_of_stock'"
                            class="featured-buy-link link-btn brand-green  cta-button show-out-of-stock cta-link-large"
                            href="javascript:void(0);">
                            {{ 'out_of_stock' | translate }}
                        </a>
                        <!-- END OF OUT OF STOCK -->
                        <!-- buy from partners -->

                        <!-- <div class="product__message limit-per-customer">{{'limit_5' | translate}}</div> -->
                        <div *ngIf="featuredProduct.retailers?.length > 0" class="buy fpc-124"
                            [class.fe-pids]="featuredProduct.manufacturer==='NVIDIA'">
                            <div [attr.class]="getEANCode(featuredProduct.productUPCOriginal)"
                                [attr.id]="featuredProduct.productID" [attr.status]="featuredProduct.prdStatus"
                                [attr.isFounderEdition]="featuredProduct.isFounderEdition" style="display:none">{{featuredProduct.retailers |
                                json}}</div>
                            <div>
                                <a kuiText class="extra_style buy-link cta-link-larges 127 buy-frm-partner"
                                    [attr.data-pid-code]="featuredProduct.productID"
                                    [attr.data-mpn-code]="getEANCode(featuredProduct.productUPCOriginal)"
                                    data-color="#76b900" data-secondary-color="#fff"
                                    (click)="productName(featuredProduct); detectFeaturedBuyClick();">
                                    {{'buy_from_partners' | translate}}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="ship-date"></div>
                </div>
                <div *ngIf="featuredProduct.digitialRiverID">
                    <dr-loading-indicator [show]="drLoading"></dr-loading-indicator>
                </div>
                <div *ngIf="featuredProduct.category!='GFN' && featuredProduct.category!='MODELS' && featuredProduct.category != 'GAMING_SOFTWARE'">
                    <!-- <a class="cta-link-regular 128" [attr.data-id]="('compare_'+featuredProduct.productUPC)"
                        [class.compare]="featuredProduct.addRemoveCompare === 'Compare'"
                        [class.remove]="featuredProduct.addRemoveCompare === 'Remove'"
                        (click)="compareProduct(featuredProduct.productUPC, featuredProduct.addRemoveCompare)">{{featuredProduct.addRemoveCompare
                        | lowercase | translate}}</a> -->

                        <button *ngIf="featuredProduct.category != 'GFN' && featuredProduct.category!='MODELS' && featuredProduct.category != 'GAMING_SOFTWARE'" kuiButton [size]="'standard'" [danger]="false" [type]="'tertiary'" class="cta-link-regular 310"
                        [attr.id]="('compare_'+featuredProduct.productUPC)"
                        [class.compare]="featuredProduct.addRemoveCompare === 'Compare'"
                        [class.remove]="featuredProduct.addRemoveCompare === 'Remove'"
                        (click)="compareProduct(featuredProduct.productUPC, featuredProduct.addRemoveCompare)">{{featuredProduct.addRemoveCompare | lowercase | translate}}</button>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="screenWidth === 'MD' || screenWidth === 'LG'" class="featured-container-lg">
        <div kuiText class="call-out">
            {{ 'featured' | translate }}
        </div>
        <div class="product-container clearfix">
            <div class="img-col-lg">
                <div class="image-lg">
                    <kui-image aspectRatio="1-1" class="featured-img-block">
                        <img #fimlg [title]="featuredProduct.category === 'MODELS' || featuredProduct.category === 'GAMING_SOFTWARE'? featuredProduct.productTitle : featuredProduct.displayName" src="{{featuredProduct.imageURL}}"
                            alt="Featured product" class="featured-img" (window:resize)="createThumbnail(fimlg)"
                            (load)="createThumbnail(fimlg)" (error)="showImgError(fimlg)" width="100%" height="100%">
                    </kui-image>
                </div>
                <a class="product-title" *ngIf="featuredProduct.isPDP;else title_content"
                    href='{{getPDPLink(featuredProduct)}}' target="_blank" rel="noopener">
                    <h2 kuiText class="product-name h--smallest" [innerHTML]="featuredProduct.productTitle"></h2>
                </a>
                <ng-template #title_content>
                    <h2 kuiText class="product-name h--smallest" [innerHTML]="featuredProduct.productTitle"></h2>
                </ng-template>
                <div class="specs-container">
                    <div *ngIf="featuredProduct.category==='GFN' || featuredProduct.category==='MODELS' || featuredProduct.category === 'GAMING_SOFTWARE'" [innerHTML]="featuredProduct.productInfo[0].value"></div>
                    <ul *ngIf="featuredProduct.category!='GFN' && featuredProduct.category!='MODELS' && featuredProduct.category != 'GAMING_SOFTWARE'">
                        <li *ngFor="let specs of featuredProduct.productInfo">
                            <div class="specs p-medium" kuiText>{{specs.name |
                                translate}}:&nbsp;{{valueTrimmer(specs.value)}} {{(((featuredProduct.category==='LAPTOP'
                                || featuredProduct.category==='STUDIO-LAPTOP') && specs.name
                                =='gpu')?'gpu_spec_sufix':'') | translate}}</div>
                        </li>
                    </ul>

                    <popover [offerLabel]="'game_bundle' | translate" role="button" tabindex="0"
                        *ngIf="featuredProduct.isOffer" [screenwidth]="screenWidth" [type]="'Game Bundle-152'"
                        [bundle]="featuredProduct.offerText"></popover>
                    <popover [offerLabel]="'game_bundle' | translate" role="button" tabindex="0"
                        *ngIf="featuredProduct.shipInfo" [screenwidth]="screenWidth" [type]="'Availability'"
                        [availability]="featuredProduct.shipInfo"></popover>
                </div>
                <div>
                    <a kuiText class="more-info" href="javascript:void(0);">More Info</a>
                </div>
            </div>
            <div #drSpinnerCont class="buy-col-lg 155" [class.dr-spinner-bg]="featuredProduct.digitialRiverID">
                <div *ngIf="!featuredProduct.digitialRiverID">
                    <div *ngIf="featuredProduct.category != 'MODELS' && featuredProduct.category != 'GAMING_SOFTWARE'" class="featured-product-price-stock">
                    <div class="price h--smallest clearfix 157" *ngIf="locale === 'ru-ru' || locale ==='pl-pl'">
                        <span kuiText [innerHtml]="getFormatedPrice(featuredProduct.productPrice,locale)"></span>
                    </div>
                    <div class="price h--smallest clearfix 158" *ngIf="locale !== 'ru-ru' && locale!=='pl-pl'">
                        <span kuiText [innerHtml]="getPriceIntegerDot(featuredProduct.productPrice)"></span><span
                            class="decimal" [innerHtml]="getPriceDecimal(featuredProduct.productPrice)"></span>
                    </div>
                    </div>
                    <div class="buy fpc-161" [class.fe-pids]="featuredProduct.manufacturer==='NVIDIA'">
                        <div [attr.class]="getEANCode(featuredProduct.productUPCOriginal)"
                            [attr.id]="featuredProduct.productID" [attr.status]="featuredProduct.prdStatus"
                            [attr.isFounderEdition]="featuredProduct.isFounderEdition" style="display:none">{{featuredProduct.retailers |
                            json}}</div>
                        <div *ngIf="featuredProduct.prdStatus !== 'gf_notify_me'">
                            <div
                                *ngIf="featuredProduct.prdStatus !== 'upcoming' && featuredProduct.prdStatus !== 'out_of_stock'">
                                <div *ngIf="featuredProduct.prdStatus !== 'check_availability'">
                                    <a kuiText [ngClass]="{'model-buy-link': featuredProduct.category === 'MODELS' || featuredProduct.category === 'GAMING_SOFTWARE', 'buy-link': (featuredProduct.category !== 'MODELS' && featuredProduct.category !== 'GAMING_SOFTWARE'), 'extra_style': true, 'cta-link-large': true, '166': true}"
                                        [attr.data-pid-code]="featuredProduct.productID"
                                        [attr.data-mpn-code]="getEANCode(featuredProduct.productUPCOriginal)"
                                        data-color="#76b900" data-secondary-color="#fff"
                                        target="_blank"
                                        (click)="productName(featuredProduct); detectFeaturedBuyClick();"
                                        [attr.href]="featuredProduct.category === 'MODELS' || featuredProduct.category === 'GAMING_SOFTWARE' ? featuredProduct.retailers[0].purchaseLink : undefined">
                                        {{featuredProduct.prdStatus|
                                        translate }}</a>
                                    <!-- <input *ngIf="locale !== 'en-us'" type="button" class="buy-link cta-link-large" value="{{featuredProduct.prdStatus| translate }}" (click)="showOverlay(productDetail)" /> -->
                                </div>
                            </div>
                        </div>


                        <a kuiText *ngIf="featuredProduct.prdStatus === 'check_availability'"
                            class="extra_style buy-link cta-link-large 167"
                            [attr.data-pid-code]="featuredProduct.productID"
                            [attr.data-mpn-code]="getEANCode(featuredProduct.productUPCOriginal)" data-color="#76b900"
                            data-secondary-color="#fff"
                            (click)="productName(featuredProduct); detectFeaturedBuyClick();">{{'check_availability' |
                            translate}}</a>



                        <!-- <span *ngIf="featuredProduct.prdStatus !== 'gf_notify_me'" class="extra_style buy-link cta-link-large 177" style="visibility:hidden" [attr.data-pid-code]="featuredProduct.productID"  [attr.data-mpn-code]="getEANCode(featuredProduct.productUPCOriginal)" data-color="#76b900" data-secondary-color="#fff" (click)="productName(featuredProduct)" >{{featuredProduct.prdStatus| translate }}</span> -->

                        <!-- <input *ngIf="locale !== 'en-us'" type="button" class="buy-link cta-link-large" value="{{featuredProduct.prdStatus| translate }}" (click)="showOverlay(productDetail)" /> -->

                        <div *ngIf="featuredProduct.prdStatus === 'gf_notify_me'"
                            class="buy cta-preorder mobile-top lb_iframe"
                            [attr.data-href]="getFormPath(locale, featuredProduct.productTitle)" href="#"
                            target="overlay"
                            onclick="NVIDIAGDC.button.click(this, $(this).data('href'),true, function() { ;return NVIDIAGDC.button.callbacks(this); }); return false;">
                            <a kuiText class="featured-buy-link link-btn brand-green cta-button show-nv-notify-me notify-me-btn cta-link-large"
                                data-nvnotify-form-path="/content/forms/af/nvidia-forms/products-notify-me/consumer-notify-me"
                                href="javascript:void(0);">{{'notify_me' | translate}}</a>
                        </div>

                        <a kuiText *ngIf="featuredProduct.prdStatus === 'upcoming' || featuredProduct.prdStatus === 'out_of_stock'"
                            class="featured-buy-link link-btn brand-green  cta-button stock-grey-out cta-link-large"
                            href="javascript:void(0);">
                            {{'out_of_stock' | translate }}
                        </a>

                    </div>
                </div>

                <div #drId *ngIf="featuredProduct.digitialRiverID"
                    class="featured product-row__item js-product-item 192" data-analytics="btn-dr-id"
                    [attr.data-digital-river-id]="changeDRId(featuredProduct.digitialRiverID)">
                    <div #priceDivFeaturedMD class="price h--smallest js-product-price clearfix 194"></div>
                    <div class="price h--smallest clearfix outside-page 194">
                        <span kuiText [innerHtml]="extractDRPriceIntegerDot(priceDivFeaturedMD, drSpinnerCont)"></span><span
                            class="decimal" [innerHtml]="extractDRPriceDecimal(priceDivFeaturedMD)"></span>
                    </div>
                    <div class="buy cta-preorder mobile-top" (click)="transmitToCart()">
                        <!-- Logic for NOTIFY ME -->

                        <!-- DR NOTIFY ME -->

                        <a kuiText *ngIf="featuredProduct.prdStatus === 'dr_notify_me'"
                            class="featured-buy-link link-btn brand-green  cta-button show-dr-notify-me cta-link-large"
                            href="javascript:void(0);">
                            {{ 'add_to_cart' | translate }}
                        </a>

                        <!-- END OF DR NOTIFY ME -->
                        <!-- NV NOTIFY ME -->
                        <a kuiText *ngIf="featuredProduct.prdStatus === 'nv_notify_me'"
                            class="featured-buy-link link-btn brand-green  cta-button show-nv-notify-me cta-link-large"
                            data-nvnotify-form-path="/content/forms/af/nvidia-forms/products-notify-me/consumer-notify-me"
                            href="javascript:void(0);">
                            {{ 'add_to_cart' | translate }}
                        </a>
                        <!-- END OF NV NOTIFY ME -->
                        <a kuiText *ngIf="featuredProduct.prdStatus === 'buy_now'"
                            class="featured-buy-link link-btn brand-green  cta-button cta-link-large"
                            href="javascript:void(0);">
                            {{ 'add_to_cart' | translate }}
                        </a>
                        <!-- OUT OF STOCK -->
                        <a kuiText *ngIf="featuredProduct.prdStatus === 'out_of_stock'"
                            class="featured-buy-link link-btn brand-green  cta-button show-out-of-stock cta-link-large"
                            href="javascript:void(0);">
                            {{ 'out_of_stock' | translate }}
                        </a>
                        <!-- END OF OUT OF STOCK -->
                    </div>
                    <!-- <div class="product__message limit-per-customer">{{'limit_5' | translate}}</div> -->
                    <div *ngIf="featuredProduct.retailers?.length > 0" class="buy fpc-124"
                        [class.fe-pids]="featuredProduct.manufacturer==='NVIDIA'">
                        <div [attr.class]="getEANCode(featuredProduct.productUPCOriginal)"
                            [attr.id]="featuredProduct.productID" [attr.status]="featuredProduct.prdStatus"
                            [attr.isFounderEdition]="featuredProduct.isFounderEdition" style="display:none">{{featuredProduct.retailers |
                            json}}</div>
                        <div>
                            <a kuiText class="extra_style buy-link cta-link-larges 243 buy-frm-partner"
                                [attr.data-pid-code]="featuredProduct.productID"
                                [attr.data-mpn-code]="getEANCode(featuredProduct.productUPCOriginal)"
                                data-color="#76b900" data-secondary-color="#fff"
                                (click)="productName(featuredProduct); detectFeaturedBuyClick();">
                                {{'buy_from_partners' | translate}}
                            </a>
                        </div>
                    </div>
                    <div class="ship-date"></div>
                </div>
                <div *ngIf="featuredProduct.digitialRiverID">
                    <dr-loading-indicator [show]="drLoading"></dr-loading-indicator>
                </div>
                <div>
                    <div *ngIf="featuredProduct.category!='GFN'">
                        <!-- <a kuiText class="cta-link-regular 232" [attr.data-id]="('compare_'+featuredProduct.productUPC)"
                            [class.compare]="featuredProduct.addRemoveCompare === 'Compare'"
                            [class.remove]="featuredProduct.addRemoveCompare === 'Remove'"
                            (click)="compareProduct(featuredProduct.productUPC, featuredProduct.addRemoveCompare)">{{featuredProduct.addRemoveCompare
                            | lowercase | translate}}</a> -->

                            <button *ngIf="featuredProduct.category != 'GFN' && featuredProduct.category!='MODELS' && featuredProduct.category != 'GAMING_SOFTWARE'" kuiButton [size]="'standard'" [danger]="false" [type]="'tertiary'" class="cta-link-regular 310"
                            [attr.id]="('compare_'+featuredProduct.productUPC)"
                            [class.compare]="featuredProduct.addRemoveCompare === 'Compare'"
                            [class.remove]="featuredProduct.addRemoveCompare === 'Remove'"
                            (click)="compareProduct(featuredProduct.productUPC, featuredProduct.addRemoveCompare)">{{featuredProduct.addRemoveCompare | lowercase | translate}}</button>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="screenWidth === 'SM'" class="featured-container-sm">
        <div kuiText class="call-out">
            {{ 'featured' | translate }}
        </div>
        <div class="product-container clearfix">
            <div class="image-sm">
                <kui-image aspectRatio="1-1">
                <img #fimsm [title]="featuredProduct.category === 'MODELS' || featuredProduct.category === 'GAMING_SOFTWARE'? featuredProduct.productTitle : featuredProduct.displayName" src="{{featuredProduct.imageURL}}"
                    alt="Featured product" class="featured-img-sm" (window:resize)="createThumbnail(fimsm)"
                    (load)="createThumbnail(fimsm)" (error)="showImgError(fimsm)" width="100%" height="100%">
                </kui-image>
            </div>
            <a class="product-title" *ngIf="featuredProduct.isPDP;else title_content"
                href='{{getPDPLink(featuredProduct)}}' target="_blank" rel="noopener">
                <h2 kuiText class="product-name h--smallest" [innerHTML]="featuredProduct.productTitle"></h2>
            </a>
            <ng-template #title_content>
                <h2 kuiText class="product-name h--smallest" [innerHTML]="featuredProduct.productTitle"></h2>
            </ng-template>
            <div class="specs-container">
                <div *ngIf="featuredProduct.category==='GFN' || featuredProduct.category==='MODELS' || featuredProduct.category === 'GAMING_SOFTWARE'" [innerHTML]="featuredProduct.productInfo[0].value"></div>
                    <ul *ngIf="featuredProduct.category!='GFN' && featuredProduct.category!='MODELS' && featuredProduct.category != 'GAMING_SOFTWARE'">
                        <li *ngFor="let specs of featuredProduct.productInfo">
                            <div class="specs p-medium" kuiText>{{specs.name |
                                translate}}:&nbsp;{{valueTrimmer(specs.value)}} {{(((featuredProduct.category==='LAPTOP'
                                || featuredProduct.category==='STUDIO-LAPTOP') && specs.name
                                =='gpu')?'gpu_spec_sufix':'') | translate}}</div>
                        </li>
                    </ul>
            </div>
            <div #drSpinnerCont *ngIf="featuredProduct.digitialRiverID" class="dr-spinner-bg-grid 255">
                <div #drId *ngIf="featuredProduct.digitialRiverID"
                    class="featured product-row__item js-product-item 257" data-analytics="btn-dr-id"
                    [attr.data-digital-river-id]="changeDRId(featuredProduct.digitialRiverID)">
                    <div #priceDivFeaturedSM class="price-sm price js-product-price h--smallest clearfix 260"></div>
                    <div class="price h--smallest outside-page">
                        <span kuiText [innerHtml]="extractDRPriceIntegerDot(priceDivFeaturedSM, drSpinnerCont)"></span><span
                            class="decimal" [innerHtml]="extractDRPriceDecimal(priceDivFeaturedSM)"></span>
                    </div>
                    <div class="buy cta-preorder mobile-top" (click)="transmitToCart()">
                        <!-- Logic for NOTIFY ME -->

                        <!-- DR NOTIFY ME -->

                        <a kuiText *ngIf="featuredProduct.prdStatus === 'dr_notify_me'"
                            class="featured-buy-link link-btn brand-green  cta-button show-dr-notify-me cta-link-large"
                            href="javascript:void(0);">
                            {{ 'out_of_stock' | translate }}
                        </a>

                        <!-- END OF DR NOTIFY ME -->
                        <!-- NV NOTIFY ME -->
                        <a kuiText *ngIf="featuredProduct.prdStatus === 'nv_notify_me'"
                            class="featured-buy-link link-btn brand-green  cta-button show-nv-notify-me cta-link-large"
                            data-nvnotify-form-path="/content/forms/af/nvidia-forms/products-notify-me/consumer-notify-me"
                            href="javascript:void(0);">
                            {{ 'out_of_stock' | translate }}
                        </a>
                        <!-- END OF NV NOTIFY ME -->
                        <a kuiText *ngIf="featuredProduct.prdStatus === 'buy_now'"
                            class="featured-buy-link link-btn brand-green  cta-button cta-link-large"
                            href="javascript:void(0);">
                            {{ 'add_to_cart' | translate }}
                        </a>
                        <!-- OUT OF STOCK -->
                        <a kuiText *ngIf="featuredProduct.prdStatus === 'out_of_stock'"
                            class="featured-buy-link link-btn brand-green  cta-button show-out-of-stock cta-link-large"
                            href="javascript:void(0);">
                            {{ 'out_of_stock' | translate }}
                        </a>
                        <!-- END OF OUT OF STOCK -->

                    </div>
                    <!-- <div class="product__message limit-per-customer">{{'limit_5' | translate}}</div> -->
                    <div *ngIf="featuredProduct.retailers?.length > 0" class="buy fpc-124"
                        [class.fe-pids]="featuredProduct.manufacturer==='NVIDIA'">
                        <div [attr.class]="getEANCode(featuredProduct.productUPCOriginal)"
                            [attr.id]="featuredProduct.productID" style="display:none">{{featuredProduct.retailers |
                            json}}</div>
                        <div>
                            <a kuiText class="extra_style buy-link cta-link-larges 321 buy-frm-partner"
                                [attr.data-pid-code]="featuredProduct.productID"
                                [attr.data-mpn-code]="getEANCode(featuredProduct.productUPCOriginal)"
                                data-color="#76b900" data-secondary-color="#fff"
                                (click)="productName(featuredProduct); detectFeaturedBuyClick();">
                                {{'buy_from_partners' | translate}}
                            </a>
                        </div>
                    </div>
                    <div class="ship-date"></div>
                </div>
            </div>
            <div *ngIf="featuredProduct.digitialRiverID">
                <dr-loading-indicator [show]="drLoading"></dr-loading-indicator>
            </div>
            <div *ngIf="!featuredProduct.digitialRiverID"> <!--  || featuredProduct.digitialRiverID" -->
                <div *ngIf="featuredProduct.category != 'MODELS' && featuredProduct.category != 'GAMING_SOFTWARE'" class="featured-product-price-stock">
                <div class="price h--smallest clearfix 294" *ngIf="locale === 'ru-ru' || locale==='pl-pl'"
                    [innerHtml]="getFormatedPrice(featuredProduct.productPrice,locale)"></div>
                <div class="price h--smallest clearfix 295" *ngIf="locale !== 'ru-ru' && locale !=='pl-pl'">
                    <span kuiText [innerHtml]="getPriceIntegerDot(featuredProduct.productPrice)"></span>
                    <span class="decimal" [innerHtml]="getPriceDecimal(featuredProduct.productPrice)"></span>
                </div>
            </div>
                <div class="buy fpc-298" [class.fe-pids]="featuredProduct.manufacturer==='NVIDIA'">
                    <div [attr.class]="getEANCode(featuredProduct.productUPCOriginal)"
                        [attr.id]="featuredProduct.productID" [attr.status]="featuredProduct.prdStatus"
                        [attr.isFounderEdition]="featuredProduct.isFounderEdition" style="display:none">{{featuredProduct.retailers | json}}
                    </div>
                    <div *ngIf="featuredProduct.prdStatus !== 'gf_notify_me'">
                        <div *ngIf="featuredProduct.prdStatus !== 'upcoming'">
                            <div
                                *ngIf="featuredProduct.prdStatus !== 'check_availability' && featuredProduct.prdStatus !== 'out_of_stock'">
                                <a kuiText [ngClass]="{'model-buy-link': featuredProduct.category === 'MODELS' || featuredProduct.category === 'GAMING_SOFTWARE', 'buy-link': (featuredProduct.category !== 'MODELS' && featuredProduct.category !== 'GAMING_SOFTWARE'), 'extra_style': true, 'cta-link-large': true, '303': true}"
                                    [attr.data-pid-code]="featuredProduct.productID"
                                    [attr.data-mpn-code]="getEANCode(featuredProduct.productUPCOriginal)"
                                    data-color="#76b900" data-secondary-color="#fff"
                                    target="_blank"
                                    (click)="productName(featuredProduct); detectFeaturedBuyClick();"
                                    [attr.href]="featuredProduct.category === 'MODELS' || featuredProduct.category === 'GAMING_SOFTWARE' ? featuredProduct.retailers[0].purchaseLink : undefined">
                                    {{featuredProduct.prdStatus|
                                    translate }}</a>
                                <!-- <input *ngIf="locale !== 'en-us'" type="button" class="buy-link cta-link-large" value="{{featuredProduct.prdStatus| translate }}" (click)="showOverlay(productDetail)" /> -->
                            </div>
                        </div>
                    </div>
                    <a kuiText *ngIf="featuredProduct.prdStatus === 'check_availability'"
                        class="extra_style buy-link cta-link-large 309"
                        [attr.data-pid-code]="featuredProduct.productID"
                        [attr.data-mpn-code]="getEANCode(featuredProduct.productUPCOriginal)" data-color="#76b900"
                        data-secondary-color="#fff"
                        (click)="productName(featuredProduct);detectFeaturedBuyClick();">{{'check_availability' |
                        translate}}</a>
                    <div *ngIf="featuredProduct.prdStatus === 'gf_notify_me'"
                        class="buy cta-preorder mobile-top lb_iframe"
                        [attr.data-href]="getFormPath(locale, featuredProduct.productTitle)" href="#" target="overlay"
                        onclick="NVIDIAGDC.button.click(this, $(this).data('href'),true, function() { ;return NVIDIAGDC.button.callbacks(this); }); return false;">
                        <a kuiText class="featured-buy-link link-btn brand-green cta-button show-nv-notify-me notify-me-btn cta-link-large"
                            data-nvnotify-form-path="/content/forms/af/nvidia-forms/products-notify-me/consumer-notify-me"
                            href="javascript:void(0);">{{'notify_me' | translate}}</a>
                    </div>
                    <a kuiText *ngIf="featuredProduct.prdStatus === 'upcoming' || featuredProduct.prdStatus === 'out_of_stock'"
                        class="featured-buy-link link-btn brand-green  cta-button stock-grey-out cta-link-large"
                        href="javascript:void(0);">
                        {{ featuredProduct.prdStatus | translate }}
                    </a>
                </div>
            </div>
            <div class="compare-remove-container"
                style="padding-bottom: 30px; display: flex; justify-content: space-between;">
                <!-- <a *ngIf="featuredProduct.category!='GFN'" kuiText class="cta-link-regular 338" [attr.data-id]="('compare_'+featuredProduct.productUPC)"
                    [class.compare]="featuredProduct.addRemoveCompare === 'Compare'"
                    [class.remove]="featuredProduct.addRemoveCompare === 'Remove'"
                    (click)="compareProduct(featuredProduct.productUPC, featuredProduct.addRemoveCompare)">{{featuredProduct.addRemoveCompare
                    | lowercase | translate}}</a> -->

                    <button *ngIf="featuredProduct.category != 'GFN' && featuredProduct.category!='MODELS' && featuredProduct.category != 'GAMING_SOFTWARE'" kuiButton [size]="'standard'" [danger]="false" [type]="'tertiary'" class="cta-link-regular 310"
                    [attr.id]="('compare_'+featuredProduct.productUPC)"
                    [class.compare]="featuredProduct.addRemoveCompare === 'Compare'"
                    [class.remove]="featuredProduct.addRemoveCompare === 'Remove'"
                    (click)="compareProduct(featuredProduct.productUPC, featuredProduct.addRemoveCompare)">{{featuredProduct.addRemoveCompare | lowercase | translate}}</button>

                <a kuiText class="float-right more-info" href="javascript:void(0);">More Info</a>
                <popover [offerLabel]="'game_bundle' | translate" role="button" tabindex="0"
                    *ngIf="featuredProduct.isOffer" [screenwidth]="screenWidth" [type]="'Game Bundle'"
                    [bundle]="featuredProduct.offerText"></popover>
                <popover [offerLabel]="'game_bundle' | translate" role="button" tabindex="0"
                    *ngIf="featuredProduct.shipInfo" [screenwidth]="screenWidth" [type]="'Availability'"
                    [availability]="featuredProduct.shipInfo"></popover>
            </div>
        </div>
    </div>
</div>

import {merge as observableMerge, fromEvent as observableFromEvent,  Observable } from 'rxjs';

import {map, debounceTime} from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable()
export class ViewportDetector {
    
  clientWidth;
  $viewport: Observable<string>;

  constructor() {

    const loadEvents$ = observableFromEvent(window, 'load');
    const resizeEvents$ = observableFromEvent(window, 'resize');
    const allEvents$ = observableMerge(
        loadEvents$,
        resizeEvents$
    ).pipe(debounceTime(100));

    this.$viewport = allEvents$.pipe(map(() => {
      this.clientWidth = document.defaultView.innerWidth;
      if (this.clientWidth > 1280) {
        return 'XL';
      }
      else {
        if (this.clientWidth <= 1280 && this.clientWidth > 1024 ) {
          return 'LG';
        }
        else {
          if (this.clientWidth > 767 && this.clientWidth <= 1024 ) {
            return 'MD';
          }
          else {
            return 'SM';
          }
        }
      }
    }));
  }

}
import { Injectable } from "@angular/core";
@Injectable()
export class ModalService {
  private modals: any[] = [];

  add(modal: any) {
    // add modal to array of active modals
    this.modals.push(modal);
  }

  remove(id: string) {
    // remove modal from array of active modals
    this.modals = this.modals.filter(x => x.id !== id);
  }

  open(id: string) {
    // open modal specified by id
    let modal: any = this.modals.filter(x => x.id === id)[0];
    modal.open();
  }

  close(id: string) {
    // close modal specified by id
    let modal: any = this.modals.filter(x => x.id === id)[0];
    modal.close();
  }

  getRegionsAndCountries() {
    return [
      {
        region:"THE AMERICAS",
        countries:[
          {
            country1:"UNITED STATES",
            storeUrl: "https://store.nvidia.com/en-us/consumer"
          },
          {
            country1:"CANADA",
            storeUrl: "https://store.nvidia.com/en-ca/consumer"
          },
          {
            country1:"CANADA (FRENCH)",
            storeUrl: "https://store.nvidia.com/fr-ca/consumer"
          }
        ]
      },
      {
        region:"EUROPE",
        countries:[
          {
            country1:"BELGIQUE",
            country2:"(BELGIUM)",
            storeUrl: "https://www.nvidia.com/fr-be/geforce/buy/"
          },
          {
            country1:"ČESKÁ REPUBLIKA",
            country2:"(CZECH REPUBLIC)",
            storeUrl: "https://www.nvidia.com/cs-cz/geforce/buy/"
          },
          {
            country1:"DANMARK",
            country2:"(DENMARK)",
            storeUrl: "https://www.nvidia.com/da-dk/geforce/buy/"
          },
          {
            country1:"DEUTSCHLAND",
            country2:"(GERMANY)",
            storeUrl: "https://store.nvidia.com/de-de/consumer/"
          },
          {
            country1:"ESPAÑA",
            country2:"(SPAIN)",
            storeUrl: "https://store.nvidia.com/es-es/consumer/"
          },
          {
            country1:"FRANCE",
            storeUrl: "https://store.nvidia.com/fr-fr/consumer/"
          },
          {
            country1:"ITALIA",
            country2:"(ITALY)",
            storeUrl: "https://store.nvidia.com/it-it/consumer/"
          },
          {
            country1:"NEDERLANDS",
            country2:"(NETHERLANDS)",
            storeUrl: "https://store.nvidia.com/nl-nl/consumer/"
          },
          {
            country1:"NORGE",
            country2:"(NORWAY)",
            storeUrl: "https://www.nvidia.com/nb-no/geforce/buy/"
          },
          {
            country1:"ÖSTERREICH",
            country2:"(AUSTRIA)",
            storeUrl: "https://store.nvidia.com/de-de/consumer/"
          },
          {
            country1:"POLSKA",
            country2:"(POLAND)",
            storeUrl: "https://store.nvidia.com/pl-pl/consumer/"
          },
          {
            country1:"РОССИЯ",
            country2:"(RUSSIA)",
            storeUrl: "https://store.nvidia.com/ru-ru/consumer/"
          },
          {
            country1:"ROMANIA",
            storeUrl: "https://www.nvidia.com/ro-ro/geforce/buy/"
          },
          {
            country1:"SCHWEIZ",
            country2:"(SWITZERLAND)",
            storeUrl: "https://www.nvidia.de/geforce/buy/"
          },
          {
            country1:"SUOMI",
            country2:"(FINLAND)",
            storeUrl: "https://www.nvidia.com/fi-fi/geforce/buy/"
          },
          {
            country1:"SVERIGE",
            country2:"(SWEDEN)",
            storeUrl: "https://www.nvidia.com/sv-se/geforce/buy/"
          },
          {
            country1:"TÜRKIYE",
            country2:"(TURKEY)",
            storeUrl: "https://www.nvidia.com/tr-tr/geforce/buy/"
          },
          {
            country1:"UNITED KINGDOM",
            storeUrl: "https://store.nvidia.com/en-gb/consumer/"
          },
        ]
      },
      {
        region:"ASIA",
        countries:[
          {
            country1:"AUSTRALIA",
            storeUrl: "https://store.nvidia.com/en-au/consumer/"
          },
          {
            country1:"中国",
            country2:"(CHINA)",
            storeUrl: "https://www.nvidia.com/zh-cn/geforce/20-series/"
          },
          {
            country1:"INDIA",
            storeUrl: "https://www.nvidia.com/en-in/geforce/buy/"
          },
          {
            country1:"Malaysia",
            storeUrl: "https://www.nvidia.com/en-my/geforce/20-series/"
          },
          {
            country1:"日本",
            country2:"(JAPAN)",
            storeUrl: "https://www.nvidia.com/ja-jp/geforce/20-series/"
          },
          {
            country1:"한국",
            country2:"(KOREA)",
            storeUrl: "https://www.nvidia.com/ko-kr/geforce/20-series/"
          },
          {
            country1:"SINGAPORE",
            storeUrl: "https://www.nvidia.com/en-sg/geforce/20-series/"
          },
          {
            country1:"台灣",
            country2:"(TAIWAN)",
            storeUrl: "https://www.nvidia.com/zh-tw/geforce/20-series/"
          },
          {
            country1:"Thailand",
            storeUrl: "https://www.nvidia.com/th-th/geforce/20-series/"
          },
          {
            country1:"Indonesia",
            storeUrl: "https://www.nvidia.com/id-id/geforce/20-series/"
          },
          {
            country1:"Vietnam",
            storeUrl: "https://www.nvidia.com/vi-vn/geforce/20-series/"
          }
        ]
      }
    ]; // end array of regions
  } // end getRegionsAndCountries()

}

export const TopNavStaticData =
  {
    "NVIDIAglobalHeader": [
      {
        "logoImagePath": "https://wwwdev.nvidia.com/content/dam/catalogs/Testing/405x215.jpg",
        "logoLinkURL": "http://www.nvidia.com",
        "loginURL": "https://wwwdev.nvidia.com/en-us/account/edit-profile/",
        "showCart": true,
        "showSearch": true,
        "showProfileIcon": true,
        "nvPlatforms": [
          {
            "navPath": "https://preview.nvidia.com/en-zz/deep-learning-ai/",
            "navLabel": "AI and Deep Learning",
            "imagePath": "https://wwwdev.nvidia.com/content/dam/en-zz/Solutions/Data-Center/Home/data-center-main-ai-accelerated-analytics-background-320-m.jpg",
            "hoveritems": [
              {
                "navPath": "",
                "navLabel": "lvl-2-items",
                "hoveritemslvl2": [
                  {
                    "navPath": "",
                    "navLabel": "level-3 items"
                  }
                ]
              }
            ]
          },
          {
            "navPath": "https://preview.nvidia.com/en-zz/data-center/",
            "navLabel": "КОНФЕРЕНЦИЯ ПО GPU-ТЕХНОЛОГИЯМ",
            "imagePath": "https://wwwdev.nvidia.com/content/dam/en-zz/Solutions/design-visualization/mdl/rendering-mdl-banner-767-m.jpg"
          },
          {
            "navPath": "https://developer.nvidia.com/cuda-zone",
            "navLabel": "NVIDIA Accelerated Computing",
            "imagePath": "https://wwwdev.nvidia.com/content/dam/en-zz/Solutions/Data-Center/Home/data-center-news-insidebigdata-297-dtm@2x.jpg"
          },
          {
            "navPath": "https://wwwdev.nvidia.com/en-us/deep-learning_noMetatags/deep-learning-ai-homepage/",
            "navLabel": " Profissional Design Visualization",
            "imagePath": "https://wwwdev.nvidia.com/content/dam/en-zz/Solutions/Data-Center/Home/data-center-news-dgx-1-407-u.jpg"
          },
          {
            "navPath": "www.facebook.com",
            "navLabel": "Facebook",
            "imagePath": "https://wwwdev.nvidia.com/content/dam/en-zz/Solutions/design-visualization/mdl/rendering-mdl-material-cloth-407-u.jpg"
          },
          {
            "navPath": "www.nvidia.com",
            "navLabel": " Self-Driving Cars for deep-learning-ai",
            "imagePath": "https://wwwdev.nvidia.com/content/dam/en-zz/Solutions/Data-Center/Home/data-center-main-ai-accelerated-analytics-background-320-m.jpg"
          },
          {
            "navPath": "http://www.gputechconf.com/",
            "navLabel": "GPU Tech Conference",
            "imagePath": "https://wwwdev.nvidia.com/content/dam/en-zz/Solutions/design-visualization/mdl/complex-cloth-iray-297-m.jpg"
          },
          {
            "navPath": "http://www.nvidia.com/object/drive-automotive-technology.html",
            "navLabel": "gpu accelaratrions in nvidia",
            "imagePath": "https://wwwdev.nvidia.com/content/dam/en-zz/Solutions/design-visualization/mdl/rendering-mdl-sample-glass-297-tm.jpg"
          },
          {
            "navPath": "www.nvidia.com",
            "navLabel": "design and proviz",
            "imagePath": ""
          }
        ],
        "globalnavItemsGrp1": [
          {
            "navPath": "shield.nvidia.com/shield-Tv",
            "navLabel": "SHIELD TV"
          },
          {
            "navPath": "www.nvidia.com",
            "navLabel": "gpu tech conference"
          },
          {
            "navPath": "https://www.nvidia.com/en-us/shield/games/",
            "navLabel": "gemes"
          }
        ],
        "globalnavItemsGrp2": [
          {
            "navPath": "www.nvidia.com",
            "navLabel": "communities"
          },
          {
            "navPath": "www.nvidia.com/support",
            "navLabel": "support"
          },
          {
            "navPath": "www.nvidia.com",
            "navLabel": "nvidia shiled amazing"
          },
          {
            "navPath": "www.gputechconf.com",
            "navLabel": "gpu tech conference"
          }
        ],
        "globalnavSubItems": [
          {
            "navPath": "/apps/nvidiaGDC/components/functional/sectionWrapper",
            "navLabel": ".VIEW ALL PRODUCTS"
          },
          {
            "navPath": "/apps/nvidiaGDC/components/functional/sectionWrapper",
            "navLabel": "ABOUT NVIDIA"
          },
          {
            "navPath": "/apps/nvidiaGDC/components/functional/sectionWrapper",
            "navLabel": "NEWSROOM"
          },
          {
            "navPath": "/apps/nvidiaGDC/components/functional/sectionWrapper",
            "navLabel": "GEAR STORE"
          },
          {
            "navPath": "/apps/nvidiaGDC/components/functional/sectionWrapper",
            "navLabel": "COMMUNITY"
          },
          {
            "navPath": "http://www.nvidia.com",
            "navLabel": "Investors"
          }
        ]
      }
    ],
    "NVIDIAsubBrandHeader": [
      {
        "logoImagePath": "",
        "logoLabel": "SHIELD",
        "logoPathURL": "https://wwwdev.nvidia.com/en-us/shield/",
        "showCart": true,
        "showSearch": true,
        "showProfileIcon": true,
        "brandNavItems": [
          {
            "navPath": "https://wwwdev.nvidia.com/en-us/shield/shield-tv/",
            "navLabel": "SHIELD TV"
          },
          {
            "navPath": "https://wwwdev.nvidia.com/en-us/shield/apps/",
            "navLabel": "APPS",
            "hoveritems": [
              {
                "navPath": "",
                "navLabel": "this is the test for navigati",
                "hoveritemslvl2": [
                  {
                    "navPath": "https://wwwdev.nvidia.com/en-us/shield/apps/",
                    "navLabel": "test2"
                  }
                ]
              },
              {
                "navPath": "",
                "navLabel": "this is the test for navigation without hoveritemslvl2"
              },
              {
                "navPath": "",
                "navLabel": "this is the test for navigation without hoveritemslvl2"
              },
              {
                "navPath": "",
                "navLabel": "this is the second item test for ",
                "hoveritemslvl2": [
                  {
                    "navPath": "http://www.nvidia.com/",
                    "navLabel": "hoveritemslvl2 link"
                  },
                  {
                    "navPath": "https://wwwdev.nvidia.com/en-us/geforce",
                    "navLabel": "test3 more words more words more words more words"
                  }
                ]
              },
              {
                "navPath": "",
                "navLabel": "this is the third item test hoveritemslvl2 more more more more more more more",
                "hoveritemslvl2": [
                  {
                    "navPath": "",
                    "navLabel": "hoveritemslvl2 item test4 link"
                  },
                  {
                    "navPath": "https://wwwdev.nvidia.com/en-us/shield/apps/",
                    "navLabel": "hoveritemslvl2 item more words more words more words more more more more words"
                  },
                  {
                    "navPath": "",
                    "navLabel": "go to hoveritems3",
                    "hoveritemslvl3": [
                      {
                        "navPath": "http://www.nvidia.com/",
                        "navLabel": "hoveritemslvl3 link"
                      },
                      {
                        "navPath": "https://wwwdev.nvidia.com/en-us/geforce",
                        "navLabel": "another hoveritemslvl3 link"
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            "navPath": "https://wwwdev.nvidia.com/en-us/shield/games/",
            "navLabel": "GAMING",
            "hoveritems": [
              {
                "navPath": "https://wwwdev.nvidia.com/en-us/shield/games/",
                "navLabel": "Overview"
              },
              {
                "navPath": "https://wwwdev.nvidia.com/en-us/shield/games/nvidia-shield---games---geforce-now/",
                "navLabel": "Geforcenow"
              },
              {
                "navPath": "https://wwwdev.nvidia.com/en-us/shield/games/geforce-now/",
                "navLabel": "GameStream"
              }
            ]
          },
          {
            "navPath": "https://wwwdev.nvidia.com/en-us/shield/smart-home/",
            "navLabel": "AI HOME"
          },
          {
            "navPath": "https://wwwdev.nvidia.com/en-us/shield/tablet/",
            "navLabel": "TABLET"
          },
          {
            "navPath": "https://shield.nvidia.com/blog",
            "navLabel": "COMMUNITY"
          },
          {
            "navPath": "https://shield.nvidia.com/support",
            "navLabel": "SUPPORT"
          }
        ]
      }
    ],
    "NVIDIAglobalFooter": [
      {
        "country": "",
        "countrySelectorURL": "",
        "socialLabel": "Follow what?",
        "socialItems": [
          {
            "facebook": "http://nvidianews.nvidia.com/",
            "twitter": "http://www.twitter.com",
            "youtube": "http://www.youtube.com",
            "twitchtv": "https://www.twitch.tv/nvidia",
            "reddit": "",
            "linkedin": "www.linkedin.com",
            "instagramURL": "https://www.linkedin.com/company/3608",
            "weibo": "http://weibo.com/login.php",
            "youku": "",
            "wechat": ""
          }
        ],
        "globalFooterLegalLinks": [
          {
            "navPath": "http://www.nvidia.com/object/nv-account.html",
            "navLabel": "Terms and Conditions"
          },
          {
            "navPath": "https://www.nvidia.com/en-us/about-nvidia/legal-info/",
            "navLabel": "Legal Info "
          },
          {
            "navPath": "https://www.nvidia.com/en-us/about-nvidia/privacy-policy/",
            "navLabel": "Privacy Policy"
          }
        ],
        "footerTableItems": [
          {
            "grpLabel": "Footer nav links",
            "footerItems": [
              {
                "navPath": "https://wwwdev.nvidia.com/en-us/privacy_policy/",
                "navLabel": "GPU.new"
              },
              {
                "navPath": "/apps/nvidiaGDC/components/functional/sectionWrapper",
                "navLabel": "GPU meaning what?"
              },
              {
                "navPath": "http://www.gputechconf.com/highlights",
                "navLabel": "highlights in gputech conference"
              },
              {
                "navPath": "http://www.cnn.com",
                "navLabel": "connect"
              }
            ]
          },
          {
            "grpLabel": "Footer nav 2 links",
            "footerItems": [
              {
                "navPath": "www.facebook.com",
                "navLabel": "Nvidia homepage"
              },
              {
                "navPath": "www.gmail.com",
                "navLabel": " NVIDIA Partner Network  in Nvidia"
              },
              {
                "navPath": "https://wwwdev.nvidia.com/en-us/legacy/textcomponent2/",
                "navLabel": "Nvidia2"
              },
              {
                "navPath": "http://www.nvidia.com/object/about-nvidia.html",
                "navLabel": "About Nvidia  NVIDIA Partner Network "
              }
            ]
          },
          {
            "grpLabel": "Footer nav 3 links",
            "footerItems": [
              {
                "navPath": "/apps/nvidiaGDC/components/functional/sectionWrapper",
                "navLabel": "Geforce"
              },
              {
                "navPath": "/apps/nvidiaGDC/components/functional/sectionWrapper",
                "navLabel": "whats geforce now"
              },
              {
                "navPath": "/apps/nvidiaGDC/components/functional/sectionWrapper",
                "navLabel": "Vr Ready in nvidia geforce"
              },
              {
                "navPath": "www.nvidia.com",
                "navLabel": "About Nvidia"
              }
            ]
          }
        ]
      }
    ],
    "NVIDIAsubBrandFooter": [
      {
        "countrySelectorURL": "https://wwwdev.nvidia.com/en-us/shield/country-selector/",
        "socialLabel": "Follow SHIELD",
        "socialItems": [
          {
            "facebook": "https://www.facebook.com/NVIDIASHIELD/",
            "twitter": "https://twitter.com/nvidiashield",
            "youtube": "https://www.youtube.com/channel/UCL-g3eGJi1omSDSz48AML-g",
            "twitchtv": "https://www.twitch.tv/nvidia",
            "reddit": "",
            "linkedin": "",
            "instagramURL": "",
            "weibo": "",
            "youku": "",
            "wechat": ""
          }
        ],
        "footerTableItems": [
          {
            "grpLabel": "SHIELD TV",
            "footerItems": [
              {
                "navPath": "https://wwwdev.nvidia.com/en-us/shield/shield-tv/#4kvoicesearch",
                "navLabel": "4K HDR"
              },
              {
                "navPath": "https://wwwdev.nvidia.com/en-us/shield/shield-tv/#4kvoicesearch",
                "navLabel": "Voice Search"
              },
              {
                "navPath": "https://wwwdev.nvidia.com/en-us/shield/shield-tv/#Performance",
                "navLabel": "Performance"
              },
              {
                "navPath": "https://wwwdev.nvidia.com/en-us/shield/smart-home/#smarthome",
                "navLabel": "AI Home"
              },
              {
                "navPath": "https://wwwdev.nvidia.com/en-us/shield/shield-tv/#accessories",
                "navLabel": "Accessories"
              },
              {
                "navPath": "https://wwwdev.nvidia.com/en-us/shield/shield-tv/#buynow",
                "navLabel": "Buy Now"
              }
            ]
          },
          {
            "grpLabel": "Games",
            "footerItems": [
              {
                "navPath": "https://wwwdev.nvidia.com/en-us/shield/games/#pcgames",
                "navLabel": "PC Gaming"
              },
              {
                "navPath": "https://wwwdev.nvidia.com/en-us/shield/games/#geforcenow",
                "navLabel": "GeForce NOW"
              },
              {
                "navPath": "https://wwwdev.nvidia.com/en-us/shield/games/#android",
                "navLabel": "Android"
              },
              {
                "navPath": "https://wwwdev.nvidia.com/en-us/shield/games/#controller",
                "navLabel": "Controller"
              },
              {
                "navPath": "https://wwwdev.nvidia.com/en-us/shield/games/#library",
                "navLabel": "Library"
              }
            ]
          },
          {
            "grpLabel": "Apps",
            "footerItems": [
              {
                "navPath": "https://wwwdev.nvidia.com/en-us/shield/apps/#entertainment",
                "navLabel": "Entertainment"
              },
              {
                "navPath": "https://wwwdev.nvidia.com/en-us/shield/apps/#voicesearch",
                "navLabel": "Voice Search"
              },
              {
                "navPath": "https://wwwdev.nvidia.com/en-us/shield/apps/#casting",
                "navLabel": "Casting"
              },
              {
                "navPath": "https://wwwdev.nvidia.com/en-us/shield/apps/#accessmedia",
                "navLabel": "Plex Media Server"
              },
              {
                "navPath": "https://wwwdev.nvidia.com/en-us/shield/apps/#library",
                "navLabel": "Library"
              }
            ]
          }
        ]
      }
    ]
  };




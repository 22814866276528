import { takeUntil } from "rxjs/operators";
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
  ViewChild,
  ElementRef,
  NgZone,
  AfterViewChecked,
} from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Store } from "@ngrx/store";
import { ActivatedRoute, Router } from "@angular/router";
import { Product } from "../../shared/interface/product";
import { Subject } from "rxjs";
import { FeaturedProduct } from "../../shared/interface/featured-product";
import { ProductService } from "../product.service";
import * as productActions from "../product.actions";
import * as reducers from "../../reducers";
import { ProductCompare } from "../../shared/interface/product-compare";
import {
  resizeImg,
  changeDRId,
  showOverlay,
  showImgError,
  getBuyButtonText,
  getPriceIntegerDot,
  getFormatedPrice,
  getPriceDecimal,
  extractDRPriceIntegerDot,
  extractDRPriceDecimal,
  productName,
} from "../../shared/utils/utils";
import * as $ from "jquery";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";

@Component({
  selector: "featured-product",
  templateUrl: "./featured-product.component.html",
  styleUrls: ["./featured-product.component.scss"],
})
export class FeaturedProductComponent implements OnInit, AfterViewChecked {
  @Input() screenWidth: string;
  @Input() viewportType: string;
  //@Input() featuredProduct: FeaturedProduct;
  @Input() productCompare: ProductCompare[];
  @Input() suggestedProduct;
  @Output() fpLoaded = new EventEmitter();
  @Output() hatchOverlayDisplay = new EventEmitter();
  @ViewChild("drId") drId: ElementRef;
  locale: string;
  thumbnailW: number = 300;
  thumbnailH: number = 169;
  thumbnailWSM: number = 270;
  thumbnailHSM: number = 152;
  changeDRId;
  productName;
  showImgError;
  getBuyButtonText;
  getPriceIntegerDot;
  getFormatedPrice;
  getPriceDecimal;
  extractDRPriceIntegerDot;
  extractDRPriceDecimal;
  featuredProduct: FeaturedProduct;
  destroy$: Subject<boolean> = new Subject<boolean>();
  afterViewChecked: boolean = false;
  drLoading: boolean = false;

  constructor(
    private store: Store<reducers.State>,
    private zone: NgZone,
    private elRef: ElementRef,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService
  ) {
    this.productName = productName;
    this.changeDRId = changeDRId;
    this.showImgError = showImgError;
    this.getBuyButtonText = getBuyButtonText;
    this.getPriceIntegerDot = getPriceIntegerDot;
    this.getFormatedPrice = getFormatedPrice;
    this.getPriceDecimal = getPriceDecimal;
    this.extractDRPriceIntegerDot = extractDRPriceIntegerDot;
    this.extractDRPriceDecimal = extractDRPriceDecimal;
  }

  ngOnInit() {
    this.store
      .select(reducers.getProductFeatured)
      .subscribe((featuredProduct) => {
        this.featuredProduct = featuredProduct;
        this.afterViewChecked = false;
      });

    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      this.locale = params["locale"];
    });
  }

  detectFeaturedBuyClick() {
    // console.log("Submit button is clicked------->!");
    let main;
    $(".buy-link").on("click", function (event) {
      // console.log(event, "Submit button is clicked!");

      // modal neeraj
      var lastFocusedElement;
      lastFocusedElement = document.activeElement;
      setTimeout(function () {
        const FOCUSABLE_SELECTORS =
          "a.popClose, img, button,h1,div.cellPrice, div.bundle_icon_class, a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[tabindex], *[contenteditable]";

        const closeModalBtn = document.querySelector(".popClose");
        const modal = document.querySelector(".popBody");
        if (modal) {
          modal.classList.add("modal-active");
          main = document.querySelector(".popBg");
          openModal();
          //   console.log("----*--lastFocusedElement->", closeModalBtn);
        }
        function openModal() {
          const focusableElements = Array.from(
            modal.querySelectorAll(FOCUSABLE_SELECTORS)
          );
          focusableElements.forEach((el) => el.setAttribute("tabindex", "0"));
          (modal.querySelector(FOCUSABLE_SELECTORS) as HTMLElement).focus();
          modal.removeAttribute("aria-hidden");
          main.setAttribute("aria-hidden", "true");
          closeModalBtn.addEventListener("click", modalClose);
        }
      }, 1000);

      function modalClose() {
        // Return the focus to the last focused element
        lastFocusedElement.focus();
      }
      //end modal focus neeraj
      //   event.preventDefault();
    });
  }
  ngAfterViewChecked() {
    // this.detectFeaturedBuyClick()
    if (!this.afterViewChecked && this.featuredProduct) {
      // 	$('.js-product-item').addClass('dr-loading');
      this.afterViewChecked = true;
      this.fpLoaded.emit(this.afterViewChecked);
    }
  }
  getFormPath(locale, displayName) {
    //  (locale == 'en-us')?locale='en-zz':'';
    var currentdomain = window.location.hostname;
    currentdomain == "localhost" ? (currentdomain = "wwwdev.nvidia.com") : "";
    if (locale == "ru-ru") {
      var path =
        "https://" +
        currentdomain +
        "/" +
        locale +
        "/geforce/graphics-cards/30-series/notify-me-form/";
    } else {
      var path =
        "https://" +
        currentdomain +
        "//content/forms/af/nvidia-forms/products-notify-me/consumer-notify-me/?afAcceptLang=" +
        locale +
        "&productName=" +
        displayName;
    }
    //var path = 'https://'+currentdomain+'/'+locale+'/geforce/graphics-cards/30-series/notify-me-form/';
    //console.log(path,"Formoath");

    return path;
  }

  getEANCode(productUPC) {
    //  console.log("GET EAN CODE", ("0"+productUPC));
    return productUPC;
  }

  createThumbnail(img) {
    if (window.matchMedia("(max-width: 767px)").matches) {
      //add if(img.naturalWidth && img.naturalHeight) {} to make sure has img
      resizeImg(img, this.thumbnailWSM, this.thumbnailHSM, this.locale);
    } else {
      resizeImg(img, this.thumbnailW, this.thumbnailH, this.locale);
    }
  }

  showOverlay(productDetail) {
    let popupFlag = false;
    if (popupFlag == false) {
      popupFlag = true;
      showOverlay(
        productDetail,
        this.locale,
        this.store,
        this.hatchOverlayDisplay
      );
    }
  }

  compareProduct(productUPC, addRemoveCompare) {
    if (addRemoveCompare === "Compare") {
      if (this.productCompare.indexOf(null) > -1) {
        this.store.dispatch(
          new productActions.AddComparedProduct({
            upc: productUPC,
            type: 0,
            locale: this.locale,
          })
        );
      }
    } else {
      this.store.dispatch(new productActions.RemoveComparedProduct(productUPC));
    }
    //if(this.locale == 'en-us'){
    //window['hatchwidgetHelpers'].reloadScript();
    //   window['hatchwidgetHelpers'].reloadScript()
    //    }
    $(".js-product-item").removeData();
    //setTimeout(function(){window['appSetupHelpers'].scanPID()},500);
    this.afterViewChecked = true;
  }

  transmitToCart() {
    var that = this;
    setTimeout(() => {
      $(".cart-main-section .cart__empty")[0] &&
        ($(".cart-main-section .cart__empty")[0].innerHTML =
          that.translate.instant("empty_cart"));
      $(".cart-main-section .cart__title")[0] &&
        ($(".cart-main-section .cart__title")[0].innerHTML =
          that.translate.instant("cart"));
      $(".cart-main-section .cart__items").each(function () {
        $(this)
          .find(".cart_remove .cart-item__remove")
          .each(function (i) {
            $(".cart_remove .cart-item__remove")[i].innerHTML =
              that.translate.instant("remove");
          });
      });
      // $('.cart-main-section .cart__checkout-button')[0] && ($('.cart-main-section .cart__checkout-button')[0].innerHTML = that.translate.instant('checkout'));
    }, 100);
  }
  valueTrimmer(v) {
    let t;
    if (v.length > 0) {
      t = v.trim();
    }
    return t;
  }
  getPDPLink(productDetail) {
    let l = "";

    if (productDetail.retailers.length > 0) {
      let foundLink = false;
      productDetail.retailers.forEach((element) => {
        if (element.purchaseLink && element.partnerId === "111") {
          let l_parts = element.purchaseLink.split("/");
          l = "/" + l_parts.slice(1).join("/");
          foundLink = true;
          //  console.log("-----l----->",l);
        }
      });
      if (foundLink) {
      return l;
      }
    }

if (productDetail.category === "SWITCH_SYSTEMS" || productDetail.category === "DPU") {
      //  l = "/" + this.locale + "/consumer/product/"+productDetail.productSKU+"/"+(productDetail.productTitle.replace(/\s+|[,\/]/g, ""));
      l =
        ("/" +
        $("html").attr("lang") +
        "/consumer/product/" +
        productDetail.productSKU.toLowerCase() +
        "/" +
        productDetail.productTitle
          .replace(productDetail.productSKU, " ")
          .replaceAll(" ", "-")
          .replaceAll("/", "-")
          .toLowerCase()
          .replace(/-+/gi, "-"));
      
          return l;
    }

    else {
      // return ("/" +$("html").attr("lang") +"/consumer/product/" +productDetail.productSKU.toLowerCase() +"/" +productDetail.productTitle.replace(productDetail.productSKU, " ").replaceAll(" ", "-").replaceAll("/", "-").toLowerCase().replace(/-+/gi, "-"));
      return (
        "/" +
        $("html").attr("lang") +
        "/consumer/product/" +
        productDetail.productSKU.toLowerCase() +
        "/" +
        productDetail.productTitle
          .replace(productDetail.productSKU, " ")
          .replaceAll("+", "")
          .replaceAll(".", "-")
          .replaceAll(" ", "-")
          .replaceAll("/", "-")
          .toLowerCase()
          .replace(/-+/gi, "-") +
        "/"
      );
    }
    //  return  ("/" + this.locale + "/consumer/product/"+productDetail.productSKU+"/"+(productDetail.productTitle.replace(/\s+|[,\/]/g, "")).replace(/[^a-z0-9]/gi,''));
  }
}

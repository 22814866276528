<div role="navigation" aria-label="Main Product Details" name="resultsDiv" id="resultsDiv"
	class="product-details-main-container">

	<!-- BEGINNING OF GRID LAYOUT -->
	<div class="product-details-container nv-gridTileLayout" *ngIf="layoutType === 'grid'"
		[class.grid-bottom-margin]="setBottomMargin">
		<div aria-haspopup="true" tabindex="0" [ngClass]="{'nv-productTile': locale}"
			class="droplink product-details-grid-tile grid-tile-spacing "
			*ngFor="let productDetail of productDetails; let i = index">

			<div kuiText *ngIf="productDetail.isFeaturedProduct && !locale"
				class="product-details-featured-product image-cont">{{
				'featured' | translate }}</div>

			<div class="nv-tileTopRow">
				<div *ngIf="productDetail.bestSeller" class="nv-bestSeller">{{ 'is_bestselling' | translate }}</div>

				<div *ngIf="productDetail.isOffer" class="nv-specialOfferContainer">
					<div class="nv-specialOffer">
						<div class="nv-specialOfferIcon"></div>
						{{ 'special_offer' | translate }}
					</div>
					<span class="nv-offerToolTip">{{productDetail.offerText}}</span>

				</div>


			</div>
			<!-- <div kuiText *ngIf="productDetail.isFeaturedProduct && locale === 'en-us'"
				class="nv-compare">
				<button
					*ngIf="productDetail.category != 'GFN' && productDetail.category != 'MODELS' && productDetail.category != 'GAMING_SOFTWARE'"
					kuiButton [size]="'standard'" [danger]="false" [type]="'tertiary'" class="310"
					[attr.id]="('compare_'+productDetail.productUPC)"
					[class.compare]="productDetail.addRemoveCompare === 'Compare'"
					[class.remove]="productDetail.addRemoveCompare === 'Remove'"
					(click)="compareProduct(productDetail)">{{productDetail.addRemoveCompare | lowercase |
					translate}}</button>
			</div>
			<div kuiText *ngIf="!productDetail.isFeaturedProduct && !locale"
				class="product-details-featured-product image-cont" style="opacity: 0;">-</div>
			<div kuiText *ngIf="!productDetail.isFeaturedProduct && locale"
				class="nv-compare">
				<button
					*ngIf="productDetail.category != 'GFN' && productDetail.category != 'MODELS' && productDetail.category != 'GAMING_SOFTWARE'"
					kuiButton [size]="'standard'" [danger]="false" [type]="'tertiary'" class="310"
					[attr.id]="('compare_'+productDetail.productUPC)"
					[class.compare]="productDetail.addRemoveCompare === 'Compare'"
					[class.remove]="productDetail.addRemoveCompare === 'Remove'"
					(click)="compareProduct(productDetail)">{{productDetail.addRemoveCompare | lowercase |
					translate}}</button>
			</div> -->
			<div style="position: relative;"
				[ngStyle]="locale ? {'display': 'flex', 'flex-direction': 'column', 'height': '100%'}: {}">
				<div class="product-details-image-container" [ngStyle]="locale ? {'align-self': 'center'}: {}">
					<a *ngIf="productDetail.category !== 'MODELS' && productDetail.category !== 'GAMING_SOFTWARE' 
					&& productDetail.category !== 'GFN' && !productDetail.digitialRiverID && productDetail.internalLink"
						href='{{productDetail.internalLink}}' target="_blank" rel="noopener">
						<kui-image [aspectRatio]="locale ? '16-11' : '1-1'"
							[ngStyle]="locale ? {'display': 'flex'} : {}">
							<img [ngClass]="{'nv-image': locale}" width="100%" height="100%"
								[attr.data-pid-code]="productDetail.productID"
								[attr.data-ctaType]="(productDetail.bcPID!=null && productDetail.bcPID>0 && productDetail.retailers.length===1 
									&& productDetail.retailers[0].partnerId==='111') 
									|| (productDetail.isFounderEdition && productDetail.prdStatus === 'buy_now') ? 'add_to_cart' 
									: productDetail.prdStatus"
								[attr.data-productTitle]="productDetail.productTitle"
								[title]="productDetail.category === 'GFN' || productDetail.category === 'MONITOR' || productDetail.category === 'MICE' || productDetail.category === 'MODELS' || productDetail.category === 'GAMING_SOFTWARE' ? productDetail.productTitle : productDetail.displayName.replace('No GPU','')"
								src="{{productDetail.imageURL}}"
								[alt]="productDetail.category === 'GFN' || productDetail.category === 'MONITOR' || productDetail.category === 'MICE' || productDetail.category === 'MODELS' || productDetail.category === 'GAMING_SOFTWARE' ? productDetail.productTitle : productDetail.displayName.replace('No GPU','')"
								(load)="createThumbnail($event.target)" (error)="showImgError($event.target)" />
						</kui-image>
					</a>
					<kui-image *ngIf="productDetail.category === 'MODELS' || productDetail.category === 'GAMING_SOFTWARE' 
					|| productDetail.category === 'GFN' || productDetail.digitialRiverID || !productDetail.internalLink"
						[aspectRatio]="locale ? '16-11' : '1-1'" [ngStyle]="locale ? {'display': 'flex'} : {}">
						<img [ngClass]="{'nv-image': locale}" width="100%" height="100%"
							[attr.data-pid-code]="productDetail.productID"
							[attr.data-ctaType]="(productDetail.bcPID!=null && productDetail.bcPID>0 && productDetail.retailers.length===1 
								&& productDetail.retailers[0].partnerId==='111') 
								|| (productDetail.isFounderEdition && productDetail.prdStatus === 'buy_now') ? 'add_to_cart' 
								: productDetail.prdStatus"
							[attr.data-productTitle]="productDetail.productTitle"
							[title]="productDetail.category === 'GFN' || productDetail.category === 'MONITOR' || productDetail.category === 'MICE' || productDetail.category === 'MODELS' || productDetail.category === 'GAMING_SOFTWARE' ? productDetail.productTitle : productDetail.displayName.replace('No GPU','')"
							src="{{productDetail.imageURL}}"
							[alt]="productDetail.category === 'GFN' || productDetail.category === 'MONITOR' || productDetail.category === 'MICE' || productDetail.category === 'MODELS' || productDetail.category === 'GAMING_SOFTWARE' ? productDetail.productTitle : productDetail.displayName.replace('No GPU','')"
							(load)="createThumbnail($event.target)" (error)="showImgError($event.target)" />
					</kui-image>

				</div>
				<a class="product-title"
					*ngIf="productDetail.category !== 'MODELS' && productDetail.category !== 'GAMING_SOFTWARE' 
				&& productDetail.category !== 'GFN' && !productDetail.digitialRiverID && productDetail.internalLink; else title_content"
					href='{{productDetail.internalLink}}' target="_blank" rel="noopener">
					<h2 kuiText title="{{productDetail.productTitle}}"
						[ngClass]="{'nv-productTitle': locale, 'product-name h--smallest': !locale}"
						[attr.data-pid-code]="productDetail.productID"
						[attr.data-ctaType]="(productDetail.bcPID!=null && productDetail.bcPID>0 && productDetail.retailers.length===1 
								&& productDetail.retailers[0].partnerId==='111') 
								|| (productDetail.isFounderEdition && productDetail.prdStatus === 'buy_now') ? 'add_to_cart' 
								: productDetail.prdStatus"
						[attr.data-productTitle]="productDetail.productTitle" [innerHTML]="productDetail.productTitle">
					</h2>

				</a>
				<ng-template #title_content>
					<h2 kuiText title="{{productDetail.productTitle}}"
						[ngClass]="{'nv-productTitle': locale, 'product-name h--smallest': !locale}"
						[attr.data-pid-code]="productDetail.productID"
						[attr.data-ctaType]="(productDetail.bcPID!=null && productDetail.bcPID>0 && productDetail.retailers.length===1 
								&& productDetail.retailers[0].partnerId==='111') 
								|| (productDetail.isFounderEdition && productDetail.prdStatus === 'buy_now') ? 'add_to_cart' 
								: productDetail.prdStatus"
						[attr.data-productTitle]="productDetail.productTitle" [innerHTML]="productDetail.productTitle">
					</h2>
					<h2 *ngIf="productDetail.fe_offer" kuiText
						[ngClass]="{'nv-productTitle': locale, 'product-name h--smallest': !locale}"
						[attr.data-pid-code]="productDetail.productID"
						[attr.data-ctaType]="(productDetail.bcPID!=null && productDetail.bcPID>0 && productDetail.retailers.length===1 
								&& productDetail.retailers[0].partnerId==='111') 
								|| (productDetail.isFounderEdition && productDetail.prdStatus === 'buy_now') ? 'add_to_cart' 
								: productDetail.prdStatus"
						[attr.data-productTitle]="productDetail.productTitle" [innerHTML]="productDetail.fe_offer"></h2>

				</ng-template>
				<!-- <a *ngIf="locale === 'en-us' && 
						(productDetail.manufacturer.toUpperCase() === 'MSI' || productDetail.manufacturer.toUpperCase() === 'ASUS')" 
						href='/{{locale}}/consumer/?page=1&limit=9&locale={{locale}}&manufacturer={{productDetail.manufacturer}}'
						 target="_blank" class="nv-visit-link" [innerHTML]="'Visit the ' + productDetail.manufacturer + ' store'"></a> -->
				<div class="specs-container 30" style="padding-bottom: 0px;"
					[ngClass]="{'specs-container-grid-laptop': productDetail.category === 'LAPTOP'}">
					<div *ngIf="productDetail.category==='GFN' || productDetail.category==='MODELS' || productDetail.category==='GAMING_SOFTWARE'"
						[innerHTML]="productDetail.productInfo[0].value" class="nv-description"></div>
					<ul
						*ngIf="productDetail.category!='GFN' && productDetail.category!='MODELS' && productDetail.category!='GAMING_SOFTWARE' && locale != 'en-us'">
						<li *ngFor="let specs of productDetail.productInfo | slice:0:2">
							<div class="specs p-medium p-medium">
								<div kuiText style="display: inline-block;font-size: 14px!important;">{{specs.name |
									translate}}:&nbsp;{{valueTrimmer(specs.value)}}
									{{(((productDetail.category==='LAPTOP' || productDetail.category==='STUDIO-LAPTOP')
									&& specs.name =='gpu')?'gpu_spec_sufix':'') | translate}}</div>
							</div>
						</li>
					</ul>
					<ul
						*ngIf="productDetail.category!='GFN' && productDetail.category!='MODELS' && productDetail.category!='GAMING_SOFTWARE' && locale == 'en-us'">
						<li *ngFor="let specs of productDetail.productInfo | slice:0:2">
							<div class="specs p-medium p-medium">
								<div kuiText style="display: inline-block;font-size: 14px!important;">
									{{specs.name |
									translate}}:&nbsp;{{valueTrimmer(specs.value)}}
									{{(((productDetail.category==='LAPTOP' || productDetail.category==='STUDIO-LAPTOP')
									&& specs.name =='gpu')?'gpu_spec_sufix':'') | translate}}</div>
							</div>
						</li>
					</ul>
				</div>
				<div #drSpinnerCont *ngIf="productDetail.digitialRiverID" class="dr-spinner-bg-grid 40"
					[style]="locale ? {'margin-top': 'auto'} : {}">
					<div #drId *ngIf="productDetail.digitialRiverID" class="product-row__item js-product-item 45"
						data-analytics="btn-dr-id"
						[attr.data-digital-river-id]="changeDRId(productDetail.digitialRiverID)">
						<div *ngIf="locale" class="nv-priceAndCTA">
							<div class="nv-priceAndCompare">
								<div #priceDiv class="price js-product-price clearfix h--smallest 42 nv-price"></div>
								<div kuiText class="nv-compare">
									<span
										*ngIf="productDetail.category != 'GFN' && productDetail.category != 'MODELS' && productDetail.category != 'GAMING_SOFTWARE'"
										class="nv-freeShipping">{{'free_shipping' | translate}}</span>
									<!-- <button
										*ngIf="productDetail.category != 'GFN' && productDetail.category != 'MODELS' && productDetail.category != 'GAMING_SOFTWARE'"
										kuiButton [size]="'standard'" [danger]="false" [type]="'tertiary'"
										class="310 nv-compareButton" [attr.id]="('compare_'+productDetail.productUPC)"
										[class.compare]="productDetail.addRemoveCompare === 'Compare'"
										[class.remove]="productDetail.addRemoveCompare === 'Remove'"
										(click)="compareProduct(productDetail)">{{productDetail.addRemoveCompare |
										lowercase |
										translate}}</button> -->
								</div>
							</div>
							<div class="cta-preorder mobile-top" (click)="transmitToCart()">
								<div *ngIf="productDetail.retailers?.length > 0" class="buy fpc-66 nv-buyFromPartners"
									[class.fe-pids]="productDetail.manufacturer==='NVIDIA'">
									<div [attr.class]="getEANCode(productDetail.productUPCOriginal)"
										[attr.id]="productDetail.productID" [attr.status]="productDetail.prdStatus"
										[attr.isFounderEdition]="productDetail.isFounderEdition" style="display:none">
										{{productDetail.retailers |
										json}}</div>
									<div>
										<!-- <a kuiText
											class="extra_style buy-link cta-link-larges 68 buy-frm-partner nv-buyFromPartners"
											[attr.data-pid-code]="productDetail.productID"
[attr.data-ctaType]="productDetail.prdStatus"
											[attr.data-mpn-code]="getEANCode(productDetail.productUPCOriginal)"
											data-color="#76b900" data-secondary-color="#fff"
											(click)="productName(productDetail);">
											{{'buy_from_partners' | translate}}
										</a> -->
									</div>
								</div>
								<!-- Logic for NOTIFY ME -->
								<!-- DR NOTIFY ME -->
								<a kuiText *ngIf="productDetail.prdStatus === 'dr_notify_me'"
									[attr.data-pid-code]="productDetail.productID"
									[attr.data-ctaType]="'out_of_stock'"
									[attr.data-productTitle]="productDetail.productTitle"
									class="featured-buy-link link-btn brand-green  cta-button show-dr-notify-me cta-link-large 44"
									href="javascript:void(0);">
									{{ 'out_of_stock' | translate }}
								</a>
								<!-- END OF DR NOTIFY ME -->
								<!-- NV NOTIFY ME -->
								<a kuiText *ngIf="productDetail.prdStatus === 'nv_notify_me'"
									[attr.data-pid-code]="productDetail.productID"
									[attr.data-ctaType]="'out_of_stock'"
									[attr.data-productTitle]="productDetail.productTitle"
									class="featured-buy-link link-btn brand-green  cta-button show-nv-notify-me cta-link-large"
									data-nvnotify-form-path="/content/forms/af/nvidia-forms/products-notify-me/consumer-notify-me"
									href="javascript:void(0);">
									{{ 'out_of_stock' | translate }}
								</a>
								<!-- END OF NV NOTIFY ME -->
								<a kuiText *ngIf="productDetail.prdStatus === 'buy_now'"
									[attr.data-pid-code]="productDetail.productID"
									[attr.data-ctaType]="'add_to_cart'"
									[attr.data-productTitle]="productDetail.productTitle"
									class="featured-buy-link link-btn brand-green  cta-button cta-link-large"
									href="javascript:void(0);">
									{{ 'add_to_cart' | translate }}
								</a>
								<a kuiText
									*ngIf="(productDetail.prdStatus === 'try_now' && productDetail.category === 'MODELS') || productDetail.category === 'GAMING_SOFTWARE'"
									[attr.data-pid-code]="productDetail.productID"
									[attr.data-ctaType]="productDetail.prdStatus"
									[attr.data-productTitle]="productDetail.productTitle"
									class="featured-buy-link link-btn brand-green  cta-button cta-link-large"
									target="_blank" [href]="productDetail.retailers[0].purchaseLink">
									{{ productDetail.prdStatus | translate }}
								</a>
								<!-- OUT OF STOCK -->
								<a kuiText *ngIf="productDetail.prdStatus === 'out_of_stock'"
									[attr.data-pid-code]="productDetail.productID"
									[attr.data-ctaType]="productDetail.prdStatus"
									[attr.data-productTitle]="productDetail.productTitle"
									class="featured-buy-link link-btn brand-green  cta-button show-out-of-stock cta-link-large"
									href="javascript:void(0);">
									{{ 'out_of_stock' | translate }}
								</a>
								<!-- END OF OUT OF STOCK -->
								<!-- <div class="product__message limit-per-customer">{{'limit_5' | translate}}</div> -->

							</div>
							<div class="ship-date 77"></div>
						</div>

						<div *ngIf="!locale">
							<div #priceDiv class="price js-product-price clearfix h--smallest 42"></div>
							<div class="price  outside-page 43">
								<span kuiText [innerHtml]="extractDRPriceIntegerDot(priceDiv, drSpinnerCont)"></span>
								<span kuiText class="decimal" [innerHtml]="extractDRPriceDecimal(priceDiv)"></span>
							</div>
							<div class="cta-preorder mobile-top"
								[class.outside-page]="screenWidth === 'LG' || screenWidth === 'XL'"
								(click)="transmitToCart()">
								<!-- Logic for NOTIFY ME -->
								<!-- DR NOTIFY ME -->
								<a kuiText *ngIf="productDetail.prdStatus === 'dr_notify_me'"
									[attr.data-pid-code]="productDetail.productID"
									[attr.data-ctaType]="'out_of_stock'"
									[attr.data-productTitle]="productDetail.productTitle"
									class="featured-buy-link link-btn brand-green  cta-button show-dr-notify-me cta-link-large 44"
									href="javascript:void(0);">
									{{ 'out_of_stock' | translate }}
								</a>
								<!-- END OF DR NOTIFY ME -->
								<!-- NV NOTIFY ME -->
								<a kuiText *ngIf="productDetail.prdStatus === 'nv_notify_me'"
									[attr.data-pid-code]="productDetail.productID"
									[attr.data-ctaType]="'out_of_stock'"
									[attr.data-productTitle]="productDetail.productTitle"
									class="featured-buy-link link-btn brand-green  cta-button show-nv-notify-me cta-link-large"
									data-nvnotify-form-path="/content/forms/af/nvidia-forms/products-notify-me/consumer-notify-me"
									href="javascript:void(0);">
									{{ 'out_of_stock' | translate }}
								</a>
								<!-- END OF NV NOTIFY ME -->
								<a kuiText *ngIf="productDetail.prdStatus === 'buy_now'"
									[attr.data-pid-code]="productDetail.productID"
									[attr.data-ctaType]="'add_to_cart'"
									[attr.data-productTitle]="productDetail.productTitle"
									class="featured-buy-link link-btn brand-green  cta-button cta-link-large"
									href="javascript:void(0);">
									{{ 'add_to_cart' | translate }}
								</a>
								<a kuiText
									*ngIf="(productDetail.prdStatus === 'try_now' && productDetail.category === 'MODELS') || productDetail.category === 'GAMING_SOFTWARE'"
									[attr.data-pid-code]="productDetail.productID"
									[attr.data-ctaType]="productDetail.prdStatus"
									[attr.data-productTitle]="productDetail.productTitle"
									class="featured-buy-link link-btn brand-green  cta-button cta-link-large"
									target="_blank" [href]="productDetail.retailers[0].purchaseLink">
									{{ productDetail.prdStatus | translate }}
								</a>
								<!-- OUT OF STOCK -->
								<a kuiText *ngIf="productDetail.prdStatus === 'out_of_stock'"
									[attr.data-pid-code]="productDetail.productID"
									[attr.data-ctaType]="productDetail.prdStatus"
									[attr.data-productTitle]="productDetail.productTitle"
									class="featured-buy-link link-btn brand-green  cta-button show-out-of-stock cta-link-large"
									href="javascript:void(0);">
									{{ 'out_of_stock' | translate }}
								</a>
								<!-- END OF OUT OF STOCK -->
								<!-- <div class="product__message limit-per-customer">{{'limit_5' | translate}}</div> -->
								<div *ngIf="productDetail.retailers?.length > 0" class="buy fpc-66"
									[class.fe-pids]="productDetail.manufacturer==='NVIDIA'">
									<div [attr.class]="getEANCode(productDetail.productUPCOriginal)"
										[attr.id]="productDetail.productID" [attr.status]="productDetail.prdStatus"
										[attr.isFounderEdition]="productDetail.isFounderEdition" style="display:none">
										{{productDetail.retailers |
										json}}</div>
									<div>
										<!-- <a kuiText class="extra_style buy-link cta-link-larges 68 buy-frm-partner"
											[attr.data-pid-code]="productDetail.productID"
[attr.data-ctaType]="productDetail.prdStatus"
											[attr.data-mpn-code]="getEANCode(productDetail.productUPCOriginal)"
											data-color="#76b900" data-secondary-color="#fff"
											(click)="productName(productDetail);">
											{{'buy_from_partners' | translate}}
										</a> -->
									</div>
								</div>

							</div>
							<div class="ship-date 77"></div>
						</div>
					</div>
					<div *ngIf="!locale" class="compare-remove-container pdc-68"
						style="padding-bottom: 30px; display: flex; justify-content: space-between;"
						[class.outside-page]="screenWidth === 'LG' || screenWidth === 'XL'">
						<!-- <a *ngIf="productDetail.category != 'GFN'" kuiText class="cta-link-regular 69" [attr.id]="('compare_'+productDetail.productUPC)"
							style="flex:inherit" [class.compare]="productDetail.addRemoveCompare === 'Compare'"
							[class.remove]="productDetail.addRemoveCompare === 'Remove'"
							(click)="compareProduct(productDetail)">{{productDetail.addRemoveCompare | lowercase |
							translate}}</a> -->

						<button
							*ngIf="productDetail.category != 'GFN' && productDetail.category != 'MODELS' && productDetail.category!='GAMING_SOFTWARE'"
							kuiButton [size]="'standard'" [danger]="false" [type]="'tertiary'" class="310"
							[attr.id]="('compare_'+productDetail.productUPC)"
							[class.compare]="productDetail.addRemoveCompare === 'Compare'"
							[class.remove]="productDetail.addRemoveCompare === 'Remove'"
							(click)="compareProduct(productDetail)">{{productDetail.addRemoveCompare | lowercase |
							translate}}</button>

						<popover role="button" tabindex="0" *ngIf="productDetail.isOffer" [screenwidth]="screenWidth"
							[type]="'Game Bundle'" [offerLabel]="'game_bundle' | translate"
							[bundle]="productDetail.offerText"></popover>
						<popover role="button" tabindex="0" *ngIf="productDetail.shipInfo" [screenwidth]="screenWidth"
							[type]="'Availability'" [availability]="productDetail.shipInfo"></popover>
					</div>
				</div>
				<div *ngIf="productDetail.digitialRiverID" class="75">
					<dr-loading-indicator [show]="drLoading"></dr-loading-indicator>
				</div>
				<div *ngIf="!productDetail.digitialRiverID"
					[ngClass]="locale ? 'product_detail_78 nv-priceAndCTAContainer' : 'product_detail_78'">
					<div *ngIf="locale" class="nv-priceAndCTA">

						<div class="nv-priceAndCompare">


							<div *ngIf="productDetail.category != 'MODELS' && productDetail.category!='GAMING_SOFTWARE'"
								class="product-details-price-stock">
								<div class="price clearfix h--smallest 80 nv-price"
									*ngIf="locale === 'ru-ru' || locale === 'pl-pl' || locale == 'vi-vn' || locale === 'ja-jp'">
									<span kuiText
										[innerHtml]="getFormatedPrice(productDetail.productPrice,locale)"></span>
								</div>

								<div class="price clearfix h--smallest 81 nv-price"
									*ngIf="locale !== 'ru-ru' && locale !=='pl-pl'  && locale !== 'vi-vn' && locale !== 'ja-jp'">
									<span kuiText [innerHtml]="getPriceIntegerDot(productDetail.productPrice)"></span>
									<span kuiText class="decimal"
										[innerHtml]="getPriceDecimal(productDetail.productPrice)"></span>
								</div>
							</div>
							<div [style]="(locale === 'fr-fr' || locale === 'es-mx') ? {'display': 'flex', 'flex-direction': 'column'} : {}"
								class="price clearfix h--smallest 81 nv-price price-strike">
								<span
									*ngIf="productDetail.mrp && parseCurrencyStringToNumber(productDetail.mrp) > parseCurrencyStringToNumber(productDetail.productPrice)"
									kuiText [innerHtml]="getFormatedPrice(productDetail.mrp,locale)"></span>
								<span
									[style]="(locale === 'fr-fr' || locale === 'es-mx') ? {'padding-left': '0px'} : {}"
									*ngIf="productDetail.mrp && parseCurrencyStringToNumber(productDetail.mrp) > parseCurrencyStringToNumber(productDetail.productPrice)"
									kuiText>
									{{'save' | translate}}
									{{
									calculatePercentageDifference(productDetail.mrp,productDetail.productPrice,locale)
									}} </span>
							</div>
							<div kuiText class="nv-compare">
								<span *ngIf="productDetail.bcPID>1 && productDetail.bcPID!==null "
									class="nv-freeShipping">{{'free_shipping' | translate}}</span>
								<!-- <button
										[style.padding]=" productDetail.mrp && parseCurrencyStringToNumber(productDetail.mrp) > parseCurrencyStringToNumber(productDetail.productPrice) ? 0 : null"
										*ngIf="(productDetail.bcPID < 1 || productDetail.bcPID === null) && productDetail.category !== 'GFN' && productDetail.category !== 'MODELS' && productDetail.category !== 'GAMING_SOFTWARE'"
										kuiButton [size]="'standard'" [danger]="false" [type]="'tertiary'"
										class="310 nv-compareButton" [attr.id]="('compare_'+productDetail.productUPC)"
										[class.compare]="productDetail.addRemoveCompare === 'Compare'"
										[class.remove]="productDetail.addRemoveCompare === 'Remove'"
										(click)="compareProduct(productDetail)">{{productDetail.addRemoveCompare |
										lowercase |
										translate}}</button> -->
							</div>

						</div>


						<div class="clearfix pdc-86" [class.fe-pids]="productDetail.manufacturer==='NVIDIA'">
							<div [attr.id]="productDetail.productID"
								[attr.class]="getEANCode(productDetail.productUPCOriginal)"
								[attr.status]="productDetail.prdStatus"
								[attr.isFounderEdition]="productDetail.isFounderEdition"
								style="display:none; visibility:hidden">{{productDetail.retailers | json}}</div>
							<div *ngIf="productDetail.prdStatus !== 'gf_notify_me'">
								<div *ngIf="productDetail.prdStatus !== 'upcoming'">
									<div
										*ngIf="productDetail.prdStatus !== 'check_availability' && productDetail.prdStatus !== 'out_of_stock'">
										<a *ngIf="!(productDetail.bcPID!=null && productDetail.bcPID>0 && productDetail.retailers.length===1 
										      && productDetail.retailers[0].partnerId==='111')"
											[attr.href]="productDetail.category === 'MODELS' || productDetail.category==='GAMING_SOFTWARE' || productDetail.category === 'GFN' ? productDetail.retailers[0].purchaseLink : productDetail.internalLink"
											target="_blank">
											<span kuiText
												[ngClass]="{'model-buy-link': (productDetail.category === 'MODELS' || productDetail.category==='GAMING_SOFTWARE'), 'extra_style': true, 'cta-link-large': true, 'buy-link-atc': true}"
												[attr.data-productTitle]="productDetail.productTitle"
												(click)="productName(productDetail); detectBuyClick();"
												[attr.data-pid-code]="productDetail.productID"
												[attr.data-ctaType]="productDetail.prdStatus" data-color="#76b900"
												data-secondary-color="#fff" [attr.mp]="4"
												[attr.data-mpn-code]="getEANCode(productDetail.productUPCOriginal)">
												{{productDetail.prdStatus| translate}}</span>
										</a>

										<a *ngIf="productDetail.bcPID!=null && productDetail.bcPID>0 && productDetail.retailers.length===1 
											&& productDetail.retailers[0].partnerId==='111'" kuiText class="extra_style buy-link-atc cta-link-large 511"
											[attr.data-pid-code]="productDetail.productID"
											[attr.data-ctaType]="'add_to_cart'"
											[attr.data-productTitle]="productDetail.productTitle"
											[attr.data-mpn-code]="getEANCode(productDetail.productUPCOriginal)"
											data-color="#76b900" data-secondary-color="#fff"
											(click)="cmpatc(productDetail);">{{"add_to_cart"|translate }}
										</a>
									</div>
								</div>
							</div>
							<a kuiText *ngIf="productDetail.prdStatus === 'check_availability'"
								class="extra_style buy-link 98" styles="" [attr.data-pid-code]="productDetail.productID"
								[attr.data-ctaType]="productDetail.prdStatus"
								[attr.data-mpn-code]="getEANCode(productDetail.productUPCOriginal)" data-color="#76b900"
								data-secondary-color="#fff"
								(click)="productName(productDetail); detectBuyClick();">{{'check_availability' |
								translate}}</a>
							<div *ngIf="productDetail.prdStatus === 'gf_notify_me'"
								class="buy cta-preorder mobile-top lb_iframe"
								[attr.data-href]="getFormPath(locale, productDetail.productTitle)" href="#"
								target="overlay"
								onclick="NVIDIAGDC.button.click(this, $(this).data('href'),true, function() { ;return NVIDIAGDC.button.callbacks(this); }); return false;">
								<a kuiText [attr.data-pid-code]="productDetail.productID"
									[attr.data-ctaType]="productDetail.prdStatus"
									[attr.data-productTitle]="productDetail.productTitle"
									class="featured-buy-link cta-link-large link-btn brand-green cta-button show--me notify-me-btn"
									data-nvnotify-form-path="/content/forms/af/nvidia-forms/products-notify-me/consumer-notify-me"
									href="javascript:void(0);">{{'notify_me' | translate}}</a>
							</div>
							<a kuiText
								*ngIf="productDetail.prdStatus === 'upcoming' || productDetail.prdStatus === 'out_of_stock'"
								class="100 link-btn cta-button stock-grey-out cta-link-large"
								href="javascript:void(0);">
								{{ productDetail.prdStatus | translate }}
							</a>
						</div>


					</div>




					<div *ngIf="!locale">
						<div *ngIf="productDetail.category != 'MODELS' && productDetail.category!='GAMING_SOFTWARE'"
							class="product-details-price-stock">
							<div class="price clearfix h--smallest 80"
								*ngIf="locale === 'ru-ru' || locale === 'pl-pl' || locale == 'vi-vn'">
								<span kuiText [innerHtml]="getFormatedPrice(productDetail.productPrice,locale)"></span>
							</div>
							<div class="price clearfix h--smallest 81"
								*ngIf="locale !== 'ru-ru' && locale !=='pl-pl'  && locale !== 'vi-vn'">
								<span kuiText [innerHtml]="getPriceIntegerDot(productDetail.productPrice)"></span>
								<span kuiText class="decimal"
									[innerHtml]="getPriceDecimal(productDetail.productPrice)"></span>
							</div>
						</div>
						<div *ngIf="screenWidth === 'SM' || screenWidth === 'MD'">
							<div class="clearfix pdc-86" [class.fe-pids]="productDetail.manufacturer==='NVIDIA'"
								style="padding-bottom: 30px;">
								<div [attr.id]="productDetail.productID"
									[attr.class]="getEANCode(productDetail.productUPCOriginal)"
									[attr.status]="productDetail.prdStatus"
									[attr.isFounderEdition]="productDetail.isFounderEdition"
									style="display:none; visibility:hidden">{{productDetail.retailers | json}}</div>
								<div *ngIf="productDetail.prdStatus !== 'gf_notify_me'">
									<div *ngIf="productDetail.prdStatus !== 'upcoming'">
										<div
											*ngIf="productDetail.prdStatus !== 'check_availability' && productDetail.prdStatus !== 'out_of_stock'">
											<a [attr.href]="productDetail.category === 'MODELS' || productDetail.category==='GAMING_SOFTWARE' ? productDetail.retailers[0].purchaseLink : undefined"
												target="_blank">
												<span kuiText
													[ngClass]="{'model-buy-link': (productDetail.category === 'MODELS' || productDetail.category==='GAMING_SOFTWARE'), 'buy-link': (productDetail.category !== 'MODELS' && productDetail.category!=='GAMING_SOFTWARE'), 'extra_style': true, 'cta-link-large': true}"
													(click)="productName(productDetail); detectBuyClick();"
													[attr.data-pid-code]="productDetail.productID"
													[attr.data-ctaType]="productDetail.prdStatus" data-color="#76b900"
													data-secondary-color="#fff" [attr.mp]="390"
													[attr.data-mpn-code]="getEANCode(productDetail.productUPCOriginal)">
													{{productDetail.prdStatus| translate}}</span>
											</a>
										</div>
									</div>
								</div>
								<a kuiText *ngIf="productDetail.prdStatus === 'check_availability'"
									class="extra_style buy-link 98" styles=""
									[attr.data-pid-code]="productDetail.productID"
									[attr.data-ctaType]="productDetail.prdStatus"
									[attr.data-mpn-code]="getEANCode(productDetail.productUPCOriginal)"
									data-color="#76b900" data-secondary-color="#fff"
									(click)="productName(productDetail); detectBuyClick();">{{'check_availability' |
									translate}}</a>
								<div *ngIf="productDetail.prdStatus === 'gf_notify_me'"
									class="buy cta-preorder mobile-top lb_iframe"
									[attr.data-href]="getFormPath(locale, productDetail.productTitle)" href="#"
									target="overlay"
									onclick="NVIDIAGDC.button.click(this, $(this).data('href'),true, function() { ;return NVIDIAGDC.button.callbacks(this); }); return false;">
									<a kuiText [attr.data-pid-code]="productDetail.productID"
										[attr.data-ctaType]="productDetail.prdStatus"
										[attr.data-productTitle]="productDetail.productTitle"
										class="featured-buy-link cta-link-large link-btn brand-green cta-button show--me notify-me-btn"
										data-nvnotify-form-path="/content/forms/af/nvidia-forms/products-notify-me/consumer-notify-me"
										href="javascript:void(0);">{{'notify_me' | translate}}</a>
								</div>
								<a kuiText
									*ngIf="productDetail.prdStatus === 'upcoming' || productDetail.prdStatus === 'out_of_stock'"
									class="100 link-btn cta-button stock-grey-out cta-link-large"
									href="javascript:void(0);">
									{{ productDetail.prdStatus | translate }}
								</a>
							</div>
							<div class="compare-remove-container pdc-104"
								style="padding-bottom: 30px; display: flex; justify-content: space-between;">
								<!-- <a *ngIf="productDetail.category != 'GFN'" kuiText class="cta-link-regular 105" [attr.id]="('compare_'+productDetail.productUPC)"
								[class.compare]="productDetail.addRemoveCompare === 'Compare'"
								[class.remove]="productDetail.addRemoveCompare === 'Remove'"
								(click)="compareProduct(productDetail)">{{productDetail.addRemoveCompare | lowercase |
								translate}}</a> -->
								<button
									*ngIf="productDetail.category != 'GFN' && productDetail.category != 'MODELS' && productDetail.category!='GAMING_SOFTWARE'"
									kuiButton [size]="'standard'" [danger]="false" [type]="'tertiary'" class="310"
									[attr.id]="('compare_'+productDetail.productUPC)"
									[class.compare]="productDetail.addRemoveCompare === 'Compare'"
									[class.remove]="productDetail.addRemoveCompare === 'Remove'"
									(click)="compareProduct(productDetail)">{{productDetail.addRemoveCompare | lowercase
									|
									translate}}</button>

								<popover role="button" tabindex="0" *ngIf="productDetail.isOffer"
									[screenwidth]="screenWidth" [type]="'Game Bundle'"
									[offerLabel]="'game_bundle' | translate" [bundle]="productDetail.offerText">
								</popover>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- product hover effect -->
			<div aria-label="Product hover effect" *ngIf="!productDetail.digitialRiverID && !locale"
				class="product-details-grid-overview 120">
				<div kuiText *ngIf="productDetail.isFeaturedProduct"
					class="product-details-featured-product image-cont">{{
					'featured' | translate }}</div>
				<div kuiText *ngIf="!productDetail.isFeaturedProduct"
					class="product-details-featured-product image-cont" style="visibility: hidden">{{ 'featured' |
					translate }}</div>
				<div class="product-details-image-container">
					<a href='{{getPDPLink(productDetail)}}' target="_blank" rel="noopener noreferrer"
						*ngIf="productDetail.isPDP;else img_content">
						<kui-image aspectRatio="1-1">
							<img [title]="productDetail.category === 'GFN' || productDetail.category === 'MONITOR' || productDetail.category === 'MICE' || productDetail.category === 'MODELS' || productDetail.category === 'GAMING_SOFTWARE' ? productDetail.productTitle : productDetail.displayName.replace('No GPU','')"
								[attr.data-pid-code]="productDetail.productID"
								[attr.data-ctaType]="productDetail.prdStatus"
								[attr.data-productTitle]="productDetail.productTitle" src="{{productDetail.imageURL}}"
								[alt]="productDetail.category === 'GFN' || productDetail.category === 'MONITOR' || productDetail.category === 'MICE' || productDetail.category === 'MODELS' || productDetail.category === 'GAMING_SOFTWARE' ? productDetail.productTitle : productDetail.displayName.replace('No GPU','')"
								(load)="createThumbnail($event.target)" (error)="showImgError($event.target)"
								width="100%" height="100%" />
						</kui-image>
					</a>
					<ng-template #img_content>
						<kui-image aspectRatio="1-1">
							<img [title]="productDetail.category === 'GFN' || productDetail.category === 'MONITOR' || productDetail.category === 'MICE' || productDetail.category === 'MODELS' || productDetail.category === 'GAMING_SOFTWARE' ? productDetail.productTitle : productDetail.displayName.replace('No GPU','')"
								[attr.data-pid-code]="productDetail.productID"
								[attr.data-ctaType]="productDetail.prdStatus"
								[attr.data-productTitle]="productDetail.productTitle" src="{{productDetail.imageURL}}"
								[alt]="productDetail.category === 'GFN' || productDetail.category === 'MONITOR' || productDetail.category === 'MICE' || productDetail.category === 'MODELS' || productDetail.category === 'GAMING_SOFTWARE' ? productDetail.productTitle : productDetail.displayName.replace('No GPU','')"
								(load)="createThumbnail($event.target)" (error)="showImgError($event.target)"
								width="100%" height="100%" />
						</kui-image>
					</ng-template>
				</div>
				<a class="product-title" *ngIf="productDetail.isPDP;else title_content"
					href='{{getPDPLink(productDetail)}}' target="_blank" rel="noopener">
					<h2 kuiText class="product-name h--smallest" [innerHTML]="productDetail.productTitle"></h2>
				</a>
				<ng-template #title_content>
					<h2 kuiText class="product-name h--smallest" [innerHTML]="productDetail.productTitle"></h2>
				</ng-template>
				<div class="specs-container 222"
					[ngClass]="{'specs-container-grid-laptop': productDetail.category === 'LAPTOP'}">
					<div *ngIf="productDetail.category==='GFN' || productDetail.category==='MODELS' || productDetail.category==='GAMING_SOFTWARE'"
						[innerHTML]="productDetail.productInfo[0].value"></div>
					<ul
						*ngIf="productDetail.category!='GFN' && productDetail.category!='MODELS' && productDetail.category!='GAMING_SOFTWARE'">
						<li *ngFor="let specs of productDetail.productInfo">
							<div class="specs p-medium p-medium">
								<div kuiText style="display: inline-block;">{{specs.name |
									translate}}:&nbsp;{{valueTrimmer(specs.value)}}
									{{((((productDetail.category==='LAPTOP' || productDetail.category==='STUDIO-LAPTOP')
									|| productDetail.category==='STUDIO-LAPTOP') && specs.name
									=='gpu')?'gpu_spec_sufix':'') | translate}}</div>
							</div>
						</li>
					</ul>
					<popover role="button" tabindex="0" *ngIf="productDetail.shipInfo" [screenwidth]="screenWidth"
						[type]="'Availability'" [availability]="productDetail.shipInfo"></popover>
				</div>
				<div *ngIf="productDetail.category != 'MODELS' && productDetail.category!='GAMING_SOFTWARE'"
					class="product-details-price-stock">
					<div kuiText class="price clearfix h--smallest 139" *ngIf="locale === 'ru-ru' || locale==='pl-pl'"
						[innerHtml]="getFormatedPrice(productDetail.productPrice,locale)"></div>

					<div class="price clearfix h--smallest 140" *ngIf="locale !== 'ru-ru' && locale !=='pl-pl'">
						<span kuiText [innerHtml]="getPriceIntegerDot(productDetail.productPrice)"></span>
						<span kuiText class="decimal" [innerHtml]="getPriceDecimal(productDetail.productPrice)"></span>
					</div>
				</div>

				<div class="clearfix pdc-139" [class.fe-pids]="productDetail.manufacturer==='NVIDIA'"
					style="padding-bottom: 15px;">
					<div [attr.id]="productDetail.productID" [attr.class]="getEANCode(productDetail.productUPCOriginal)"
						[attr.status]="productDetail.prdStatus" [attr.isFounderEdition]="productDetail.isFounderEdition"
						style="display:none; visibility:hidden">{{productDetail.retailers | json}}</div>
					<!-- <span *ngIf="productDetail.prdStatus !== 'gf_notify_me'" class="extra_style buy-link cta-link-large 149" style="visibility:hidden" [attr.data-pid-code]="productDetail.productID"
[attr.data-ctaType]="productDetail.prdStatus" [attr.data-mpn-code]="getEANCode(productDetail.productUPCOriginal)" data-color="#76b900" data-secondary-color="#fff" (click)="productName(productDetail)" >{{productDetail.prdStatus| translate }}</span>-->
					<div *ngIf="productDetail.prdStatus !== 'gf_notify_me'">
						<div *ngIf="productDetail.prdStatus !== 'upcoming'">
							<div
								*ngIf="productDetail.prdStatus !== 'check_availability' && productDetail.prdStatus !== 'out_of_stock'">
								<a kuiText
									[ngClass]="{'model-buy-link': (productDetail.category === 'MODELS' || productDetail.category==='GAMING_SOFTWARE'), 'buy-link': (productDetail.category !== 'MODELS' && productDetail.category!=='GAMING_SOFTWARE'), 'extra_style': true, 'cta-link-large': true, '152': true}"
									[attr.data-pid-code]="productDetail.productID"
									[attr.data-ctaType]="productDetail.prdStatus"
									[attr.data-mpn-code]="getEANCode(productDetail.productUPCOriginal)"
									data-color="#76b900" data-secondary-color="#fff" target="_blank"
									(click)="productName(productDetail); detectBuyClick();" [attr.mp]="529"
									[attr.href]="productDetail.category === 'MODELS' || productDetail.category==='GAMING_SOFTWARE' ? productDetail.retailers[0].purchaseLink : undefined">
									{{productDetail.prdStatus|
									translate }}</a>
								<!-- <input *ngIf="!locale" type="button" class="buy-link cta-link-large" value="{{productDetail.prdStatus| translate }}" (click)="showOverlay(productDetail)" /> -->
							</div>
						</div>
					</div>
					<a *ngIf="productDetail.prdStatus === 'check_availability'"
						class="extra_style buy-link cta-link-large 158" [attr.data-pid-code]="productDetail.productID"
						[attr.data-ctaType]="productDetail.prdStatus"
						[attr.data-mpn-code]="getEANCode(productDetail.productUPCOriginal)" data-color="#76b900"
						data-secondary-color="#fff"
						(click)="productName(productDetail); detectBuyClick();">{{'check_availability' |
						translate}}</a>

					<!-- <input *ngIf="!locale" type="button" class="buy-link cta-link-large" value="{{productDetail.prdStatus| translate }}" (click)="showOverlay(productDetail)" /> -->
					<div *ngIf="productDetail.prdStatus === 'gf_notify_me'"
						class="buy cta-preorder mobile-top lb_iframe"
						[attr.data-href]="getFormPath(locale, productDetail.productTitle)" href="#" target="overlay"
						onclick="NVIDIAGDC.button.click(this, $(this).data('href'),true, function() { ;return NVIDIAGDC.button.callbacks(this); }); return false;">
						<a kuiText
							class="featured-buy-link link-btn brand-green cta-button show--me notify-me-btn cta-link-large"
							[attr.data-pid-code]="productDetail.productID" [attr.data-ctaType]="productDetail.prdStatus"
							[attr.data-productTitle]="productDetail.productTitle"
							data-nvnotify-form-path="/content/forms/af/nvidia-forms/products-notify-me/consumer-notify-me"
							href="javascript:void(0);">{{'notify_me' | translate}}</a>
					</div>
					<a kuiText
						*ngIf="productDetail.prdStatus === 'upcoming' || productDetail.prdStatus === 'out_of_stock'"
						class="156 featured-buy-link link-btn brand-green  cta-button stock-grey-out cta-link-large"
						[attr.data-pid-code]="productDetail.productID" [attr.data-ctaType]="productDetail.prdStatus"
						[attr.data-productTitle]="productDetail.productTitle" href="javascript:void(0);">
						{{ productDetail.prdStatus | translate }}
					</a>

				</div>
				<div style="display: flex; justify-content: space-between; flex-wrap: wrap">
					<div class="compare-remove-container pdc-162" style="padding-bottom: 30px; flex: 50%">
						<!-- <a *ngIf="productDetail.category != 'GFN'" kuiText class="cta-link-regular 163" [attr.id]="('compare_'+productDetail.productUPC)"
							[class.compare]="productDetail.addRemoveCompare === 'Compare'"
							[class.remove]="productDetail.addRemoveCompare === 'Remove'"
							(click)="compareProduct(productDetail)">{{productDetail.addRemoveCompare | lowercase |
							translate}}</a> -->

						<button
							*ngIf="productDetail.category != 'GFN' && productDetail.category != 'MODELS' && productDetail.category!='GAMING_SOFTWARE'"
							kuiButton [size]="'standard'" [danger]="false" [type]="'tertiary'" class="310"
							[attr.id]="('compare_'+productDetail.productUPC)"
							[class.compare]="productDetail.addRemoveCompare === 'Compare'"
							[class.remove]="productDetail.addRemoveCompare === 'Remove'"
							(click)="compareProduct(productDetail)">{{productDetail.addRemoveCompare | lowercase |
							translate}}</button>
					</div>
					<popover role="button" tabindex="0" *ngIf="productDetail.isOffer" [screenwidth]="screenWidth"
						[type]="'Game Bundle'" [offerLabel]="'game_bundle' | translate"
						[bundle]="productDetail.offerText"></popover>
				</div>
			</div>

			<div aria-label="Product hover effect"
				*ngIf="productDetail.digitialRiverID && (screenWidth === 'LG' || screenWidth === 'XL') && !locale"
				class="product-details-grid-overview" style="display: block;">
				<div kuiText *ngIf="productDetail.isFeaturedProduct"
					class="product-details-featured-product image-cont">{{
					'featured' | translate }}</div>
				<div kuiText *ngIf="!productDetail.isFeaturedProduct"
					class="product-details-featured-product image-cont" style="visibility: hidden">{{ 'featured' |
					translate }}</div>
				<div class="product-details-image-container">
					<a href='{{getPDPLink(productDetail)}}' target="_blank" rel="noopener noreferrer"
						*ngIf="productDetail.isPDP;else img_content">
						<kui-image aspectRatio="1-1">
							<img [title]="productDetail.category === 'GFN' || productDetail.category === 'MONITOR' || productDetail.category === 'MICE' || productDetail.category === 'MODELS' || productDetail.category === 'GAMING_SOFTWARE' ? productDetail.productTitle : productDetail.displayName.replace('No GPU','')"
								[attr.data-pid-code]="productDetail.productID"
								[attr.data-ctaType]="productDetail.prdStatus"
								[attr.data-productTitle]="productDetail.productTitle" src="{{productDetail.imageURL}}"
								[alt]="productDetail.category === 'GFN' || productDetail.category === 'MONITOR' || productDetail.category === 'MICE' || productDetail.category === 'MODELS' || productDetail.category === 'GAMING_SOFTWARE' ? productDetail.productTitle : productDetail.displayName.replace('No GPU','')"
								(load)="createThumbnail($event.target)" (error)="showImgError($event.target)"
								width="100%" height="100%" />
						</kui-image>
					</a>
					<ng-template #img_content>
						<kui-image aspectRatio="1-1">
							<img [title]="productDetail.category === 'GFN' || productDetail.category === 'MONITOR' || productDetail.category === 'MICE' || productDetail.category === 'MODELS' || productDetail.category === 'GAMING_SOFTWARE' ? productDetail.productTitle : productDetail.displayName.replace('No GPU','')"
								[attr.data-pid-code]="productDetail.productID"
								[attr.data-ctaType]="productDetail.prdStatus"
								[attr.data-productTitle]="productDetail.productTitle" src="{{productDetail.imageURL}}"
								[alt]="productDetail.category === 'GFN' || productDetail.category === 'MONITOR' || productDetail.category === 'MICE' || productDetail.category === 'MODELS' || productDetail.category === 'GAMING_SOFTWARE' ? productDetail.productTitle : productDetail.displayName.replace('No GPU','')"
								(load)="createThumbnail($event.target)" (error)="showImgError($event.target)"
								width="100%" height="100%" />
						</kui-image>
					</ng-template>
				</div>
				<a class="product-title" *ngIf="productDetail.isPDP;else title_content"
					href='{{getPDPLink(productDetail)}}' target="_blank" rel="noopener">
					<h2 kuiText class="product-name h--smallest" [innerHTML]="productDetail.productTitle"></h2>
				</a>
				<ng-template #title_content>
					<h2 kuiText class="product-name h--smallest" [innerHTML]="productDetail.productTitle"></h2>
				</ng-template>
				<div class="specs-container 339">
					<div *ngIf="productDetail.category==='GFN' || productDetail.category==='MODELS' || productDetail.category==='GAMING_SOFTWARE' "
						[innerHTML]="productDetail.productInfo[0].value"></div>
					<ul
						*ngIf="productDetail.category!='GFN' && productDetail.category!='MODELS' && productDetail.category!='GAMING_SOFTWARE'">
						<li *ngFor="let specs of productDetail.productInfo">
							<div class="specs p-medium p-medium">
								<div kuiText style="display: inline-block;">{{specs.name |
									translate}}:&nbsp;{{valueTrimmer(specs.value)}}
									{{(((productDetail.category==='LAPTOP' || productDetail.category==='STUDIO-LAPTOP')
									&& specs.name =='gpu')?'gpu_spec_sufix':'') | translate}}</div>
							</div>
						</li>
					</ul>
					<!-- <popover *ngIf="productDetail.isOffer" [screenwidth]="screenWidth" [type]="'Game Bundle'" [bundle]="productDetail.offerText"></popover>
					<br />
					<popover *ngIf="productDetail.shipInfo" [screenwidth]="screenWidth" [type]="'Availability'" [availability]="productDetail.shipInfo"></popover>
					-->
				</div>
				<div #drSpinnerCont *ngIf="productDetail.digitialRiverID" class="dr-spinner-bg-grid 201">
					<div #drId class="product-row__item js-product-item 189" data-analytics="btn-dr-id"
						[attr.data-digital-river-id]="changeDRId(productDetail.digitialRiverID)">
						<div #priceDivOverview class="price js-product-price clearfix h--smallest 203"></div>
						<div class="price  outside-page">
							<span kuiText
								[innerHtml]="extractDRPriceIntegerDot(priceDivOverview, drSpinnerCont)"></span>
							<span kuiText class="decimal" [innerHtml]="extractDRPriceDecimal(priceDivOverview)"></span>
						</div>
						<div class="cta-preorder mobile-top" (click)="transmitToCart()">
							<!-- Logic for NOTIFY ME -->
							<!-- DR NOTIFY ME -->
							<a kuiText *ngIf="productDetail.prdStatus === 'dr_notify_me'"
								[attr.data-pid-code]="productDetail.productID"
								[attr.data-ctaType]="'add_to_cart'"
								[attr.data-productTitle]="productDetail.productTitle"
								class="featured-buy-link link-btn brand-green  cta-button show-dr-notify-me 159 cta-link-large"
								href="javascript:void(0);">
								{{ 'add_to_cart' | translate }}
							</a>
							<!-- END OF DR NOTIFY ME -->
							<!-- NV NOTIFY ME -->
							<a kuiText *ngIf="productDetail.prdStatus === 'nv_notify_me'"
								[attr.data-pid-code]="productDetail.productID"
								[attr.data-ctaType]="'add_to_cart'"
								[attr.data-productTitle]="productDetail.productTitle"
								class="featured-buy-link link-btn brand-green  cta-button show-nv-notify-me cta-link-large"
								data-nvnotify-form-path="/content/forms/af/nvidia-forms/products-notify-me/consumer-notify-me"
								href="javascript:void(0);">
								{{ 'add_to_cart' | translate }}
							</a>
							<!-- END OF NV NOTIFY ME -->
							<a kuiText *ngIf="productDetail.prdStatus === 'buy_now'"
								[attr.data-pid-code]="productDetail.productID"
								[attr.data-ctaType]="'add_to_cart'"
								[attr.data-productTitle]="productDetail.productTitle"
								class="featured-buy-link link-btn brand-green  cta-button cta-link-large"
								href="javascript:void(0);">
								{{ 'add_to_cart' | translate }}
							</a>
							<a kuiText
								*ngIf="(productDetail.prdStatus === 'try_now' && productDetail.category === 'MODELS') || productDetail.category === 'GAMING_SOFTWARE'"
								[attr.data-pid-code]="productDetail.productID"
								[attr.data-ctaType]="productDetail.prdStatus"
								[attr.data-productTitle]="productDetail.productTitle"
								class="featured-buy-link link-btn brand-green  cta-button cta-link-large"
								target="_blank" [href]="productDetail.retailers[0].purchaseLink">
								{{ productDetail.prdStatus | translate }}
							</a>
							<!-- OUT OF STOCK -->
							<a kuiText *ngIf="productDetail.prdStatus === 'out_of_stock'"
								[attr.data-pid-code]="productDetail.productID"
								[attr.data-ctaType]="productDetail.prdStatus"
								[attr.data-productTitle]="productDetail.productTitle"
								class="featured-buy-link link-btn brand-green  cta-button show-out-of-stock cta-link-large"
								href="javascript:void(0);">
								{{ 'out_of_stock' | translate }}
							</a>
							<!-- END OF OUT OF STOCK -->
							<!-- <div class="product__message limit-per-customer">{{'limit_5' | translate}}</div> -->
							<div *ngIf="productDetail.retailers?.length > 0" class="buy fpc-226"
								[class.fe-pids]="productDetail.manufacturer==='NVIDIA'">
								<div [attr.class]="getEANCode(productDetail.productUPCOriginal)"
									[attr.id]="productDetail.productID" [attr.status]="productDetail.prdStatus"
									[attr.isFounderEdition]="productDetail.isFounderEdition" style="display:none">
									{{productDetail.retailers |
									json}}</div>
								<div>
									<a kuiText class="extra_style buy-link cta-link-larges 230 buy-frm-partner"
										[attr.data-pid-code]="productDetail.productID"
										[attr.data-ctaType]="productDetail.prdStatus"
										[attr.data-mpn-code]="getEANCode(productDetail.productUPCOriginal)"
										data-color="#76b900" data-secondary-color="#fff"
										(click)="productName(productDetail);">
										{{'buy_from_partners' | translate}}
									</a>
								</div>
							</div>
						</div>
						<div class="ship-date 226"></div>
					</div>
				</div>
				<div *ngIf="productDetail.digitialRiverID">
					<dr-loading-indicator [show]="drLoading"></dr-loading-indicator>
				</div>
				<div style="display: flex; justify-content: space-between; flex-wrap: wrap">
					<div class="compare-remove-container pdc-220"
						style="padding-top: 30px;padding-bottom: 30px; flex: 50%">
						<!-- <a *ngIf="productDetail.category != 'GFN'" kuiText class="cta-link-regular 221" [attr.id]="('compare_'+productDetail.productUPC)"
							[class.compare]="productDetail.addRemoveCompare === 'Compare'"
							[class.remove]="productDetail.addRemoveCompare === 'Remove'"
							(click)="compareProduct(productDetail)">{{productDetail.addRemoveCompare | lowercase |
							translate}}</a> -->
						<button
							*ngIf="productDetail.category != 'GFN' && productDetail.category != 'MODELS' && productDetail.category != 'GAMING_SOFTWARE'"
							kuiButton [size]="'standard'" [danger]="false" [type]="'tertiary'" class="310"
							[attr.id]="('compare_'+productDetail.productUPC)"
							[class.compare]="productDetail.addRemoveCompare === 'Compare'"
							[class.remove]="productDetail.addRemoveCompare === 'Remove'"
							(click)="compareProduct(productDetail)">{{productDetail.addRemoveCompare | lowercase |
							translate}}</button>

					</div>
					<popover role="button" tabindex="0" *ngIf="productDetail.isOffer" [screenwidth]="screenWidth"
						[type]="'Game Bundle'" [offerLabel]="'game_bundle' | translate"
						[bundle]="productDetail.offerText"></popover>
					<popover role="button" tabindex="0" *ngIf="productDetail.shipInfo" [screenwidth]="screenWidth"
						[type]="'Availability'" [availability]="productDetail.shipInfo"></popover>
				</div>
			</div>

		</div>
	</div>
	<!-- END OF GRID LAYOUT -->
	<!-- BEGINNING OF LIST VIEW -->
	<div class="product-details-container" *ngIf="layoutType === 'list'">
		<div class="product-details-list-tile" *ngFor="let productDetail of productDetails;">
			<div class="img-col-xl">
				<div kuiText *ngIf="productDetail.isFeaturedProduct"
					class="product-details-featured-product image-cont">
					{{'featured' | translate }}</div>
				<div class="product-details-image-container-list">
					<a href='{{getPDPLink(productDetail)}}' target="_blank" rel="noopener noreferrer"
						*ngIf="productDetail.isPDP;else img_content">
						<kui-image aspectRatio="1-1">
							<img #im [attr.data-pid-code]="productDetail.productID"
								[attr.data-ctaType]="productDetail.prdStatus"
								[attr.data-productTitle]="productDetail.productTitle"
								[title]="productDetail.category === 'GFN' || productDetail.category === 'MONITOR' || productDetail.category === 'MICE' || productDetail.category === 'MODELS' || productDetail.category === 'GAMING_SOFTWARE' ? productDetail.productTitle : productDetail.displayName.replace('No GPU','')"
								src="{{productDetail.imageURL}}"
								[alt]="productDetail.category === 'GFN' || productDetail.category === 'MONITOR' || productDetail.category === 'MICE' || productDetail.category === 'MODELS' || productDetail.category === 'GAMING_SOFTWARE' ? productDetail.productTitle : productDetail.displayName.replace('No GPU','')"
								class="featured-img" (window:resize)="createThumbnailList(im)"
								(load)="createThumbnailList(im)" (error)="showImgError($event.target)" width="100%"
								height="100%" />
						</kui-image>
					</a>
					<ng-template #img_content>
						<kui-image aspectRatio="1-1">
							<img #im [attr.data-pid-code]="productDetail.productID"
								[attr.data-ctaType]="productDetail.prdStatus"
								[attr.data-productTitle]="productDetail.productTitle"
								[title]="productDetail.category === 'GFN' || productDetail.category === 'MONITOR' || productDetail.category === 'MICE' || productDetail.category === 'MODELS' || productDetail.category === 'GAMING_SOFTWARE' ? productDetail.productTitle : productDetail.displayName.replace('No GPU','')"
								src="{{productDetail.imageURL}}"
								[alt]="productDetail.category === 'GFN' || productDetail.category === 'MONITOR' || productDetail.category === 'MICE' || productDetail.category === 'MODELS' || productDetail.category === 'GAMING_SOFTWARE' ? productDetail.productTitle : productDetail.displayName.replace('No GPU','')"
								class="featured-img" (window:resize)="createThumbnailList(im)"
								(load)="createThumbnailList(im)" (error)="showImgError($event.target)" width="100%"
								height="100%" />
						</kui-image>
					</ng-template>
				</div>
			</div>
			<div class="details-col">
				<div *ngIf="productDetail.isFeaturedProduct" class="product-details-featured-product"
					style="visibility: hidden">{{ 'featured' | translate }}</div>
				<a class="product-title" *ngIf="productDetail.isPDP;else title_content"
					href='{{getPDPLink(productDetail)}}' target="_blank" rel="noopener">
					<h2 kuiText class="product-name h--smallest" [innerHTML]="productDetail.productTitle"></h2>
				</a>
				<ng-template #title_content>
					<h2 kuiText class="product-name h--smallest" [innerHTML]="productDetail.productTitle"></h2>
				</ng-template>
				<div class="specs-container 473"
					[ngClass]="{'specs-container-list-laptop': productDetail.category === 'LAPTOP'}">
					<div *ngIf="productDetail.category==='GFN' || productDetail.category==='MODELS' || productDetail.category === 'GAMING_SOFTWARE'"
						[innerHTML]="productDetail.productInfo[0].value"></div>
					<ul
						*ngIf="productDetail.category!='GFN' && productDetail.category!='MODELS' && productDetail.category != 'GAMING_SOFTWARE'">
						<li *ngFor="let specs of productDetail.productInfo">
							<div kuiText class="specs p-medium p-medium">{{specs.name |
								translate}}:&nbsp;{{valueTrimmer(specs.value)}}
								{{(((productDetail.category==='LAPTOP' || productDetail.category==='STUDIO-LAPTOP') &&
								specs.name =='gpu')?'gpu_spec_sufix':'') | translate}}</div>
						</li>
					</ul>
					<div style="display: flex; justify-content: space-between;">
						<popover role="button" tabindex="0" *ngIf="productDetail.isOffer" [screenwidth]="screenWidth"
							[type]="'Game Bundle'" [offerLabel]="'game_bundle' | translate"
							[bundle]="productDetail.offerText"></popover>
						<popover role="button" tabindex="0" *ngIf="productDetail.shipInfo" [screenwidth]="screenWidth"
							[type]="'Availability'" [offerLabel]="'availability' | translate"
							[availability]="productDetail.shipInfo"></popover>
					</div>
				</div>
			</div>
			<div #drSpinnerCont class="buy-col-lg 253" [class.dr-spinner-bg]="productDetail.digitialRiverID">
				<div kuiText *ngIf="productDetail.isFeaturedProduct" class="product-details-featured-product"
					style="visibility: hidden">{{ 'featured' | translate }}</div>
				<div *ngIf="!productDetail.digitialRiverID">
					<div *ngIf="productDetail.category != 'MODELS' && productDetail.category != 'GAMING_SOFTWARE'"
						class="product-details-price-stock-list">
						<div kuiText class="price clearfix h--smallest 256"
							*ngIf="locale === 'ru-ru' || locale==='pl-pl' || locale =='vi-vn'"
							[innerHtml]="getFormatedPrice(productDetail.productPrice,locale)"></div>
						<div class="price clearfix h--smallest 257"
							*ngIf="locale !== 'ru-ru' && locale !=='pl-pl'  && locale !=='vi-vn'">
							<span kuiText [innerHtml]="getPriceIntegerDot(productDetail.productPrice)"></span><span
								kuiText class="decimal"
								[innerHtml]="getPriceDecimal(productDetail.productPrice)"></span>
						</div>
					</div>
					<div class="buy pdc-258" [class.fe-pids]="productDetail.manufacturer==='NVIDIA'">
						<div [attr.class]="getEANCode(productDetail.productUPCOriginal)"
							[attr.id]="productDetail.productID" [attr.status]="productDetail.prdStatus"
							[attr.isFounderEdition]="productDetail.isFounderEdition"
							style="display:none; visibility:hidden">
							{{productDetail.retailers | json}}</div>
						<div *ngIf="productDetail.prdStatus !== 'gf_notify_me'">
							<div *ngIf="productDetail.prdStatus !== 'upcoming'">
								<div
									*ngIf="productDetail.prdStatus !== 'check_availability'  && productDetail.prdStatus !== 'out_of_stock'">
									<a kuiText
										[ngClass]="{'model-buy-link': (productDetail.category === 'MODELS' || productDetail.category === 'GAMING_SOFTWARE'), 'buy-link': (productDetail.category !== 'MODELS' && productDetail.category !== 'GAMING_SOFTWARE')}"
										[attr.data-pid-code]="productDetail.productID"
										[attr.data-ctaType]="productDetail.prdStatus"
										[attr.data-mpn-code]="getEANCode(productDetail.productUPCOriginal)"
										data-color="#76b900" data-secondary-color="#fff" target="_blank"
										(click)="productName(productDetail); detectBuyClick();" [attr.mp]="836"
										[attr.href]="productDetail.category === 'MODELS' || productDetail.category === 'GAMING_SOFTWARE' ? productDetail.retailers[0].purchaseLink : undefined">{{productDetail.prdStatus|
										translate }}
									</a>
									<!-- <a 
										kuiText class="buy-link1" target="_blank"
											[attr.data-pid-code]="productDetail.productID"
[attr.data-ctaType]="productDetail.prdStatus"
											[attr.data-mpn-code]="getEANCode(productDetail.productUPCOriginal)"
											data-color="#76b900" data-secondary-color="#fff"
											(click)="openPDP(productDetail);">{{productDetail.prdStatus|
											translate }}
										</a> -->
									<!-- </ng-template> -->
								</div>
							</div>
						</div>
						<a kuiText *ngIf="productDetail.prdStatus === 'check_availability'"
							class="extra_style buy-link cta-link-large 269" styles=""
							[attr.data-pid-code]="productDetail.productID" [attr.data-ctaType]="productDetail.prdStatus"
							[attr.data-mpn-code]="getEANCode(productDetail.productUPCOriginal)" data-color="#76b900"
							data-secondary-color="#fff"
							(click)="productName(productDetail); detectBuyClick();">{{'check_availability' |
							translate}}
						</a>
						<div *ngIf="productDetail.prdStatus === 'gf_notify_me'"
							class="buy cta-preorder mobile-top lb_iframe"
							[attr.data-href]="getFormPath(locale, productDetail.productTitle)" href="#" target="overlay"
							onclick="NVIDIAGDC.button.click(this, $(this).data('href'),true, function() { ;return NVIDIAGDC.button.callbacks(this); }); return false;">
							<a kuiText [attr.data-pid-code]="productDetail.productID"
								[attr.data-ctaType]="productDetail.prdStatus"
								[attr.data-productTitle]="productDetail.productTitle"
								class="featured-buy-link link-btn brand-green cta-button show--me notify-me-btn cta-link-large"
								data-nvnotify-form-path="/content/forms/af/nvidia-forms/products-notify-me/consumer-notify-me"
								href="javascript:void(0);">{{'notify_me' | translate}}</a>
						</div>
						<a kuiText
							*ngIf="productDetail.prdStatus === 'upcoming' || productDetail.prdStatus === 'out_of_stock'"
							class="274 dr-spinner-bg link-btn brand-green  cta-button stock-grey-out cta-link-large"
							href="javascript:void(0);">
							{{ productDetail.prdStatus | translate }}
						</a>
					</div>
				</div>
				<!-- need to check this condition -->
				<div #drId *ngIf="productDetail.digitialRiverID" class="280 js-product-item" data-analytics="btn-dr-id"
					[attr.data-digital-river-id]="changeDRId(productDetail.digitialRiverID)">
					<div #priceDivList class="price  h--smallest js-product-price clearfix 305"></div>
					<div class="price h--smallest outside-page"> <span kuiText
							[innerHtml]="extractDRPriceIntegerDot(priceDivList, drSpinnerCont)"></span><span kuiText
							class="decimal" [innerHtml]="extractDRPriceDecimal(priceDivList)"></span> </div>
					<div class="buy cta-preorder mobile-top" (click)="transmitToCart()">
						<!-- Logic for NOTIFY ME -->
						<!-- DR NOTIFY ME -->
						<a kuiText *ngIf="productDetail.prdStatus === 'dr_notify_me'"
							[attr.data-pid-code]="productDetail.productID" [attr.data-ctaType]="'add_to_cart'"
							[attr.data-productTitle]="productDetail.productTitle"
							class="featured-buy-link link-btn brand-green  cta-button show-dr-notify-me cta-link-large 236  "
							href="javascript:void(0);">
							{{ 'add_to_cart' | translate }}
						</a>
						<!-- END OF DR NOTIFY ME -->
						<!-- NV NOTIFY ME -->
						<a kuiText *ngIf="productDetail.prdStatus === 'nv_notify_me'"
							[attr.data-pid-code]="productDetail.productID" [attr.data-ctaType]="'add_to_cart'"
							[attr.data-productTitle]="productDetail.productTitle"
							class="featured-buy-link link-btn brand-green  cta-button show-nv-notify-me cta-link-large"
							data-nvnotify-form-path="/content/forms/af/nvidia-forms/products-notify-me/consumer-notify-me"
							href="javascript:void(0);">
							{{ 'add_to_cart' | translate }}
						</a>
						<!-- END OF NV NOTIFY ME -->
						<a kuiText *ngIf="productDetail.prdStatus === 'buy_now'"
							[attr.data-pid-code]="productDetail.productID" [attr.data-ctaType]="'add_to_cart'"
							[attr.data-productTitle]="productDetail.productTitle"
							class="featured-buy-link link-btn brand-green  cta-button cta-link-large"
							href="javascript:void(0);">
							{{ 'add_to_cart' | translate }}
						</a>
						<a kuiText
							*ngIf="(productDetail.prdStatus === 'try_now' && productDetail.category === 'MODELS') || productDetail.category === 'GAMING_SOFTWARE'"
							[attr.data-pid-code]="productDetail.productID" [attr.data-ctaType]="productDetail.prdStatus"
							[attr.data-productTitle]="productDetail.productTitle"
							class="featured-buy-link link-btn brand-green  cta-button cta-link-large" target="_blank"
							[href]="productDetail.retailers[0].purchaseLink">
							{{ productDetail.prdStatus | translate }}
						</a>
						<!-- OUT OF STOCK -->
						<a kuiText *ngIf="productDetail.prdStatus === 'out_of_stock'"
							[attr.data-pid-code]="productDetail.productID" [attr.data-ctaType]="productDetail.prdStatus"
							[attr.data-productTitle]="productDetail.productTitle"
							class="featured-buy-link link-btn brand-green  cta-button show-out-of-stock cta-link-large"
							href="javascript:void(0);">
							{{ 'out_of_stock' | translate }}
						</a>
						<!-- END OF OUT OF STOCK -->
						<!-- <div class="product__message limit-per-customer">{{'limit_5' | translate}}</div> -->
						<div *ngIf="productDetail.retailers?.length > 0" class="buy fpc-66"
							[class.fe-pids]="productDetail.manufacturer==='NVIDIA'">
							<div [attr.class]="getEANCode(productDetail.productUPCOriginal)"
								[attr.id]="productDetail.productID" [attr.status]="productDetail.prdStatus"
								[attr.isFounderEdition]="productDetail.isFounderEdition" style="display:none">
								{{productDetail.retailers |
								json}}</div>
							<div>
								<a kuiText class="extra_style buy-link cta-link-larges 329 buy-frm-partner"
									[attr.data-pid-code]="productDetail.productID"
									[attr.data-ctaType]="productDetail.prdStatus"
									[attr.data-mpn-code]="getEANCode(productDetail.productUPCOriginal)"
									data-color="#76b900" data-secondary-color="#fff"
									(click)="productName(productDetail);">
									{{'buy_from_partners' | translate}}
								</a>
							</div>
						</div>
					</div>
					<div class="ship-date 329"></div>
				</div>
				<div *ngIf="productDetail.digitialRiverID">
					<dr-loading-indicator [show]="drLoading"></dr-loading-indicator>
				</div>
				<div *ngIf="productDetail.category != 'GFN' && productDetail.category != 'MODELS' && productDetail.category != 'GAMING_SOFTWARE'"
					class="compare-remove-container">
					<button kuiButton [size]="'standard'" [danger]="false" [type]="'tertiary'" class="310"
						[attr.id]="('compare_'+productDetail.productUPC)"
						[class.compare]="productDetail.addRemoveCompare === 'Compare'"
						[class.remove]="productDetail.addRemoveCompare === 'Remove'"
						(click)="compareProduct(productDetail)">{{productDetail.addRemoveCompare | lowercase |
						translate}}</button>

				</div>
			</div>
		</div>
	</div>
</div>
import { catchError, switchMap, map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Observable, of as observableOf } from "rxjs";

import { Store, Action } from "@ngrx/store";
import { createEffect, Actions, ofType } from "@ngrx/effects";
import * as productActions from "./product.actions";
import { ProductService } from "./product.service";
import { State } from "../reducers";
import { Router, ActivatedRoute, RoutesRecognized } from "@angular/router";

@Injectable()
export class ProductEffects {
  constructor(
    private actions$: Actions,
    private productService: ProductService,
    private store: Store<State>,
    private router: Router,
    private route: ActivatedRoute
  ) {}

   loadProducts$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(productActions.ActionTypes.LOAD_PRODUCT),
    map((action: any) => action.payload),
    switchMap((payload) => {
      return this.productService
        .getProduct(payload.productSearchCriteria, payload.refreshRequired)
        .pipe(
          map((res: any) => {
            if (res.searchedProducts.productDetails) {
              if (
                payload.productSearchCriteria.category &&
                payload.productSearchCriteria.category.split(",").length ==1
              ) {
                const cat_traslation ={
                  gpu: "graphics-cards",
                  laptop: "gaming-laptops",
                  "studio-laptop": "studio-laptops",
                  desktop: "gaming-desktops",
                  monitor: "gaming-monitors",
                  nvlinks: "nvlinks",
                  mobile_workstation: "mobile-workstations",
                  desktop_workstation: "desktop-workstations",
                  creator_laptops: "creator-laptops",
                  mice: "mice",
                  gfn: "gfn",
                  gaming_software: "gaming-software",
                  "rtx-laptop": "rtx-laptops",
                  studio_desktop: "studio-desktops",
                  models: "gaming-models"
                };
                this.router.navigate(
                  [
                    `/${
                      payload.locale
                    }/consumer/${cat_traslation[payload.productSearchCriteria.category.toLowerCase()]}`,
                  ],
                  {
                    relativeTo: this.route,
                    queryParams: payload.productSearchCriteria,
                  }
                );
              } else {
                this.router.navigate([`/${payload.locale}/consumer`], {
                  relativeTo: this.route,
                  queryParams: payload.productSearchCriteria,
                });
              }
              return new productActions.LoadProductComplete(res);
            } else {
              this.router.navigate([
                `https://www.nvidia.com/en-us/consumer/page-not-found`,
              ]);
            }
          }),
          catchError(() =>
            observableOf(
              new productActions.LoadProductError({
                message: "Failed To Load Products.",
              })
            )
          )
        );
    })
  ));

   loadRetailInfo$ = createEffect(() => this.actions$.pipe(
    ofType(productActions.ActionTypes.LOAD_RETAIL_INFO),
    map((action: any) => action.payload),
    switchMap((payload) =>
      this.productService.getRetailInfo(payload).pipe(
        map((res: any) => {
          return new productActions.LoadRetailInfoComplete(res);
        }),
        catchError(() =>
          observableOf(
            new productActions.LoadRetailInfoError({
              message: "Failed To Load Retail Info.",
            })
          )
        )
      )
    )
  ));

  // @Effect() loadHeaderInfo$ = this.actions$
  //   .ofType(productActions.ActionTypes.LOAD_HEADER_INFO)
  //   .map((action: any) => action.payload)
  //   .switchMap(payload => this.productService.getHeaderData(payload)
  //     .map((res: any) => {
  //       return new productActions.LoadHeaderInfoComplete(res.json());
  //     })
  //     .catch(() => Observable.of(new productActions.LoadHeaderInfoError({ message: 'Failed To Load Header Info.' })))
  //   );
}

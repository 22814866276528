
<nav role="navigation" class="filters">
  

  <form>
    <!-- <button #filtersReset kuiButton [size]="'large'" [danger]="false" [type]="'primary'"
      style="margin-bottom: 25px;"
      name="filtersReset"
      aria-label="filters Reset"
      class="screen-lg-xl  cta-link-regular 26-pfc chips_filterchip"
      [class.reset-filter-disabled]="disableReset"
      (click)="resetFilters($event)" *ngIf="screenWidth != 'SM' && locale !== 'en-us' ">{{ 'clear_all' | translate }}
      
      <img alt="cross icon" loading="lazy" width="20" height="20" decoding="async" data-nimg="1" src="../../../assets/images/cross_filter.svg" style="color: transparent; cursor: pointer;">
    
    </button> -->

    <!-- <button #filtersReset kuiButton [size]="'large'" [danger]="false" [type]="'primary'"
      style="margin-bottom: 25px;"
      name="filtersReset"
      aria-label="filters Reset"
      class="screen-lg-xl reset-filter cta-link-regular 26-pfc"
      [class.reset-filter-disabled]="disableReset"
      (click)="resetFilters($event)" *ngIf="screenWidth != 'SM' && locale !== 'en-us'">{{ 'reset_filter' | translate }}</button> -->
    <ul class="filter-list" >
      <fieldset
        tabindex="0"
        id="{{ filterItem.filterField }}"
        [ngClass]="{ md: screenWidth === 'MD', sm: screenWidth === 'SM' }"
        class="filter-item medium-6 small-12"
        *ngFor="
          let filterItem of productFilter;
          let filterItemIndex = index
        "
      >
        <div
          *ngIf="
            filterItem.displayName !== 'price' ||
            filterItem.defaultMinRangeVal !== filterItem.defaultMaxRangeVal
          "
        >
          <legend kuiText class="filter-title nv-legend" *ngIf="filterItem.displayName !== 'sff_ready' " (click)="toggleAccordion(filterItem.filterField)">
            {{ filterItem.displayName | lowercase | translate }}
            <span class="nv-arrowIcon" [class.rotate]="accordionStates[filterItem.filterField]"></span>
          </legend>

          <legend kuiText class="filter-title nv-legend" style="display: none;" *ngIf="filterItem.displayName === 'sff_ready' ">
            {{ filterItem.displayName | lowercase | translate }}
            <span class="nv-arrowIcon" [class.rotate]="accordionStates[filterItem.filterField]"></span>
          </legend>
          
          <!-- <legend kuiText class="filter-title nv-legend 112" *ngIf="filterItem.displayName === 'sff_ready' ">
            <div class="filter-content">
              <div class="c-checkbox c-checkbox--normal">
                <ul class="filter-values">
                  <li
                    class="filter-value"
                    *ngFor="
                      let values of filterItem?.filterValues
                        | slice
                          : 0
                          : getMaxToShow(
                              filterItemIndex,
                              filterItem?.filterValues
                            );
                      let i = index
                    "
                  >
                 <input kuiCheckbox 
                    class="sr-only"
                    type="checkbox"
                    id="{{ filterItem.filterField }}-{{ i }}"
                    name="{{ filterItem.filterField }}-{{ i }}"
                    (change)="
                      changed(
                        filterItem,
                        values,
                        $event.currentTarget.checked, i
                      )
                    "
                    [value]="values.checked"
                    [checked]="values.checked"
                  />   
                  <label class="c-checkbox__label" for="{{ filterItem.filterField }}-{{ i }}">
                    <span kuiText> {{ filterItem.displayName | lowercase | translate }} </span>
                  </label> 
                  </li>
                </ul>                 
              </div>  
            </div>


          </legend> -->
          
          <div *ngIf="filterItem.fieldType === 'checkbox' || filterItem.fieldType === 'Checkbox'">
            
            <div class="filter-content {{filterItem.filterField}}" *ngIf="accordionStates[filterItem.filterField] && filterItem.displayName !== 'sff_ready'" >
              <ul class="filter-values">
                <li
                  class="filter-value"
                  *ngFor="
                    let values of filterItem?.filterValues
                      | slice
                        : 0
                        : getMaxToShow(
                            filterItemIndex,
                            filterItem?.filterValues
                          );
                    let i = index
                  "
                >
                  <div class="c-checkbox c-checkbox--normal">
                    <input kuiCheckbox 
                      class="sr-only"
                      type="checkbox"
                      id="{{ filterItem.filterField }}-{{ i }}"
                      name="{{ filterItem.filterField }}-{{ i }}"
                      (change)="
                        changed(
                          filterItem,
                          values,
                          $event.currentTarget.checked, i
                        )
                      "
                      [value]="values.checked"
                      [checked]="values.checked"
                    />
                    <label
                      class="c-checkbox__label"
                      for="{{ filterItem.filterField }}-{{ i }}"
                    >
                      <span kuiText 
                        *ngIf="
                          filterItem.filterField !==
                            'customer_rating_filter' &&
                          filterItem.dataType !== 'Boolean' &&
                          filterItem.displayName !== 'category'
                        "
                        >{{ values.dispValue | translate }}</span
                      >
                      <span kuiText 
                        *ngIf="
                          filterItem.filterField !==
                            'customer_rating_filter' &&
                          filterItem.dataType !== 'Boolean' &&
                          filterItem.displayName === 'category'
                        "
                        >{{
                          filterItem.displayName + "_" + values.dispValue
                            | lowercase
                            | translate
                        }}</span
                      >
                      <span kuiText 
                        *ngIf="
                          filterItem.filterField !==
                            'customer_rating_filter' &&
                          filterItem.dataType === 'Boolean'
                        "
                        >{{ values.dispValueDesription | translate }}</span
                      >
                      <rating-generator
                        *ngIf="
                          filterItem.filterField ===
                          'customer_rating_filter'
                        "
                        [points]="values.dispValue"
                      ></rating-generator>
                      <!-- <span *ngIf="filterItem.showCount  && filterItem.displayName !== 'category'" class="float-right">({{values.units}})</span> -->
                    </label>
                    <span kuiText 
                    *ngIf="filterItem.displayName === 'category' && filterItem.showCount &&  values.units!== 0"
                    class="float-right"
                    >({{ values.units }})</span>

                  <span kuiText 
                    *ngIf="filterItem.displayName != 'category' && filterItem.showCount"
                    class="float-right">({{ values.units }})</span>
                  </div>
                  <button 
                    *ngIf="
                      i === getMaxToShow(filterItemIndex) - 1 &&
                      filterItem.filterValues?.length > maxToShow
                    "
                    [class.show-more-content]="
                      showMore(filterItemIndex, filterItem?.filterValues)
                    "
                    [class.show-less-content]="
                      !showMore(filterItemIndex, filterItem?.filterValues)
                    "
                    (click)="
                      toggleShowMoreLess(
                        filterItemIndex,
                        filterItem.filterValues?.length
                      )
                    "
                  >
                    <span kuiText class="title">{{
                      getShowMoreLessLabel(filterItemIndex) | translate
                    }}</span>
                  </button>
                </li>
              </ul>
            </div>

            <div class="filter-content-sff" *ngIf="locale=='en-us' && filterItem.displayName === 'sff_ready'" >
              <ul class="filter-values">
                <li
                  class="filter-value"
                  *ngFor="
                    let values of filterItem?.filterValues
                      | slice
                        : 0
                        : getMaxToShow(
                            filterItemIndex,
                            filterItem?.filterValues
                          );
                    let i = index
                  "
                >
                  <div class="c-checkbox c-checkbox--normal">
                    <input kuiCheckbox 
                      class="sr-only"
                      type="checkbox"
                      id="{{ filterItem.filterField }}-{{ i }}"
                      name="{{ filterItem.filterField }}-{{ i }}"
                      (change)="
                        changed(
                          filterItem,
                          values,
                          $event.currentTarget.checked, i
                        )
                      "
                      [value]="values.checked"
                      [checked]="values.checked"
                    />
                    <label
                      class="c-checkbox__label"
                      for="{{ filterItem.filterField }}-{{ i }}"
                    >
                      <span style="font-weight: bold;" kuiText 
                        *ngIf="
                          filterItem.filterField !==
                            'customer_rating_filter' &&
                          filterItem.dataType !== 'Boolean' &&
                          filterItem.displayName !== 'category'
                        "
                        >{{ values.dispValue | translate }}</span
                      >
                      <span kuiText 
                        *ngIf="
                          filterItem.filterField !==
                            'customer_rating_filter' &&
                          filterItem.dataType !== 'Boolean' &&
                          filterItem.displayName === 'category'
                        "
                        >{{
                          filterItem.displayName + "_" + values.dispValue
                            | lowercase
                            | translate
                        }}</span
                      >
                      <span kuiText 
                        *ngIf="
                          filterItem.filterField !==
                            'customer_rating_filter' &&
                          filterItem.dataType === 'Boolean'
                        "
                        >{{ values.dispValueDesription | translate }}</span
                      >
                      <rating-generator
                        *ngIf="
                          filterItem.filterField ===
                          'customer_rating_filter'
                        "
                        [points]="values.dispValue"
                      ></rating-generator>
                      <!-- <span *ngIf="filterItem.showCount  && filterItem.displayName !== 'category'" class="float-right">({{values.units}})</span> -->
                    </label>
                    <span kuiText 
                    *ngIf="filterItem.displayName === 'category' && filterItem.showCount &&  values.units!== 0"
                    class="float-right"
                    >({{ values.units }})</span>

                  <span kuiText 
                    *ngIf="filterItem.displayName != 'category' && filterItem.showCount"
                    class="float-right">({{ values.units }})</span>
                  </div>
                  <button 
                    *ngIf="
                      i === getMaxToShow(filterItemIndex) - 1 &&
                      filterItem.filterValues?.length > maxToShow
                    "
                    [class.show-more-content]="
                      showMore(filterItemIndex, filterItem?.filterValues)
                    "
                    [class.show-less-content]="
                      !showMore(filterItemIndex, filterItem?.filterValues)
                    "
                    (click)="
                      toggleShowMoreLess(
                        filterItemIndex,
                        filterItem.filterValues?.length
                      )
                    "
                  >
                    <span kuiText class="title">{{
                      getShowMoreLessLabel(filterItemIndex) | translate
                    }}</span>
                  </button>
                </li>
              </ul>
            </div>

          </div>
          <div *ngIf="filterItem.fieldType === 'range'">
            <div class="filter-content">
              <range-slider
                [screenWidth]="screenWidth"
                [rangeSliderInput]="filterItem"
                (filterRangeInputChange)="changed(type, range, $event, i)"
              ></range-slider>
            </div>
          </div>
        </div>

      </fieldset>
    </ul>
  </form>
</nav>
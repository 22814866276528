import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Location } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router'
import { TranslateService } from '@ngx-translate/core';
import { ScriptService } from './shared/service/script.service';
import * as reducers from './reducers';
import * as productActions from './products/product.actions';
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {

  locale: string;
  loadAPI: Promise<any>;
  productSearchCriteria = {
    page: 1,
    limit: 12,
    locale: "",
  };

  constructor(private router: Router, private scripts: ScriptService,
    private translate: TranslateService, private store: Store<reducers.State>) {

    this.locale = window.location.pathname.split('/')[1]

    if (this.locale === "en-ca") {
      $("html").attr("lang", "en-ca");
    } else if (this.locale === "fr-ca") {
      $("html").attr("lang", "fr-ca");
    } else if (this.locale === "en-gb") {
      $("html").attr("lang", "en-gb");
    } else if (this.locale === "en-us") {
      $("html").attr("lang", "en-us");
    } else if (this.locale === "de-de") {
      $("html").attr("lang", "de-de");
    } else if (this.locale == "fr-fr") {
      $("html").attr("lang", "fr-fr");
    } else if (this.locale == "en-au") {
      $("html").attr("lang", "en-au");
    } else if (this.locale == "ru-ru") {
      $("html").attr("lang", "ru-ru");
    } else if (this.locale == "pl-pl") {
      $("html").attr("lang", "pl-pl");
    } else if (this.locale == "es-es") {
      $("html").attr("lang", "es-es");
    } else if (this.locale == "nl-nl") {
      $("html").attr("lang", "nl-nl");
    } else if (this.locale == "it-it") {
      $("html").attr("lang", "it-it");
    } else if (this.locale === "zh-tw") {
      $("html").attr("lang", "zh-tw");
    } else if (this.locale === "de-at") {
      $("html").attr("lang", "de-at");
    } else if (this.locale === "id-id") {
      $("html").attr("lang", "id-id");
    }
    else if (this.locale === "vi-vn") {
      $("html").attr("lang", "vi-vn");
    }
    if (this.locale === 'en-us') {
      this.loadAPI = new Promise((resolve) => {
        this.enableQueueIt();
      });
    }
    this.loadAPI = new Promise((resolve) => {
    this.enableDynamicScript();
  });

  }

  enableQueueIt() {
    let interceptDomain = "api.store.nvidia.com";
    let domain_prefix = window.location.host.split(".")[0];
    if (domain_prefix != "store") {
      interceptDomain = domain_prefix.split("-")[0] + "-" + interceptDomain;
    }
    let queueit_client = document.createElement('script');
    queueit_client['src'] = '//static.queue-it.net/script/queueclient.min.js';
    queueit_client.type = 'text/javascript';
    document.getElementsByTagName('head')[0].appendChild(queueit_client);
    let queueit_config = document.createElement('script');
    queueit_config.src = '//static.queue-it.net/script/queueconfigloader.min.js';
    queueit_config.type = 'text/javascript';
    queueit_config.setAttribute("data-queueit-c", "nvidia");
    queueit_config.setAttribute('data-queueit-intercept-domain', interceptDomain);
    queueit_config.setAttribute('data-queueit-intercept', "true");
    document.getElementsByTagName('head')[0].appendChild(queueit_config);
  }



  // apiUri = ""

  enableDynamicScript() {
    let apiUri = "";
	
    // apicall
    const hostUri = window.location.host;
    if (hostUri === "localhost:4200") {
    apiUri = "https://stg-api.nvidia.partners/tags/v1/tag?storeName=CMP";
    }
    if (hostUri === "dev-store.nvidia.com") {
    apiUri = "https://stg-api.nvidia.partners/tags/v1/tag?storeName=CMP";
    }
    if (hostUri === "stg-store.nvidia.com") {
    apiUri = "https://stg-api.nvidia.partners/tags/v1/tag?storeName=CMP";
    }
    if (hostUri === "store.nvidia.com") {
    apiUri = "https://api.nvidia.partners/tags/v1/tag?storeName=CMP";
    }
    // apicall
    const xhttpr = new XMLHttpRequest();
    xhttpr.open("GET", apiUri, true);

    xhttpr.send();
      

    xhttpr.onload = () => {
      if (xhttpr.status === 200) {

        const response = JSON.parse(xhttpr.response);

        response?.forEach((element) => {
          if (element.isActive) {
            let tags = document.createElement("script");
                tags.setAttribute("async", "");
                if (Object.keys(element?.attributes).length > 0) {
                  Object.keys(element?.attributes).forEach((attr) => {
                    tags.setAttribute(attr, element?.attributes[attr]);
                  });
                }

            document.getElementsByTagName("head")[0].appendChild(tags);
          }
        });
      } else {
        // Handle error
        console.log("no adobe--script-->");

      }
    };
  }

  ngOnInit() {
    // cart hide
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.locale = e.url.split("/")[1];
        this.translate.setDefaultLang('en-us');
        this.translate.use(this.locale);
      }
    });
    window.addEventListener("pageshow", function (evt) {
      if (evt.persisted) {
        setTimeout(function () {
          window.location.reload();
        }, 0);
      }
    }, false);
  }

  translate2(text, locale) {
    const translations = {
      'da-dk': {
          'Search Marketplace': 'Søg i Marketplace'
      },
      'de-at': {
          'Search Marketplace': 'Marktplatz durchsuchen'
      },
      'de-de': {
          'Search Marketplace': 'Marktplatz durchsuchen'
      },
      'en-au': {
          'Search Marketplace': 'Search Marketplace'
      },
      'en-ca': {
          'Search Marketplace': 'Search Marketplace'
      },
      'en-gb': {
          'Search Marketplace': 'Search Marketplace'
      },
      'en-in': {
          'Search Marketplace': 'Search Marketplace'
      },
      'en-my': {
          'Search Marketplace': 'Search Marketplace'
      },
      'en-ph': {
          'Search Marketplace': 'Search Marketplace'
      },
      'en-sg': {
          'Search Marketplace': 'Search Marketplace'
      },
      'es-ar': {
          'Search Marketplace': 'Buscar en el Mercado'
      },
      'es-es': {
          'Search Marketplace': 'Buscar en el Mercado'
      },
      'es-la': {
          'Search Marketplace': 'Buscar en el Mercado'
      },
      'es-mx': {
          'Search Marketplace': 'Buscar en el Mercado'
      },
      'fi-fi': {
          'Search Marketplace': 'Hae Markkinapaikka'
      },
      'fr-ca': {
          'Search Marketplace': 'Rechercher dans le Marketplace'
      },
      'fr-fr': {
          'Search Marketplace': 'Rechercher dans le Marketplace'
      },
      'id-id': {
          'Search Marketplace': 'Cari'
      },
      'it-it': {
          'Search Marketplace': 'Cerca nel Marketplace'
      },
      'ja-jp': {
          'Search Marketplace': '検索'
      },
      'ko-kr': {
          'Search Marketplace': '검색'
      },
      'nb-no': {
          'Search Marketplace': 'Søk i Markedsplassen'
      },
      'nl-nl': {
          'Search Marketplace': 'Zoek in de Marktplaats'
      },
      'pl-pl': {
          'Search Marketplace': 'Szukaj na Marketplace'
      },
      'pt-br': {
          'Search Marketplace': 'Buscar no Marketplace'
      },
      'ru-ru': {
          'Search Marketplace': 'Поиск на Маркетплейсе'
      },
      'sv-se': {
          'Search Marketplace': 'Sök på Marknadsplatsen'
      },
      'th-th': {
          'Search Marketplace': 'ค้นหา'
      },
      'vi-vn': {
          'Search Marketplace': 'Tìm kiếm'
      },
      'zh-tw': {
          'Search Marketplace': '查詢'
      },
      'en-us': {
          'Search Marketplace': 'Search Marketplace'
      }
  };

    // Return the translation if it exists, otherwise return the original text
    return translations[locale] && translations[locale][text] ? translations[locale][text] : text;
}

  ngAfterViewInit() {
    var searchBox: HTMLInputElement = document.getElementById('subNav-searchBox') as HTMLInputElement;
    var searchButton = document.getElementById('subNav-searchSubmit');
    var removeButton = document.getElementById('subNav-searchRemove');
    let params = new URL(window.location.href).searchParams;
    let searchValue = params.get("search");

    let emptySearchPayload = {
      fieldType: "search",
      value: ""
    };

    let defaultPayload = {
      page: 1,
      limit: 12,
      locale: this.locale,
    };

    if (searchBox && searchButton && removeButton) {
      if (searchValue) {
        searchBox.value = searchValue;
        removeButton.style.display = searchBox.value ? '' : 'none';
      }

      searchButton.addEventListener('click', () => {
        let payload = {
          fieldType: "search",
          value: searchBox?.value?.trim()
        };

        this.store.dispatch(
          new productActions.ClearProductFilterSelection(defaultPayload)
        );
        this.store.dispatch(new productActions.UpdateProductParams(payload));
      });

      searchBox.addEventListener('keypress', (e) => {
        if (e.key === 'Enter') {
          let payload = {
            fieldType: "search",
            value: searchBox?.value?.trim()
          };

          this.store.dispatch(
            new productActions.ClearProductFilterSelection(defaultPayload)
          );
          this.store.dispatch(new productActions.UpdateProductParams(payload));
        }
      });

      removeButton.addEventListener('click', () => {
        searchBox.value = '';
        this.store.dispatch(
          new productActions.ClearProductFilterSelection(defaultPayload)
        );
        this.store.dispatch(new productActions.UpdateProductParams(emptySearchPayload));
        removeButton.style.display = 'none';
      });

      searchBox.addEventListener('input', () => {
        removeButton.style.display = searchBox.value ? '' : 'none';
      });
    }

    else {
      const observer = new MutationObserver(() => {
        var searchBox: HTMLInputElement = document.getElementById('subNav-searchBox') as HTMLInputElement;
        // console.log("-----searchBox--->", searchBox)
        // Change the placeholder attribute
        // searchBox.placeholder = this.translate2(searchBox.placeholder);
        if(searchBox){
          searchBox.placeholder = this.translate2(searchBox.placeholder, this.locale);
        }
        var searchButton = document.getElementById('subNav-searchSubmit');
        var removeButton = document.getElementById('subNav-searchRemove');
        let params = new URL(window.location.href).searchParams;
        let searchValue = params.get("search");

        let emptySearchPayload = {
          fieldType: "search",
          value: ""
        };

        let defaultPayload = {
          page: 1,
          limit: 12,
          locale: this.locale,
        };

        if (searchBox && searchButton && removeButton) {
          observer.disconnect();

          if (searchValue) {
            searchBox.value = searchValue;
            removeButton.style.display = searchBox.value ? '' : 'none';
          }

          searchButton.addEventListener('click', () => {
            let payload = {
              fieldType: "search",
              value: searchBox?.value?.trim()
            };

            this.store.dispatch(
              new productActions.ClearProductFilterSelection(defaultPayload)
            );
            this.store.dispatch(new productActions.UpdateProductParams(payload));
          });

          searchBox.addEventListener('keypress', (e) => {
            if (e.key === 'Enter') {
              let payload = {
                fieldType: "search",
                value: searchBox?.value?.trim()
              };

              this.store.dispatch(
                new productActions.ClearProductFilterSelection(defaultPayload)
              );
              console.log(payload);
              this.store.dispatch(new productActions.UpdateProductParams(payload));
            }
          });

          removeButton.addEventListener('click', () => {
            searchBox.value = '';
            this.store.dispatch(
              new productActions.ClearProductFilterSelection(defaultPayload)
            );
            this.store.dispatch(new productActions.UpdateProductParams(emptySearchPayload));
            removeButton.style.display = 'none';
          });

          searchBox.addEventListener('input', () => {
            removeButton.style.display = searchBox.value ? '' : 'none';
          });
        }
      })
      observer.observe(document, { childList: true, subtree: true });
    }
  }
}

<form (ngSubmit)="onSubmit(searchSortForm)" [formGroup]="searchSortForm" id="searchform">
  <div
    *ngIf="screenWidth === 'SM' || screenWidth === 'MD'"
    class="form-row">
    <div class="search-form-container form-group">
      <input id="srchBox" aria-label="search input"
        class="search-input search-input-text search-helper-text form-control" type="text" formControlName="search"
        autocomplete="off" placeholder="{{ 'search_placeholder' | translate }}">
      <input type="submit" class="submit-button" aria-required="true">
      <a role="link" aria-label="clear search" href="javascript:void(0);"
        class="form-control-clear glyphicon glyphicon-remove-form form-control-feedback hidden"
        id="removeButtonSMMD"
        (click)="clearSearch(searchSortForm)"></a>

    </div>
  </div>
  <div class="form-row">
    <div *ngIf="screenWidth !== 'SM'"class="bounding-box" class='bounding-box nv-boundingBox'>
      <suggested-product  [screenWidth]="screenWidth" [suggestedProduct]="suggestedProduct"
          [totalProducts]="totalProducts" [productSearchTerm]="productSearch"></suggested-product>

      <div class=sort-section class="sort-section nv-sortBy">
        <!-- <label *ngIf="productSort.length && locale !== 'en-us'" for="sortfilter" kuiText tabindex="0" class="sort-label">{{ 'sort_by' |
          translate }}:</label> -->
        <simple-dropdown *ngIf="productSort.length" [totalProducts]="totalProducts" [productSort]="productSort" [locale]="locale"
          (optionChange)="onSort($event)"></simple-dropdown>
      </div>

      <div class="search-container grid-container {{screenWidth}}" class="nv-viewIcons" *ngIf="screenWidth!='SM'">
        <!-- <label *ngIf="locale !== 'en-us'" class="search-label" kuiText>{{ 'view' | translate }}:</label> -->
        <button kuiButton type="button" aria-label="grid view" (click)="updateView($event, 'grid')" [size]="'tiny'"
          [danger]="false" [type]="'tertiary'"
          [ngClass]="currentView === 'grid' ? 'grid-view-active' : 'grid-view-default'"></button>
          <button kuiButton type="button" aria-label="list view" (click)="updateView($event, 'list')" [size]="'tiny'"
          [danger]="false" [type]="'tertiary'"
          [ngClass]="currentView === 'list' ? 'list-view-active' : 'list-view-default'"></button>
      </div>
    </div>

    <div *ngIf="screenWidth === 'SM'" style="margin-top: 10px; margin-bottom: 10px;" class="bounding-box" class='bounding-box nv-boundingBox'>
      <div sort-section class="sort-section nv-sortBy">
        <!-- <label *ngIf="productSort.length && locale !== 'en-us'" for="sortfilter" kuiText tabindex="0" class="sort-label">{{ 'sort_by' |
          translate }}:</label> -->
        <simple-dropdown *ngIf="productSort.length" [totalProducts]="totalProducts" [productSort]="productSort" [locale]="locale"
          (optionChange)="onSort($event)"></simple-dropdown>
      </div>

      <suggested-product [screenWidth]="screenWidth" [suggestedProduct]="suggestedProduct"
          [totalProducts]="totalProducts" [productSearchTerm]="productSearch"></suggested-product>

      <div class="search-container grid-container {{screenWidth}}" class="nv-viewIcons" *ngIf="screenWidth!='SM'">
        <!-- <label *ngIf="locale !== 'en-us'" class="search-label" kuiText>{{ 'view' | translate }}:</label> -->
        <button kuiButton type="button" aria-label="grid view" (click)="updateView($event, 'grid')" [size]="'tiny'"
          [danger]="false" [type]="'tertiary'"
          [ngClass]="currentView === 'grid' ? 'grid-view-active' : 'grid-view-default'"></button>
          <button kuiButton type="button" aria-label="list view" (click)="updateView($event, 'list')" [size]="'tiny'"
          [danger]="false" [type]="'tertiary'"
          [ngClass]="currentView === 'list' ? 'list-view-active' : 'list-view-default'"></button>
      </div>
    </div>


  </div>
</form>
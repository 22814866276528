  <div class="total-products-text"  *ngIf="totalProducts > 0" style='padding-top: 0px'>
    <div *ngIf="productSearchTerm && locale !=='ru-ru' && locale !=='th-th'">{{totalProducts}} {{ 'search_results_found' | translate | lowercase }} '<b>{{productSearchTerm}}</b>'</div>
    <div *ngIf="productSearchTerm && locale ==='ru-ru'">{{ 'search_results_found_part1' | translate | lowercase }} '<b>{{productSearchTerm}}</b>' {{ 'search_results_found_part2' | translate | lowercase }}: {{totalProducts}} </div>
    <div *ngIf="productSearchTerm && locale ==='th-th'">{{ 'search_results_found' | translate | lowercase }} {{totalProducts}} '<b>{{productSearchTerm}}</b>' {{ 'search_results_found_part1' | translate | lowercase }} </div>
<div *ngIf="!productSearchTerm && !suggestedProduct.length && locale ==='th-th' && totalProducts != 1 && totalProducts != 0" style="padding-bottom: 30px; font-weight: bold">{{"filter_results_found" | translate | lowercase}} {{totalProducts}} {{"filter_results_found-2" | translate | lowercase}}</div>
	<div *ngIf="!productSearchTerm && !suggestedProduct.length && locale !=='ru-ru' && locale !=='th-th' && totalProducts != 1 && totalProducts != 0" style="padding-bottom: 0px; font-weight: bold">{{totalProducts}} {{"filter_results_found" | translate | lowercase}}</div>
	<div *ngIf="!productSearchTerm && !suggestedProduct.length && locale ==='ru-ru' && totalProducts != 1 && totalProducts != 0" style="padding-bottom: 30px; font-weight: bold">{{"filter_results_found" | translate | lowercase}} {{totalProducts}} </div>

	<div *ngIf="!productSearchTerm && !suggestedProduct.length && locale ==='th-th' && totalProducts == 1" [ngStyle]="{'padding-bottom': '0px', 'font-weight': 'bold'}">{{"filter_results_found_one" | translate | lowercase}} {{totalProducts}} {{"filter_results_found-2" | translate | lowercase}}</div>
	<!-- <div *ngIf="!productSearchTerm && !suggestedProduct.length && locale ==='th-th' && totalProducts == 0" [ngStyle]="locale === 'en-us' ? {'padding-bottom': '0px', 'font-weight': 'bold'} : {'padding-bottom': '30px', 'font-weight': 'bold'}">{{"filter_results_found_zero" | translate | lowercase}} {{totalProducts}} {{"filter_results_found-2" | translate | lowercase}}</div> -->

	<div *ngIf="!productSearchTerm && !suggestedProduct.length && locale !=='ru-ru' && locale !=='th-th' && totalProducts == 1" style="padding-bottom: 0px; font-weight: bold;">{{totalProducts}} {{"filter_results_found_one" | translate | lowercase}}</div>
	<!-- <div *ngIf="!productSearchTerm && !suggestedProduct.length && locale !=='ru-ru' && locale !=='th-th' && totalProducts == 0" [ngStyle]="locale === 'en-us' ? {'padding-bottom': '0px', 'font-weight': 'bold'} : {'padding-bottom': '30px', 'font-weight': 'bold'}">{{"filter_results_found_zero" | translate | lowercase}}</div> -->
    
	<div *ngIf="!productSearchTerm && !suggestedProduct.length && locale ==='ru-ru' && totalProducts == 1" [ngStyle]="{'padding-bottom': '0px', 'font-weight': 'bold'}">{{"filter_results_found_one" | translate | lowercase}} {{totalProducts}} </div>
	<!-- <div *ngIf="!productSearchTerm && !suggestedProduct.length && locale ==='ru-ru' && totalProducts == 0" [ngStyle]="locale === 'en-us' ? {'padding-bottom': '0px', 'font-weight': 'bold'} : {'padding-bottom': '30px', 'font-weight': 'bold'}">asdasd {{"filter_results_found_zero" | translate | lowercase}} {{totalProducts}} </div> -->
  </div>

  <div *ngIf="suggestedProduct.productDetails?.length > 0">
	<div style='margin-top: -20px' *ngIf="productSearchTerm !== '' && locale !=='th-th'" class="suggested-product-text">
		{{"error_search" | translate}}
		<span class="search-term">'{{productSearchTerm}}'</span>
		<div style='padding-top: 0px'>{{"error_search_suggest" | translate}}</div>
	</div>
	<div *ngIf="productSearchTerm !== '' && locale ==='th-th'" class="suggested-product-text">
		{{"error_search" | translate}}
		<span class="search-term">'{{productSearchTerm}}'</span>
		<div style='padding-top: 0px'>{{"error_search_suggest" | translate}}</div>
	</div>
	<div *ngIf="productSearchTerm === '' && locale !=='th-th'">
		<div class="suggested-product-text"> {{suggestedProduct.totalProducts}} {{ 'error_filter' | translate }}</div>
	</div>
	<div *ngIf="productSearchTerm === '' && locale ==='th-th'">
		<div class="suggested-product-text">{{ 'error_filter' | translate }} {{suggestedProduct.totalProducts}} {{ 'error_filter-1' | translate }}</div>
	</div>
  </div>
  <!-- div *ngIf="suggestedProduct.productDetails?.length > 0">
		<div>
			<div class="suggested-product-text"> {{suggestedProduct.totalProducts}} {{ 'error_filter' | translate }}</div>
		</div>
  </div -->

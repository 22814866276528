import { Routes, RouterModule } from "@angular/router";
import { ModuleWithProviders } from "@angular/core";

import { ProductComponent } from "./products/product.component";
import { ComparePageComponent } from "./products/compare/compare-page/compare-page.component";
import { RedirectComponent } from "./redirect/redirect.component";
import { DataResolverService } from "./products/fe-resolver";
import { AppModule } from "./app.module";
import { ProductPdpComponent } from "./products/product-pdp/product-pdp.component";

export const routes: Routes = [
  {
    path: ":locale/consumer",
    component: ProductComponent,
    resolve: { feinfo: DataResolverService },
  },
  {
    path: ":locale/consumer/compare",
    component: ComparePageComponent,
    resolve: { feinfo: DataResolverService },
  },
  {
    path: ":locale/consumer/:category",
    component: ProductComponent,
    resolve: { feinfo: DataResolverService },
  },


  {
    path: ":locale/geforce/store",
    component: ProductComponent,
    resolve: { feinfo: DataResolverService },
  },
  {
    path: ":locale/geforce/store/compare",
    component: ComparePageComponent,
    resolve: { feinfo: DataResolverService },
  },
  {
    path: ":locale/geforce/store/:category",
    component: ProductComponent,
    resolve: { feinfo: DataResolverService },
  },

  {
    path: "**",
    component: RedirectComponent,
  },
];
 

export const appRoutingProviders: any[] = [];

export const routing: ModuleWithProviders<AppModule> = RouterModule.forRoot(
  routes,{}
);

import { Component, OnInit,OnDestroy , Input, Output, EventEmitter, ViewEncapsulation, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Product } from '../../shared/interface/product';
import { Facets } from '../../shared/interface/facets';
import { ProductService } from '../product.service';
import { ProductSearchCriteria } from '../../shared/interface/product-search-criteria';
import { Store } from '@ngrx/store';
import * as reducers from '../../reducers';
import * as productActions from '../product.actions';
import { PreviousRouteService } from '../../shared/service/previous-route.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
@Component({
  selector: 'category-header',
  templateUrl: './category-header.component.html',
  styleUrls: ['./category-header.component.scss',  '../../../assets/styles/app.scss']
})

export class CategoryHeaderComponent implements OnInit {


  @Input() screenWidth: string;
  @Input() productFilter: Facets[];
  @Output() filterChange = new EventEmitter();
  @Output() clearFilter = new EventEmitter();
  @Output() toggleReset = new EventEmitter();
  @ViewChild("filtersReset") filtersResetBtn: ElementRef;
  // @Output() onResult: EventEmitter<any> = new EventEmitter<any>();
  primarylapShop:string;

  primaryBreadcrumb: string;
  locale: string;
  destroy$: Subject<boolean> = new Subject<boolean>();
  secondaryBreadcrumb: string;
  isPaging: boolean = false;

  disableReset: boolean = true;
  //defaultMinRangeVal;
  //defaultMaxRangeVal;
  showFilterCol: boolean = false;
  someRange = [];
  filterCriteria = {
    query: "Geforce",
  };
  maxToShow: number = 8;
  maxes = [];
  showMoreLessStates = [];
  showMoreLabel: string = "show_more";
  showLessLabel: string = "show_less";
  //showMore = 8;
  private origin;
  private countryCode;
  private feSkuInfo;
  private feAPIs = {
    "http://localhost:4200": "https://stg-api.store.nvidia.com",
    "https://dev-shop.nvidia.com": "https://stg-api.store.nvidia.com",
    "https://stg-shop.nvidia.com": "https://stg-api.store.nvidia.com",
    "https://shop.nvidia.com": "https://api.store.nvidia.com",
    "https://dev-store.nvidia.com": "https://stg-api.store.nvidia.com",
    "https://stg-store.nvidia.com": "https://api.store.nvidia.com",
    "https://store.nvidia.com": "https://api.store.nvidia.com",
  };
  constructor(
    private store: Store<reducers.State>,
    private previousRouteService: PreviousRouteService,
    private productService: ProductService,
    private http: HttpClient,
    private route: ActivatedRoute
  ) {
    this.origin = window.location.origin;
    this.countryCode = window.location.pathname
      .split("/")[1]
      .split("-")[1]
      .toUpperCase();
    if (this.countryCode == "GB") {
      this.countryCode = "UK";
    }
  }


  


  
  ngOnInit() {
    var element = document.querySelector("body");
    // localStorage.removeItem('cat1');          
    if(window.location.href.indexOf("/studio-laptops/") !== -1 || window.location.href.indexOf("/gaming-laptops/") !== -1){
      console.log("cat1_locaal is  available");
       localStorage.setItem('cat1', "banner-image-enable"); 

    }else{
      localStorage.removeItem('cat2');   
      localStorage.removeItem('cat1');   
      element.classList.remove("laptop_studio_white");  
      element.classList.remove("banner-image-enable");  

      console.log("cat2_locaal is ---> not available");

    }

    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      this.locale = params["locale"];
    });

    this.store
      .select(reducers.getProductParams)
      .subscribe((params: ProductSearchCriteria) => {
        // this.disableReset = false;
        // let arr = Object.keys(params);        
       
        
      
      });
      this.route.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe((queryParams) => {

        let cat2_local = localStorage.getItem('cat2');  
        
       

        this.secondaryBreadcrumb='';
        this.primaryBreadcrumb='';
        if (!this.previousRouteService.getIsFromCompare() && !this.isPaging) {

          let size = Object.keys(queryParams).length;
          let defaultQueryParams = Object.assign({}, queryParams, {
            page: 1,
            // locale: this.locale,
          });
          // if (size > 3 && !this.isRefreshed) {

          // }
          const validCategories =
          [ 'GPU', 'LAPTOP', 'STUDIO-LAPTOP', 'DESKTOP', 'STUDIO_DESKTOP', 'MONITOR', 'GFN', 
          'NVLINKS', 'MODELS', 'GAMING_SOFTWARE'];

          if((size==6||size==5)&&queryParams?.category){ this.primaryBreadcrumb='primary';}

          if(queryParams?.category ){
            let category = queryParams?.category;
            // let category = validCategories.find(category => queryParams.category.toLowerCase().includes(category.toLowerCase()));
            // console.log("categories", category);
            // let mainurl = "https://stg-store.nvidia.com/en-us/consumer/studio-laptops/?page=1&amp;limit=9&amp;locale=en-us&amp;category=STUDIO-LAPTOP&amp;category_filter=GPU~206,LAPTOP~160,STUDIO-LAPTOP~9,DESKTOP~82,MONITOR~28,GFN~4,NVLINKS~1,MODELS~8,GAMING_SOFTWARE~2";
            // console.log("window--->", window.location.href, "--->", window.location.href.indexOf("/studio-laptops/") !== -1);

            if(cat2_local === "Studio Laptops" && window.location.href.indexOf("/studio-laptops/") !== -1){
              // console.log("cat2_locaal is available", cat2_local);
              let categoryClassname2 = "laptop_studio_white";
              element.classList.add("base_v4", categoryClassname2);
                  
            }else{
              element.classList.remove("laptop_studio_white");  
              // Removing an item
              localStorage.removeItem('cat2');   
            }

            if(category === "LAPTOP" || category === "STUDIO-LAPTOP" || category === "LAPTOP,STUDIO-LAPTOP" || category === "STUDIO-LAPTOP,LAPTOP" || window.location.href.indexOf("/studio-laptops/") !== -1 || window.location.href.indexOf("/gaming-laptops/") !== -1){
              
              if(category === "LAPTOP,STUDIO-LAPTOP" || category === "STUDIO-LAPTOP,LAPTOP"){
                this.primarylapShop='primary-laptop';
              }else{
                this.primarylapShop='';
              }
              localStorage.setItem('cat1', "banner-image-enable"); 
              let cat1_local = localStorage.getItem('cat1');  

              // let categoryClassname = category.toLowerCase() + "_white";
              var categoryClassname = cat1_local;
              element.classList.add("base_v4", categoryClassname);
              // Setting a string value
              // const categorylocal = { cat1: category };
              console.log("cat1_local-->", categoryClassname)


              // localStorage.setItem('cat1', category); 

            }else{
              // Removing an item
              // alert(this)
              localStorage.removeItem('cat1');   
              localStorage.removeItem('cat2');  
              element.classList.remove("laptop_studio_white");  
              element.classList.remove("banner-image-enable");  

              this.primarylapShop='';

       
            }

          }
          if(queryParams?.category === undefined){
            // console.log("queryParams?.category--->",queryParams?.category );
            // element.classList.remove("laptop_studio_white");  
            // alert(this)
            // element.classList.remove("laptop_white");            

          }

          if(size==6&& (queryParams?.category?.split(',')?.length==1)){
           
            if( queryParams?.category){
              let category = validCategories.find(category => queryParams.category.toLowerCase().includes(category.toLowerCase()));
              
              if (category) {
                this.secondaryBreadcrumb = "category_" + category.toLowerCase();

              }

              else {
              this.secondaryBreadcrumb= "default";
              }

            }             
          }
          if(size==5&& (queryParams?.category?.split(',')?.length==1 || queryParams?.gpu?.split(',')?.length==1 || queryParams?.manufacturer?.split(',')?.length==1 || queryParams?.nv_technology?.split(',')?.length==1  || queryParams?.screen_size?.split(',')?.length==1  )){
            if( queryParams?.category){
              let category = validCategories.filter(
								(category) =>
									queryParams.category.toLowerCase() == category.toLowerCase()
							)?.[0];
              if (category) {
                this.secondaryBreadcrumb = "category_" + category.toLowerCase();
              }

              else {
              this.secondaryBreadcrumb= "default";
              }
            }
            else if( queryParams?.gpu){
              this.secondaryBreadcrumb= queryParams?.gpu?.split(',')?.[0] || queryParams?.manufacturer?.split(',')?.[0] || queryParams?.nv_technology?.split(',')?.[0]  || queryParams?.screen_size?.split(',')?.[0];

            }
             
          }
        }

      });
    }


}

import { Action } from '@ngrx/store';
import { type } from '../util';
import { Product } from '../shared/interface/product';
import { Facets } from '../shared/interface/facets';
import { ProductSearchCriteria } from '../shared/interface/product-search-criteria';
import { RetailInfo } from '../shared/interface/retail-info';
import { HeaderInfo } from '../shared/interface/header/header-info';

export const ActionTypes = {

  LOAD_PRODUCT: type('[Product] Load Product'),
  LOAD_PRODUCT_COMPLETE: type('[Product] Load Product Complete'),
  LOAD_PRODUCT_ERROR: type('[Product] Load Product Error'),
  
  CLEAR_PRODUCT_FILTER_SELECTION: type('[Product] Clear Product Filter Selection'),

  UPDATE_PRODUCT_PARAMS: type('[Product] Update Product Params'),

  UPDATE_PRODUCT_PARAMS_ONLOAD: type('[Product] Update Product Params Onload'),

  ADD_COMPARED_PRODUCT: type('[Compare] Add Compared Product'),
  
  REMOVE_COMPARED_PRODUCT: type('[Compare] Remove Compared Product'),
  
  LOAD_RETAIL_INFO: type('[Product] Load Retail Info'),
  LOAD_RETAIL_INFO_COMPLETE: type('[Product] Load Retail Info Complete'),
  LOAD_RETAIL_INFO_ERROR: type('[Product] Load Retail Info Error'),

  LOAD_HEADER_INFO: type('[Header] Load Header Info'),
  LOAD_HEADER_INFO_COMPLETE: type('[Header] Load Header Info Complete'),
  LOAD_HEADER_INFO_ERROR: type('[Header] Load Header Info Error'),

  LOAD_LOCALE: type('[App] Load Locale'),

  SEARCH_PRODUCTS: type('[Search] Search products'),

  VIEW_TYPE: type('[Search] View Type'),

  CLEAR_PRODUCT: type('[Product] Clear Product'),

};

export class LoadProduct implements Action {
  type = ActionTypes.LOAD_PRODUCT;

  constructor(public payload: { productSearchCriteria: ProductSearchCriteria, refreshRequired?: boolean, locale: string }) { }
}

export class LoadProductComplete implements Action {
  type = ActionTypes.LOAD_PRODUCT_COMPLETE;

  constructor( public payload: Product) { }
}

export class LoadProductError implements Action {
  type = ActionTypes.LOAD_PRODUCT_ERROR;

  constructor( public payload: { message: string }) { }
}

export class ClearProductFilterSelection implements Action {
  type = ActionTypes.CLEAR_PRODUCT_FILTER_SELECTION;

  constructor(public payload: { page: number, limit: number, locale: string }) { }
}

export class UpdateProductParams implements Action {
  type = ActionTypes.UPDATE_PRODUCT_PARAMS;

  constructor(public payload ) { }
}

export class UpdateProductParamsOnload implements Action {
  type = ActionTypes.UPDATE_PRODUCT_PARAMS_ONLOAD;

  constructor(public payload ) { }
}

export class AddComparedProduct implements Action {
  type = ActionTypes.ADD_COMPARED_PRODUCT;

  constructor(public payload: { upc: string, type: number,locale: string } ) { }
}

export class RemoveComparedProduct implements Action {
  type = ActionTypes.REMOVE_COMPARED_PRODUCT;

  constructor(public payload: number) { }
}

export class LoadRetailInfo implements Action {
  type = ActionTypes.LOAD_RETAIL_INFO;

  constructor(public payload: string ) { }
}

export class LoadRetailInfoComplete implements Action {
  type = ActionTypes.LOAD_RETAIL_INFO_COMPLETE;

  constructor( public payload: RetailInfo) { }
}

export class LoadRetailInfoError implements Action {
  type = ActionTypes.LOAD_RETAIL_INFO_ERROR;

  constructor( public payload: { message: string }) { }
}

export class LoadHeaderInfo implements Action {
  type = ActionTypes.LOAD_HEADER_INFO;

  constructor(public payload: string ) { }
}

export class LoadHeaderInfoComplete implements Action {
  type = ActionTypes.LOAD_HEADER_INFO_COMPLETE;

  constructor( public payload: HeaderInfo) { }
}

export class LoadHeaderInfoError implements Action {
  type = ActionTypes.LOAD_RETAIL_INFO_ERROR;

  constructor( public payload: { message: string }) { }
}

export class LoadLocale implements Action {
  type = ActionTypes.LOAD_LOCALE;

  constructor(public payload: string ) { }
}

export class SearchProducts implements Action {
  type = ActionTypes.SEARCH_PRODUCTS;

  constructor(public payload: { page: number, limit: number, search: string }) { }
}

export class ViewType implements Action {
  type = ActionTypes.VIEW_TYPE;

  constructor(public payload: string) { }
}

export class ClearProduct implements Action {
  type = ActionTypes.CLEAR_PRODUCT;

  constructor() { }
}

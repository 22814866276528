import { Observable } from 'rxjs';
import { Action } from '@ngrx/store';
import * as productActions from './product.actions';
import { Facets } from '../shared/interface/facets';
import { ProductDetails } from '../shared/interface/product-details';
import { ProductSearchCriteria } from '../shared/interface/product-search-criteria';
import { ProductCompare } from '../shared/interface/product-compare';
import { RetailInfo } from '../shared/interface/retail-info';
import { FeaturedProduct } from '../shared/interface/featured-product';
import { HeaderInfo } from '../shared/interface/header/header-info';

export interface ProductState {

  productFilter: Facets[];
  productSearch: string;
  productSort: any;
  productFeatured: FeaturedProduct;
  productDetails: ProductDetails[];
  productParams: ProductSearchCriteria;
  productLoading: boolean;
  productCompare: ProductCompare[];
  totalProducts: number;
  buildVersion: number;
  paginationParams: {
    page: number,
    limit: number
  };
  retailInfo: RetailInfo;
  retailInfoLoading: boolean;
  globalHeader: object;
  subBrandHeader: object;
  suggestedProduct: {
    productDetails: ProductDetails[],
    totalProducts: number,
    search: null
  };
  locale: string;
  isFeaturedProductIncludedInCount: boolean;
}

export const initialState: ProductState = {

  productFilter: [],
  productSearch: '',
  productSort: [],
  productFeatured: null,
  totalProducts: 0,
  buildVersion: 0,
  productDetails: [],
  productParams: {
    page: 1,
    limit: 12,
    locale: ''
  },
  productLoading: false,
  productCompare: initProductCompare(),
  paginationParams: {
    page: 0,
    limit: 0
  },
  retailInfo: null,
  retailInfoLoading: false,
  globalHeader: {},
  subBrandHeader: {},
  suggestedProduct: {
    productDetails: [],
    totalProducts: 0,
    search: null
  },
  locale: '',
  isFeaturedProductIncludedInCount: false
};

function initProductCompare():ProductCompare[] {
	try {
		let productCompare:ProductCompare[] = JSON.parse(localStorage.getItem(getCurLocale() + "-compare"));
		if (productCompare) {
			return productCompare;
		}
	} catch (e) {}
	return [null, null, null, null];
}



function getCurLocale(): string {
	return window.location.pathname.split('/')[1];
}



export function reducer( state = initialState, action ): ProductState {
  switch ( action.type ) {

    case productActions.ActionTypes.LOAD_PRODUCT: {
      return Object.assign({}, state, {
        productLoading: true
      });
    }

    case productActions.ActionTypes.LOAD_PRODUCT_COMPLETE: {
      let product = action.payload;
      return Object.assign({}, state, {

        productFilter: getValidProductFilters(product.filters),
        productSearch: product.search === null ? '' : product.search,
        productSort: product.sort,
        totalProducts: product.pagination.totalRecords,
        buildVersion: storebuildVersion(product.version),
        isFeaturedProductIncludedInCount: product.pagination.isFeaturedProductIncludedInCount,
        productFeatured: getFeaturedProduct(product.searchedProducts, state),
        productDetails: product.pagination.page > 1 ? addMoreProducts(state, product, product.searchedProducts.productDetails, state.productCompare) : getProductDetailsWithCompare(product.searchedProducts.productDetails, state.productCompare),
        suggestedProduct: (product.searchedProducts.totalProducts === 0 && product.searchedProducts.suggestedProductDetails.length) ?
          Object.assign({}, state.suggestedProduct, {
            productDetails: getSuggestedProductsWithCompare(product.searchedProducts.suggestedProductDetails.slice(0, 3), state.productCompare),
            totalProducts: product.searchedProducts.totalProducts,
            search: product.search
          }): {
			productDetails: [],
			totalProducts: 0,
			search: null
		  },
        productLoading: false
      });
    }

    case productActions.ActionTypes.UPDATE_PRODUCT_PARAMS: {
      let payload = action.payload;
      //console.log("STATE",state.productParams, payload, state.productFilter[2]);
      return { ...state,
        productParams: updateProductParams(state.productParams, payload, state.productFilter[2])
      }
    }

	case productActions.ActionTypes.ADD_COMPARED_PRODUCT: { 
		let payload, singleProduct, newProductCompare, obj;
    let drresp: any;
    let updateProductDetails : any;
    let updateFeaturedDetail : any;
		payload = action.payload;
    drresp = localStorage.getItem("nv_Products_"+payload.locale);
    updateProductDetails = [];
    if(drresp && drresp!=''){
      drresp = JSON.parse(drresp);
      let sindex = -1;
      for (let sp of state.productDetails) {
        //let sp = state.productDetails[idx];
        //addRemoveCompare
        updateProductDetails[++sindex]=(Object.assign({}, sp, {}));
        sp = updateProductDetails[sindex];
        for(let dpid of drresp) {
            //let dpid = drresp[dridx];
            if(sp['digitialRiverID'] == dpid['id']){
              sp['productPrice']=dpid['salePriceWithQuantity'];
             // console.log(dpid);
              if(dpid['inventoryStatus']['productIsInStock']){
                sp['prdStatus']='add_to_cart';
              } else {
                sp['prdStatus']='out_of_stock';
              }
              break;
            } else {
              if(state.productFeatured && state.productFeatured['digitialRiverID']
                && state.productFeatured['digitialRiverID'] != '' && dpid['id']==state.productFeatured['digitialRiverID']){
                  updateFeaturedDetail = Object.assign({}, state.productFeatured, { });
                  updateFeaturedDetail['productPrice']=dpid['salePriceWithQuantity'];
                  //console.log(dpid);
                  if(dpid['inventoryStatus']['productIsInStock']){
                    updateFeaturedDetail['prdStatus']='add_to_cart';
                  } else {
                    updateFeaturedDetail['prdStatus']='out_of_stock';
                  }
              }
            }
        }
      }
    }

		if (payload.type === 0) {
			singleProduct = state.productFeatured;
			newProductCompare = addToProductCompare(state.productCompare, singleProduct);
			if (newProductCompare) {
				if (singleProduct) {
					obj = toggleAddRemoveCompare([singleProduct], singleProduct.productUPC, true);
					if (obj.found) {
						return Object.assign({}, state, {
							productFeatured: obj.newProducts[0], //productFeatured is not an array, so get newProducts[0]
							productCompare: newProductCompare
						});
					}
				}
				return Object.assign({}, state, {
					productCompare: newProductCompare
				});
			}
		} else if (payload.type === 1) {
			singleProduct = state.suggestedProduct.productDetails.filter(product => product.productUPC === payload.upc)[0];
			newProductCompare = addToProductCompare(state.productCompare, singleProduct);
			if (newProductCompare) {
				if (singleProduct) {
					obj = toggleAddRemoveCompare(state.suggestedProduct.productDetails, singleProduct.productUPC, true);
					if (obj.found) {
						return Object.assign({}, state, {
							suggestedProduct: Object.assign({}, state.suggestedProduct, {
								productDetails: obj.newProducts
							}),
							productCompare: newProductCompare
						});
					}
				}
				return Object.assign({}, state, {
					productCompare: newProductCompare
				});
			}
		} else if (payload.type === 3) {
			singleProduct = state.productDetails.filter(product => product.productUPC === payload.upc)[0];
			newProductCompare = addToProductCompare(state.productCompare, singleProduct);
			if (newProductCompare) {
				if (singleProduct) {
					obj = toggleAddRemoveCompare([singleProduct], singleProduct.productUPC, true);
					// if (obj.found) {
					// 	return Object.assign({}, state, {
					// 		productFeatured: obj.newProducts[0], //productFeatured is not an array, so get newProducts[0]
					// 		productCompare: newProductCompare
					// 	});
					// }
				}
				return Object.assign({}, state, {
					productCompare: newProductCompare
				});
			}
		}  else {
			singleProduct = state.productDetails.filter(product => product.productUPC === payload.upc)[0];
			newProductCompare = addToProductCompare(state.productCompare, singleProduct);
			if (newProductCompare) {
				if (singleProduct) {
					obj = toggleAddRemoveCompare(state.productDetails, singleProduct.productUPC, true);
					if (obj.found) {
						return Object.assign({}, state, {
							productDetails: obj.newProducts,
							productCompare: newProductCompare
						});
					}
				}
				return Object.assign({}, state, {
					productCompare: newProductCompare
				});
			}
		}
		return Object.assign({}, state);
	}

	case productActions.ActionTypes.REMOVE_COMPARED_PRODUCT: {
		let payload, singleProduct, newProductCompare, obj;

		payload = action.payload;
		newProductCompare = removeFromProductCompare(state.productCompare, payload);
		if (newProductCompare) {
			singleProduct = state.productFeatured;
			if (singleProduct && singleProduct.productUPC === payload) {
				obj = toggleAddRemoveCompare([singleProduct], payload, false);
				if (obj.found) {
					return Object.assign({}, state, {
						productFeatured: obj.newProducts[0], //productFeatured is not an array, so get newProducts[0]
						productCompare: newProductCompare
					});
				}
			}

			singleProduct = state.suggestedProduct.productDetails.filter(product => product.productUPC === payload)[0];
			if (singleProduct) {
				obj = toggleAddRemoveCompare(state.suggestedProduct.productDetails, singleProduct.productUPC, false);
				if (obj.found) {
					// return Object.assign({}, state, {
					// 	suggestedProduct: Object.assign({}, state.suggestedProduct, {
					// 		productDetails: obj.newProducts
					// 	}),
					// 	productCompare: newProductCompare
					// });
				}
			}

			singleProduct = state.productDetails.filter(product => product.productUPC === payload)[0];
			if (singleProduct) {
				obj = toggleAddRemoveCompare(state.productDetails, singleProduct.productUPC, false);
				if (obj.found) {
					// return Object.assign({}, state, {
					// 	productDetails: obj.newProducts,
					// 	productCompare: newProductCompare
					// });
				}
			}
			return Object.assign({}, state, {
				productCompare: newProductCompare
			});
		}
		return Object.assign({}, state);
	}

	case productActions.ActionTypes.LOAD_RETAIL_INFO: {
      return Object.assign({}, state, {
        retailInfoLoading: true
      });
    }

    case productActions.ActionTypes.LOAD_RETAIL_INFO_COMPLETE: {
      let retailInfo = action.payload;
      return Object.assign({}, state, {
		  retailInfo: retailInfo,
		  retailInfoLoading: false
	  });
    }

    case productActions.ActionTypes.LOAD_HEADER_INFO_COMPLETE: {
      let payload = action.payload;
      return Object.assign({}, state, {
        globalHeader: payload.NVIDIAglobalHeader[0],
        subBrandHeader: payload.NVIDIAsubBrandHeader[0]
      })
    }

  case productActions.ActionTypes.UPDATE_PRODUCT_PARAMS_ONLOAD: {
      let payload = action.payload;
      return Object.assign({}, state, {
        productParams: payload
      })
    }

  case productActions.ActionTypes.LOAD_LOCALE: {
      let payload = action.payload;
      return Object.assign({}, state, {
        locale: payload,
        productParams: Object.assign({}, state.productParams, {
          locale: payload
        })
      })
    }

   case productActions.ActionTypes.CLEAR_PRODUCT_FILTER_SELECTION: {
      let payload = action.payload;
      return Object.assign({}, state, {
        productParams: payload
      })
    }

    default: {
      return state;
    }
  }
}

function storebuildVersion(version)
{

  var b_ver = {edge_version:version,frontend_version:"55.04212020"}
  try {
    localStorage.setItem("Build version", JSON.stringify(b_ver));
  } catch (e) {}

}


function addMoreProducts(state, product, productDetails, productCompare) {
  //console.log('suggestedProduct' + JSON.stringify(state.suggestedProduct));

  let addProducts = [...state.productDetails, ...product.searchedProducts.productDetails];
  return getProductDetailsWithCompare(addProducts, productCompare);
}

function getProductDetailsWithCompare(productDetails, productCompare) {
  return productDetails.map(productDetail => {
    let result = productCompare.filter(productCompare => productCompare !== null && (productCompare.productUPC === productDetail.productUPC));
    if (result.length > 0) {
      return Object.assign({}, productDetail, {
        addRemoveCompare: 'Remove'
      });
    }
    return Object.assign({}, productDetail, {
      addRemoveCompare: 'Compare'
    });
  });
}

function getSuggestedProductsWithCompare(suggestedProductDetails, productCompare) { 
  return suggestedProductDetails.map(suggestedProductDetail => {
    let result = productCompare.filter(productCompare => productCompare !== null && (productCompare.productUPC === suggestedProductDetail.productUPC));
    if (result.length > 0) {
      return Object.assign({}, suggestedProductDetail, {
        isSuggested: true,
		addRemoveCompare: 'Remove'
      });
    }
    return Object.assign({}, suggestedProductDetail, {
      isSuggested: true,
	  addRemoveCompare: 'Compare'
    });
  });
}

function allValuesSame(productCompare) {
  for(var i = 1; i < productCompare.length; i++) {
    if(productCompare[i] !== productCompare[0])
    return false;
  }
  return true;
}

function toggleAddRemoveCompare(products, upc, isCompare) {
	let i, found, product, newProducts, label;

	found = false;
	newProducts = products.slice(0);
	label = isCompare ? 'Compare' : 'Remove';
	for (i = 0; i < newProducts.length; i++) {
		product = newProducts[i];
		if (product.productUPC === upc && product.addRemoveCompare === label) {
			newProducts[i] = Object.assign({}, product, {
				addRemoveCompare: isCompare ? 'Remove' : 'Compare'
			});
			found = true;
			break;
		}
	}
	return {found: found, newProducts: newProducts};
}

function addToProductCompare(productCompare, singleProduct) {
		let arr = productCompare.slice(0);
		let i = arr.indexOf(null);
		if (i >= 0) {
			arr.splice(i, 1, singleProduct);
			saveProductCompare(arr);
			return arr;
		}
		return null;
}

function removeFromProductCompare(productCompare, upc) { 
	let i = productCompare.map(item => {
		if (item) {
			return item.productUPC;
		}
		return null;
	}).indexOf(upc);

	if (i >= 0) {
		let arr = [
			...productCompare.slice(0, i),
			...productCompare.slice(i + 1, productCompare.length), null
		];
		saveProductCompare(arr);
		return arr;
	}
	return null;
}

function saveProductCompare(productCompare) {
	try {
		localStorage.setItem(getCurLocale() + "-compare", JSON.stringify(productCompare));
	} catch (e) {}
}

function getFeaturedProduct(searchedProducts, state) {
  if (searchedProducts.featuredProductsFlag) {
	let featuredProduct = searchedProducts.featuredProduct;
	if (!featuredProduct) {
		return state.productFeatured;
	}
	let result = state.productCompare.filter(productCompare => productCompare !== null && (productCompare.productUPC === featuredProduct.productUPC));
	if (result.length) {
		return Object.assign({}, featuredProduct, {
			isFeatured: true,
			addRemoveCompare: 'Remove'
		});
	}
	return Object.assign({}, featuredProduct, {
	  isFeatured: true,
      addRemoveCompare: 'Compare'
    });
  }
  else {
    return false;
  }
}

function updateProductParams(allParams, payload, priceFilter) {
  //("PayLoad", payload);
 if(payload && payload.fieldType !== 'search' && payload.fieldType !== 'sort' && payload.fieldType !== 'searchSort') {

    if(allParams && allParams.hasOwnProperty(payload.displayName)) {
        // console.log("allParams",allParams);
      if ((payload.fieldType === 'checkbox' || payload.fieldType === 'Checkbox') && payload.trueValue) {
        let d = allParams;
        for(var i in allParams) {
          if (i.indexOf('_filter') !== -1) {
            d = Object.assign(omit(allParams, i));
          }
        }
        return Object.assign({}, d, {
          [payload.displayName]: d[payload.displayName] === '' ? payload.values.dispValue : d[payload.displayName] + ',' + payload.values.dispValue,
          [payload.filterField]: payload.fieldType === 'checkbox' || payload.fieldType === 'Checkbox' ? getAllFilterValues(payload.filterValues) : payload.filterValues.toString()
        })
      }
      else if ((payload.fieldType === 'checkbox' || payload.fieldType === 'Checkbox') && !payload.trueValue) {
        if (allParams[payload.displayName].split(',').filter(item => item !== payload.values.dispValue).join(',') === '') {
          let c = Object.assign(omit(allParams, payload.displayName));
    		  //c = omit(c, 'price');
    		  //c = omit(c, 'price_filter');
          // let d = Object.assign(omit(c, (payload.displayName + '_filter')));
          // return d;
          for(var i in c) {
            let d;
            if (i.indexOf('_filter') !== -1) {
              d = Object.assign(omit(c, i));
              if (!d.hasOwnProperty('manufacturer') && !d.hasOwnProperty('gpu') && !d.hasOwnProperty('price_filter') && d.hasOwnProperty('price') && [priceFilter.defaultMinRangeVal, priceFilter.defaultMaxRangeVal].toString() === d.price) {
                d = Object.assign(omit(omit(c, 'price'), i));
              }
              if (!d.hasOwnProperty('manufacturer') && !d.hasOwnProperty('gpu') && d.hasOwnProperty('price_filter') && d.hasOwnProperty('price') && (d.price === d.price_filter)) {
                d = Object.assign(omit(omit(omit(c, 'price_filter'), 'price'), i));
              }
              return d;
            }
          }
          if (!c.hasOwnProperty('manufacturer') && !c.hasOwnProperty('gpu') && [priceFilter.defaultMinRangeVal, priceFilter.defaultMaxRangeVal].toString() === c.price) {
            c = Object.assign(omit(c, 'price'));
          }
          return c;
        }
        else {
          let d = allParams;
          for(var i in allParams) {
            if (i.indexOf('_filter') !== -1) {
              d = Object.assign(omit(allParams, i));
            }
          }
          return Object.assign({}, d, {
            [payload.displayName]: d[payload.displayName].split(',').filter(item => item !== payload.values.dispValue).join(','),
            [payload.filterField]: payload.fieldType === 'checkbox' || payload.fieldType === 'Checkbox' ? getAllFilterValues(payload.filterValues) : payload.filterValues.toString()
          });
        }
      }
      else {
		    if (payload.values[0] === payload.defaultMinRangeVal && payload.values[1] === payload.defaultMaxRangeVal && (!allParams.hasOwnProperty('manufacturer') && !allParams.hasOwnProperty('gpu'))
          && ((!allParams.hasOwnProperty('search') && !allParams.search))) {
          return omit(omit(allParams, payload.displayName), payload.displayName + '_filter');
        }
        return Object.assign({}, allParams, {
          [payload.displayName]: payload.values[0] + ',' + payload.values[1],
          [payload.filterField]: payload.defaultMinRangeVal + ',' + payload.defaultMaxRangeVal
        });
      }
    }
    else {
      for(var i in allParams) {
        let c;
        if (i.indexOf('_filter') !== -1) {
          c = Object.assign(omit(allParams, i));
          return Object.assign({}, c, {
            [payload.displayName]: payload.fieldType === 'checkbox' || payload.fieldType === 'Checkbox' ? payload.values.dispValue : payload.values.toString(),
            [payload.filterField]: payload.fieldType === 'checkbox' || payload.fieldType === 'Checkbox' ? getAllFilterValues(payload.filterValues) : [payload.defaultMinRangeVal, payload.defaultMaxRangeVal].toString()
          })
        }
      }
      if (payload.fieldType === 'checkbox' || payload.fieldType === 'Checkbox') {

        return Object.assign({}, allParams, {
          [payload.displayName]: payload.values.dispValue,
          [payload.filterField]: getAllFilterValues(payload.filterValues)
        });
      }
      else if(payload.fieldType === 'range' && (payload.values[0] !== payload.defaultMinRangeVal || payload.values[1] !== payload.defaultMaxRangeVal)) {
        return Object.assign({}, allParams, {
          [payload.displayName]: payload.values.toString(),
          [payload.filterField]: payload.filterValues.toString()
        });
      }
      else {
        return allParams;
      }
    }
  } else {
    if (payload && payload.fieldType === 'searchSort') {
        return Object.assign({}, allParams, {
          search: payload.values[0].value,
          sorting: payload.values[1].value
        })
    }
    else {
      if (payload && payload.fieldType === 'sort') {
        return Object.assign({}, allParams, {
          sorting: payload.value
        })

      }
      if (payload && payload.fieldType === 'search') {
        let c;
        if (!payload.value) {
          c = Object.assign(omit(omit(allParams, 'search'), 'sorting'));
          return c;
        }
        c = Object.assign(omit(allParams, 'sorting'));
        return Object.assign({}, c, {
          search: payload.value
        })

      }
    }

  }

}

function getValidProductFilters(filters) {
  return filters.filter(filter => (filter.filterValues && filter.filterValues.length) || (filter.filterValues === null && filter.fieldType === 'range')
  );
}

function omit(obj, omitKey) {
  return Object.keys(obj).reduce((result, key) => {
    if(key !== omitKey) {
       result[key] = obj[key];
    }
    return result;
  }, {});
}

function getAllFilterValues(filterValues) {
  let filterValuesString = filterValues.map(filterValue => {
    return (filterValue.dispValue + '~' + filterValue.units);
  })
  return filterValuesString.join(',');
}

export const getProductFilter = ( state: ProductState ) => state.productFilter;
export const getProductSearch = ( state: ProductState ) => state.productSearch;
export const getProductSort = ( state: ProductState ) => state.productSort;
export const getProductFeatured = ( state: ProductState ) => state.productFeatured;
export const getProductDetails = ( state: ProductState ) => state.productDetails;
export const getProductParams = ( state: ProductState ) => state.productParams;
export const getProductLoading = ( state: ProductState ) => state.productLoading;
export const getProductCompare = ( state: ProductState ) => state.productCompare;
export const getRetailInfo = ( state: ProductState ) => state.retailInfo;
export const getRetailInfoLoading = ( state: ProductState ) => state.retailInfoLoading;
export const getGlobalHeader = ( state: ProductState ) => state.globalHeader;
export const getSubBrandHeader = ( state: ProductState ) => {
   //console.log('state:' + JSON.stringify(state));
  return state.subBrandHeader;
}
export const getTotalProducts = (state: ProductState ) => state.totalProducts;
export const getBuildVersoin = (state: ProductState) => state.buildVersion;
export const getSuggestedProduct = ( state: ProductState) => state.suggestedProduct;
export const getLocale = ( state: ProductState) => state.locale;
export const getIsFeaturedProductIncludedInCount = ( state: ProductState) => state.isFeaturedProductIncludedInCount;

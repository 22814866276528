import { Component, Input } from "@angular/core";

@Component({
  selector: "css-loading-indicator",
  template: `
    <div *ngIf="show || checkoutClick" class="center-content">
      <div class="swg-spinner-wrapper">
        <div class="swg-spinner"></div>
      </div>
    </div>
  `,
  styles: [
    `
      .center-content {
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        z-index: 1105;
        display: block;
      }
      .swg-spinner-wrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: -1.5em;
      }
      .swg-spinner {
        border-radius: 50%;
        width: 3em;
        height: 3em;
        position: relative;
        border: 0.4em solid #76b900;
        border-left-color: transparent;
        -webkit-animation: load8 1.1s infinite linear;
        animation: load8 1.1s infinite linear;
      }
      @-webkit-keyframes load8 {
        0% {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
        }
        100% {
          -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
        }
      }
      @keyframes load8 {
        0% {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
        }
        100% {
          -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
        }
      }
    `,
  ],
})
export class CssLoadingIndicator {
  checkoutClick: boolean;
  @Input() show: boolean = false;
  constructor() {
    this.checkoutClick = JSON.parse(sessionStorage.getItem("checkoutClicked"));
  }
}

 
<span class="main-col small-12 medium-12 column filter-chips-main" style="">

 

  <span style="display: inline; " class=" " *ngFor="let filterItem of productFilter; let filterItemIndex = index">
    <span style="display: inline;" class=" " *ngIf="filterItem.displayName !== 'price' || filterItem.defaultMinRangeVal !== filterItem.defaultMaxRangeVal && (filterItem.fieldType === 'checkbox' || filterItem.fieldType === 'Checkbox')" >
      <!-- <span style="" *ngIf="filterItem.fieldType === 'checkbox'"> -->
        <span style="display: inline; float: left;"  *ngFor="let values of filterItem?.filterValues | slice: 0 : getMaxToShow(filterItemIndex, filterItem?.filterValues); let i = index">

          <span style="display: inline; float: left;" *ngIf=" filterItem.filterField !== 'customer_rating_filter' && filterItem.dataType !== 'Boolean' && selectedCategorieslist ">           
            <!-- <span *ngIf="selectedCategorieslist"> -->
              <ul style="float: left; display: inline; padding-left: 0px; -webkit-margin-after: 0px;" *ngFor="let category of selectedCategorieslist; let categoryIndex = index">
                <!-- <span  *ngIf="category.categories"> -->

                  <li kuiText  class="filtersStrip" *ngFor="let catgry of category.categories;let i = index;">
                    <span class="chips_filterchip" *ngIf="values.dispValue === catgry">
                      <b>{{ filterItem.displayName | lowercase | translate }}:</b>
                      {{
                        filterItem.displayName + "_" + values.dispValue
                          | lowercase
                          | translate
                      }}
                        <img (click)="changed(filterItem, values, false, i)" alt="cross icon" loading="lazy" width="20" height="20" decoding="async" data-nimg="1" [src]="isStudioTheme() ? '../../../assets/images/cross_filter-black.svg' : '../../../assets/images/cross_filter.svg'" style="color: transparent; cursor: pointer;">
                      </span>
                      </li>

                      <li kuiText  class="filtersStrip" *ngFor="let catgry of category.selectedGPU;let i = index;">
                      <span class="chips_filterchip" *ngIf="values.dispValue === catgry">
                        <b>{{ filterItem.displayName | lowercase | translate }}:</b>
                        {{ values.dispValue | translate }}
                        <img (click)="changed(filterItem, values, false, i)" alt="cross icon" loading="lazy" width="20" height="20" decoding="async" data-nimg="1" [src]="isStudioTheme() ? '../../../assets/images/cross_filter-black.svg' : '../../../assets/images/cross_filter.svg'" style="color: transparent; cursor: pointer;">
                      </span>
                      </li>

                      <li kuiText  class="filtersStrip" *ngFor="let catgry of category.selectedMnu;let i = index;">
                      <span class="chips_filterchip" *ngIf="values.dispValue === catgry">
                        <b>{{ filterItem.displayName | lowercase | translate }}:</b>
                        {{ values.dispValue | translate }}
                        <img (click)="changed(filterItem, values, false, i)" alt="cross icon" loading="lazy" width="20" height="20" decoding="async" data-nimg="1" [src]="isStudioTheme() ? '../../../assets/images/cross_filter-black.svg' : '../../../assets/images/cross_filter.svg'" style="color: transparent; cursor: pointer;">
                      </span>
                      </li>

                      <li kuiText  class="filtersStrip" *ngFor="let catgry of category.selectedScrSize;let i = index;">
                      <span class="chips_filterchip" *ngIf="values.dispValue === catgry">
                        <b>{{ filterItem.displayName | lowercase | translate }}:</b>
                        {{ values.dispValue | translate }}
                        <img (click)="changed(filterItem, values, false, i)" alt="cross icon" loading="lazy" width="20" height="20" decoding="async" data-nimg="1" [src]="isStudioTheme() ? '../../../assets/images/cross_filter-black.svg' : '../../../assets/images/cross_filter.svg'" style="color: transparent; cursor: pointer;">
                      </span>
                      </li>

                      <li kuiText  class="filtersStrip" *ngFor="let catgry of category.selectedOffer;let i = index;">
                      <span class="chips_filterchip" *ngIf="values.dispValue === catgry">
                        <b>{{ filterItem.displayName | lowercase | translate }}:</b>
                        {{ values.dispValue | translate }}
                        <img (click)="changed(filterItem, values, false, i)" alt="cross icon" loading="lazy" width="20" height="20" decoding="async" data-nimg="1" [src]="isStudioTheme() ? '../../../assets/images/cross_filter-black.svg' : '../../../assets/images/cross_filter.svg'" style="color: transparent; cursor: pointer;">
                      </span>
                      </li>

                      <li kuiText  class="filtersStrip" *ngFor="let catgry of category.selectedNvTech;let i = index;">
                      <span class="chips_filterchip" *ngIf="values.dispValue === catgry">
                        <b>{{ filterItem.displayName | lowercase | translate }}:</b>
                        {{ values.dispValue | translate }}
                        <img (click)="changed(filterItem, values, false, i)" alt="cross icon" loading="lazy" width="20" height="20" decoding="async" data-nimg="1" [src]="isStudioTheme() ? '../../../assets/images/cross_filter-black.svg' : '../../../assets/images/cross_filter.svg'" style="color: transparent; cursor: pointer;">
                      </span>
                      </li>

                      <li kuiText  class="filtersStrip" *ngFor="let catgry of category.selectedsffReady;let i = index;">
                      <span class="chips_filterchip" *ngIf="values.dispValue === catgry">
                        <!-- <b>{{ filterItem.displayName | lowercase | translate }}:</b> -->
                        {{ values.dispValue | translate }}
                        <img (click)="changed(filterItem, values, false, i)" alt="cross icon" loading="lazy" width="20" height="20" decoding="async" data-nimg="1" [src]="isStudioTheme() ? '../../../assets/images/cross_filter-black.svg' : '../../../assets/images/cross_filter.svg'" style="color: transparent; cursor: pointer;">
                      </span>
                      </li>

                    </ul>
                  </span> 
                  </span>
                </span>    
                </span>
              <span style="float: left;">
                <button  #filtersReset kuiButton [size]="'large'" [danger]="false" [type]="'primary'"
                style=" margin-top: 10px; border: none; background: none;"
                name="filtersReset"
                aria-label="filters Reset"
                class="screen-lg-xl  cta-link-regular 26-pfc chips_filterchip"
                [class.reset-filter-disabled]="disableReset"
                (click)="resetFilters($event)" *ngIf="screenWidth != 'SM' && !this.disableReset" >{{ 'clear_all' | translate }}
                
                <img alt="cross icon" loading="lazy" width="20" height="20" decoding="async" data-nimg="1" [src]="isStudioTheme() ? '../../../assets/images/cross_filter-black.svg' : '../../../assets/images/cross_filter.svg'" style="color: transparent; cursor: pointer;">
              </button>
        </span>
    

</span>
  <!-- </div> -->
<!-- </div> -->


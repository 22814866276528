import { takeUntil } from "rxjs/operators";
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  NgZone,
  AfterViewChecked,
  ViewChild,
  ElementRef,
  HostListener,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable, Subject } from "rxjs";
import * as reducers from "../../reducers";
import { ViewportDetector } from "../../shared/viewport-detector";
import { ProductDetails } from "../../shared/interface/product-details";
import * as productActions from "../product.actions";
import { LayoutService } from "../../shared/service/layout.service";
import { CartService } from "../../shared/service/cart.service";
import { ProductCompare } from "../../shared/interface/product-compare";
import { ProductService } from "../product.service";
import {
  resizeImg,
  changeDRId,
  showOverlay,
  showImgError,
  getBuyButtonText,
  getFormatedPrice,
  getPriceIntegerDot,
  getPriceDecimal,
  extractDRPriceIntegerDot,
  extractDRPriceDecimal,
  productName,
} from "../../shared/utils/utils";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
declare var $: any;

@Component({
  selector: "product-details",
  templateUrl: "./product-details.component.html",
  styleUrls: ["./product-details.component.scss"],
})
export class ProductDetailsComponent implements OnInit, AfterViewChecked {
  @Input() screenWidth: string;
  @Input() productCompare: ProductCompare[];
  @Output() hatchOverlayDisplay = new EventEmitter();
  @Output() curViewType = new EventEmitter();
  @ViewChild("drId") drId: ElementRef;

  viewportType: string;
  layoutType: string;
  locale: string;
  thumbnailWGrid: number = 300;
  thumbnailHGrid: number = 231;
  thumbnailWListXL: number = 300;
  thumbnailHListXL: number = 231;
  thumbnailWListLG: number = 190;
  thumbnailHListLG: number = 107;
  changeDRId;
  productName;
  showImgError;
  getBuyButtonText;
  getPriceIntegerDot;
  getFormatedPrice;
  getPriceDecimal;
  extractDRPriceIntegerDot;
  extractDRPriceDecimal;
  setBottomMargin: boolean = false;
  destroy$: Subject<boolean> = new Subject<boolean>();
  productDetails: ProductDetails[] = [];
  afterViewChecked: boolean = false;
  featuredProductLoaded: boolean;
  drLoading: boolean = false;
  sessionStorageViewTypeKey: string = "-view-types";

  constructor(
    private store: Store<reducers.State>,
    private route: ActivatedRoute,
    private viewportDetector: ViewportDetector,
    private router: Router,
    private layoutService: LayoutService,
    private cartService: CartService,
    private elRef: ElementRef,
    private translate: TranslateService,
    private productService: ProductService
  ) {
    this.changeDRId = changeDRId;
    this.showImgError = showImgError;
    this.productName = productName;
    this.getBuyButtonText = getBuyButtonText;
    this.getPriceIntegerDot = getPriceIntegerDot;
    this.getFormatedPrice = getFormatedPrice;
    this.getPriceDecimal = getPriceDecimal;
    this.extractDRPriceIntegerDot = extractDRPriceIntegerDot;
    this.extractDRPriceDecimal = extractDRPriceDecimal;
    window.addEventListener(
      "orientationchange",
      () => {
        this.getViewType();
      },
      false
    );
  }
  valueTrimmer(v) {
    let t;
    if (v.length > 0) {
      t = v.trim();
    }
    return t;
  }
  @HostListener("window:resize") onScreenResize() {
    this.getViewType();
  }
  cmpatc(productDetail) {
    localStorage.setItem("reqpid", productDetail.productID);
    const hosts = window.location.host.split("-");
    let apiDomain = "api.store.nvidia.com";
    if (hosts.length > 1) {
      apiDomain = hosts[0] + "-" + apiDomain;
    }


    $.ajax({
      url: "https://" + apiDomain + "/cart-management/v1/cart/networking",
      headers: {
        "nvidia-client": true
      },
      data: JSON.stringify({
        'cart': {
          'products': [{
            'quantity': 1,
            'product_id': productDetail.bcPID
          }]
        }
      }),
      method: 'POST'
    }).done(function (response) {
      const getCookie = function (name: string): string | null {
        const cookies = decodeURIComponent(document.cookie).split(';');
        for (let i = 0; i < cookies.length; i++) {
          let cookie = cookies[i].trim();
          if (cookie.startsWith(name + '=')) {
            return cookie.substring(name.length + 1);
          }
        }
        return null;
      };
      const bcSessionToken = getCookie('sessionToken');
      if (bcSessionToken) {
        window.location.href = "https://" + apiDomain + "/account/v1/bigcommerce/auth?state=" + encodeURIComponent(response.viewCartUri);
      } else {
        window.location.href = response.viewCartUri;
      }
    }).fail(function (error) {
      jQuery(jQuery("a[data-pid-code=" + localStorage.getItem("reqpid") + "]")[0]).prop('disabled', true).addClass('cmpOOS');
      jQuery(jQuery("a[data-pid-code=" + localStorage.getItem("reqpid") + "]")[0]).text("Out of Stock");
      jQuery(jQuery("a[data-pid-code=" + localStorage.getItem("reqpid") + "]")[0]).attr('data-ctaType', 'out_of_stock');
    });
  }
  getPDPLink(productDetail) {
    let l = "";

    if (productDetail.retailers.length > 0) {
      let foundLink = false;
      productDetail.retailers.forEach((element) => {
        if (element.purchaseLink && element.partnerId === "111") {
          let l_parts = element.purchaseLink.split("/");
          l = "/" + l_parts.slice(1).join("/");
          foundLink = true;
          //  console.log("-----l----->",l);
        }
      });
      if (foundLink) {
      return l;
      }
    }

 if (productDetail.category === "SWITCH_SYSTEMS" || productDetail.category === "DPU") {
      //  l = "/" + this.locale + "/consumer/product/"+productDetail.productSKU+"/"+(productDetail.productTitle.replace(/\s+|[,\/]/g, ""));
      l =
        ("/" +
        $("html").attr("lang") +
        "/consumer/product/" +
        productDetail.productSKU.toLowerCase() +
        "/" +
        productDetail.productTitle
          .replace(productDetail.productSKU, " ")
          .replaceAll(" ", "-")
          .replaceAll("/", "-")
          .toLowerCase()
          .replace(/-+/gi, "-"));

        return l;
    }

    else {
      // return ("/" +$("html").attr("lang") +"/consumer/product/" +productDetail.productSKU.toLowerCase() +"/" +productDetail.productTitle.replace(productDetail.productSKU, " ").replaceAll(" ", "-").replaceAll("/", "-").toLowerCase().replace(/-+/gi, "-"));
      return (
        "/" +
        $("html").attr("lang") +
        "/consumer/product/" +
        productDetail.productSKU.toLowerCase() +
        "/" +
        productDetail.productTitle
          .replace(productDetail.productSKU, " ")
          .replaceAll("+", "")
          .replaceAll(".", "-")
          .replaceAll(" ", "-")
          .replaceAll("/", "-")
          .toLowerCase()
          .replace(/-+/gi, "-") +
        "/"
      );
    }
    //  return  ("/" + this.locale + "/consumer/product/"+productDetail.productSKU+"/"+(productDetail.productTitle.replace(/\s+|[,\/]/g, "")).replace(/[^a-z0-9]/gi,''));
  }
  getViewType() {
    if (this.screenWidth === "SM") {
      this.layoutType = "grid";
      this.curViewType.emit("grid");
      this.saveViewType("grid");
    } else if (
      (this.screenWidth === "LG" || this.screenWidth === "XL") &&
      this.layoutType === "grid"
    ) {
      this.setGridBottomMargin();
    }
  }

  saveViewType(view) {
    try {
      localStorage.setItem(
        this.getCurLocale() + this.sessionStorageViewTypeKey,
        JSON.stringify(view)
      );
    } catch (e) {}
  }

  openPDP(product) {
    const { productSKU, displayName } = product;
    window.open(
      `https://dev-store.nvidia.com/store-products/v1/product/${productSKU
        .replace(/\s+/g, "-")
        .toLowerCase()}/${displayName.replace(/\s+/g, "-").toLowerCase()}/`,
      "_blank"
    );
  }

  detectBuyClick() {
    $(".buy-link-p").on("click", function (event) {
      // modal neeraj
      var lastFocusedElement;
      lastFocusedElement = document.activeElement;
      setTimeout(function () {
        const FOCUSABLE_SELECTORS =
          "a.popClose, img, button,h1,div.cellPrice, div.bundle_icon_class, a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[tabindex], *[contenteditable]";

        const closeModalBtn = document.querySelector(".popClose");
        const modal = document.querySelector(".popBody");
        modal.classList.add("modal-active");
        const main = document.querySelector(".popBg");
        openModal();

        function openModal() {
          const focusableElements = Array.from(
            modal.querySelectorAll(FOCUSABLE_SELECTORS)
          );
          focusableElements.forEach((el) => el.setAttribute("tabindex", "0"));
          (modal.querySelector(FOCUSABLE_SELECTORS) as HTMLElement).focus();
          modal.removeAttribute("aria-hidden");
          main.setAttribute("aria-hidden", "true");
          closeModalBtn.addEventListener("click", modalClose);
        }
      }, 1000);

      function modalClose() {
        // Return the focus to the last focused element
        lastFocusedElement.focus();
      }
      // end modal focus neeraj
    });
  }

  ngAfterViewChecked() {
    if (
      !this.afterViewChecked &&
      (this.productDetails.length || this.featuredProductLoaded)
    ) {
      if (
        !localStorage.getItem("hasCompared") ||
        localStorage.getItem("hasCompared") == "" ||
        localStorage.getItem("hasCompared") == "null"
      ) {
        try {
          window["hatchwidgetHelpers"].reloadScript();
        } catch (err) {
          console.error("not found  widget helpers");
        }
      }
      try {
        window["searchHelpers"].hideFeatured();
      } catch (err) {
        console.error("not found  search helpers");
      }

      $(".js-product-item").removeData();
      if (
        !localStorage.getItem("hasCompared") ||
        localStorage.getItem("hasCompared") != "" ||
        localStorage.getItem("hasCompared") != "null"
      ) {
        setTimeout(function () {
          window["appSetupHelpers"].scanPID('');
          localStorage.getItem("hasCompared")
        }, 150);
      }
      this.afterViewChecked = true;
    }
  }

  ngOnInit() {
    var that = this;
    this.setStartViewType();
    window["hatchwidgetHelpers"].nvbuyinit();
    window["searchHelpers"].hideFeatured();
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      this.locale = params["locale"];
    });

    this.store
      .select(reducers.getProductDetails)
      .pipe(takeUntil(this.destroy$))
      .subscribe((productDetails) => {
        this.productDetails = productDetails;
        this.afterViewChecked = false;
      });

    this.store
      .select(reducers.getSuggestedProduct)
      .pipe(takeUntil(this.destroy$))
      .subscribe((suggestedProducts) => {
        if (suggestedProducts.productDetails.length > 0) {
          this.productDetails = suggestedProducts.productDetails;
          this.afterViewChecked = false;
        }
      });

    window.addEventListener("orientationchange", this.onOrientationChange);

    this.translate.onLangChange
      .pipe(takeUntil(this.destroy$))
      .subscribe((event: LangChangeEvent) => {
        setTimeout(() => {
          $(".cart-main-section .cart__empty")[0] &&
            ($(".cart-main-section .cart__empty")[0].innerHTML =
              that.translate.instant("empty_cart"));
          $(".cart-main-section .cart__title")[0] &&
            ($(".cart-main-section .cart__title")[0].innerHTML =
              that.translate.instant("cart"));
          $(".cart-main-section .cart__items").each(function () {
            $(this)
              .find(".cart_remove .cart-item__remove")
              .each(function (i) {
                $(".cart_remove .cart-item__remove")[i].innerHTML =
                  that.translate.instant("remove");
              });
          });

          /*Hide featured text from all products except top 2*/

          console.log("On INIT Even after");
        }, 1000);
      });
  }

  onOrientationChange() {
    var mqp = window.matchMedia("(orientation: portrait)");
    var mql = window.matchMedia("(orientation: landscape)");
    console.log("window.resize==>", $(window).width());
    if (/SHIELD/.test(navigator.userAgent) && (mqp.matches || mql.matches)) {
      window["hatchwidgetHelpers"].reloadScript();
      window["searchHelpers"].hideFeatured();

      $(".js-product-item").removeData();
      setTimeout(function () {
        window["appSetupHelpers"].scanPID("");
      }, 500);
    }
  }

  setStartViewType() {
    if (this.screenWidth === "SM") {
      this.layoutType = "grid";
    } else {
      this.retrieveViewType();
    }
    this.setGridBottomMargin();
  }

  retrieveViewType() {
    let view = "";
    try {
      view = JSON.parse(
        localStorage.getItem(
          this.getCurLocale() + this.sessionStorageViewTypeKey
        )
      );
    } catch (e) {}
    if (view === "grid" || view === "list") {
      this.layoutType = view;
    } else {
      this.layoutType = "grid";
    }
    this.curViewType.emit(this.layoutType);
  }

  getCurLocale(): string {
    return window.location.pathname.split("/")[1];
  }
  // Method to preprocess currency string and parse it to a number
  parseCurrencyStringToNumber(currencyString: string): number {
    // Remove non-numeric characters from the currency string and parse it to a number
    if (this.locale == "en-ph") {
      const cleanedString = currencyString.replace(/&[^;]+;/g, '');
      return parseFloat(cleanedString.replace(/[^\d.-]/g,''));
    } else {
      var regex = /-?\d+(?:,\d{3})*(?:\.\d+)?/;
      return parseFloat(currencyString.match(regex)[0].replace(/,/g, ""));
    }
  }
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  fpLoaded(data) {
    this.featuredProductLoaded = data;
  }

  sendData(data: string) {
    this.layoutType = data;
    this.setGridBottomMargin();

    window["hatchwidgetHelpers"].reloadScript();
    window["searchHelpers"].hideFeatured();

    $(".js-product-item").removeData();
    setTimeout(function () {
      window["appSetupHelpers"].scanPID('');
    }, 500);

    this.afterViewChecked = true;
    this.saveViewType(this.layoutType);
  }

  setGridBottomMargin() {
    this.setBottomMargin = false;
    if (this.layoutType === "grid") {
      this.setBottomMargin = true;
    }
  }

  compareProduct(productDetail) {
    let compareTranslatedLabel = "compare";
    localStorage.setItem("hasCompared", productDetail.digitialRiverID);
    let compareData = JSON.parse(
      localStorage.getItem(this.locale + "-compare")
    );
    let compareLabel = productDetail.addRemoveCompare;
    let total_item = 0;
    if (compareData && compareData != null) {
      for (let cpid of compareData) {
        if (cpid && cpid != null) {
          total_item = total_item + 1;
          if (cpid.productID == productDetail.productID) {
            compareLabel = "Remove";
          }
        }
      }
    }

    if (total_item == 0) {
      compareLabel = "Compare";
    }

    if (total_item <= 4) {
      if (compareLabel === "Compare") {
        this.translate
          .get("remove")
          .subscribe((r) => (compareTranslatedLabel = r));
        if (this.productCompare.indexOf(null) > -1) {
          $("button[id='compare_" + productDetail.productUPC + "']")
            .addClass("remove")
            .removeClass("compare")
            .text(compareTranslatedLabel);
          this.store.dispatch(
            new productActions.AddComparedProduct({
              upc: productDetail.productUPC,
              type: 3,
              locale: this.locale,
            })
          );
        }
      } else {
        this.translate
          .get("compare")
          .subscribe((r) => (compareTranslatedLabel = r));
        $("button[id='compare_" + productDetail.productUPC + "']")
          .addClass("compare")
          .removeClass("remove")
          .text(compareTranslatedLabel);
        this.store.dispatch(
          new productActions.RemoveComparedProduct(productDetail.productUPC)
        );
      }
    }
  }

  createThumbnailList(img) {
    if (window.matchMedia("(max-width: 1280px)").matches) {
      resizeImg(img, this.thumbnailWListLG, this.thumbnailHListLG, this.locale);
    } else {
      resizeImg(img, this.thumbnailWListXL, this.thumbnailHListXL, this.locale);
    }
  }

  createThumbnail(img) {
    resizeImg(img, this.thumbnailWGrid, this.thumbnailHGrid, this.locale);
  }

  showOverlay(productDetail) {
    let popupFlag = false;
    if (popupFlag == false) {
      popupFlag = true;
      showOverlay(
        productDetail,
        this.locale,
        this.store,
        this.hatchOverlayDisplay
      );
    }
  }

  getEANCode(productUPC) {
    return productUPC;
  }

  getFormPath(locale, displayName) {
    var currentdomain = window.location.hostname;
    currentdomain == "localhost" ? (currentdomain = "wwwdev.nvidia.com") : "";

    if (locale == "ru-ru") {
      var path =
        "https://" +
        currentdomain +
        "/" +
        locale +
        "/geforce/graphics-cards/30-series/notify-me-form/";
    } else {
      var path =
        "https://" +
        currentdomain +
        "//content/forms/af/nvidia-forms/products-notify-me/consumer-notify-me/?afAcceptLang=" +
        locale +
        "&productName=" +
        displayName;
    }
    return path;
  }

  transmitToCart() {
    this.cartService.emitChange("Data from child");
    var that = this;
    setTimeout(() => {
      $(".cart-main-section .cart__empty")[0] &&
        ($(".cart-main-section .cart__empty")[0].innerHTML =
          that.translate.instant("empty_cart"));
      $(".cart-main-section .cart__title")[0] &&
        ($(".cart-main-section .cart__title")[0].innerHTML =
          that.translate.instant("cart"));
      $(".cart-main-section .cart__items").each(function () {
        $(this)
          .find(".cart_remove .cart-item__remove")
          .each(function (i) {
            $(".cart_remove .cart-item__remove")[i].innerHTML =
              that.translate.instant("remove");
          });
      });
    }, 100);
  }
  calculatePercentageDifference(mrp: string, productPrice: string, locale: string): string | null {
    if (!mrp || !productPrice) {
      return null; // Return null if either MRP or product price is not provided
    }

    const mrpValue = this.parseCurrencyStringToNumber(mrp);
    const productPriceValue = this.parseCurrencyStringToNumber(productPrice);

    if (isNaN(mrpValue) || isNaN(productPriceValue)) {
      return null; // Return null if parsing fails
    }
    let priceDifference = Math.round(mrpValue - productPriceValue);
    //const percentageDifference = ((priceDifference / mrpValue) * 100).toFixed(1); // Round to one decimal place
    if (priceDifference && priceDifference > 0) {
      let priceformatval = getFormatedPrice("" + priceDifference, locale);
      if(locale == "da-dk" || locale === 'sv-se' || locale ==='nb-no'){
        return priceformatval.replace(".00","");
      }
      return priceformatval.split(".")[0];
    } else {
      return null;
    }
  }
}

import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Product } from '../../shared/interface/product';
import { Facets } from '../../shared/interface/facets';
import { ProductService } from '../product.service';
import { ProductSearchCriteria } from '../../shared/interface/product-search-criteria';
import { Store } from '@ngrx/store';
import * as reducers from '../../reducers';
import * as productActions from '../product.actions';
import { PreviousRouteService } from '../../shared/service/previous-route.service';


@Component({
  selector: "product-chips-filters",
  templateUrl:'./product-chips-filters.component.html',
  styleUrls:['./product-chips-filters.component.scss']
})
export class ProductChipsFiltersComponent implements OnInit {
  @Input() screenWidth: string;
  @Input() productFilter: Facets[];
  @Output() filterChange = new EventEmitter();
  @Output() clearFilter = new EventEmitter();
  @Output() toggleReset = new EventEmitter();
  @ViewChild("filtersReset") filtersResetBtn: ElementRef;
  // @Output() onResult: EventEmitter<any> = new EventEmitter<any>();

 


  disableReset: boolean = true;
  //defaultMinRangeVal;
  //defaultMaxRangeVal;

  someRange = [];
  filterCriteria = {
    query: "Geforce",
  };
  maxToShow: number = 8;
  maxes = [];
  showMoreLessStates = [];
  showMoreLabel: string = "show_more";
  showLessLabel: string = "show_less";
  //showMore = 8;
  private origin;
  private countryCode;
  private feSkuInfo;
  private feAPIs = {
    "http://localhost:4200": "https://stg-api.store.nvidia.com",
    "https://dev-shop.nvidia.com": "https://stg-api.store.nvidia.com",
    "https://stg-shop.nvidia.com": "https://stg-api.store.nvidia.com",
    "https://shop.nvidia.com": "https://api.store.nvidia.com",
    "https://dev-store.nvidia.com": "https://stg-api.store.nvidia.com",
    "https://stg-store.nvidia.com": "https://api.store.nvidia.com",
    "https://store.nvidia.com": "https://api.store.nvidia.com",
  };
  constructor(
    private store: Store<reducers.State>,
    private previousRouteService: PreviousRouteService,
    private productService: ProductService,
    private http: HttpClient
  ) {
    this.origin = window.location.origin;
    this.countryCode = window.location.pathname
      .split("/")[1]
      .split("-")[1]
      .toUpperCase();
    if (this.countryCode == "GB") {
      this.countryCode = "UK";
    }
  }

  // accordian init
  accordionStates: { [key: string]: boolean } = {
    'category_filter': true,
    'gpu_filter': true,
    'manufacturer_filter': true,
    'screen_size_filter': true,
    'has_offer_filter': true,
    'nv_technology_filter': true,
  };
  
  // Function to toggle accordion state
  toggleAccordion(filterField: string) {
    this.accordionStates[filterField] = !this.accordionStates[filterField];
  }

  selectedCategorieslist:{ }[];
   categories ;
   selectedGPU;
   selectedMnu;
   selectedScrSize;
   selectedOffer;
   selectedNvTech;
   selectedsffReady;
  //  sendResult(selected, productFs) {
  //   const result = selected;
  //   const productF = productFs
  //   console.log("productFs---->", productFs)
  //   this.onResult.emit([result, productF]);
  // }
  checkSelectedCategorieslist;
  totalLength = 0;



  ngOnInit() {

    this.store
      .select(reducers.getProductParams)
      .subscribe((params: ProductSearchCriteria) => {
        this.disableReset = false;
        let arr = Object.keys(params);   
        // if(arr.length > 3){
        //   console.log("params-->", params, arr)

        // }     
         this.categories = params['category'] ? params['category'].split(',') : [];
         this.selectedGPU = params['gpu'] ? params['gpu'].split(',') : [];
         this.selectedMnu = params['manufacturer'] ? params['manufacturer'].split(',') : [];
         this.selectedScrSize = params['screen_size'] ? params['screen_size'].split(',') : [];
         this.selectedOffer = params['has_offer'] ? params['has_offer'].split(',') : [];
         this.selectedNvTech = params['nv_technology'] ? params['nv_technology'].split(',') : [];
         this.selectedsffReady = params['sff_ready'] ? params['sff_ready'].split(',') : [];
        
         this.selectedCategorieslist= [params].map((value, index) => ({
          // index,
          categories:this.categories,
          selectedGPU:this.selectedGPU,
          selectedMnu:this.selectedMnu,
          selectedScrSize:this.selectedScrSize,
          selectedOffer:this.selectedOffer,
          selectedNvTech:this.selectedNvTech,
          selectedsffReady:this.selectedsffReady
        }));
        
        
        if (
          params.page === 1 &&
          params.limit &&
          params.locale &&
          (arr.length === 3 ||
            (arr.length === 4 &&
              (arr.indexOf("search") >= 0 || arr.indexOf("sorting") >= 0)) ||
            (arr.length === 5 &&
              arr.indexOf("search") >= 0 &&
              arr.indexOf("sorting") >= 0))
        ) {
          this.disableReset = true;
        }
        /*if (params.page === 1 && params.limit && params.locale &&
					(arr.length === 3 || (arr.length === 4 && (arr.indexOf('search') >= 0 || arr.indexOf('sorting') >= 0 || (arr.indexOf('price') >= 0 && this.isDefaultPriceRange(params)))) ||
					(arr.length === 5 && (arr.indexOf('search') >= 0 && arr.indexOf('sorting') >= 0 || arr.indexOf('price') >= 0 && this.isDefaultPriceRange(params))) ||
					(arr.length === 7 && arr.indexOf('search') >= 0 && arr.indexOf('sorting') >= 0 && arr.indexOf('price') >= 0 && this.isDefaultPriceRange(params)))) {
					this.disableReset = true;
				}*/
        this.filtersResetBtn &&
          (this.filtersResetBtn.nativeElement.disabled = this.disableReset);
        this.toggleReset.emit(this.disableReset);
      });

      
      for(let i = 0; i < this.selectedCategorieslist.length; i++ ){
          const obj = this.selectedCategorieslist[i]
          let hasValue = false;

          // Iterate over each property of the object
          for (const key in obj) {
              if (Object.prototype.hasOwnProperty.call(obj, key)) {

                if (Array.isArray(obj[key])) {
                  this.totalLength += obj[key].length;
                  console.log('totalLength-->', this.totalLength);
                  if(this.totalLength>1){
                  hasValue = true;
                }
              }
             
              }
          }

          if (hasValue) {
            this.checkSelectedCategorieslist = true;
              console.log("At least one property has a value.--->", hasValue);
          } else {
            this.checkSelectedCategorieslist = false;
              console.log("No property has a value.--->", hasValue);
          }
        }
        

    }
 
  /*isDefaultPriceRange(params):boolean {
		let minMax = params.price.split(',');
		return minMax[0].trim() == this.defaultMinRangeVal && minMax[1].trim() == this.defaultMaxRangeVal;
	}*/
  
  

  changed(filterItem, values, val, i) {

    // console.log("---ghghj->")

    
    let payload;
    jQuery("button[class='extra_style buy-link i-408']").remove();
    if (filterItem && (filterItem.fieldType === "checkbox" || filterItem.fieldType === "Checkbox")) {
      payload = {
        displayName: filterItem.displayName,
        filterField: filterItem.filterField,
        values: values,
        trueValue: val,
        fieldType: filterItem.fieldType,
        filterValues: filterItem.filterValues,
      };
    }
    if (val && val.type && val.type.fieldType === "range") {
      payload = {
        displayName: val.type.displayName,
        filterField: val.type.filterField,
        fieldType: val.type.fieldType,
        values: val.range,
        filterValues: [
          val.type.selectedMinRangeVal,
          val.type.selectedMaxRangeVal,
        ],
        defaultMinRangeVal: val.type.defaultMinRangeVal,
        defaultMaxRangeVal: val.type.defaultMaxRangeVal,
      };
      //this.defaultMinRangeVal =  val.type.defaultMinRangeVal;
      //this.defaultMaxRangeVal =  val.type.defaultMaxRangeVal;
    }

    // this.store.dispatch(new productActions.UpdateProductFilter(payload));
    this.previousRouteService.setIsFromCompare(false);
    this.store.dispatch(new productActions.UpdateProductParams(payload));
    window["hatchwidgetHelpers"].nvbuyinit();
  }

  onFilterRangeInputChange(event) {
    this.filterCriteria[event.name] = [];
    this.filterCriteria[event.name].push(event.values);
    let copyOffilterCriteria = JSON.stringify(this.filterCriteria);
    this.filterChange.emit(copyOffilterCriteria);
  }

  resetFilters(e) {
    e.target.blur();
    this.clearFilter.emit();
  }

  anySelectedOutsideMaxToShow(filterValues): boolean {
    for (let i = this.maxToShow; i < filterValues.length; i++) {
      if (filterValues[i].checked) {
        return true;
      }
    }
    return false;
  }

  getMaxToShow(filterItemIndex, filterValues): number {
    if (this.maxes[filterItemIndex]) {
      return this.maxes[filterItemIndex];
    } else if (filterValues && this.anySelectedOutsideMaxToShow(filterValues)) {
      this.maxes[filterItemIndex] = filterValues.length;
      return filterValues.length;
    }
    return this.maxToShow;
  }

  showMore(filterItemIndex, filterValues): boolean {
    if (this.showMoreLessStates[filterItemIndex] === undefined) {
      this.showMoreLessStates[filterItemIndex] = true;
      if (filterValues && this.anySelectedOutsideMaxToShow(filterValues)) {
        this.showMoreLessStates[filterItemIndex] = false;
      }
    }
    return this.showMoreLessStates[filterItemIndex];
  }

  toggleShowMoreLess(filterItemIndex, len) {
    if (this.showMoreLessStates[filterItemIndex]) {
      this.maxes[filterItemIndex] = len;
    } else {
      this.maxes[filterItemIndex] = this.maxToShow;
    }
    this.showMoreLessStates[filterItemIndex] =
      !this.showMoreLessStates[filterItemIndex];
  }

  getShowMoreLessLabel(filterItemIndex): string {
    if (this.showMoreLessStates[filterItemIndex]) {
      return this.showMoreLabel;
    }
    return this.showLessLabel;
  }

  isStudioTheme(): boolean {
    if(localStorage.getItem('cat2')) {
      return true;
    }
    return false;
  }


}
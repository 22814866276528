


import {
  ActionReducerMap,
  createSelector,
  createFeatureSelector,
  ActionReducer,
  MetaReducer,
} from '@ngrx/store';
import { Observable } from 'rxjs';
import { routerReducer } from '@ngrx/router-store';
import { environment } from '../../environments/environment';

import { combineReducers } from '@ngrx/store';

import * as fromProduct from '../products/product.reducers';
import * as fromRouter from '@ngrx/router-store';

export interface State {

  product: fromProduct.ProductState;

}

export const reducers: ActionReducerMap<State> = {

  product: fromProduct.reducer
  
};

const productionReducer: ActionReducer<State> = combineReducers(reducers);

export function reducer( state: any, action: any ) {

    return productionReducer(state, action);

}

/**
 * Products
 */
export const getProductState = (state: State) => state.product;
export const getProductFilter = createSelector(getProductState, fromProduct.getProductFilter);
export const getProductSearch = createSelector(getProductState, fromProduct.getProductSearch);
export const getProductSort = createSelector(getProductState, fromProduct.getProductSort);
export const getTotalProducts = createSelector(getProductState, fromProduct.getTotalProducts);
export const getProductFeatured = createSelector(getProductState, fromProduct.getProductFeatured);
export const getProductDetails = createSelector(getProductState, fromProduct.getProductDetails);
export const getProductParams = createSelector(getProductState, fromProduct.getProductParams);
export const getProductLoading = createSelector(getProductState, fromProduct.getProductLoading);
export const getProductCompare = createSelector(getProductState, fromProduct.getProductCompare);
export const getRetailInfo = createSelector(getProductState, fromProduct.getRetailInfo);
export const getRetailInfoLoading = createSelector(getProductState, fromProduct.getRetailInfoLoading);
export const getSuggestedProduct = createSelector(getProductState, fromProduct.getSuggestedProduct);
export const getLocale = createSelector(getProductState, fromProduct.getLocale);
export const getIsFeaturedProductIncludedInCount = createSelector(getProductState, fromProduct.getIsFeaturedProductIncludedInCount);
export const getGlobalHeader = createSelector(getProductState, fromProduct.getGlobalHeader);
export const getSubBrandHeader = createSelector(getProductState, fromProduct.getSubBrandHeader);
import { Component, OnInit, Input, HostListener } from '@angular/core';
import { resizeImg, showImgError, getBuyButtonText, stopBodyScrolling } from '../../shared/utils/utils';
import { ActivatedRoute, Router } from '@angular/router';



@Component({
  selector: 'hatch-overlay',
  templateUrl: './hatch-overlay.component.html',
  styleUrls: ['./hatch-overlay.component.scss']
})
export class HatchOverlayComponent implements OnInit {
  @Input() hatchOverlayDisplay: string;
  @Input() showSpinner: boolean;
  @Input() retailInfo;
  @Input() productDetail;
  @Input() winPageYOffset;
  locale: string;
  resizeImg;
  showImgError;
  stopBodyScrolling;
  getBuyButtonText;
  screenWidth: string;
  thumbnailW: number = 130;
  thumbnailH: number = 130;
  showBlankRetailers: boolean = false;

  constructor(private route: ActivatedRoute) {
	this.resizeImg = resizeImg;
	this.showImgError = showImgError;
	this.getBuyButtonText = getBuyButtonText;
	this.stopBodyScrolling = stopBodyScrolling;
  }

  ngOnInit() {
	this.route.params.subscribe(params => {
       this.locale = params['locale'];
    });
    if(window.location.href.indexOf('10.110') > -1) {
      //this.showBlankRetailers = true;
    }
  }
  
  @HostListener('window:resize') onScreenResize() {
  	this.getScreenWidth();
  }
  
  getScreenWidth() {
	if (window.matchMedia("(min-width: 1281px)").matches) {
		this.screenWidth = "XL";
	} else if (window.matchMedia("(min-width: 1025px)").matches) {
		this.screenWidth = "LG";
	} else if (window.matchMedia("(min-width: 768px)").matches) {
		this.screenWidth = "MD";
	} else {
		this.screenWidth = "SM";
	}
  }

  hideHatchOverlay() {
	this.hatchOverlayDisplay = "none";
	document.body.style.overflow = '';
	this.stopBodyScrolling(false);
	window.scroll(0, this.winPageYOffset);
  }
  
  closeOverlay(e, overlayContainer) {
  	if ((e.type === 'click' && e.target === overlayContainer) || (e.type === 'keyup' && e.keyCode === 27)) {
  		this.hideHatchOverlay();
  	}
  }


}

import { Injectable, Input, Directive } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ProductService } from './product.service';
import { Observable } from 'rxjs';



@Injectable()
export class DataResolverService implements Resolve<Observable<any>> {
  constructor(private productService: ProductService) {}
  @Input() show: boolean =true;
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return null;//this.productService.getFeDetails();
  }
}
<div class="banner-wrapper 2244 home banner_home__Vtt9b">
  <div class="banner_banner-content__nXqXz container"> 
     <div class="breadcrumb_container">
     <nav aria-label="breadcrumb" class="breadcrumb-wrapper" *ngIf="primaryBreadcrumb && secondaryBreadcrumb && secondaryBreadcrumb != 'default'">
        <ol class=" breadcrumb breadcrumb_dark__K3Cvh">
          <li class="breadcrumb-item "><a>Marketplace</a></li>
          <li *ngIf="secondaryBreadcrumb && secondaryBreadcrumb != 'default'" class="breadcrumb-item active">{{ secondaryBreadcrumb  | translate }} </li>
        </ol>
      </nav></div>
      <div style="width: 950px;" class="">
         <div class="banner_col-md-8__14wka col-md-6 col-xs-12 col-sm-10">

           <h1  
           class="banner-heading h--large" kuiText
             [class.hide-shop-geforce-graphics-cards-header]="showFilterCol">
             <span *ngIf="primaryBreadcrumb && secondaryBreadcrumb && secondaryBreadcrumb == 'default' && primarylapShop != 'primary-laptop'" class="">{{ "header_text" | translate }}</span>
             <span *ngIf="primaryBreadcrumb && secondaryBreadcrumb && secondaryBreadcrumb != 'default' && primarylapShop != 'primary-laptop'" class="">{{ secondaryBreadcrumb | translate }}</span>
             <span *ngIf="!(primaryBreadcrumb && secondaryBreadcrumb) && primarylapShop != 'primary-laptop'" class="">{{ "header_text" | translate }}</span>

             <span *ngIf="primarylapShop == 'primary-laptop' && secondaryBreadcrumb != 'default' " class="">{{ "header_text_gaming_studio"| translate }} </span>

           </h1>
           <p *ngIf="primaryBreadcrumb && secondaryBreadcrumb  && secondaryBreadcrumb == 'default'" class="nv-subHeaderText">{{ "subheader_text" | translate }}</p>
           <p *ngIf="primaryBreadcrumb && secondaryBreadcrumb && secondaryBreadcrumb != 'default'" class="nv-subHeaderText">{{ (secondaryBreadcrumb + "_subheader_text") | translate }}</p>
           <p *ngIf="!(primaryBreadcrumb && secondaryBreadcrumb)" class="nv-subHeaderText">{{ "subheader_text" | translate }}</p>
            
         </div>
      </div>
   </div>
</div>


<div class="general-container general-container-custom">
	<div class="main-wrapper">
		<div class="nav-container">
			<a class="back-to-search" href="javascript:void(0)" (click)="backToSearch()">{{ 'back_to_search' | translate
				}}</a>
		</div>
		<div class="heading-container">
			<h1 kuiText class="top-heading h1-like-h2 h--medium">{{ 'compare' | translate}}</h1>
		</div>
	</div>
	<div class="product-main-container">
		<div class="items-container" tabindex="0">
			<div class="item-container" *ngFor="let compareItem of productCompare; let i = index">
				<div *ngIf="compareItem" style="position: relative; margin-top:2px" tabindex="0">
					<div>
						<button kuiButton [size]="'standard'" [danger]="false" [type]="'tertiary'" class="remove"
							(click)="removeProduct(compareItem)">{{ 'remove' | translate }}</button>
					</div>
					<div class="compare-page-img-container">
						<kui-image aspectRatio="1-1">
							<img title="{{compareItem.displayName}}" src="{{compareItem.imageURL}}"
								alt="{{compareItem.displayName}}" class="compare-page-item-img"
								(load)="resizeImg($event.target, thumbnailW, thumbnailH, locale)"
								(error)="showImgError($event.target)">
						</kui-image>
					</div>
					<div kuiText class="compare-item-name" [innerHTML]="compareItem.productTitle">
						<!-- {{compareItem.productTitle}} -->
					</div>


					<div #drSpinnerCont *ngIf="compareItem.digitialRiverID" class="dr-spinner-bg-grid 26">
						<div #drId class="comparepage product-row__item js-product-item 27" data-analytics="btn-dr-id"
							[attr.data-digital-river-id]="changeDRId(compareItem.digitialRiverID)">
							<div #priceDivCompare class="price compare-page-price h--smallest js-product-price clearfix"
								style="margin-bottom: -5px;"></div>
							<div class="price compare-page-price h--smallest outside-page">
								<span kuiText
									[innerHtml]="extractDRPriceIntegerDot(priceDivCompare, drSpinnerCont)"></span><span
									kuiText class="decimal" [innerHtml]="extractDRPriceDecimal(priceDivCompare)"></span>
							</div>
							<div class="buy-link-container cta-preorder mobile-top" (click)="transmitToCart()">
								<!-- Logic for NOTIFY ME -->
								<!-- DR NOTIFY ME -->
								<a kuiText *ngIf="compareItem.prdStatus === 'dr_notify_me'"
									class="featured-buy-link cta-link-large link-btn brand-green  cta-button show-dr-notify-me 36"
									href="javascript:void(0);">
									{{ 'out_of_stock' | translate }}
								</a>
								<!-- END OF DR NOTIFY ME -->
								<!-- NV NOTIFY ME -->
								<a kuiText *ngIf="compareItem.prdStatus === 'nv_notify_me'"
									class="featured-buy-link cta-link-large link-btn brand-green  cta-button show-nv-notify-me 41"
									data-nvnotify-form-path="/content/forms/af/nvidia-forms/products-notify-me/consumer-notify-me"
									href="javascript:void(0);">
									{{ 'out_of_stock' | translate }}
								</a>
								<!-- END OF NV NOTIFY ME -->
								<a kuiText *ngIf="compareItem.prdStatus === 'buy_now'"
									class="featured-buy-link cta-link-large link-btn brand-green  cta-button"
									href="javascript:void(0);">
									{{ 'add_to_cart' | translate }}
								</a>
								<!-- OUT OF STOCK -->
								<a kuiText *ngIf="compareItem.prdStatus === 'out_of_stock'"
									class="featured-buy-link cta-link-large link-btn brand-green  cta-button show-out-of-stock 49"
									href="javascript:void(0);">
									{{ 'out_of_stock' | translate }}
								</a>
								<!-- END OF OUT OF STOCK -->


								<!-- 	<a class="featured-buy-link link-btn brand-green js-add-button cta-button show-out-of-stock " href="javascript:void(0);">
									{{ 'add_to_cart' | translate }}
								</a> -->

							</div>
							<!-- <div class="product__message limit-per-customer">{{'limit_5' | translate}}</div> -->
							<div *ngIf="compareItem.digitialRiverID && compareItem.retailers?.length > 0"
								class="buy fpc-55" [class.fe-pids]="compareItem.manufacturer==='NVIDIA'">
								<div [attr.class]="getEANCode(compareItem.productUPCOriginal)" [attr.status]="compareItem.prdStatus"
									[attr.isFounderEdition]="compareItem.isFounderEdition"
									[attr.id]="compareItem.productID" style="display:none">{{compareItem.retailers |
									json}}</div>
								<div>
									<button kuiButton [size]="'standard'" [danger]="false" [type]="'tertiary'"
										class="extra_style buy-link cta-link-larges 130 buy-frm-partner"
										[attr.data-pid-code]="compareItem.productID"
										[attr.data-mpn-code]="getEANCode(compareItem.productUPCOriginal)"
										data-color="#76b900" data-secondary-color="#fff">
										{{'buy_from_partners' | translate}}
									</button>
								</div>
							</div>
							<div class="ship-date 60"></div>
						</div>
					</div>
					<div *ngIf="compareItem.digitialRiverID">
						<dr-loading-indicator [show]="drLoading"></dr-loading-indicator>
					</div>

					<div *ngIf="!compareItem.digitialRiverID">
						<div class="price compare-page-price h--smallest 68">
							<span kuiText *ngIf="locale === 'ru-ru' || locale==='pl-pl' || locale==='vi-vn'"
								[innerHtml]="getFormatedPrice(compareItem.productPrice,locale)"></span>
							<span kuiText *ngIf="locale !== 'ru-ru' && locale!=='pl-pl' && locale!=='vi-vn'"
								[innerHtml]="getPriceIntegerDot(compareItem.productPrice)"></span>
							<span kuiText class="decimal"
								*ngIf="locale !== 'ru-ru' && locale!=='pl-pl'  && locale!=='vi-vn'">{{getPriceDecimal(compareItem.productPrice)}}</span>
						</div>
						<div class="buy-link-container clearfix 72"
							[class.fe-pids]="compareItem.manufacturer==='NVIDIA'">

							<!-- <span *ngIf="locale === 'en-us'" class="extra_style buy-link" style="visibility:hidden" [attr.data-mpn-code]="getEANCode(compareItem.productUPCOriginal)" data-color="#76b900" data-secondary-color="#fff" (click)="productName(compareItem)" >{{getBuyButtonText(locale) | translate | uppercase}}</span>
							 <input *ngIf="locale !== 'en-us'" type="button" class="buy-link" value="{{getBuyButtonText(locale) | translate | uppercase}}" (click)="showOverlay(compareItem)" /> -->

							<!-- <span class="extra_style buy-link" style="visibility:hidden" [attr.data-mpn-code]="getEANCode(compareItem.productUPCOriginal)" data-color="#76b900" data-secondary-color="#fff" (click)="productName(compareItem)" >{{getBuyButtonText(locale) | translate | uppercase}}</span> -->
							<div [attr.class]="getEANCode(compareItem.productUPCOriginal)" [attr.status]="compareItem.prdStatus"
								[attr.isFounderEdition]="compareItem.isFounderEdition"
								[attr.id]="compareItem.productID" style="display:none">{{compareItem.retailers | json}}
							</div>
							<!-- <span class="extra_style buy-link" style="visibility:hidden" [attr.data-mpn-code]="getEANCode(compareItem.productUPCOriginal)" data-color="#76b900" data-secondary-color="#fff" (click)="productName(compareItem)" >{{getBuyButtonText(locale) | translate | uppercase}}</span> -->
							<div *ngIf="compareItem.prdStatus !== 'gf_notify_me'">
								<div *ngIf="compareItem.prdStatus !== 'upcoming'">
									<div
										*ngIf="compareItem.prdStatus !== 'check_availability' && compareItem.prdStatus !== 'out_of_stock'">
										<button kuiButton [size]="'standard'" [danger]="false" [type]="'tertiary'"
											class="extra_style buy-link cta-link-large"
											[attr.data-mpn-code]="getEANCode(compareItem.productUPCOriginal)"
											[attr.data-pid-code]="compareItem.productID" data-color="#76b900"
											data-secondary-color="#fff"
											(click)="productName(compareItem)">{{compareItem.prdStatus | translate
											}}</button>
									</div>
								</div>
							</div>
							<div *ngIf="compareItem.prdStatus === 'gf_notify_me'"
								class="buy cta-preorder mobile-top lb_iframe 89"
								[attr.data-href]="getFormPath(locale, compareItem.productTitle)" href="#"
								target="overlay"
								onclick="NVIDIAGDC.button.click(this, $(this).data('href'),true, function() { ;return NVIDIAGDC.button.callbacks(this); }); return false;">
								<a kuiText
									class="featured-buy-link cta-link-large link-btn brand-green cta-button show-nv-notify-me notify-me-btn"
									data-nvnotify-form-path="/content/forms/af/nvidia-forms/products-notify-me/consumer-notify-me"
									href="javascript:void(0);">{{'notify_me' | translate}}</a>
							</div>

							<a kuiText
								*ngIf="compareItem.prdStatus === 'upcoming' || compareItem.prdStatus === 'out_of_stock'"
								class="featured-buy-link cta-link-large link-btn brand-green  cta-button stock-grey-out 93"
								href="javascript:void(0);">
								{{ compareItem.prdStatus | translate }}
							</a>

							<button kuiButton [size]="'standard'" [danger]="false" [type]="'tertiary'"
								*ngIf="compareItem.prdStatus === 'check_availability'"
								class="extra_style buy-link cta-link-large 97"
								[attr.data-mpn-code]="getEANCode(compareItem.productUPCOriginal)"
								[attr.data-pid-code]="compareItem.productID" data-color="#76b900"
								data-secondary-color="#fff" (click)="productName(compareItem)">{{'check_availability' |
								translate}}</button>
						</div>
					</div>


					<div class="item-specs-container">
						<div class="top-empty-spec"></div>
						<div *ngFor="let specs of specTitles; let i=index">
							<div *ngIf="screenWidth === 'XS'" class="item-spec empty-item-spec"></div>
							<div class="item-spec 113" [ngStyle]="{'height.px': specs.customHeight}">
								{{compareItem.compareProductInfo[i]?.value ? compareItem.compareProductInfo[i]?.value :
								"--"}}
							</div>
						</div>
					</div>
				</div>
				<div *ngIf="!compareItem" style="position: relative;">
					<div>
						<button class="add-compare" kuiButton [size]="'standard'" [danger]="false" [type]="'tertiary'"
							(click)="backToSearch()">{{ 'add' | translate }}</button>
					</div>
					<div class="compare-page-img-container" style="visibility: visible;">
						<div class="no-image compare-page-no-image"></div>
					</div>
					<div class="compare-item-name">

					</div>
					<div class="price compare-page-price h--smallest 129">

					</div>
					<div class="buy-link-container">

					</div>
					<div *ngIf="specTitles.length" [ngClass]="{'empty-specs-container':specTitles.length > 0 }"
						class="item-specs-container">
						<div class="top-empty-spec"></div>
						<div *ngFor="let specTitle of specTitles">
							<div *ngIf="screenWidth === 'XS'" class="item-spec empty-item-spec"></div>
							<div class="item-spec 139" ></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div *ngIf="specTitles.length" class="spec-titles-container">
		<div kuiText class="top-spec-title">{{ 'specs' | translate }}</div>
		<div *ngFor="let specTitle of specTitles">
			<div kuiText class="spec-title 149" [ngStyle]="{'max-height': auto}">{{specTitle.key | translate}}
			</div>
			<div *ngIf="screenWidth === 'XS'" class="spec-title empty-spec-title"></div>
		</div>
	</div>
	<div class="cover"></div>
	<!-- <div *ngIf="locale!='en-us'" class="compare-footer">
		<footer></footer>
	</div> -->
</div>
<div class="compare-footer compare-footer-position-unset">
	<footer></footer>
</div>
<hatch-overlay [showSpinner]="retailInfoLoading$ | async" [winPageYOffset]="winPageYOffset"
	[hatchOverlayDisplay]="hatchOverlayDisplay" [retailInfo]="retailInfo$ | async"
	[productDetail]="productDetail"></hatch-overlay>

import {takeUntil} from 'rxjs/operators';
import { Component, OnInit, AfterViewChecked, ViewChild, ElementRef, HostListener } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as reducers from '../../../reducers';
import { ProductCompare } from '../../../shared/interface/product-compare';
import * as productActions from '../../product.actions';
import { Store } from '@ngrx/store';
import { Observable ,  Subject } from 'rxjs';
import { Location } from '@angular/common';
import {ProductService} from '../../product.service';
import { ActivatedRoute, Router } from '@angular/router';
import { resizeImg, changeDRId, showOverlay, showImgError, getBuyButtonText, getFormatedPrice,getPriceIntegerDot, getPriceDecimal, extractDRPriceIntegerDot, extractDRPriceDecimal, productName } from '../../../shared/utils/utils';
import { SeoService } from '../../../shared/service/seo.service';
import { PreviousRouteService } from '../../../shared/service/previous-route.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';



@Component({
  selector: 'compare-page',
  templateUrl: './compare-page.component.html',
  styleUrls: ['./compare-page.component.scss']
})
export class ComparePageComponent implements OnInit {
    @ViewChild('drId') drId: ElementRef;

    buyNow: string = 'BUY NOW';
    destroy$: Subject < boolean > = new Subject < boolean > ();
    productCompare;
    specTitles: any[] = [];
    customHeight: {};
    sessionStorageSpecTitlesKey: string = "spec-titles";
    retailInfoLoading$;
    retailInfo$;
    hatchOverlayDisplay: string = "none";
    productDetail;
    winPageYOffset: number;
    customProductCompare: any[] = [];
    screenWidth: string;
    resizeImg;
    showImgError;
    productName;
    getBuyButtonText;
    getPriceIntegerDot;
    getFormatedPrice;
    getPriceDecimal;
    extractDRPriceIntegerDot;
    extractDRPriceDecimal;
    thumbnailW: number = 245;
    thumbnailH: number = 138;
    changeDRId;
    locale: string;
    afterViewChecked: boolean = false;
    drLoading: boolean = false;
    drTimer;
    

    constructor(private store: Store < reducers.State > ,
        private location: Location,
        private route: ActivatedRoute,
        private router: Router,
        private seoService: SeoService,
        private previousRouteService: PreviousRouteService,
        private elRef: ElementRef,
        private translate: TranslateService,
        private productService: ProductService,
        private http: HttpClient) {
        this.origin = window.location.origin;
        this.countryCode = window.location.pathname.split("/")[1].split("-")[1].toUpperCase();
        if (this.countryCode == "GB") {
            this.countryCode = "UK";
        }
        this.resizeImg = resizeImg;
        this.productName = productName;
        this.changeDRId = changeDRId;
        this.showImgError = showImgError;
        this.getBuyButtonText = getBuyButtonText;
        this.getPriceIntegerDot = getPriceIntegerDot;
        this.getFormatedPrice=getFormatedPrice;
        this.getPriceDecimal = getPriceDecimal;
        this.extractDRPriceIntegerDot = extractDRPriceIntegerDot;
        this.extractDRPriceDecimal = extractDRPriceDecimal;
        this.getScreenWidth();
        let fedata = this.route.snapshot.data;
        var tempData = fedata.feinfo;
        this.productService.setFedata(tempData);
    }
    private feAPIs = {
        'http://localhost:4200': 'https://stg-api.store.nvidia.com',
        'https://dev-shop.nvidia.com': 'https://stg-api.store.nvidia.com',
        'https://stg-shop.nvidia.com': 'https://api.store.nvidia.com',
        'https://shop.nvidia.com': 'https://api.store.nvidia.com',
        'https://dev-store.nvidia.com': 'https://stg-api.store.nvidia.com',
         'https://stg-store.nvidia.com': 'https://api.store.nvidia.com',
         'https://store.nvidia.com': 'https://api.store.nvidia.com'
    };
    private origin;
    private countryCode;

    ngOnInit() {
        var that = this;
        window['hatchwidgetHelpers'].nvbuyinit();


        var element = document.querySelector("body");
		let categoryClassname = "laptop_studio_white";
        
		if (localStorage?.getItem('cat2')) {

			element.classList.add("base_v4", categoryClassname);

		} else {
			element.classList.remove("laptop_studio_white");  

		}


        this.route.params.subscribe(params => {
            this.locale = params['locale'];
        });
        this.store.select(reducers.getProductCompare).subscribe(productCompare => {
            this.productCompare = productCompare; 

            if (!this.specTitles[0]) {
                this.displaytableData();
            }
        });
        if (this.countryCode == "DE" || this.countryCode == "FR" || this.countryCode == "ES" || this.countryCode == "NL") {
            let femap = {};
            this.store.select(reducers.getProductCompare).subscribe(productCompare => {
                let pcArray = productCompare;
                this.productCompare = JSON.parse(JSON.stringify(productCompare));
                //_.merge({}, this.productCompare);
                try{
                    for (var fe of this.productService.getFeData()) {
                        for (var pc of this.productCompare) {
                            if (pc!=null && pc && fe.fe_sku == pc.productUPC) {
                                if (fe.is_active == "true") {
                                    pc['prdStatus'] = "buy_now";
                                } else {
                                    pc['prdStatus'] = "out_of_stock";
                                }
                            }
                        }
                    }
                } catch(err){
                    console.error(err);
                }
                if (!this.specTitles[0]) {
                    this.displaytableData();
                }
            });
        }


        this.retailInfoLoading$ = this.store.select(reducers.getRetailInfoLoading);
        this.retailInfo$ = this.store.select(reducers.getRetailInfo);
        this.seoService.generateTags(this.locale, true, 'product');
        this.previousRouteService.setIsFromCompare(true);


        this.translate.onLangChange.pipe(takeUntil(this.destroy$)).subscribe((event: LangChangeEvent) => {
            setTimeout(() => {
                $('.cart-main-section .cart__empty')[0] && ($('.cart-main-section .cart__empty')[0].innerHTML = that.translate.instant('empty_cart'));
                $('.cart-main-section .cart__title')[0] && ($('.cart-main-section .cart__title')[0].innerHTML = that.translate.instant('cart'));
                $('.cart-main-section .cart__items').each(function() {
                    $(this).find('.cart_remove .cart-item__remove').each(function(i) {
                        $('.cart_remove .cart-item__remove')[i].innerHTML = that.translate.instant('remove');
                    });
                });
                // $('.cart-main-section .cart__checkout-button')[0] && ($('.cart-main-section .cart__checkout-button')[0].innerHTML = that.translate.instant('checkout'));
            }, 1000);
        });


    }
    valueTrimmer(v){
        let t;
        if (v.length>0) {
          t=v.trim()
        }
        return t;
      }
    @HostListener('window:resize') onScreenResize() {
        this.getScreenWidth();
        clearTimeout(this.drTimer);
        this.drTimer = setTimeout(() => {
            $('.js-product-item').removeData();
            setTimeout(function() {
                window['appSetupHelpers'].scanPID()
            }, 500);
            //  if(this.locale == 'en-us'){

            window['hatchwidgetHelpers'].reloadScript();
            //}
        }, 1500);
    }

    displaytableData() {

        var defaultHeights = {
            "de-de": 54,
            "fr-fr": 54,
            "en-us": 27,
            "en-gb": 27,
            "en-ca": 27,
            "fr-ca": 54,
            "en-au": 27,
            "ru-ru": 54,
            "pl-pl": 54,
            "es-es": 54,
            "nl-nl": 54,
            "it-it": 54,
            "zh-tw": 54
        };

        var defaultHeight = 27;
        var curLocale = window.location.pathname.split('/')[1];

        this.customHeight = {};
        if (this.productCompare) {

            this.customProductCompare = this.productCompare.map((item) =>
                Object.assign({}, item)
            );
            if (this.productCompare[0] != null) {
                this.specTitles = [];
            }
            //adding specs 
            for (let k = 0; k < this.productCompare.length; k++) { 
                if (this.productCompare[k] != null) {
                    for (let i = 0; i < this.productCompare[k].compareProductInfo.length; i++) {
                        this.specTitles.push(this.productCompare[k].compareProductInfo[i].name);
                        this.specTitles = Array.from(new Set(this.specTitles));
                        this.customHeight[this.productCompare[k].compareProductInfo[i].name] = defaultHeights[curLocale];
                    }
                }
            }
            
            for (let k = 0; k < this.customProductCompare.length; k++) { 
                let customCompareList = [];
                for (let j = 0; j < this.specTitles.length; j++) {
                    let customCompare = {};
                    customCompare['name'] = this.specTitles[j];

                    if (this.customProductCompare[k]) {
                        if (this.customProductCompare[k].compareProductInfo)
                            for (let c = 0; c < this.customProductCompare[k].compareProductInfo.length; c++) {
                                if (this.customProductCompare[k].compareProductInfo[c].name === this.specTitles[j]) {
                                    try {
                                        if (this.customProductCompare[k].compareProductInfo[c].value) {
                                            let cellChar = this.customProductCompare[k].compareProductInfo[c].value.length;
                                            cellChar = cellChar / 29;
                                            var cellHeight = ((cellChar <= 1) ? 1 : ((cellChar - Math.floor(cellChar) < 0.5) ? (Math.round(cellChar) + 1) : Math.round(cellChar))) * defaultHeight;
                                            if (this.customHeight[this.specTitles[j]] < cellHeight) {
                                                this.customHeight[this.specTitles[j]] = cellHeight;
                                                //  this.customHeights = Object.keys(this.customHeight).map(key => ({key,value: this.customHeight[key]}));

                                            }
                                            customCompare['value'] = this.customProductCompare[k].compareProductInfo[c].value;
                                            customCompare['value'] = customCompare['value'] ? customCompare['value']: "--" ;
                                        }
                                    } catch (error) {
                                        customCompare['value'] = "--";
                                        console.log("error:", error);
                                    }
                                }
                                customCompare['customHeight'] = cellHeight;
                            }
                    }
                    customCompareList.push(customCompare);
                }
                //here re-filter

                //console.log(customCompareList);
                if (this.customProductCompare[k].compareProductInfo)
                    this.customProductCompare[k].compareProductInfo = customCompareList;
                else
                    this.customProductCompare[k] = null;
                Object.preventExtensions(this.customProductCompare);
                Object.preventExtensions(this.customProductCompare[k]);

            }
            
            for (var j = 0; j < this.customProductCompare.length; j++) { 
                if (this.customProductCompare[j])
                    for (var i = 0; i < this.customProductCompare[j].compareProductInfo.length; i++) {
                        this.customProductCompare[j].compareProductInfo[i].customHeight = this.customHeight[this.customProductCompare[j].compareProductInfo[i].name];
                    }
            }
            
            for (var i = 0; i < this.specTitles.length; i++) {
                //this.specTitles[i] = {'key':this.specTitles[i],'value':}
                var obj = {
                    'value': ((curLocale == "de-de" || curLocale == "fr-fr" || curLocale == "pl-pl" || curLocale == "es-es" || curLocale == "it-it") ? 54 : this.customHeight[this.specTitles[i]]),
                    'key': this.specTitles[i]
                };
                //console.log("267 obj:", obj)
                // this.specTitles.push(obj);
                this.specTitles[i] = obj; // Object.keys(obj).map(key => ({key,value: obj[key]}));
            }
            
            this.productCompare = this.customProductCompare;
            console.log(" this.productCompare ", this.productCompare);
            this.saveSpecTitles();
        } else {
            this.retrieveSpecTitles();
        }

    }
    getScreenWidth() {
        if (window.matchMedia("(min-width: 1281px)").matches) {
            this.screenWidth = "XL";
        } else if (window.matchMedia("(min-width: 1025px)").matches) {
            this.screenWidth = "LG";
        } else if (window.matchMedia("(min-width: 768px)").matches) {
            this.screenWidth = "MD";
        } else if (window.matchMedia("(min-width: 463px)").matches) {
            this.screenWidth = "SM";
        } else {
            this.screenWidth = "XS";
        }
    }


    /*onOrientationChange() {
      listenForNotifyMe();
    }*/

    saveSpecTitles() {
        try {
            localStorage.setItem(this.sessionStorageSpecTitlesKey, JSON.stringify(this.specTitles));
        } catch (e) {}
    }

    retrieveSpecTitles() {
        let specTitles;
        try {
            specTitles = JSON.parse(localStorage.getItem(this.sessionStorageSpecTitlesKey)) as string[];
            if (specTitles) {
                this.specTitles = specTitles;
            }
        } catch (e) {}
    }

    ngAfterViewChecked() {
        if (!this.afterViewChecked && this.productCompare && this.productCompare.length) {
            //if(this.locale == 'en-us'){

            window['hatchwidgetHelpers'].reloadScript()
            //  }
            $('.js-product-item').removeData();
            setTimeout(function() {
                window['appSetupHelpers'].scanPID()
            }, 500);
            this.afterViewChecked = true;
        }
    }

    // ngDoCheck() {
    //   if(this.drId && this.drId.nativeElement.classList.contains('dr-loading')) {
    //     this.drLoading = true;
    //   }
    //   else {
    //     this.drLoading = false;
    //   }
    // }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    getFormPath(locale, displayName) {
        //  (locale == 'en-us')?locale='en-zz':'';
        var currentdomain = window.location.hostname;
        (currentdomain == 'localhost') ? currentdomain = 'www.nvidia.com': '';
        //var path = 'https://'+currentdomain+'/'+locale+'/geforce/graphics-cards/30-series/notify-me-form/';
        //console.log(path,"Formoath");
        if (locale == 'ru-ru') {
            var path = 'https://' + currentdomain + '/' + locale + '/consumer/graphics-cards/30-series/notify-me-form/';
        } else {
            var path = 'https://' + currentdomain + '//content/forms/af/nvidia-forms/products-notify-me/consumer-notify-me/?afAcceptLang=' + locale + '&productName=' + displayName;
        }
        return path;
    }

    removeProduct(compareItem) {
        this.store.dispatch(new productActions.RemoveComparedProduct(compareItem.productUPC));
        this.store.select(reducers.getProductCompare).subscribe(productCompare => {
            this.displaytableData();
        });
        //if(this.locale == 'en-us'){
        window['hatchwidgetHelpers'].reloadScript();
        window['appSetupHelpers'].scanPID()
        //  }
        $('.js-product-item').removeData();
      
    }

    getEANCode(productUPC) {
        //  console.log("GET EAN CODE", ("0"+productUPC));
        return (productUPC);
    }

    backToSearch() {
        window.scroll(0, 0);
        //this.location.back();
        this.router.navigate(['/' + this.locale + '/consumer/']);
        document.body.style.overflow = '';
    }

    showOverlay(productDetail) {
        if (this.locale !== "en-us" && this.locale !== "en-gb") {
            this.productDetail = productDetail;
            this.hatchOverlayDisplay = "none";
            this.winPageYOffset = window.pageYOffset;
            setTimeout(() => {
                this.hatchOverlayDisplay = "block";
            }, 10);
        }
        showOverlay(productDetail, this.locale, this.store, null);
    }

    transmitToCart() {
        var that = this;
        setTimeout(() => {
            $('.cart-main-section .cart__empty')[0] && ($('.cart-main-section .cart__empty')[0].innerHTML = that.translate.instant('empty_cart'));
            $('.cart-main-section .cart__title')[0] && ($('.cart-main-section .cart__title')[0].innerHTML = that.translate.instant('cart'));
            $('.cart-main-section .cart__items').each(function() {
                $(this).find('.cart_remove .cart-item__remove').each(function(i) {
                    $('.cart_remove .cart-item__remove')[i].innerHTML = that.translate.instant('remove');
                });
            });
            // $('.cart-main-section .cart__checkout-button')[0] && ($('.cart-main-section .cart__checkout-button')[0].innerHTML = that.translate.instant('checkout'));
        }, 100);
    }
}
import { Component, Input } from '@angular/core';


@Component({
  selector: 'dr-loading-indicator',
  template: `        
  <div *ngIf="show" class="dr-center-content">
    <div class="dr-swg-spinner-wrapper">
      <div class="dr-swg-spinner"></div>
    </div>
  </div>
  `,
  styles: [`
`]
})
export class DrLoadingIndicator {

  @Input() show: boolean = false;

  constructor() {
  }

  ngOnInit() {

  }
}

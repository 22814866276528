
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable()
export class FeService {
    constructor(private http: HttpClient) {}

  getProductInfo(queryParams, forceRefresh?: boolean): Observable<any> { 
    console.log("-----fe---service ---->--", queryParams);
 
    var resp= this.http.get('https://dev-api.nvidia.partners/zwxrnyowbw/product/search?page=1&limit=9&locale=en-us', queryParams).pipe(
    map(this.extractData));
       return resp;
  }

  private extractData(res: any){ 
    let body = res;  
    return body;
  }
}
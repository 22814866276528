import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";


@Component({
  selector: "range-slider",
  template: `
    <div class="range-slider-container">

    <div *ngIf="locale ==='ru-ru'"class="position-relative ru-range-slider">

        <input class="range-slider-input-ru 10" type="number" min="0" pattern="[0-9]*" [(ngModel)]="range[0]" (change)="onChanges(rangeSliderInput, range, $event)" name="val1" />
        <label *ngIf="rangeSliderInput.unitsOfMeasure" class="currency-symbol-ru 11" [innerHtml]="rangeSliderInput.unitsOfMeasure"></label>
      <!---
      <label *ngIf="rangeSliderInput.unitsOfMeasure !== '$' || rangeSliderInput.unitsOfMeasure === '£'" class="range-slider-input-label-right 13" [innerHtml]="rangeSliderInput.unitsOfMeasure"></label>
      --->
    </div>
      <div *ngIf="locale !== 'ru-ru'" class="position-relative ru-range-slider">
        <label *ngIf="rangeSliderInput.unitsOfMeasure" class="currency-symbol 17"><p [innerHTML]="rangeSliderInput.unitsOfMeasure"></p></label>
        <input class="range-slider-input 18" type="number" min="0" pattern="[0-9]*" [(ngModel)]="range[0]" (change)="onChanges(rangeSliderInput, range, $event)" name="val1" />
        <!---
        <label *ngIf="rangeSliderInput.unitsOfMeasure !== '$' || rangeSliderInput.unitsOfMeasure === '£'" class="range-slider-input-label-right 20" [innerHtml]="rangeSliderInput.unitsOfMeasure"></label>
        --->
      </div>
      <div *ngIf="locale ==='ru-ru'"class="position-relative ru-range-slider">

          <input class="range-slider-input-ru" type="number" min="0" pattern="[0-9]*" [(ngModel)]="range[1]" (change)="onChanges(rangeSliderInput, range, $event)" name="val1" />
          <label *ngIf="rangeSliderInput.unitsOfMeasure" class="currency-symbol-ru 26" [innerHtml]="rangeSliderInput.unitsOfMeasure"></label>
        <!---
        <label *ngIf="rangeSliderInput.unitsOfMeasure !== '$' || rangeSliderInput.unitsOfMeasure === '£'" class="range-slider-input-label-right 28" [innerHtml]="rangeSliderInput.unitsOfMeasure"></label>
        --->
      </div>


      <div *ngIf="locale !=='ru-ru'" class="position-relative ru-range-slider">
        <label *ngIf="rangeSliderInput.unitsOfMeasure" class="currency-symbol 34"><p [innerHTML]="rangeSliderInput.unitsOfMeasure"></p></label>
        <input class="range-slider-input" type="number" min="0" pattern="[0-9]*" [(ngModel)]="range[1]" (change)="onChanges(rangeSliderInput, range, $event)" name="val2" />
        <!---
        <label *ngIf="rangeSliderInput.unitsOfMeasure !== '$' || rangeSliderInput.unitsOfMeasure === '£'" class="range-slider-input-label-right 37" [innerHtml]="rangeSliderInput.unitsOfMeasure"></label>
        --->
      </div>
    </div>
    <div class="nouislider-container" *ngIf="screenWidth !== 'SM'">
      <nouislider [connect]="true"
        [step]="1"
        [min]="rangeSliderInput.defaultMinRangeVal"
        [max]="rangeSliderInput.defaultMaxRangeVal"
        [(ngModel)]="range"
        name="name1-{{rangeSliderInput.minRangeVal}}"
        (change)="onChanges(rangeSliderInput, range, $event)">
      </nouislider>
    </div>
  `,
  styles: [``]
})
export class RangeSliderComponent implements OnInit {
  @Input()
  rangeSliderInput;
  @Input()
  screenWidth: string;
  @Output()
  filterRangeInputChange = new EventEmitter();
  range: number[];
  locale: string;
  constructor() {}

  ngOnInit() {
  let locale = window.location.pathname.split('/')[1];
    this.locale = locale;

    this.range = [
      this.rangeSliderInput.selectedMinRangeVal,
      this.rangeSliderInput.selectedMaxRangeVal
    ];
  }

  onChanges(type, range, $event) {
    let min = ~~range[0];
    let max = ~~range[1];
    let defMin = this.rangeSliderInput.defaultMinRangeVal;
    let defMax = this.rangeSliderInput.defaultMaxRangeVal;

    if (min < defMin || min > max) {
      min = defMin;
    }
    if (max > defMax || max < min) {
      max = defMax;
    }
    if (min === max) {
      if (min > defMin) {
        min--;
      } else {
        max++;
      }
    }
    range[0] = min;
    range[1] = max;
    this.range = [min, max];
    this.filterRangeInputChange.emit({ type, range, $event });
  }
}
